
import { getCanonicalRequest, getSigningKey, getStringToSign } from './signRequest';

import { Sha256 } from '@aws-crypto/sha256-js';
import { toHex } from '@smithy/util-hex-encoding';

/**
 * Calculates and returns an AWS API Signature.
 * https://docs.aws.amazon.com/IAM/latest/UserGuide/create-signed-request.html
 *
 * @param request `HttpRequest` to be signed.
 * @param signRequestOptions `SignRequestOptions` object containing values used to construct the signature.
 * @returns AWS API Signature to sign a request or url with.
 *
 * @internal
 */


// TODO: V6 update to different crypto dependency?


/**
 * Returns the hashed data a `Uint8Array`.
 *
 * @param key `SourceData` to be used as hashing key.
 * @param data Hashable `SourceData`.
 * @returns `Uint8Array` created from the data as input to a hash function.
 */
export const getHashedData = (
	key,
	data,
) => {
	const sha256 = new Sha256(key ?? undefined);
	sha256.update(data);
	// TODO: V6 flip to async digest
	const hashedData = sha256.digestSync();

	return hashedData;
};

/**
 * Returns the hashed data as a hex string.
 *
 * @param key `SourceData` to be used as hashing key.
 * @param data Hashable `SourceData`.
 * @returns String using lowercase hexadecimal characters created from the data as input to a hash function.
 *
 * @internal
 */
export const getHashedDataAsHex = (
	key,
	data,
) => {
	const hashedData = getHashedData(key, data);

	return toHex(hashedData);
};


export const getSignature = (
	request,
    {
		credentialScope,
		longDate,
		secretAccessKey,
		shortDate,
		signingRegion,
		signingService,
		uriEscapePath,
	}
) => {
	// step 1: create a canonical request
	const canonicalRequest = getCanonicalRequest(request, uriEscapePath);

	// step 2: create a hash of the canonical request
	const hashedRequest = getHashedDataAsHex(null, canonicalRequest);

	// step 3: create a string to sign
	const stringToSign = getStringToSign(
		longDate,
		credentialScope,
		hashedRequest,
	);

	// step 4: calculate the signature
	const signature = getHashedDataAsHex(
		getSigningKey(secretAccessKey, shortDate, signingRegion, signingService),
		stringToSign,
	);

	return signature;
};