export const schema = {
    "models": {
        "Question": {
            "name": "Question",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "correctAnswers": {
                    "name": "correctAnswers",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "correctAnswer": {
                    "name": "correctAnswer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "incorrectAnswers": {
                    "name": "incorrectAnswers",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Questions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserQuestionAnswer": {
            "name": "UserQuestionAnswer",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "topic": {
                    "name": "topic",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "question": {
                    "name": "question",
                    "isArray": false,
                    "type": {
                        "model": "Question"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "userQuestionAnswerQuestionId"
                        ]
                    }
                },
                "chosenAnswers": {
                    "name": "chosenAnswers",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "wasCorrect": {
                    "name": "wasCorrect",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "timeToAnswer": {
                    "name": "timeToAnswer",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "points": {
                    "name": "points",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "userQuestionAnswerQuestionId": {
                    "name": "userQuestionAnswerQuestionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserQuestionAnswers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "userId",
                            "topic",
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserSurvey": {
            "name": "UserSurvey",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requestChannel": {
                    "name": "requestChannel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requestTopic": {
                    "name": "requestTopic",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "completed": {
                    "name": "completed",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "UserSurveys",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "userId",
                            "requestChannel",
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Fact": {
            "name": "Fact",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "topic": {
                    "name": "topic",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "interactions": {
                    "name": "interactions",
                    "isArray": true,
                    "type": {
                        "model": "UserFactInteraction"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "factInteractionsDate",
                            "factInteractionsTopic",
                            "factInteractionsId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Facts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "date",
                            "topic",
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserFactInteraction": {
            "name": "UserFactInteraction",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fact": {
                    "name": "fact",
                    "isArray": false,
                    "type": {
                        "model": "Fact"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "factInteractionsDate",
                            "factInteractionsTopic",
                            "factInteractionsId"
                        ]
                    }
                },
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "factInteractionsDate": {
                    "name": "factInteractionsDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "factInteractionsTopic": {
                    "name": "factInteractionsTopic",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "factInteractionsId": {
                    "name": "factInteractionsId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserFactInteractions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "userId",
                            "id",
                            "action"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "WebhookEvents": {
            "name": "WebhookEvents",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "_ttl": {
                    "name": "_ttl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "WebhookEvents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                }
            ]
        }
    },
    "enums": {},
    "nonModels": {
        "Search": {
            "name": "Search",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userAskedIn": {
                    "name": "userAskedIn",
                    "isArray": false,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ASKED": {
                    "name": "ASKED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Asked"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Tag": {
            "name": "Tag",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "streamTaggedsOut": {
                    "name": "streamTaggedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "TAGGED": {
                    "name": "TAGGED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Tagged"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Tagged": {
            "name": "Tagged",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Stream": {
            "name": "Stream",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "latestVersion": {
                    "name": "latestVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "topicIncludesOut": {
                    "name": "topicIncludesOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userFollowingsIn": {
                    "name": "userFollowingsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "questionsetMadesOut": {
                    "name": "questionsetMadesOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionset"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "runSpawnedsOut": {
                    "name": "runSpawnedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Run"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userRankedsOut": {
                    "name": "userRankedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userRequestedsIn": {
                    "name": "userRequestedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userViewedsIn": {
                    "name": "userViewedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userOpenedsIn": {
                    "name": "userOpenedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userStartedsIn": {
                    "name": "userStartedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userCompletedsIn": {
                    "name": "userCompletedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "tagTaggedsIn": {
                    "name": "tagTaggedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Tag"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "RANKED": {
                    "name": "RANKED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Ranked"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MADE": {
                    "name": "MADE",
                    "isArray": false,
                    "type": {
                        "nonModel": "Made"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "INCLUDES": {
                    "name": "INCLUDES",
                    "isArray": false,
                    "type": {
                        "nonModel": "Includes"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FOLLOWING": {
                    "name": "FOLLOWING",
                    "isArray": false,
                    "type": {
                        "nonModel": "Following"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SPAWNED": {
                    "name": "SPAWNED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Spawned"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "REQUESTED": {
                    "name": "REQUESTED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Requested"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "VIEWED": {
                    "name": "VIEWED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Viewed"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OPENED": {
                    "name": "OPENED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Opened"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "STARTED": {
                    "name": "STARTED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Started"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "COMPLETED": {
                    "name": "COMPLETED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Completed"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TAGGED": {
                    "name": "TAGGED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Tagged"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Topic": {
            "name": "Topic",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "difficulty": {
                    "name": "difficulty",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userRankedsOut": {
                    "name": "userRankedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userEngagedsIn": {
                    "name": "userEngagedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userSkippedsIn": {
                    "name": "userSkippedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topicSubsOut": {
                    "name": "topicSubsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topicSubsIn": {
                    "name": "topicSubsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userSelectedsIn": {
                    "name": "userSelectedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "questionnodeInsIn": {
                    "name": "questionnodeInsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamIncludesIn": {
                    "name": "streamIncludesIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "unansweredQuestions": {
                    "name": "unansweredQuestions",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "ENGAGED": {
                    "name": "ENGAGED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Engaged"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SKIPPED": {
                    "name": "SKIPPED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Skipped"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SUB": {
                    "name": "SUB",
                    "isArray": false,
                    "type": {
                        "nonModel": "Sub"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SELECTED": {
                    "name": "SELECTED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Selected"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "INCLUDES": {
                    "name": "INCLUDES",
                    "isArray": false,
                    "type": {
                        "nonModel": "Includes"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "User": {
            "name": "User",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "user_id": {
                    "name": "user_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "streamRankedsIn": {
                    "name": "streamRankedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topicRankedsIn": {
                    "name": "topicRankedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamFollowingsOut": {
                    "name": "streamFollowingsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamRequestedsOut": {
                    "name": "streamRequestedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamViewedsOut": {
                    "name": "streamViewedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamOpenedsOut": {
                    "name": "streamOpenedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamStartedsOut": {
                    "name": "streamStartedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamCompletedsOut": {
                    "name": "streamCompletedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "playStartedsOut": {
                    "name": "playStartedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Play"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topicEngagedsOut": {
                    "name": "topicEngagedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topicSkippedsOut": {
                    "name": "topicSkippedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topicSelectedsOut": {
                    "name": "topicSelectedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "answerGavesOut": {
                    "name": "answerGavesOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Answer"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "questionnodeAskedsOut": {
                    "name": "questionnodeAskedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "questionnodeAnsweredsOut": {
                    "name": "questionnodeAnsweredsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "searchAskedsOut": {
                    "name": "searchAskedsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Search"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "RANKED": {
                    "name": "RANKED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Ranked"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ENGAGED": {
                    "name": "ENGAGED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Engaged"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SKIPPED": {
                    "name": "SKIPPED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Skipped"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ANSWERED": {
                    "name": "ANSWERED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Answered"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ASKED": {
                    "name": "ASKED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Asked"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "STARTED": {
                    "name": "STARTED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Started"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "GAVE": {
                    "name": "GAVE",
                    "isArray": false,
                    "type": {
                        "nonModel": "Gave"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SELECTED": {
                    "name": "SELECTED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Selected"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FOLLOWING": {
                    "name": "FOLLOWING",
                    "isArray": false,
                    "type": {
                        "nonModel": "Following"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "REQUESTED": {
                    "name": "REQUESTED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Requested"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "VIEWED": {
                    "name": "VIEWED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Viewed"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OPENED": {
                    "name": "OPENED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Opened"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Ranked": {
            "name": "Ranked",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "elo": {
                    "name": "elo",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Completed": {
            "name": "Completed",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "occuredAt": {
                    "name": "occuredAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Viewed": {
            "name": "Viewed",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "occuredAt": {
                    "name": "occuredAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Opened": {
            "name": "Opened",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "occuredAt": {
                    "name": "occuredAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Requested": {
            "name": "Requested",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "occuredAt": {
                    "name": "occuredAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Following": {
            "name": "Following",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Questionnode": {
            "name": "Questionnode",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fact": {
                    "name": "fact",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "correctAnswer": {
                    "name": "correctAnswer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "incorrectAnswers": {
                    "name": "incorrectAnswers",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "requestedTopic": {
                    "name": "requestedTopic",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userAnsweredsIn": {
                    "name": "userAnsweredsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userAskedsIn": {
                    "name": "userAskedsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "questionsetContainsIn": {
                    "name": "questionsetContainsIn",
                    "isArray": false,
                    "type": {
                        "nonModel": "Questionset"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "runMadeIn": {
                    "name": "runMadeIn",
                    "isArray": false,
                    "type": {
                        "nonModel": "Run"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "answerTosIn": {
                    "name": "answerTosIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Answer"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topicInOut": {
                    "name": "topicInOut",
                    "isArray": false,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "rulesPassedIn": {
                    "name": "rulesPassedIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Rule"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "rulesFailedIn": {
                    "name": "rulesFailedIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Rule"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "ANSWERED": {
                    "name": "ANSWERED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Answered"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ASKED": {
                    "name": "ASKED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Asked"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "CONTAINS": {
                    "name": "CONTAINS",
                    "isArray": false,
                    "type": {
                        "nonModel": "Contains"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TO": {
                    "name": "TO",
                    "isArray": false,
                    "type": {
                        "nonModel": "To"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "IN": {
                    "name": "IN",
                    "isArray": false,
                    "type": {
                        "nonModel": "In"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "PASSED": {
                    "name": "PASSED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Passed"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FAILED": {
                    "name": "FAILED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Failed"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MADE": {
                    "name": "MADE",
                    "isArray": false,
                    "type": {
                        "nonModel": "Made"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Rule": {
            "name": "Rule",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PASSED": {
                    "name": "PASSED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Passed"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FAILED": {
                    "name": "FAILED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Failed"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Passed": {
            "name": "Passed",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Failed": {
            "name": "Failed",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Run": {
            "name": "Run",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionnodeMadesOut": {
                    "name": "questionnodeMadesOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamSpawnedIn": {
                    "name": "streamSpawnedIn",
                    "isArray": false,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MADE": {
                    "name": "MADE",
                    "isArray": false,
                    "type": {
                        "nonModel": "Made"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SPAWNED": {
                    "name": "SPAWNED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Spawned"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Spawned": {
            "name": "Spawned",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TimedSession": {
            "name": "TimedSession",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionnodeContainssOut": {
                    "name": "questionnodeContainssOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "Questionset": {
            "name": "Questionset",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "published_date": {
                    "name": "published_date",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "questionnodeContainssOut": {
                    "name": "questionnodeContainssOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "playOfsIn": {
                    "name": "playOfsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Play"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "streamMadeIn": {
                    "name": "streamMadeIn",
                    "isArray": false,
                    "type": {
                        "nonModel": "Stream"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "CONTAINS": {
                    "name": "CONTAINS",
                    "isArray": false,
                    "type": {
                        "nonModel": "Contains"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OF": {
                    "name": "OF",
                    "isArray": false,
                    "type": {
                        "nonModel": "Of"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "IN": {
                    "name": "IN",
                    "isArray": false,
                    "type": {
                        "nonModel": "In"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Answer": {
            "name": "Answer",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "created_at": {
                    "name": "created_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "answer_id": {
                    "name": "answer_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "was_correct": {
                    "name": "was_correct",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "questionnodeToOut": {
                    "name": "questionnodeToOut",
                    "isArray": false,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "userGaveIn": {
                    "name": "userGaveIn",
                    "isArray": false,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "playDuringsOut": {
                    "name": "playDuringsOut",
                    "isArray": true,
                    "type": {
                        "nonModel": "Play"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "TO": {
                    "name": "TO",
                    "isArray": false,
                    "type": {
                        "nonModel": "To"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "GAVE": {
                    "name": "GAVE",
                    "isArray": false,
                    "type": {
                        "nonModel": "Gave"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "DURING": {
                    "name": "DURING",
                    "isArray": false,
                    "type": {
                        "nonModel": "During"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Play": {
            "name": "Play",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "gameMode": {
                    "name": "gameMode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userStartedIn": {
                    "name": "userStartedIn",
                    "isArray": false,
                    "type": {
                        "nonModel": "User"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "start_time": {
                    "name": "start_time",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "finished": {
                    "name": "finished",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "correct": {
                    "name": "correct",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "incorrect": {
                    "name": "incorrect",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "rounds": {
                    "name": "rounds",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "score": {
                    "name": "score",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "speedScore": {
                    "name": "speedScore",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "dayInt": {
                    "name": "dayInt",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "weekInt": {
                    "name": "weekInt",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "monthInt": {
                    "name": "monthInt",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "yearInt": {
                    "name": "yearInt",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "questionsetOfOut": {
                    "name": "questionsetOfOut",
                    "isArray": false,
                    "type": {
                        "nonModel": "Questionset"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "answerDuringsIn": {
                    "name": "answerDuringsIn",
                    "isArray": true,
                    "type": {
                        "nonModel": "Answer"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "STARTED": {
                    "name": "STARTED",
                    "isArray": false,
                    "type": {
                        "nonModel": "Started"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OF": {
                    "name": "OF",
                    "isArray": false,
                    "type": {
                        "nonModel": "Of"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "DURING": {
                    "name": "DURING",
                    "isArray": false,
                    "type": {
                        "nonModel": "During"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Engaged": {
            "name": "Engaged",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Made": {
            "name": "Made",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Skipped": {
            "name": "Skipped",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Answered": {
            "name": "Answered",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "was_correct": {
                    "name": "was_correct",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "answer_id": {
                    "name": "answer_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "created_at": {
                    "name": "created_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Asked": {
            "name": "Asked",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "created_at": {
                    "name": "created_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Contains": {
            "name": "Contains",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Sub": {
            "name": "Sub",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Started": {
            "name": "Started",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "occuredAt": {
                    "name": "occuredAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "To": {
            "name": "To",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Gave": {
            "name": "Gave",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Of": {
            "name": "Of",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Selected": {
            "name": "Selected",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "created_at": {
                    "name": "created_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Includes": {
            "name": "Includes",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "In": {
            "name": "In",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "During": {
            "name": "During",
            "fields": {
                "_id": {
                    "name": "_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Score": {
            "name": "Score",
            "fields": {
                "user_id": {
                    "name": "user_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "score": {
                    "name": "score",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TopicQuestions": {
            "name": "TopicQuestions",
            "fields": {
                "questions": {
                    "name": "questions",
                    "isArray": true,
                    "type": {
                        "nonModel": "Questionnode"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "topic": {
                    "name": "topic",
                    "isArray": false,
                    "type": {
                        "nonModel": "Topic"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "90cdb4f8c4b50db247debb4f49ffdd43"
};