import React, { useState, useEffect } from "react";

import {
    client,
  } from "../../api/questions";

import {StaticCircleLoadingIndicator} from "../CircleLoadingIndicator"
import { convertToForceWindows, starMap, calculateAccelerationAtTime } from "./index";




export const ReviewBar = (props) => {

    const [questionsForReview, setQuestionsForReview] = useState([])
    // const [forceWindows, setForceWindows] = useState([])


    useEffect(() => {
        // const fetchForceWindows = async () => {

        //     const pastUserFinishes = await client.plays.pastDailiesSummaries()

        //     const hourlyPoints = {};
        //     const hourlyStars = {};

        //     pastUserFinishes?.map((finish) => {
        //         // finish.start_time is a string like "2021-09-01T00:00:00.000Z"
        //         const startTime = new Date(finish.start_time);
        //         const localHourString = startTime.toLocaleString('sv-SE', {
        //         year: 'numeric',
        //         month: '2-digit',
        //         day: '2-digit',
        //         hour: '2-digit',
        //         timeZoneName: 'short'
        //         }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"

        //         if (!hourlyPoints[localHourString]) {
        //         hourlyPoints[localHourString] = 0;
        //         hourlyStars[localHourString] = 0;
        //         }

        //         hourlyStars[localHourString] += starMap(finish.score);
        //         hourlyPoints[localHourString] += finish.score;
        //     });

        //     const hourlyStarsSorted = Object.keys(hourlyStars).sort((a, b) => {
        //         const aDate = new Date(a.replace(' GMT−7', ':00:00-07:00'))
        //         const bDate = new Date(b.replace(' GMT−7', ':00:00-07:00'))
        //         const diff = bDate - aDate
        //         return diff
        //       })

        //     const hourlyStarsSorted2 = hourlyStarsSorted.map((date) => {
        //         return { date, score: hourlyStars[date] }
        //       })
        
        //       // add an entry for the current hour if it doesn't exist
        //       const now = new Date();
        //       const currentHourString = now.toLocaleString('sv-SE', {
        //         year: 'numeric',
        //         month: '2-digit',
        //         day: '2-digit',
        //         hour: '2-digit',
        //         timeZoneName: 'short'
        //       }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"
        //       if (!hourlyStarsSorted2.find((entry) => entry.date === currentHourString)) {
        //         hourlyStarsSorted2.unshift({ date: currentHourString, score: 0 });
        //       }
        //       console.log(currentHourString)
        //       console.log(hourlyStarsSorted2)

        //     const scoresAsForces = convertToForceWindows(hourlyStarsSorted2)
        //     setForceWindows(scoresAsForces)
        // }
        const fetchQuestionToReview = async () => {
          const questions = await client.questions.questionsToReview()
          setQuestionsForReview(questions)
        }
        fetchQuestionToReview()
        // fetchForceWindows()
      }, [props.pastQAResults]);


    //   const timeInSeconds = (new Date().getTime() / 1000).toFixed(0);
    //   const currentPosition = calculatePositionAtTime(scoresAsForces, timeInSeconds);
    //   const currentSpeed = calculateSpeedAtTime(scoresAsForces, timeInSeconds);
    //   const { activeScore, currentAcceleration } = calculateAccelerationAtTime(forceWindows, timeInSeconds);



    


    return (
        <div className="relative h-full ">
            <StaticCircleLoadingIndicator litSegments={questionsForReview.length} maximum={questionsForReview.length} themeColor={"purple"}/>
            <div className="flex justify-center items-center absolute 
          bg-slate-900
          w-full h-full top-0 right-0 font-logo text-center text-base hover:scale-95 active:scale-100 transition-all 
          text-purple-300 font-bold dark:text-purple-300 font-bold leading-8 p-1 w-11 h-11 rounded-full">
           {questionsForReview.length}
          </div>
        </div>

    )
}