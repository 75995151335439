import { jsonrepair } from "jsonrepair";
// import { Auth, Signer } from "aws-amplify";
import { fetchUserAttributes, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { fetchWithRetry } from "../util/fetch";
const DEFAULT_PARAMS = {
  model: "gpt-4",
  // "model": "gpt-3.5-turbo",
  temperature: 1, // timesout above 1.3
  // "max_tokens": 256,
  // "top_p": 1,
  // "frequency_penalty": 0,
  // "presence_penalty": 0
};

const prompt = (content, filter) => {
  const PROMPTS = {
    summary:
      "generate a one-sentence summary, no more than 15 words, of the following message: \n\n",
    critic:
      "generate a mocking summary, no more than 15 words, of the following message: \n\n",
    reddit: "generate a reddit-style title for the following message: \n\n",
    praise:
      "generate a praiseful title, no more than 15 words, for the following message: \n\n",
  };

  const PROMPTS_END = {
    summary: "\n\nSummary:",
    critic: "\n\nSummary: ",
    reddit: "\n\nTitle: ",
    praise: "\n\nTitle: ",
  };

  return PROMPTS[filter] + content + PROMPTS_END[filter];
};

async function autoCorrectWithContext(word, context) {
  if (context.length === 0) {
    return complete(`
    What is the correct spelling of the word "${word}"?. Please return only the word, no commentary, and in the original capitalization.
    `);
  } else {
    const contextWords = context.join(" ");
    return complete(`
    Return the correct spelling of "${word}"? It is found in this context: "${contextWords} ${word}"? 
    Please return only the word with no additional commentary.
    Do not make any changes to capitalization.
    `);
  }
}

async function autoCorrect(content) {
  return complete(
    `
    What is the correct spelling of the following word? Please return only the word, no commentary, and in the same capitalization. 
    If there are multiple correct spellings, return the most common one.
    If you can't find a correct spelling, then return the input unchanged. 
    If the word is spelled correctly, return the input unchanged, with no commentary, and no other words:\n\n` +
      content,
  );
}

async function autoCorrectBody(content) {
  const corrected = await complete(
    `Return the input with spelling corrected:` + content,
  );
  return corrected.replace(/^"(.+(?="$))"$/, "$1");
}

async function query(system, history) {
  const messages = [
    { role: "system", content: system },
    { role: "user", content: history },
  ];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages } };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  const data = await response.json();
  // return data.choices[0].text;
  return data.choices[0].message.content;
}

// yes
export async function queryWFunctions(system, history, functions) {
  const messages = [
    { role: "system", content: system },
    { role: "user", content: history },
  ];
  const function_name = functions[0].name;
  const function_call = { name: function_name };
  const params_ = { ...DEFAULT_PARAMS, messages, functions, function_call };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer sk-proj-IomUX5sebK0ULvEEYlRWT3BlbkFJ1HNt5BCXvopg8mjd6WOT",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  const data = await response.json();
  // return data.choices[0].text;
  return data.choices[0].message.function_call;
}

async function getNextTopics(prompt) {
  const messages = [
    {
      role: "system",
      content:
        "You are a helpful and intelligent assistant that creates exam topics, following the instructions precisely.",
    },
    { role: "user", content: prompt },
  ];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages } };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  const data = await response.json();
  // return data.choices[0].text;
  const ret = data.choices[0].message.content;
  return ret;
}

async function completeWithSystem(prompt, content) {
  const messages = [
    {
      role: "system",
      content:
        "You are an educated and intelligent assistant that creates in-depth and nuanced exam questions. Reach deep into your knowledge to create verbose questions that require a deep understanding to answer.",
    },
    { role: "user", content: prompt + "\n" + content },
  ];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages } };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  const data = await response.json();
  // return data.choices[0].text;
  const ret = data.choices[0].message.content;
  return ret;
}

async function getExplanationStream(prompt) {
  const messages = [
    {
      role: "system",
      content:
        "You are a tutor with personality that explains exam questions in 1-3 sentences. If explanation is not necessary, add additional information on the subject ",
    },
    { role: "user", content: prompt },
  ];
  // entertataning, succint, depressing, sassy
  const params_ = { ...DEFAULT_PARAMS, ...{ messages }, stream: true };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );

  // eslint-disable-next-line no-undef
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

  return reader;
}

// yes
export async function completeWithSystemStream(prompt, content) {
  const messages = [
    {
      role: "system",
      content:
        "You are an educated and intelligent assistant that creates in-depth and nuanced exam questions. Reach deep into your knowledge to create verbose questions that require a deep understanding to answer.",
    },
    { role: "user", content: prompt + "\n" + content },
  ];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages }, stream: true };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );

  // eslint-disable-next-line no-undef
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

  return reader;
}

async function completeWithSystem2(prompt, content) {
  const messages = [
    {
      role: "system",
      content:
        "You categorize questions into relevant domains. Be specific as possible and only use the domains provided.",
    },
    { role: "user", content: prompt + "\n" + content },
  ];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages } };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  const data = await response.json();
  // return data.choices[0].text;
  const ret = data.choices[0].message.content;
  return ret;
}

async function completeWithSystem2Stream(prompt, content) {
  const messages = [
    {
      role: "system",
      content:
        "You categorize questions into relevant domains. Be specific as possible and only use the domains provided.",
    },
    { role: "user", content: prompt + "\n" + content },
  ];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages }, stream: true };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  // eslint-disable-next-line no-undef
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

  return reader;
}

// export async function completeWithSystemAndHistory(prompt, content, chatHistory) {
//     const messages = [{"role": "system", "content": prompt}, ...chatHistory, {"role": "user", "content": content}]
//     const params_ = { ...DEFAULT_PARAMS, ...{messages} };
//     const requestOptions = {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer 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'
//         },
//         body: JSON.stringify(params_)
//     };

//     const response = await fetchWithRetry('https://api.openai.com/v1/chat/completions', requestOptions);
//     const data = await response.json();
//     // return data.choices[0].text;
//     return data.choices[0].message.content
// }
async function complete(prompt) {
  const messages = [{ role: "user", content: prompt }];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages } };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  const data = await response.json();
  // return data.choices[0].text;
  return data.choices[0].message.content;
}

const completionCall = async (content) => {
  const messages = [{ role: "user", content: content }];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages } };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );
  const data = await response.json();
  return data.choices[0].message.content;
};

const generateTitle = async (style, message) => {
  if (style === "raw") {
    return message;
  }
  const content = prompt(message, style);
  const resp = await completionCall(content);
  return resp.replace(/^"(.+(?="$))"$/, "$1");
};

const getEmbedding = async (input) => {
  const params_ = { model: "text-embedding-ada-002", input };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/embeddings",
    requestOptions,
  );
  const data = await response.json();
  return data.data[0].embedding;
};

const extractContent = (value) => {
  const dt = value.split("data: ");

  let newContent = "";
  for (let i = 0; i < dt.length; i++) {
    try {
      if (dt[i] != "" && !dt[i].includes("[DONE]")) {
        let parsed = JSON.parse(dt[i]);
        if (parsed.choices[0].delta.content !== undefined) {
          newContent += parsed.choices[0].delta.content;
        }
      }
    } catch (e) {
      console.warn("failed to parse. should try hailmary", e);
      // if (failedItems.length === 1) {
      //     let hailmary = failedItems[0] + dt[i]
      //     console.log('trying to stitch broken data rows together. hailmary: ', failedItems[0], dt[i])

      //     try {
      //         let parsed = JSON.parse(hailmary);
      //         newContent += parsed.choices[0].delta.content;
      //     } catch (e) {
      //         console.log('still failed')
      //     }
      // }
      // failedItems.push(dt[i]);
    }
  }
  return newContent;
};

const extractFunction = (value) => {
  const dt = value.split("data: ");

  let newContent = "";
  for (let i = 0; i < dt.length; i++) {
    try {
      if (dt[i] !== "" && dt[i] !== "[DONE]\n\n") {
        let parsed = JSON.parse(dt[i]);

        if (parsed.choices[0].delta.function_call !== undefined) {
          newContent += parsed.choices[0].delta.function_call.arguments;
        }
      }
    } catch (e) {
      console.warn("failed to parse. should try hailmary", e);
      // if (failedItems.length === 1) {
      //     let hailmary = failedItems[0] + dt[i]
      //     console.log('trying to stitch broken data rows together. hailmary: ', failedItems[0], dt[i])

      //     try {
      //         let parsed = JSON.parse(hailmary);
      //         newContent += parsed.choices[0].delta.content;
      //     } catch (e) {
      //         console.log('still failed')
      //     }
      // }
      // failedItems.push(dt[i]);
    }
  }
  return newContent;
};

const extractExplanationFields = (row) => {
  return { explanation: row };
};

const extractQuestionFields = (row) => {
  let { type, text, correctAnswer, incorrectAnswers } = row;
  // const correctAnswers = correctAnswer ? [correctAnswer] : undefined

  // sometimes GPT sends Answer instead of correctAnswer
  if (correctAnswer === undefined) {
    let { Answer } = row;
    if (Answer !== undefined) {
      correctAnswer = Answer;
    }
  }

  // if correctAnswer has any of  a), b)... or a. b. at the beginning, then strip it out
  const regex = /^[a-dA-d][\)\.]/;
  if (correctAnswer?.match(regex)) {
    correctAnswer = correctAnswer.replace(regex, "").trim();
  }

  // if incorrectAnswers has any of  a), b)... or a. b. at the beginning, then strip it out
  incorrectAnswers = incorrectAnswers?.map((i) => {
    if (i && i.match(regex)) {
      i = i.replace(regex, "").trim();
    }
    return i;
  });

  const fields = { type, text, correctAnswer, incorrectAnswers };

  const filteredFields = Object.keys(fields)
    .filter((key) => fields[key] !== undefined)
    .reduce((obj, key) => {
      obj[key] = fields[key];
      return obj;
    }, {});

  return filteredFields;
};

const processChunk = (respData, extractor, final) => {
  let mergedFields = {};
  // remove any newlines and/or extra brackets
  let respDataProcessed = final
    ? respData
    : respData.replace(/\n/g, "").replace(/^[^{}]*(?=\{)/, "");

  // make sure we still have something after that
  if (respDataProcessed !== "") {
    // try to repair the json
    try {
      respDataProcessed = jsonrepair(respDataProcessed);
    } catch (e) {
      return mergedFields;
    }

    // if the first and last character are not "[" and "]", then add them
    if (respDataProcessed[0] !== "[")
      respDataProcessed = "[" + respDataProcessed + "]";

    // make sure we have a match after repair
    if (respDataProcessed !== undefined) {
      try {
        // try to parse the json
        respDataProcessed = JSON.parse(respDataProcessed);
      } catch (e) {
        console.warn("failed to parse", e, respDataProcessed);

        // drop everything after the last curly brace
        respDataProcessed = respDataProcessed.replace(
          /([\s\S]*\})[\s\S]*/,
          "$1",
        );
        // and re-add the square bracket
        respDataProcessed = respDataProcessed + "]";
        try {
          respDataProcessed = JSON.parse(respDataProcessed);
        } catch (e) {
          console.warn("failed to parse again", e, respDataProcessed);
          return {};
        }
      }

      // make sure we still have a match after parsing
      if (respDataProcessed !== undefined) {
        // ensure we have an array
        if (!Array.isArray(respDataProcessed))
          respDataProcessed = [respDataProcessed];

        // process each match
        respDataProcessed.forEach((m) => {
          const fields = extractor(m);

          // merge fields with mergedFields
          mergedFields = { ...mergedFields, ...fields };
        });
      }
    }
  }
  return mergedFields;
};

const questions = [];

const questionPromptV1 = `
# You
You make questions for an online learning game. Your goal is to teach but also to entertain. If the questions are boring then the users will not want to play the game.
# Instructions
Generate a specific and detailed fact that only a trained expert on the provided topic would know. 
The fact should be as specific as possible, without unnecessary words or phrases. Include enough detail to make relations and cause and effect clear.
Make each fact as distinct from the others as possible. Do not repeat the same format or structure. 

Then convert that fact into a Multiple Choice exam problem, being sure to maintain the specificity of the fact and following the guidelines below.
- makes_sense: The question must make sense
- readable: It must be easy to read
- objective: It must have a single, unambiguous answer. The question should not be open-ended. Short answers should be all that is necessary
- short_answer: The answer to the question should be a short answer, no more than a few words
- single_clause: The answer to the question should be a single statement, without commas or conjunctions. no "and", "or", "but", etc
- specific: The question should have only one or a few correct answer. Nothing like "Name a true fact about X" or "How is X described"
- not_asking_definition: The question should not provide a keyword and ask for its definition, explanation, description, etc
- not_generic: The question must not follow a generic format like "What is a true statement about X" or "Can you describe" or "what is a definition of" or any variants thereof
- interesting: The question should be specific and mysterious enough to make the user want to answer it 
- precise: It must use correct prepositions
- short: The question should use less words. Avoid unncecessary filler words. Do not say "What is the name of" or "what is the term for"
- direct: The question should not ask the user if they can answer a question. Just ask the question. Don't include "can you", "how would you", etc
- no_intro: The question should not introduce the question with the field/topic/concept that it is related to. Do not qualify the question with a "in <topic>" clausae
- no_statement: The question should not lead with a statement
- no_negative: The question should not use the word NOT or any prefix that means NOT. If it does, fix the question by removing that word or prefix
- no_answer_references: It must not include 'of the following' or any variants thereof
`;
const exampleQuestion = `[
    {"fact": "Paris is the capital city of France"},
    {"type": "multipleChoice"},
    {"text": "What is the capital of France?"},
    {"correctAnswer": "Paris"},
    {"incorrectAnswers": ["London", "New York", "Berlin"]}
]`;
const formatPromptV1 = `
# Format
Return the response in json format. 
Do not provide any text outside of the json.
Do not provide any keys other than the ones specified.
Be sure that the json is correctly formatted, with the correct number of commas, brackets, and quotes. 
It should match this format: 
${exampleQuestion}
`;
const questionSystemPromptV1 = questionPromptV1 + formatPromptV1;

export const makeQuestion = async (index, gpt4, topic, onFieldsReady) => {
  let url = "";

  // let url = "gpt-3.5-turbo"
  let model = "gpt-4";
  let loggedIn = false;
  let user;
  try {
    user = await getCurrentUser()
  } catch (e) {
    console.error("logged in users only", e)
    return
  }
  if (user) {
    url = process.env.REACT_APP_REGISTERED_GPT_API;
    loggedIn = true;
  } else {
    url = process.env.REACT_APP_GUEST_GPT_API;
  }

  let pastQuestions = [];
  let pastMessages = [];
  for (let q of pastQuestions) {
    pastMessages.push({
      role: "assistant",
      content: JSON.stringify(q),
    });
    pastMessages.push({
      role: "user",
      content: `Thank you, give me another question on the topic of ${topic}, this one different from the previous ones. `,
    });
  }

  let messages = [
    {
      role: "system",
      content: questionSystemPromptV1,
    },
    {
      role: "user",
      content: topic,
    },
  ].concat(pastMessages);

  let request = {
    url,
    data: JSON.stringify({ model, messages }), // for signed request
    body: JSON.stringify({ model, messages }), // for unsigned
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (loggedIn) {
    const session = await fetchAuthSession()
    request.headers.Authorization = `Bearer ${session?.tokens?.idToken}`;
  } else {
    // const creds = essentialCredentials(
    //   await currentCredentials(),
    // );
    const creds = await fetchAuthSession()
    // const credentials = {
    //   secret_key: creds.secretAccessKey,
    //   access_key: creds.accessKeyId,
    //   session_token: creds.sessionToken,
    // };
    // const region = "us-west-2";
    // const service = "lambda";
    // const serviceInfo = { region, service };
    // request = signer.sign(request, credentials, serviceInfo);
  }

  const response = await fetchWithRetry(request.url, {
    method: request.method,
    headers: request.headers,
    body: request.body,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Create a ReadableStream
  const reader = response.body.getReader();

  // Read the stream
  let result = await reader.read();

  // Decode streamed data
  const decoder = new TextDecoder("utf-8");
  let respSum = "";

  while (!result.done) {
    respSum += decoder.decode(result.value || new Uint8Array(), {
      stream: true,
    });

    const fields = processChunk(respSum, extractQuestionFields);
    if (Object.keys(fields).length > 0) {
      fields["index"] = index;
      onFieldsReady(index, fields, topic);
    }

    result = await reader.read();
  }

  // handle the last chunk
  respSum += decoder.decode(result.value || new Uint8Array(), { stream: true });

  const fields = processChunk(respSum, extractQuestionFields, true);
  fields["index"] = index;
  if (Object.keys(fields).length <= 0) {
    console.warn("something is wrong ", fields);
  }
  onFieldsReady(index, fields, topic, true);
};
// yes
export const newStream = async (prompt, index, gpt4, topic, onFieldsReady) => {
  let model = "";

  let url = "gpt-3.5-turbo";
  let loggedIn = false;
  const user = await fetchUserAttributes()
  if (user) {
    if (gpt4) model = "gpt-4";
    url = process.env.REACT_APP_REGISTERED_GPT_API;
    loggedIn = true;
  } else {
    url = process.env.REACT_APP_GUEST_GPT_API;
  }

  const messages = [
    {
      role: "system",
      content:
        "You are an educated and intelligent assistant that creates in-depth and nuanced exam questions. Reach deep into your knowledge to create verbose questions that require a deep understanding to answer.",
    },
    { role: "user", content: prompt },
  ];
  let request = {
    url,
    data: JSON.stringify({ model, messages }), // for signed request
    body: JSON.stringify({ model, messages }), // for unsigned
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (loggedIn) {
    request.headers.Authorization = `Bearer ${(await fetchAuthSession())
      .getIdToken()
      .getJwtToken()}`;
  } else {
    // const essentialCredentials = essentialCredentials(
    //   await currentCredentials(),
    // );
    const essentialCredentials = await fetchAuthSession()
    // const credentials = {
    //   secret_key: essentialCredentials.secretAccessKey,
    //   access_key: essentialCredentials.accessKeyId,
    //   session_token: essentialCredentials.sessionToken,
    // };
    // const region = "us-west-2";
    // const service = "lambda";
    // const serviceInfo = { region, service };
    // request = signer.sign(request, credentials, serviceInfo);
  }

  const response = await fetchWithRetry(request.url, {
    method: request.method,
    headers: request.headers,
    body: request.body,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Create a ReadableStream
  const reader = response.body.getReader();

  // Read the stream
  let result = await reader.read();

  // Decode streamed data
  const decoder = new TextDecoder("utf-8");
  let respSum = "";

  while (!result.done) {
    respSum += decoder.decode(result.value || new Uint8Array(), {
      stream: true,
    });

    const fields = processChunk(respSum, extractQuestionFields);
    if (Object.keys(fields).length > 0) {
      fields["index"] = index;
      onFieldsReady(index, fields, topic);
    }

    result = await reader.read();
  }

  // handle the last chunk
  respSum += decoder.decode(result.value || new Uint8Array(), { stream: true });

  const fields = processChunk(respSum, extractQuestionFields);
  fields["index"] = index;
  if (Object.keys(fields).length <= 0) {
    console.warn("something is wrong ", fields);
  }
  onFieldsReady(index, fields, topic, true);
};

const chatStream = async (
  system,
  content2,
  functions,
  index,
  onFieldsReady,
) => {
  const messages = [
    { role: "system", content: system },
    { role: "user", content: content2 },
  ];
  const function_name = functions[0].name;
  const function_call = { name: function_name };
  const params_ = {
    ...DEFAULT_PARAMS,
    messages,
    functions,
    function_call,
    stream: true,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Create a ReadableStream
  const reader = response.body.getReader();

  // Read the stream
  let result = await reader.read();

  // Decode streamed data
  const decoder = new TextDecoder("utf-8");
  let respSum = "";

  while (!result.done) {
    respSum += decoder.decode(result.value || new Uint8Array(), {
      stream: true,
    });
    const content = extractFunction(respSum);

    const fields = { content };
    if (Object.keys(fields).length > 0) {
      onFieldsReady(index, fields);
    }

    result = await reader.read();
  }

  // handle the last chunk
  respSum += decoder.decode(result.value || new Uint8Array(), { stream: true });
  const content = extractFunction(respSum);

  const fields = { content };
  if (Object.keys(fields).length > 0) {
    onFieldsReady(index, fields, true);
  }
};

// yes
export const chatStreamNoFunction = async (
  system,
  content2,
  index,
  onFieldsReady,
) => {
  const messages = [
    { role: "system", content: system },
    { role: "user", content: content2 },
  ];
  const params_ = { ...DEFAULT_PARAMS, messages, stream: true };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer sk-proj-qX1dEODqgY4NaNxDHa6CT3BlbkFJWrAZyQDpRmRNXIsyqSsI",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Create a ReadableStream
  const reader = response.body.getReader();

  // Read the stream
  let result;
  try {
    result = await reader.read();
  } catch (e) {
    console.error("failure to read from stream", e);
    throw e;
  }

  // Decode streamed data
  const decoder = new TextDecoder("utf-8");
  let respSum = "";

  while (!result.done) {
    const newChunk = decoder.decode(result.value || new Uint8Array(), {
      stream: true,
    });
    const newChunkProcessed = newChunk.replace(/\n/g, "");
    respSum += newChunkProcessed;
    const content = extractContent(respSum);

    const fields = { content };
    if (Object.keys(fields).length > 0) {
      onFieldsReady(index, fields);
    }

    try {
      result = await reader.read();
    } catch (e) {
      console.error("failure to read from stream", e);
      throw e;
    }
  }

  // handle the last chunk
  respSum += decoder.decode(result.value || new Uint8Array(), { stream: true });
  const content = extractContent(respSum);

  const fields = { content };
  if (Object.keys(fields).length > 0) {
    onFieldsReady(index, fields, true);
  }
};

// yes
export const newExplanationStream = async (prompt, index, onFieldsReady) => {
  const messages = [
    {
      role: "system",
      content:
        "Explain the answer with one sentence or less, adding detail that was lacking in the question and without restating detail found in the question",
    },
    { role: "user", content: prompt },
  ];
  const params_ = { ...DEFAULT_PARAMS, ...{ messages }, stream: true };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params_),
  };

  const response = await fetchWithRetry(
    "https://api.openai.com/v1/chat/completions",
    requestOptions,
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Create a ReadableStream
  const reader = response.body.getReader();

  // Read the stream
  let result = await reader.read();

  // Decode streamed data
  const decoder = new TextDecoder("utf-8");
  let respSum = "";

  while (!result.done) {
    const newChunk = decoder.decode(result.value || new Uint8Array(), {
      stream: true,
    });
    const newChunkProcessed = newChunk.replace(/\n/g, "");
    respSum += newChunkProcessed;
    const content = extractContent(respSum);

    const fields = { explanation: content };
    if (Object.keys(fields).length > 0) {
      onFieldsReady(index, fields);
    }

    result = await reader.read();
  }

  // handle the last chunk
  respSum += decoder.decode(result.value || new Uint8Array(), { stream: true });
  const content = extractContent(respSum);

  const fields = { explanation: content };
  if (Object.keys(fields).length > 0) {
    onFieldsReady(index, fields, true);
  }
};

// const getPrompt = (question, domains) => {
//     const prompt = `
//         You should provide back
//         - the domains that the question falls under, e.g. "2.1.1.1 - Neuroscience"
//         - an explanation of why you chose those domains

//         ${question}

//         Domains:
//         ${domains}

//         return the following JSON object back, with the keys filled in with your response.
//         return nothing else other than the JSON object.

//         { "domains": ["x"], "explanation": "x" }
//     `
//     return prompt
// }

//     [
//     {
//         "type": "multipleChoice",
//         "questionText": "Who will be the 47th President of the United States?",
//         "correctAnswer": "Elon Musk",
//         "incorrectAnswers": [
//             "Donald Trump Jr",
//             "Beyonce",
//             "Jeff Jackson"
//         ]
//     }
// ])

// [
//     {
//         "type": "multipleChoice",
//         "questionText": "What are the fundamental theorems of quantum mechanics?",
//         "correctAnswer": "Uncertainty principle, wave-particle duality, and quantization of states",
//         "incorrectAnswers": [
//             "The moon is big, the earth is bigger, saturn is even bigger, and the sun is biggest",
//             "The wheels on the bus go round and round, round and round, round and round",
//             "Politicians are all the same. They either start as criminals or become one along the way"
//         ]
//     }
// ])

// [
//     {
//         "type": "multipleChoice",
//         "questionText": "What",
//         "correctAnswer": "Uncertainty",
//         "incorrectAnswers": [
//             "The",
//             "The",
//             "Polit"
//         ]
//     }
// ])
