import React, { useState, useEffect } from "react";
import { queryWFunctions } from "../api/openai";
import { QuestionComponent } from "./Questions/QuestionComponent";
import { motion, AnimatePresence } from "framer-motion";
import { DataStore } from "@aws-amplify/datastore";
import { Topic, UserQuestionAnswer } from "../models";
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io";
import LoadingIndicator from "./LoadingIndicator";
import { ScoreBar } from "./ScoreBar";

const questionsInitial = [
  {
    questionText: "",
    correctAnswer: "",
    incorrectAnswers: [],
  },
];

const functionsQuestion = [
  {
    name: "print_question",
    description: "Print a question in the desired format",
    parameters: {
      type: "object",
      required: ["questions"],
      properties: {
        questions: {
          type: "array",
          items: {
            type: "object",
            properties: {
              questionText: {
                type: "string",
              },
              correctAnswer: {
                type: "string",
              },
              incorrectAnswers: {
                type: "array",
                items: {
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
];

const functionsQuestions = [
  {
    name: "print_questions",
    description: "Print questions in the desired format",
    parameters: {
      type: "object",
      required: ["questions"],
      properties: {
        questions: {
          type: "array",
          items: {
            type: "object",
            properties: {
              questionText: {
                type: "string",
              },
              correctAnswer: {
                type: "string",
              },
              incorrectAnswers: {
                type: "array",
                items: {
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
];

const functions = [
  {
    name: "print_subtopics",
    description:
      "Provide subtpoics for a course with the provided title, each with a name and description, where the description is a list of the primary concepts covered in the subtopic",
    parameters: {
      type: "object",
      required: ["subtopics"],
      properties: {
        subtopics: {
          type: "array",
          items: {
            type: "object",
            properties: {
              name: {
                type: "string",
              },
              description: {
                type: "string",
              },
            },
          },
        },
      },
    },
  },
];

// state a true fact, then ask the student to explain why the fact is true.
// The questions should test fundamental knowledge of the topic.
// The questions should be of the format: X is defined as...
// The questions should test knowledge of key people in the history of the topic.
// if X is true, then why ...?

const getQuestions = async (topicName, pastQuestions) => {
  let questionSystem = `
    You are a professor creating questions for a test.
    The questions should test practical knowledge of the topic.
    All answer options should be the same length, a single sentence or less. 
    Reach deep into your knowledge to create questions that require a deep understanding to answer.
    `;

  const questionFormat =
    "Generate one question on the provided topic and pass the question to the print_questions function";

  let content = "Topic: " + topicName;
  if (pastQuestions) {
    questionSystem += "Do not repeat any of the previous questions";
    content +=
      "\n" +
      "Past questions: " +
      pastQuestions.map((q) => q.questionText).join(", ");
  }

  const system = questionSystem + "\n" + questionFormat;
  const resp = await queryWFunctions(system, content, functionsQuestion);

  const { name, arguments: args } = resp;


  const argsJSON = JSON.parse(args);

  return argsJSON.questions;
};

// const questionsInitial = [
//     {
//         questionText: "What is the capital of California?",
//         correctAnswer: "Sacramento",
//         incorrectAnswers: ["Los Angeles", "San Francisco", "San Diego"],
//     }

// ]

export const ModulePreview = (props) => {
  const { index, lesson } = props;

  const [expanded, setExpanded] = useState(false);
  const [questionIndexDisplayed, setQuestionIndexDisplayed] = useState(0);
  const [questions, setQuestions] = useState(questionsInitial);
  const [doneLoading, setDoneLoading] = useState(false);
  const [pastQAResults, setPastQAResults] = useState([]);
  const [countCorrect, setCountCorrect] = useState(0);

  useEffect(() => {
    const run = async () => {
      const newQuestions = await getQuestions(lesson.name);
      setQuestions(newQuestions);
      setDoneLoading(true);
    };
    run();
  }, []);

  // useEffect(() => {
  //   /**
  //    * This keeps `post` fresh.
  //    */
  //   const sub = DataStore.observeQuery(UserQuestionAnswer, (u) =>
  //     u.and((u) => [
  //       u.userQuestionAnswerTopicRelationId.eq(lesson.id),
  //       u.userQuestionAnswerTopicRelationId.ne(null),
  //     ]),
  //   ).subscribe(({ items }) => {
  //     const topicQuestions = items;
  //     const newPastQAResults = topicQuestions.map((q) =>
  //       q.wasCorrect ? 1 : -1,
  //     );

  //     const countCorrect = newPastQAResults.filter((v) => v === 1).length;
  //     setCountCorrect(countCorrect);
  //     setPastQAResults(newPastQAResults);
  //   });

  //   return () => {
  //     sub.unsubscribe();
  //   };
  // }, []);

  const nextQuestion = async () => {
    setDoneLoading(false);
    // at the last one, need to grab another
    if (questionIndexDisplayed === questions.length - 1) {
      // start with a placeholder
      const questionsNew = [...questions];
      questionsNew.push({
        questionText: "",
        correctAnswer: "",
        incorrectAnswers: [],
      });
      setQuestions(questionsNew);
      setQuestionIndexDisplayed(questionIndexDisplayed + 1);

      // then grab a new one

      const newQuestions = await getQuestions(lesson.name, questions);
      setDoneLoading(true);

      const questionsNew2 = [...questionsNew].filter(
        (q, i) => q.questionText !== "",
      );
      questionsNew2.push(...newQuestions);
      setQuestions(questionsNew2);
    } else {
      setQuestionIndexDisplayed(questionIndexDisplayed + 1);
    }
  };

  const previousQuestion = () => {
    if (questionIndexDisplayed === 0) {
      return;
    }
    setQuestionIndexDisplayed(questionIndexDisplayed - 1);
  };

  const questionToDisplay = questions[questionIndexDisplayed] || {
    questionText: "",
    correctAnswer: "",
    incorrectAnswers: [],
  };

  const complete = countCorrect > 6;
  const showDescription = true;

  return (
    <div
      className={
        (complete ? "border-l-4 border-green-500" : "pl-1") +
        " bg-slate-800/50 py-4 my-4"
      }
    >
      <div className={`h-2 mx-3`}>
        <ScoreBar pastQAResults={pastQAResults} />
      </div>
      <div
        key={index}
        className="group/module flex  justify-between items-center dark:text-white p-3 rounded-lg w-full mb-3"
        onClick={() => setExpanded(!expanded)}
      >
        <div>
          <h2 onClick={() => {}} className="text-xl font-slab">
            {" "}
            <span className="text-white/50">{index + 1}</span> {lesson.name}
          </h2>
          {showDescription && (
            <p className="py-2 text-sm text-white/70">{lesson.description}</p>
          )}
        </div>
        <button
          onClick={() => setExpanded(!expanded)}
          className="group-hover/module:visible invisible hover:text-blue-500 text-white/70 transition text-3xl"
        >
          {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
      </div>
      <AnimatePresence initial={false} mode="wait">
        {expanded && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            className="flex relative overflow-hidden"
          >
            <div className="grow">
              <div className="border-t-2 border-slate-500/50 m-3"></div>
              <QuestionComponent
                key={questionToDisplay.text}
                topicString={lesson.name}
                topic={lesson.name}
                correctAnswerPlacement={2}
                question={questionToDisplay}
                showExplanation={true}
                doneLoading={doneLoading}
              />
            </div>
            <div className="flex flex-col absolute right-0 h-full">
              {questionIndexDisplayed !== 0 && (
                <div
                  onClick={previousQuestion}
                  className="basis-1/2 cursor-pointer h-[200px] z-40 w-12 flex rounded-sm dark:hover:bg-slate-700 hover:ring-4 items-center"
                >
                  <IoIosArrowBack
                    id="next-button"
                    className="text-[100px] text-blue-500"
                  />
                </div>
              )}
              <div
                onClick={nextQuestion}
                className={
                  (questionIndexDisplayed === 0 ? "mt-[189px]" : "") +
                  " basis-1/2 cursor-pointer h-[200px] z-40 w-12 flex rounded-sm dark:hover:bg-slate-700 hover:ring-4 items-center"
                }
              >
                <IoIosArrowForward
                  id="next-button"
                  className="text-[100px] text-blue-500"
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const CoursePage = () => {
  const [lessons, setLessons] = useState([]);
  // const [userSystem, setUserSystem] = useState('generate 5 sibling courses that only slightly overlap with the original.')
  const [userSystem, setUserSystem] = useState(
    "generate 4-7 courses that sequentially build up a foundational knowledge of the subject. the description should be a list of the top 3-5 topics covered in the course.",
  );
  const [courseName, setCourseName] = useState("");
  const [loading, setLoading] = useState(false);

  const system1 =
    "generate subtopics for a course with the provided title, and pass those subtopics to the print_subtopics function";
  const system2 = `
    generate 5 distinct courses for the provided lesson title. The first course should be the introductory version, and each subsequent one should be more advanced.
    pass those subtopics to the print_subtopics function
    `;
  const system3 = `
    generate 5 sibling courses that only slightly overlap with the original.
    pass those subtopics to the print_subtopics function
    `;

  const systemFormat = "pass those subtopics to the print_subtopics function";

  // const system = system3

  const handleSubmit = async () => {
    const content = "Course title: " + courseName + "\n\n";
    const system = userSystem + "\n" + systemFormat;
    setLoading(true);
    const resp = await queryWFunctions(system, content, functions);

    setLoading(false);

    const { name, arguments: args } = resp;


    const argsJSON = JSON.parse(args);

    setLessons(argsJSON.subtopics);

    // const mainCourse = await DataStore.save(
    //   new Topic({
    //     name: courseName,
    //   }),
    // );

    // for (const lesson of argsJSON.subtopics) {
    //   const subtopic = await DataStore.save(
    //     new Topic({
    //       name: lesson.name,
    //       description: lesson.description,
    //       topicSubTopicsId: mainCourse.id,
    //     }),
    //   );
    // }
  };

  const getSubtopics = async (lessonName) => {
    const content = "Course title: " + lessonName + "\n\n";
    const system = userSystem + "\n" + systemFormat;
    const resp = await queryWFunctions(system, content, functions);

    const { name, arguments: args } = resp;


    const argsJSON = JSON.parse(args);

    setLessons(argsJSON.subtopics);

    // await DataStore.save(new Topic({}))
  };

  // const nextQuestion = async () => {
  //     console.log('running')
  //     // at the last one, need to grab another
  //     if (questionIndexDisplayed === questions.length - 1) {
  //         // start with a placeholder
  //         const questionsNew = [...questions]
  //         questionsNew.push({
  //             questionText: "",
  //             correctAnswer: "",
  //             incorrectAnswers: [],
  //         })
  //         setQuestions(questionsNew)
  //         setQuestionIndexDisplayed(questionIndexDisplayed + 1)

  //         // then grab a new one

  //         const newQuestions = await getQuestions()

  //         const questionsNew2 = [...questionsNew]
  //         questionsNew2[questionsNew2.length - 1] = newQuestions[0]
  //         setQuestions(questionsNew2)
  //     } else {
  //         setQuestionIndexDisplayed(questionIndexDisplayed + 1)
  //     }

  // }

  // const previousQuestion = () => {
  //     if (questionIndexDisplayed === 0) {
  //         return
  //     }
  //     setQuestionIndexDisplayed(questionIndexDisplayed - 1)

  // }

  // const questionToDisplay = questions[questionIndexDisplayed]


  return (
    <div className="bg-slate-900 pb-[80px]">
      <div className="px-3">
        <h1 className="text-2xl dark:text-slate-100 font-slab border-blue-500 border-b-2 pb-1 mb-4">
          Create new course
        </h1>
        
        <div className="m-auto bg-blue-500/20 p-4 rounded max-w-[400px] ">
          <p className="text-slate-200/80 font-condensed">
            <span className="text-white font-logo font-bold">Anyq</span> isn't
            just quizzes.
            <br />
            <br />
            It can also make personalized and interactive courses.
          </p>
        </div>

        <div className="mt-6 flex flex-col sm:flex-row justify-between items-center text-xl font-bold">
          <span className="content-box text-white font-condensed pr-4 ">
            I want to learn
          </span>
          <input
            type="text"
            placeholder="topic"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            className={
              (courseName !== "" ? "border-blue-500" : "border-slate-800") +
              " border-[1px] text-center max-w-[400px] bg-slate-800 dark:bg-slate-800 dark:text-white p-3 rounded-lg w-full my-3"
            }
          />
        </div>
        {/* <div className='flex justify-between items-center'>
                    <span className='text-white font-condensed'>with a difficulty of</span>
                    <input type="text" disabled placeholder="automatic" value="automatic" onChange={(e) => setCourseName(e.target.value)}
                        className=" text-gray-500 text-center max-w-[120px] bg-slate-800 dark:bg-slate-800  p-3 rounded-lg w-full my-3" />

                </div>
                <div className='flex justify-between items-center'>
                    <span className='text-white font-condensed'>in the format</span>
                    <input type="text" disabled placeholder="automatic" value="sequential" onChange={(e) => setCourseName(e.target.value)}
                        className=" text-gray-500 text-center max-w-[120px] bg-slate-800 dark:bg-slate-800  p-3 rounded-lg w-full my-3" />
                    <input type="text" placeholder="system instructions" value={userSystem} onChange={(e) => setUserSystem(e.target.value)} className="bg-slate-800 dark:bg-slate-800 dark:text-white p-3 rounded-lg w-full my-3" />

                </div> */}

        <div className="flex flex-col items-center my-4">
          <button
            onClick={handleSubmit}
            className="max-w-[180px] bg-slate-800 dark:bg-blue-700 hover:dark:bg-blue-800 dark:text-white rounded-lg overflow-hidden w-full h-[45px] mt-2 mb-6"
          >
            {loading ? <LoadingIndicator /> : <span>Start</span>}
          </button>
        </div>
      </div>

      {lessons.length > 0 && (
        <div className=" border-blue-500 border-t-2 py-4 mt-4 mb-8">
          <div className="p-2 my-4">
            <span className="	text-white text-2xl font-slab p-2">
              {courseName}
            </span>
          </div>

          {lessons.map((lesson, index) => {
            return <ModulePreview lesson={lesson} index={index} />;
          })}
        </div>
      )}
    </div>
  );
};
