import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  client,
} from "../api/questions";
import { process } from '../api/lm'


import { useStandardUserIdentifier, useCheckUserIsAdmin, useCheckUserGroup } from "../hooks/auth";
import Joyride from "react-joyride";



import { EloTopicTile,AdminTopicTile,  GreenTopicTile, TopicCreatorTile } from "./TopicPage"
import { BigScoreGraph, starMap, rankMap, calculateAccelerationAtTime, convertToForceWindows } from "./score";

import { useNavigate } from "react-router-dom";


import { IoMdRocket } from "react-icons/io";
import { RiTimerFlashFill } from "react-icons/ri";
import { GiLaserBurst } from "react-icons/gi";




import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoMdTrendingUp
} from "react-icons/io";
import { PiPlanetDuotone } from "react-icons/pi";


import { IoPodium } from "react-icons/io5";
import { MdDashboardCustomize } from "react-icons/md";




import { LuSquareStack } from "react-icons/lu";








const availableStreams = [
  "movies",
  "engineering",
  "music",
  "literature",
  "geography",
  "nature",
  "food and drink",
  "sports",
  "history",
  "medicine",
  "science"
]


const userStreamsStart = [
  {
    name: "loading",
    _id: "f2328423-2c42-4a75-ae6a-afa73748ab24"
  },
  // {
  //   name: "loading",
  //   _id: "dbf3c8d1-23f0-441f-89e4-3838571923aa"
  // },
  // {
  //   name: "loading",
  //   _id: "85155211-dcb4-480c-b1ee-d9e04d8bdcc6"
  // },
  // "Neural Networks",
  // "pop culture", 
  // "dungeons and dragons",
  // "scientific breakthroughs of the 1800s",
  // "synthetic biology"
]


const userStreamsDefault = [
  {
    name: "science",
    _id: "16ccca63-58f6-4dc4-9b9f-7c7d41fc577c"
  },
  {
    name: "pop culture",
    _id: "31c6b761-7e5e-4a05-b5c4-61f55bf0e586"
  },
  {
    name: "movies",
    _id: "4a008014-70a5-46dc-838c-3eed699e60a8"
  },
  {
    name: "food and drink",
    _id: "175e3a42-f243-4813-a7b4-daf713b8dd31"
  },
  {
    name: "music",
    _id: "e57f4594-da4f-49c1-803c-9bc89fcaf71d"
  },
  {
    name: "books",
    _id: "7074a141-e2b1-48bd-afee-18e225ac7cd4"
  },
  {
    name: "medicine",
    _id: "0f44745c-5093-4c7c-97f9-6da00c35bd52"
  },
  {
    name: "history",
    _id: "8870da27-8fe3-42fa-b372-1d1381c689cf"
  },
  {
    name: "geography",
    _id: "bcce6319-bb26-4a62-90d6-2055e647346c"
  },
  {
    name: "sports",
    _id: "5899f0b7-bb46-450e-924b-3bddc3444157"
  },
  // "Neural Networks",
  // "pop culture", 
  // "dungeons and dragons",
  // "scientific breakthroughs of the 1800s",
  // "synthetic biology"
]

export const TopicList = ({
  streams,
  pastUserFinishesByStream,
  pastFinishes,
  themeColor,
  height,
  pages,
  requested,
  startedStreams,
  border,
  isAdmin,
  filter,
  pageSize
}) => {
  // generate 4 random characters
  const hash = Math.random().toString(36).substring(4);
  const [topicPage, setTopicPage] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(null);
  // const [sort, setSort] = useState('new')
  const sort = filter;

  const streamsSorted = !pages
    ? streams
    : streams.sort((a, b) => {

      if (a.priority && !b.priority) return -1
      if (b.priority && !a.priority) return 1


      if (sort === 'Newest') {
        let aDate = new Date(a.createdAt)
        let bDate = new Date(b.createdAt)
        return bDate - aDate

      } else if (sort === 'Oldest') {
        let aDate = new Date(a.createdAt)
        let bDate = new Date(b.createdAt)
        return aDate - bDate
      }
    })

  const nextSort = (s) => {
    if (s === 'new') {
      return 'old'
    }
    if (s === 'old') {
      return 'new'
    }
  }

  const onDailyTileClick = async (index, streamId) => {
    setSelectedIndex(i => {
      if (i === null) client.streams.recordOpen(streamId)
      return i == index ? null : index
    })
  }

  const hideNextButton = ((topicPage + 1) * pageSize) + 1 > streams.length
  const hideBackButton = topicPage === 0


  return (
    <>
      <div
        style={{ height:(height) * pageSize }}
        className={`pb-4 relative `}>
        {/* {pages &&
          <div
            onClick={() => { setTopicPage(0); setSort((s) => nextSort(s)) }}
            className={`group float-right  text-base font-logo relative top-[-35px] `}>
            <span className={`font-bold text-${themeColor}-950 dark:text-${themeColor}-300`}>sorted by: </span>
            <span className={`group-active:text-blue-500 rounded-full px-3 py-[3px]  cursor-pointer hover:text-white hover:bg-blue-500 text-blue-400 bg-slate-800 `}>{sort}</span>
          </div>
        } */}
        {streamsSorted.slice(topicPage * pageSize, (topicPage + 1) * pageSize).map((stream, i) => {
          return (
            <AdminTopicTile
              isAdmin={isAdmin}
              hash={hash}
              key={i + stream.name}
              stream={stream}
              topic={stream.name}
              scores={pastUserFinishesByStream[stream.name]}
              building={stream.building}
              index={i}
              selectedIndex={selectedIndex}
              onClick={() => onDailyTileClick(i, stream._id)}
              streamId={stream._id}
              pastUserFinishes={pastFinishes}
              priority={stream.priority}
              themeColor={themeColor}
              pageSize={pageSize}
              height={height}
              border={border}
            />
          )
        })}
      </div>
      {pages &&
        <div className={`flex justify-between text-${themeColor}-700 dark:text-${themeColor}-300 mx-2`}>
          <button className={(hideBackButton ? "invisible" : "") + ` bg-blue-500  h-8 w-20 rounded-full cursor-pointer mt-4 hover:text-white hover:bg-blue-500 font-logo text-blue-400 bg-slate-800`} onClick={() => setTopicPage(p => p - 1)}>
            <IoIosArrowBack className="inline" />back
          </button>
          <button className={(hideNextButton ? "invisible" : "") + `  h-8 w-20 rounded-full mt-4 font-logo cursor-pointer hover:text-white hover:bg-blue-500 text-blue-400 bg-slate-800 `} onClick={() => setTopicPage(p => p + 1)}>
            next<IoIosArrowForward className="inline" />
          </button>
        </div>
      }
    </>
  )
}

function CountdownToMidnight() {
  const calculateTimeLeft = () => {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    return Math.round((tomorrow - now) / 1000);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1);

    return () => clearInterval(timer);
  }, []);

  const formatTimeLeft = () => {
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    // return `clock reset in ${hours}:${String(minutes).padStart(2, '0')}:${seconds}`;
    // if (hours > 0) return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return ` ${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    // return `t-minus  ${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    const combinedSeconds = minutes * 60 + seconds;
    // return `t-minus  ${combinedSeconds}`;
  };

  return (
    <div>{formatTimeLeft()}</div>
  );
}


export const TopicGrid = ({
  streams,
  pastUserFinishesByStream,
  pastFinishes,
  themeColor,
  height,
  pages,
  requested,
  startedStreams,
  border,
  isAdmin
}) => {
  // generate 4 random characters
  const hash = Math.random().toString(36).substring(4);
  const [topicPage, setTopicPage] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [sort, setSort] = useState('last play')

  const streamsSorted = !pages
    ? streams
    : streams.sort((a, b) => {

      if (a.priority && !b.priority) return -1
      if (b.priority && !a.priority) return 1


      if (sort === 'creation') {
        let aDate = new Date(0)
        let bDate = new Date(0)
        const requestedA = requested.find((r) => r.name === a.name)
        const requestedB = requested.find((r) => r.name === b.name)
        if (requestedA && requestedA.REQUESTED) aDate = new Date(requestedA.REQUESTED.occuredAt)
        if (requestedB && requestedB.REQUESTED) bDate = new Date(requestedB.REQUESTED.occuredAt)
        // if (sort === 'oldest') return aDate - bDate
        // if (sort === 'newest') 
        return bDate - aDate

      } else if (sort === 'last play') {
        let aDate = new Date(0)
        let bDate = new Date(0)

        const startedA = startedStreams.find((r) => r.name === a.name)
        const startedB = startedStreams.find((r) => r.name === b.name)
        if (startedA && startedA.STARTED) aDate = new Date(startedA.STARTED.occuredAt)
        if (startedB && startedB.STARTED) bDate = new Date(startedB.STARTED.occuredAt)
        return bDate - aDate

      }
    })

  const nextSort = (s) => {
    if (s === 'creation') {
      return 'last play'
    }
    if (s === 'last play') {
      return 'creation'
    }
  }

  const onDailyTileClick = async (index, streamId) => {
    setSelectedIndex(i => {
      if (i === null) client.streams.recordOpen(streamId)
      return i == index ? null : index
    })
  }

  const hideNextButton = ((topicPage + 1) * 4) > streams.length
  const hideBackButton = topicPage === 0

  return (
    <>
      <div
        style={{ height: height * 8 + 60 }}
        className={`pb-4 relative ` + (pages ? "mt-10" : "mt-4")}>
        {pages &&
          <div
            onClick={() => { setTopicPage(0); setSort((s) => nextSort(s)) }}
            className={`group float-right  text-base font-logo relative top-[-35px] `}>
            <span className={`font-bold text-${themeColor}-950 dark:text-${themeColor}-300`}>sorted by: </span>
            {/* <span className={(sort === sorts[1] && "group-active:text-purple-500) + " transition-all font-bold text-purple-300"}>{"<"}</span> */}
            <span className={`group-active:text-${themeColor}-500 text-${themeColor}-100 rounded-full px-3 py-[3px] bg-blue-500 dark:text-${themeColor}-100`}>{sort}</span>
            {/* <span className={(sort === sorts[0] && "group-active:text-purple-500") + "  transition-all font-bold text-purple-300"}>{">"}</span> */}
          </div>
        }
        {streamsSorted.slice(topicPage * 4, (topicPage + 1) * 4).map((stream, i) => {
          return (
            <AdminTopicTile
              isAdmin={isAdmin}
              pinned={true}
              hash={hash}
              key={i + stream.name}
              topic={stream.name}
              scores={pastUserFinishesByStream[stream.name]}
              building={stream.building}
              index={i}
              selectedIndex={selectedIndex}
              onClick={() => onDailyTileClick(i, stream._id)}
              streamId={stream._id}
              pastUserFinishes={pastFinishes}
              priority={stream.priority}
              themeColor={themeColor}
              height={height}
              border={border}
            />
          )
        })}
      </div>
      {pages &&
        <div className={`flex justify-between text-${themeColor}-700 dark:text-${themeColor}-300 h-12`}>
          <button className={(hideBackButton ? "invisible" : "") + ` bg-blue-500  h-8 w-20 rounded-full text-${themeColor}-100 mt-3 font-logo`} onClick={() => setTopicPage(p => p - 1)}>
            <IoIosArrowBack className="inline" />back
          </button>
          <button className={(hideNextButton ? "invisible" : "") + `bg-blue-500  h-8 w-20 rounded-full text-${themeColor}-100 mt-3 font-logo`} onClick={() => setTopicPage(p => p + 1)}>
            next<IoIosArrowForward className="inline" />
          </button>
        </div>
      }
    </>
  )
}


export const ShipControls = () => {

  const [hourlyStarGraph, setHourlyStarGraph] = useState([])
  const [questionsForReview, setQuestionsForReview] = useState([])

  useEffect(() => {
   
    async function fetchDailyCount() {
      const pastUserFinishes = await client.plays.pastDailiesSummaries()


      const pastUserFinishesByStream = pastUserFinishes?.reduce((acc, curr) => {
        const stream = curr.questionsetOfOut.streamMadeIn
        if (!acc[stream.name]) {
          acc[stream.name] = []
        }
        acc[stream.name].push(curr)
        return acc
      }, {}) || {}


      const hourlyPoints = {};
      const hourlyStars = {};

      pastUserFinishes?.map((finish) => {
        // finish.start_time is a string like "2021-09-01T00:00:00.000Z"
        const startTime = new Date(finish.start_time);
        const localHourString = startTime.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          timeZoneName: 'short'
        }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"

        if (!hourlyPoints[localHourString]) {
          hourlyPoints[localHourString] = 0;
          hourlyStars[localHourString] = 0;
        }

        hourlyStars[localHourString] += starMap(finish.score);
        hourlyPoints[localHourString] += finish.score;
      });

      // let dailyStars = hourlyStars;
      // let dailyPoints = hourlyPoints;



      const hourlyStarsSorted = Object.keys(hourlyStars).sort((a, b) => {
        const aDate = new Date(a.replace(' GMT−7', ':00:00-07:00'))
        const bDate = new Date(b.replace(' GMT−7', ':00:00-07:00'))
        const diff = bDate - aDate
        return diff
      })

      const hourlyStarsSorted2 = hourlyStarsSorted.map((date) => {
        return { date, score: hourlyStars[date] }
      })

      // add an entry for the current hour if it doesn't exist
      const now = new Date();
      const currentHourString = now.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        timeZoneName: 'short'
      }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"
      if (!hourlyStarsSorted2.find((entry) => entry.date === currentHourString)) {
        hourlyStarsSorted2.unshift({ date: currentHourString, score: 0 });
      }
      setHourlyStarGraph(hourlyStarsSorted2.reverse())

    }

    const fetchQuestionToReview = async () => {
      const questions = await client.questions.questionsToReview()
      setQuestionsForReview(questions)
    }
    fetchDailyCount()
    fetchQuestionToReview()
  }, []);

  return (
    <div className="bg-slate-900 relative z-[25] py-2 px-2 flex justify-center">
          <div className="absolute px-1 py-[12px] w-[95%] sm:w-[74%] flex justify-between overflow-hidden ">
            {/* <Link to="/a/review" className="overflow-hidden shadow relative z-[0] bg-violet-500/90 hover:bg-violet-600/90 max-w-[78px] w-full  border-violet-300 rounded-lg">
              <PiArrowURightUpFill className=" text-violet-100/70 text-2xl w-[100%] h-[45px] pt-[4px] pb-[15px] relative sm:left-1 sm:top-0"/>
             <div className="text-violet-100/90  text-xs font-logo absolute bottom-[2px] text-center w-full">Review</div>

            </Link> */}
            {/* <Link to="/a/shuffle" className="overflow-hidden col-start-6 shadow relative z-[0] bg-blue-500/90 hover:bg-blue-600/90 max-w-[78px] w-full  border-blue-300 rounded-lg flex flex-col items-end">
             <PiGlobeHemisphereWestDuotone className="text-blue-100/70 text-2xl w-[100%] h-[45px] pt-[3px] pb-[14px] absolute transform"/>
             <div className="text-blue-100/90 text-xs font-logo absolute bottom-[2px] text-center w-full">Explore</div>
            </Link> */}
          </div>
          
          <div className="w-[200px] h-[70px]">
        <BigScoreGraph isSmall={true} hourlyScores={hourlyStarGraph} questionsForReview={questionsForReview}/>
        </div>


          
        
        
        </div>
  )
}

export const HomePanel = (props) => {

  const {visible} = props

  useEffect(() => {
    if (document.cookie.includes("demoed=true")) {
      setDemoed(true)
    } else {
      setDemoed(false)
    }
  }, [document.cookie])


  const demoComplete = () => {
    document.cookie = "demoed=true; max-age=31536000; path=/";
    setDemoed(true)
  }

  const [ demoed, setDemoed ] = useState(false)

  const [hourlyStarGraph, setHourlyStarGraph] = useState([])
  const [questionsForReview, setQuestionsForReview] = useState([])

  const { data: isPremium, isPending: isPending2, isError: isError2, error:error2 } = useCheckUserGroup();

  const fetchQuestionToReview = async () => {
    const questions = await client.questions.questionsToReview()
    setQuestionsForReview(questions)
  }

  const fetchDailyCount = async () => {
    const pastUserFinishes = await client.plays.pastDailiesSummaries()


    const pastUserFinishesByStream = pastUserFinishes?.reduce((acc, curr) => {
      const stream = curr.questionsetOfOut.streamMadeIn
      if (!acc[stream.name]) {
        acc[stream.name] = []
      }
      acc[stream.name].push(curr)
      return acc
    }, {}) || {}


    const hourlyPoints = {};
    const hourlyStars = {};

    pastUserFinishes?.map((finish) => {
      // finish.start_time is a string like "2021-09-01T00:00:00.000Z"
      const startTime = new Date(finish.start_time);
      const localHourString = startTime.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        timeZoneName: 'short'
      }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"

      if (!hourlyPoints[localHourString]) {
        hourlyPoints[localHourString] = 0;
        hourlyStars[localHourString] = 0;
      }

      hourlyStars[localHourString] += starMap(finish.score);
      hourlyPoints[localHourString] += finish.score;
    });

    // let dailyStars = hourlyStars;
    // let dailyPoints = hourlyPoints;



    const hourlyStarsSorted = Object.keys(hourlyStars).sort((a, b) => {
      const aDate = new Date(a.replace(' GMT−7', ':00:00-07:00'))
      const bDate = new Date(b.replace(' GMT−7', ':00:00-07:00'))
      const diff = bDate - aDate
      return diff
    })

    const hourlyStarsSorted2 = hourlyStarsSorted.map((date) => {
      return { date, score: hourlyStars[date] }
    })

    // add an entry for the current hour if it doesn't exist
    const now = new Date();
    const currentHourString = now.toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      timeZoneName: 'short'
    }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"
    if (!hourlyStarsSorted2.find((entry) => entry.date === currentHourString)) {
      hourlyStarsSorted2.unshift({ date: currentHourString, score: 0 });
    }
    setHourlyStarGraph(hourlyStarsSorted2.reverse())

  }

  useEffect(() => {
    fetchDailyCount()
    fetchQuestionToReview()

  }, []);

  useEffect(() => {
    if (visible) {
      fetchQuestionToReview()
    }
  }, [visible])

  const handleJoyrideCallback = (data) => {
    console.log(data)
    const { action, index, type } = data;

    if (action === "next"  && type === "tour:end") {
      demoComplete()
    }
  };
  

  return (
    <div className=" z-[9000]  absolute top-0 bottom-0 pb-[60px] right-0 left-0 flex justify-center"> 
    
      
    <div className="absolute grid grid-cols-2 py-8 gap-4 px-8 h-full border-box w-[100%] sm:w-[74%] flex justify-between">
      


      <div id="race-open" className=" row-span-3 flex justify-end">
        <Link to="/a/shuffle" className="items-center flex flex-col justify-center w-full  shadow-lg overflow-hidden relative z-[0] bg-blue-300/40 dark:bg-blue-700/30 dark:bg-gradient-to-br dark:from-blue-700/30 dark:via-blue-600/80 dark:to-blue-700/30 hover:bg-blue-600/90 active:bg-blue-600/90 w-full  rounded-xl">
          {/* <IoIosFlame className="text-blue-300/50 text-2xl w-[3100%] h-[85px] absolute transform"/> */}
          {/* <PiBrainDuotone className="text-black dark:text-slate-200  absolute bottom-0 left-0 text-2xl h-[85px] pt-[3px] pb-[14px] transform"/> */}
          {/* <IoMdPlanet className="text-black dark:text-slate-200  absolute bottom-0 left-0 text-2xl h-[85px] pt-[3px] pb-[14px] transform"/> */}
          <RiTimerFlashFill className="z-[-5] text-blue-500/10 dark:text-blue-500/30   bottom-[px] text-2xl h-[55px] w-[55px] left-[20px] transform flex flex-col justify-center"/>
          <div className="text-black dark:text-white/90 text-xl font-logo">Sprint</div>
        </Link>
      </div>
      <div id="recover-open" className=" row-span-3 flex justify-start">
        <Link to="/a/review" className="items-center flex flex-col justify-center w-full  shadow-lg overflow-hidden relative z-[0] bg-violet-300/40 dark:bg-violet-700/30 dark:bg-gradient-to-br dark:from-violet-700/30 dark:via-violet-600/80 dark:to-violet-700/30 active:bg-violet-600/90 hover:bg-violet-600/90 w-full  rounded-xl">
        { questionsForReview.length > 0 && <div className="font-logo text-slate-100 absolute bg-blue-500/70 dark:bg-purple-500 top-2 right-2 rounded-full w-9 h-9 flex items-center justify-center">
            {questionsForReview.length}<span className="text-xs "></span>
          </div>
}
          {/* <PiArrowURightUpFill className=" text-black dark:text-slate-200  absolute bottom-0 left-0 text-2xl h-[85px] pt-[4px] pb-[15px] relative sm:left-1 sm:top-0"/> */}
          <IoMdTrendingUp className="  z-[-5] text-blue-500/10 dark:text-violet-500/30  text-2xl h-[55px] w-[55px] left-[20px] transform"/>
          {/* <PiFootprintsDuotone className=" text-black dark:text-slate-200  absolute bottom-0 left-0 text-2xl h-[85px] pt-[4px] pb-[15px] relative sm:left-1 sm:top-0"/> */}
          <div className="text-black dark:text-white/90 text-xl font-logo">Recover</div>
        </Link>
      </div>
      <div id="dailies-open" className="col-span-2  row-span-2 flex justify-center w-full ">
        <Link to="/a/home/news" className="text-black dark:text-slate-200 flex flex-col justify-center w-full  shadow-lg overflow-hidden relative z-[0] bg-indigo-300/40  dark:bg-indigo-700/30 dark:bg-gradient-to-br dark:from-indigo-700/30 dark:via-indigo-600/80 dark:to-indigo-700/30 active:bg-indigo-600/90 hover:bg-indigo-600/90 w-full  rounded-xl">
          {/* <div className="font-logo text-slate-100 absolute top-1 bg-blue-500/70 dark:bg-blue-500 right-1 rounded-full w-9 h-9 flex items-center justify-center">
            7
          </div> */}
          {/* <PiCardsThreeDuotone className="  absolute bottom-0 left-0 text-2xl h-[45px] pt-[4px] pb-[15px] relative sm:left-1 sm:top-0"/> */}
          <LuSquareStack className="  z-[-5] text-blue-500/10 dark:text-indigo-500/30  absolute text-2xl h-[49px] w-[49px] left-[20px] transform rotate-[-45deg] "/>
          <div className="  pl-24 text-black dark:text-white/90 text-xl font-logo">Current events</div>
        </Link>
      </div>
      <div id="explore-open" className="col-span-2  row-span-2 flex justify-center ">
        <Link to="/a/home/library" className=" flex flex-col justify-center  shadow-lg overflow-hidden relative z-[0] bg-sky-300/40 dark:bg-sky-700/30 dark:bg-gradient-to-br dark:from-sky-700/30 dark:via-sky-600/80 dark:to-sky-700/30 hover:bg-sky-600/90  active:bg-sky-600/90 w-full  rounded-xl">
          {/* <div className="font-logo text-slate-100 absolute top-1 bg-blue-500/70 dark:bg-blue-500 right-1 rounded-full w-9 h-9 flex items-center justify-center">
              1<span className="text-xs "></span>
            </div> */}
          <PiPlanetDuotone className="z-[-5] text-blue-500/10 dark:text-sky-500/30  absolute bottom-[] text-2xl h-[55px] w-[55px] left-[20px] transform"/>
          {/* <div className="text-black dark:text-slate-200 text-xl font-logo  w-full">Request</div> */}
          <div className="pl-24 text-black dark:text-white/90 text-xl font-logo">Explore</div>
        </Link>
      </div>

{ isPremium && 
  <div id="compare-open" className="col-span-2  row-span-2 flex justify-center w-full ">
        <Link to="/a/home/main" className="text-black dark:text-teal-200 flex flex-col justify-center w-full  shadow-lg overflow-hidden relative z-[0] bg-teal-300/40  dark:bg-teal-700/30 dark:bg-gradient-to-br dark:from-teal-700/30 dark:via-teal-600/80 dark:to-teal-700/30 active:bg-teal-600/90 hover:bg-teal-600/90 w-full  rounded-xl">
          {/* <div className="font-logo text-teal-100 absolute top-1 bg-blue-500/70 dark:bg-blue-500 right-1 rounded-full w-9 h-9 flex items-center justify-center">
            1
          </div> */}
          <IoPodium className="   z-[-5] text-blue-500/10 dark:text-teal-500/30  absolute text-2xl h-[55px] w-[55px] left-[20px] transform"/>
          <div className="pl-24  text-black dark:text-white/90 text-xl font-logo w-full">Compare</div>
        </Link>
      </div>
}

{ !isPremium &&
      <div id="compare-open" className="col-span-2  row-span-2 flex justify-center w-full ">
      <Link to="/a/plans" className="text-black dark:text-teal-200 flex flex-col justify-center w-full  shadow-lg overflow-hidden relative z-[0] bg-teal-300/40  dark:bg-slate-700/50  active:bg-teal-600/90 hover:bg-teal-600/90 w-full  rounded-xl">
        {/* <div className="font-logo text-teal-100 absolute top-1 bg-blue-500/70 dark:bg-blue-500 right-1 rounded-full w-9 h-9 flex items-center justify-center">
          1
        </div> */}
        <GiLaserBurst className="   z-[-5] text-blue-500/10 dark:text-slate-500/30  absolute text-2xl h-[55px] w-[55px] left-[20px] transform"/>
        <div className="relative pl-24  text-black dark:text-white/90 text-xl font-logo w-full">
          X-Ray
        <div className="mb-2 left-0 top-0">
          <span className="bg-blue-500 text-blue-100 font-logo font-bold rounded text-sm px-[6px] py-[2px] [text-shadow:_2px_2px_0_rgb(0_136_242_)]">plus</span>
        </div>
          </div>
          <div className="absolute right-0 bottom-3 text-right text-white mx-4 font-condensed"> learn more <IoIosArrowForward className="inline-block"/></div>
      </Link>
    </div>
}

{/* { !isPremium &&
      <div id="compare-open" className="col-span-2  row-span-2 flex justify-center w-full ">
        <Link to="/a/plans" className="text-black dark:text-teal-200 flex flex-col justify-center w-full  shadow-lg overflow-hidden relative z-[0] bg-teal-300/40  dark:bg-teal-700/30 dark:bg-gradient-to-br dark:from-teal-700/30 dark:via-teal-600/80 dark:to-teal-700/30 active:bg-teal-600/90 hover:bg-teal-600/90 w-full  rounded-xl">
          <IoPodium className="   z-[-5] text-blue-500/10 dark:text-teal-500/20  absolute text-2xl h-[55px] w-[55px] left-[20px] transform"/>
          <div className="mt pl-24  text-lg text-black dark:text-white/90 font-condensed w-full">Upgrade to see how <br/> you compare</div>
          <div className="absolute right-0 bottom-3 text-right text-white mx-4 font-condensed"> prices <IoIosArrowForward className="inline-block"/></div>
        </Link>
      </div>
} */}


      {/* <BigScoreGraph isSmall={false} hourlyScores={hourlyStarGraph} questionsForReview={questionsForReview}/> */}


      {/* <div className="flex justify-center ">
        <Link to="/a/review" className="flex flex-col justify-center w-full  shadow-lg relative z-[0] bg-slate-100/40 dark:bg-slate-700/30 hover:bg-emerald-600/90 w-full  rounded-lg">
        <div className="text-black dark:text-slate-200 absolute top-3 bg-slate-100/90 dark:bg-black/40 right-3 rounded-full w-9 h-9 flex items-center justify-center">
        1<span className="text-xs "></span>
        </div>
        <PiGraduationCapDuotone className="text-black dark:text-slate-200  absolute bottom-0 left-0 text-2xl h-[85px] pt-[3px] pb-[14px] transform"/>
        <div className="text-black dark:text-slate-200 text-xl font-logo text-center w-full">Courses</div>
        </Link>
      </div> */}

      

      
      
      

    </div>
          


        { !demoed &&  visible && 
    <Joyride
          continuous
          hideCloseButton
          disableScrolling
          callback={handleJoyrideCallback}
          locale={{
            last: "Done",
          }}
          floaterProps={{
            styles: {
              floater: {
                backdropFilter: 'blur(12px)',
                WebkitBackdropFilter: 'blur(12px)', // For Safari support
              }
            }
          }}
          styles={{
            options: {
              textColor: 'white',
              arrowColor: 'rgb(51, 65, 85, 0.5)',
              backgroundColor: 'rgb(51, 65, 85, 0.5)',
              zIndex: 10000,
              width: 300,
              
            },
            tooltip: {
              borderRadius: 20,
            },
            tooltipContainer: {
              textAlign: "left",
            },
            buttonNext: {
              backgroundColor: '#3b82f6',
              padding: "12px 20px",
              borderRadius: 6,
            },
            buttonBack: {
              color: '#3b82f6',
            },
          }}
          steps={[
            {
              target: "#rating-box",
              content: (
                <>
                <div className="text-blue-500 font-logo font-bold">Rating</div>
                <div className="font-medium font-condensed">
                 Your rating starts at 25 then goes up or down, based on how well you answer questions.
                </div>
                <div className="mt-2 font-medium font-condensed">

                 Join <span className="bg-blue-500/80 text-blue-100 font-logo font-bold rounded text-sm px-[6px] py-[2px] [text-shadow:_2px_2px_0_rgb(0_136_242_)]">
                      plus
                    </span> and see how you rank compared to others.
                </div>
               </>
              ),
            },
            {
              target: "#race-open",
              content: (
                <div>
                  <div className="text-blue-400 font-logo font-bold">Sprint</div>
                  <div className="font-medium font-condensed">
                    Answer random questions faster and faster to stay alive as long as you can. 
                  </div>
                  <div className="mt-2 font-condensed"> For those who think best under pressure. Or those who want to practice.</div>
                </div>
              ),
            },
            {
              target: "#recover-open",
              content: (
                <div>
                  <div className="text-purple-400 font-logo font-bold">Recover</div>
                  <div className="font-medium font-condensed">
                    Learn from your mistakes. Questions that you miss will collect here.
                  </div>
                  <div className="mt-2 font-medium font-condensed">
                    Answer them correctly and clear them out.
                  </div>
                  {/* <div className="mt-2 font-condensed"> Keep this number down to keep your rocket flying.</div> */}
                </div>
              ),
            },
            {
              target: "#dailies-open",
              content: (
                <div>
                  <div className="text-indigo-400 font-logo font-bold">Current events</div>
                    <div className="font-medium font-condensed">
                    Curated question sets related to what's going on in the world.
                    </div>
                    <div className="mt-2 font-medium font-condensed">
                      Made fresh every day.
                    </div>
                  {/* <div className="mt-2 font-condensed"> Keep this number down to keep your rocket flying.</div> */}
                </div>
              ),
            },
            {
              target: "#explore-open",
              content: (
                <div>
                  <div className="text-sky-400 font-logo font-bold">Explore</div>
                  <div className="font-medium font-condensed">
                    Find questions on any topic. If we don't have it already, we'll make it for you on the spot.
                  </div>
                  <div className="mt-2 font-medium font-condensed">
                    For inspiration, see what others have made.
                  </div>
                  {/* <div className="mt-2 font-condensed"> Keep this number down to keep your rocket flying.</div> */}
                </div>
              ),
            },
            {
              target: "#compare-open",
              content: (
                <div>
                <span className="font-medium font-condensed">
                  <div className="mb-2">
                    <span className="text-teal-400 font-logo font-bold">X-Ray</span>
                    <span className="ml-2 bg-blue-500/80 text-blue-100 font-logo font-bold rounded text-sm px-[6px] py-[2px] [text-shadow:_2px_2px_0_rgb(0_136_242_)]">
                      plus
                    </span>
                  </div>
                  Analyze your strengths and see how you rank compared to other players.
                  <div className="mt-2 font-medium font-condensed">
                    Practice where you want to improve.
                  </div>
                  
                </span>
                {/* <div className="mt-2 font-condensed"> Keep this number down to keep your rocket flying.</div> */}
              </div>
              ),
            },
          ]}
          
        />
}
        
    </div>
  )
}


export const HomePage = () => {

  const themeColor = "blue"

  const { data: userId, isLoading: userIdLoading, error: userIdError } = useStandardUserIdentifier();
  const { data: isAdmin, isError, isLoading: isLoadingSession } = useCheckUserIsAdmin();
  const { loggedIn } = userId
  const innerTo = useNavigate();
  const to = (dest, tag) => {
    window.gtag("event", tag, {
      event_category: "engagement",
    });
    return innerTo(dest);
  }

  const [userStreams, setUserStreams] = useState(userStreamsStart)
  const [questionsForReview, setQuestionsForReview] = useState([])
  // let streams = userStreams
  //   let streams = availableStreams.filter((s) => !userStreams.includes(s))
  // streams = streams.map((t) => ({ topic: t }))
  // streams = streams.sort(() => Math.random() - 0.5).slice(0, 4 - userStreams.length)
  // streams = userStreams.concat(streams)

  const [answersCorrect, setAnswersCorrect] = useState(0);
  const [points, setPoints] = useState(0);
  const [dailyCount, setDailyCount] = useState(0);
  const pointsK = Math.round((points / 1000) * 10) / 10;
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [bannerHeight, setBannerHeight] = useState(45)
  const [exploreTopics, setExploreTopics] = useState([])
  const [topicSetIndex, setTopicSetIndex] = useState(0)
  const [pastFinishes, setPastFinishes] = useState()

  const [dailyStarTotal, setDailyStarTotal] = useState(0)
  const [dailyStarGraph, setDailyStarGraph] = useState([])
  const [hourlyStarGraph, setHourlyStarGraph] = useState([])

  const [streamName, setStreamName] = useState('');
  const [sort, setSort] = useState('last play')
  const [requested, setRequestedStreams] = useState([])

  const [startedStreams, setStartedStreams] = useState([])
  const nextSort = (s) => {
    if (s === 'creation') {
      return 'last play'
    }
    if (s === 'last play') {
      return 'creation'
    }
  }
  const sorts = ['creation', 'last play']

  const [finding, setFinding] = useState(false)


  // const [text, setText] = useState('');
  // const [displayedText, setDisplayedText] = useState('or anything else');
  const [displayedText, setDisplayedText] = useState('+ stream');
  const [index, setIndex] = useState(16);
  const [fullText, setFullText] = useState('or anything else you can think of');

  const [splits, setSplits] = useState([])
  const [pastUserFinishesByStream, setPastUserFinishesByStream] = useState([])


  const nextText = () => {

    const texts = [
      "physics",
      "animation",
      "sexual positions",
      "the history of the world",
      "martian colonization",
      "neural networks",
      "skincare",
    ]

    return texts[Math.floor(Math.random() * texts.length)]
  }

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setFullText(nextText());
  //     setDisplayedText('');
  //     setIndex(0);
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [nextText]);

  // useEffect(() => {
  //   if (index < fullText.length) {
  //     const timeoutId = setTimeout(() => {
  //       setDisplayedText((prev) => prev + fullText[index]);
  //       setIndex((prev) => prev + 1);
  //     }, 300); // Adjust the typing speed here
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [index, fullText]);



  useEffect(() => {
    async function fetchStreamFollowing() {
      let streams
      let streamsResp
      try {
        streamsResp = await client.streams.following();
        // const streams = streamsResp.streamFollowingsOut
        streams = streamsResp.streamRequestedsOut
      } catch (e) {
        setUserStreams([]);
        console.log(e)
        return
      }
      if (streams) {
        const requested = streamsResp.streamRequestedsOut
        const started = streamsResp.streamStartedsOut
        // deduplicate
        const filteredStreams = streams.filter((stream, index, self) =>
          index === self.findIndex((t) => (
            t.name === stream.name
          ))
        )
        // sort by requested time
        // filteredStreams.sort((a, b) => {
        //   let aDate = new Date(0)
        //   let bDate = new Date(0)
        //   const requestedA = requested.find((r) => r.name === a.name)
        //   const requestedB = requested.find((r) => r.name === b.name)
        //   if (requestedA && requestedA.REQUESTED) aDate = new Date(requestedA.REQUESTED.occuredAt)
        //   if (requestedB && requestedB.REQUESTED) bDate = new Date(requestedB.REQUESTED.occuredAt)
        //   if (sort === 'last play') return aDate - bDate
        //   if (sort === 'creation') return bDate - aDate
        // })
        setUserStreams(filteredStreams);
        setRequestedStreams(requested || [])
        setStartedStreams(started || [])
      } else {
        setUserStreams([]);
      }
    }
    async function fetchAnswers() {
      const { playScore, answersCorrect } = await client.scores.scoreHistory();
      setPoints(playScore);
      setAnswersCorrect(answersCorrect);
    }
    async function fetchDailyCount() {
      const pastUserFinishes = await client.plays.pastDailiesSummaries()


      const pastUserFinishesByStream = pastUserFinishes?.reduce((acc, curr) => {
        const stream = curr.questionsetOfOut.streamMadeIn
        if (!acc[stream.name]) {
          acc[stream.name] = []
        }
        acc[stream.name].push(curr)
        return acc
      }, {}) || {}

      setPastUserFinishesByStream(pastUserFinishesByStream)



      const dailyPoints = {}
      const dailyStars = {}
      // start by setting today's date (local time) to 0
      const today = new Date().toLocaleDateString('sv-SE');
      dailyStars[today] = 0
      // group the pastUserFinishes by pastUserFinishes.start_time into daily buckets, then summarize .score for each bucket
      pastUserFinishes?.map((finish) => {
        //finish.start_time is a string like "2021-09-01T00:00:00.000Z"
        const startTime = new Date(finish.start_time)
        const localDateString = startTime.toLocaleDateString('sv-SE')
        if (!dailyPoints[localDateString]) {
          dailyPoints[localDateString] = 0
          dailyStars[localDateString] = 0
        }
        dailyStars[localDateString] += starMap(finish.score)
        dailyPoints[localDateString] += finish.score
        // finish.date = finish.start_time.split('T')[0]
      })

      const hourlyPoints = {};
      const hourlyStars = {};

      pastUserFinishes?.map((finish) => {
        // finish.start_time is a string like "2021-09-01T00:00:00.000Z"
        const startTime = new Date(finish.start_time);
        const localHourString = startTime.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          timeZoneName: 'short'
        }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"

        if (!hourlyPoints[localHourString]) {
          hourlyPoints[localHourString] = 0;
          hourlyStars[localHourString] = 0;
        }

        hourlyStars[localHourString] += starMap(finish.score);
        hourlyPoints[localHourString] += finish.score;
      });

      // let dailyStars = hourlyStars;
      // let dailyPoints = hourlyPoints;


      const dailyStarsSorted = Object.keys(dailyStars).sort((a, b) => {
        return new Date(b) - new Date(a)
      })

      const dailyStarsSorted2 = dailyStarsSorted.map((date) => {
        return { date, score: dailyStars[date] }
      })
      setDailyStarGraph(dailyStarsSorted2.reverse())

      const hourlyStarsSorted = Object.keys(hourlyStars).sort((a, b) => {
        const aDate = new Date(a.replace(' GMT−7', ':00:00-07:00'))
        const bDate = new Date(b.replace(' GMT−7', ':00:00-07:00'))
        const diff = bDate - aDate
        return diff
      })

      const hourlyStarsSorted2 = hourlyStarsSorted.map((date) => {
        return { date, score: hourlyStars[date] }
      })

      // add an entry for the current hour if it doesn't exist
      const now = new Date();
      const currentHourString = now.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        timeZoneName: 'short'
      }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"
      if (!hourlyStarsSorted2.find((entry) => entry.date === currentHourString)) {
        hourlyStarsSorted2.unshift({ date: currentHourString, score: 0 });
      }
      setHourlyStarGraph(hourlyStarsSorted2.reverse())



      const dailyCount = pastUserFinishes?.length || 0

      const pastFinishesByQuestionSet = pastUserFinishes?.reduce((acc, curr) => {
        if (!acc[curr.questionsetOfOut.id]) {
          acc[curr.questionsetOfOut.id] = []
        }
        acc[curr.questionsetOfOut.id].push(curr)
        return acc
      }, {}) || {}


      for (let key in pastFinishesByQuestionSet) {
        const maxScore = pastFinishesByQuestionSet[key].reduce((acc, curr) => {
          return acc.score > curr.score ? acc : curr
        })
        setDailyStarTotal(e => e + starMap(maxScore.score))
      }

      // const starsByDate = pastUserFinishes.reduce((acc, curr) => {
      //   if (!acc[curr.date]) {
      //     acc[curr.date] = 0
      //   }
      //   acc[curr.date] += starMap(curr.score)
      //   return acc
      // })

      // ("starsByDate: ", starsByDate)


      // pastFinishesByQuestionSet.forEach((finish) => {
      //   daily.userPlays = pastFinishesByQuestionSet[daily.id]
      //   if (!(daily.userPlays === undefined || daily.userPlays.length === 0)) { 
      //     availableCount += 1
      //     const maxScore = daily.userPlays.reduce((acc, curr) => {
      //       return acc.score > curr.score ? acc : curr
      //     })
      //     starTotalTmp += starMap(maxScore.score)
      //   }
      //   // playCount += daily.userPlays?.length ?? 0
      // })


      // const { dailyCount } = await client.scores.dailyCount();
      setDailyCount(dailyCount);
      setPastFinishes(pastUserFinishes)

    }

    const fetchQuestionToReview = async () => {
      const questions = await client.questions.questionsToReview()
      setQuestionsForReview(questions)
    }
    fetchDailyCount()
    fetchAnswers();
    fetchStreamFollowing();
    fetchQuestionToReview()
  }, []);


  // explore section logic here
  // useEffect(() => {
  //   const element = document.getElementById('explore-container');
  //   const height = element.clientHeight;
  //   element.style.minHeight = height + 'px';
  //   setExploreTopics([])
  //   async function fetchTopics() {
  //     const topics = await client.topics.getChildTopics('medium', 'root');

  //     for (let topic of topics) {
  //       // const promises = topics.map(async (topic) => {
  //       const babyTopics = await client.topics.getChildTopics('medium', topic.name);

  //       if (!babyTopics) {
  //         continue
  //       }

  //       const randomTopic = babyTopics[Math.floor(Math.random() * babyTopics.length)]
  //       randomTopic.parent = topic
  //       setExploreTopics(e => [...e, randomTopic])
  //       // const gbabyTopics = await client.topics.getChildTopics('medium', randomTopic.name);
  //       // const grandomTopic = gbabyTopics[Math.floor(Math.random() * gbabyTopics.length)]
  //       // setExploreTopics(e => [...e, grandomTopic])
  //     }
  //     // }); await Promise.all(promises)
  //   }
  //   fetchTopics();
  // }, [topicSetIndex]);

  // calculate time until utc midnight
  const now = new Date();
  const currentUTCDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  const nextUTCMidnight = new Date(currentUTCDate.getTime() + 24 * 60 * 60 * 1000);
  const timeDiff = nextUTCMidnight - now;

  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

  const currentUTCDateString = currentUTCDate.toISOString().split('T')[0]

  const [streaming, setStreaming] = useState(false)
  const [streamingDone, setStreamingDone] = useState(true)

  const [existingStream, setExistingStream] = useState(null)

  const onStackClick = () => {
    to('/a/review', 'review')
  }

  const onDailyTileClick = async (index, streamId) => {
    client.streams.recordOpen(streamId)
    setSelectedIndex(i => i == index ? null : index)
  }

  const variants = {
    open: { padding: '12px 10px 10px', height: 'auto', opacity: 1 },
    closed: { padding: '12px 10px 0px', height: '0px', opacity: 0 }
  };


  const extractTopicFields = (row) => {
    let { name, _id } = row;

    const fields = { name, _id };

    const filteredFields = Object.keys(fields)
      .filter((key) => fields[key] !== undefined && fields[key] !== null && fields[key] !== "")
      .reduce((obj, key) => {
        obj[key] = fields[key];
        return obj;
      }, {});

    return filteredFields;
  };

  const onFieldsReady = (msg, done) => {

    if (done) {
      setStreamingDone(true)
      if (!msg) return
    }

    setSplits(splits => {

      // if any key in splits is in msg.name, then replace that key with msg

      let replaced = false
      const newSplits = splits.map(split => {
        if (msg.name.includes(split.name) && split.status !== 'saved') {
          replaced = true
          return msg
        }
        return split
      })

      if (!replaced) {
        newSplits.push(msg)
      }

      return newSplits
    })
  }


  const findStream = async () => {
    setFinding(true)
    const system = "When provided a topic, return back the main subtopics of the provided topic."
    const format = "Return a list of topic title, no more than a few words each. Format your response as a json list of topics, each with a name property"
    const prompt2 = system + format

    let childTopics
    try {
      const stream = await client.streams.get(streamName)
      childTopics = stream.topicIncludesOut.map(topic => ({ ...topic, status: 'saved' }))
      const streamId = stream._id
      // const topic = await client.topics.getChildTopicsWithStreams('medium', streamName)
      setStreaming(false)
      setExistingStream(streamId)
      // after 1000ms, set the splits
      setTimeout(() => {
        setSplits(childTopics)
      }, 250)


    } catch (e) {
      setStreaming(true)
      setStreamingDone(false)
      process(prompt2, streamName, extractTopicFields, onFieldsReady)
      return
    }




  }

  const addStream = async () => {
    setToggle(t => !t)
    var element = document.getElementById("stream-container-2");
    const topPosition = element.getBoundingClientRect().top + window.pageYOffset - 20;
    window.scrollTo({
      top: topPosition,
      behavior: 'smooth',
    });
    if (existingStream) {
      setUserStreams(e => {
        const newPinned = [{ _id: existingStream, name: streamName, priority: true }, ...e]
        return newPinned
      })
      setTopicPage(0)
      setSplits([])
      setStreamName('')
      setFinding(false)
      setToggle(t => !t)
      const resp = await client.streams.follow(existingStream)

    } else {
      const topic = {
        name: streamName,
        subtopics: splits.map((split) => ({ name: split.name }))
      }
      setUserStreams(e => {
        const newPinned = [{ name: streamName, priority: true }, ...e]
        return newPinned
      })
      setTopicPage(0)
      setSplits([])
      setStreamName('')
      setFinding(false)
      setToggle(t => !t)
      const stream = await client.streams.make(topic)
      // now go back and add stream._id to the correct stream in pinned streams
      setUserStreams(e => {
        const filtered = e.filter((s) => s.name !== streamName)
        return [{ _id: stream._id, name: streamName, priority: true }, ...filtered]
      })

    }




  }

  const [topicPage, setTopicPage] = useState(0)
  const userStreamsSorted = userStreams.sort((a, b) => {

    if (a.priority && !b.priority) return -1
    if (b.priority && !a.priority) return 1


    if (sort === 'creation') {
      let aDate = new Date(0)
      let bDate = new Date(0)
      const requestedA = requested.find((r) => r.name === a.name)
      const requestedB = requested.find((r) => r.name === b.name)
      if (requestedA && requestedA.REQUESTED) aDate = new Date(requestedA.REQUESTED.occuredAt)
      if (requestedB && requestedB.REQUESTED) bDate = new Date(requestedB.REQUESTED.occuredAt)
      // if (sort === 'oldest') return aDate - bDate
      // if (sort === 'newest') 
      return bDate - aDate

    } else if (sort === 'last play') {
      let aDate = new Date(0)
      let bDate = new Date(0)

      const startedA = startedStreams.find((r) => r.name === a.name)
      const startedB = startedStreams.find((r) => r.name === b.name)
      if (startedA && startedA.STARTED) aDate = new Date(startedA.STARTED.occuredAt)
      if (startedB && startedB.STARTED) bDate = new Date(startedB.STARTED.occuredAt)
      return bDate - aDate

    }
  })

  const [toggle, setToggle] = useState(true)

  const hideNextButton = ((topicPage + 1) * 4) > userStreams.length
  const hideBackButton = topicPage === 0
  const showNextBackButtons = !hideNextButton || !hideBackButton

  const emptyLibrary = !userStreams || userStreams.length === 0

  const timeInSeconds = (new Date().getTime() / 1000).toFixed(0);
  const scoresAsForces = convertToForceWindows(hourlyStarGraph)
  const { activeScore, currentAcceleration } = calculateAccelerationAtTime(scoresAsForces, timeInSeconds);


  const [ home, setHome ] = useState(true)

  return (
    <>
    <div className="mb-[120px]">
      <div className="relative z-[100] max-w-[800px] md:m-auto flex flex-col justify-between ">
        {/* <ShipControls/> */}
        <div className="flex-auto ">


          {/* {!loggedIn && <button
            onClick={() => { to('/auth', 'create_account') }}
            className="z-[5000] h-[45px] sm:h-[60px] transtion-all fixed bottom-[60px] left-0 bg-blue-500 hover:bg-blue-600 w-full flex justify-center items-center ">

            <span className="font-logo text-slate-100 text-2xl" >create account</span>
          </button>
          } */}
        </div>
        <div id="stream-container-1" className=" rounded relative flex-auto px-3 pt-4 mt-2">
          {/* <h1 className="text-2xl dark:text-slate-100 font-logo border-purple-500 border-b-2 pb-1 mb-4">
            streams
          </h1> */}
          <div className="flex justify-between pt-2 px-3 text-slate-900 text-xl dark:text-slate-200 font-bold font-logo">
      Leaderboards
    </div>
    <div className="px-3 text-base py-1 text-slate-400 font-condensed">
        The main 10 topics, each with its own leaderboard
      </div>
          {/* <div className="dark:text-slate-900/60 font-logo text-center font-bold absolute right-6 top-6 bg-gray-200 dark:bg-gray-900 w-16 py-1 rounded-lg">
            <CountdownToMidnight timeMode={"hourly"} />
          </div> */}
          <div className="pb-[120px] relative mx-3 mt-3"

          >
            <TopicList
              isAdmin={isAdmin}
              pages={true}
              pageSize={5}
              index={1}
              themeColor="slate"
              border={false}
              height={70}
              requested={requested}
              startedStreams={startedStreams}
              streams={userStreamsDefault} 
              pastUserFinishesByStream={pastUserFinishesByStream} 
              pastFinishes={pastFinishes}/>

            {/* <TopicCreatorTile 
              onAddStream={addStream}
            selectedIndex={selectedIndex} index={1} onClick={() => onDailyTileClick(1)}/> */}
          </div>
          {/* { showNextBackButtons && ( */}
          {/* <div className="flex justify-between text-purple-300 h-12">
            <button className={( hideBackButton ? "invisible" : "") + " font-logo mt-3"} onClick={() => setTopicPage(p => p - 1)}>
              <IoIosArrowBack className="inline" />back
            </button>
            <button className={(hideNextButton ? "invisible" : "") + " mt-3 font-logo"} onClick={() => setTopicPage(p => p + 1)}>
              next<IoIosArrowForward className="inline" />
            </button>
          </div> */}
          {/* )} */}
          {/* <div className="grid grid-cols-2 gap-1">
            <div        
          onClick={() => {}}
          className={
            (" bg-purple-500/20 ") +
            "  backdrop-blur-sm text-center mt-6 mb-10 py-8 px-4 font-logo rounded-lg transition-all vertical-center"}>
            <div className="text-purple-100 text-xl">stack  <span className="text-purple-500">(3)</span></div>
          </div>
          <div        
          onClick={() => {}}
          className={
            (" bg-purple-500/20 ") +
            "  backdrop-blur-sm text-center mt-6 mb-10 py-8 px-4 font-logo rounded-lg transition-all vertical-center"}>
            <div className="text-purple-100 text-lg">for you</div>
          </div>
        </div> */}
        </div>
      </div>

      {/* <div id="explore-container" className="relative z-[100] h-[200px] px-3">

      </div> */}

    </div>
    </>
  );
};

export const Component = HomePage;