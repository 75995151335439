// import { HiOutlineHome } from "react-icons/hi";
import { SlShuffle } from "react-icons/sl";
import { FaArrowUpRightDots } from "react-icons/fa6";
import { PiHeadCircuit, PiHouse } from "react-icons/pi";

import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { HomePanel } from "./HomePage";
import { PiMagnifyingGlassPlusDuotone } from "react-icons/pi"; // for places you can type
import { StatsPanel } from "./ProfilePage";


export const BottomBar = ({themeColor}) => {

  const to = useNavigate()
  const location = useLocation();

  const [home, setHome] = useState(false);

  useEffect(() => {
    // Reset the home state whenever the URL changes
    setHome(false);

    if (location.pathname == '/a/home') {
      setHome(true);
    } else {
      
    }
  }, [location]);

  return (
    <>
    { home && 
     <div className="bg-white dark:bg-black/40 z-[9000] fixed top-0 bottom-[60px] w-full backdrop-blur-lg"> 
          <div className="h-[100px] bg-slate-900 mb-[20px]">
            <StatsPanel /> 
          </div>
          <div 
          style={{
            height: 'calc(90vh - 240px)',
          }}
          className="relative">
          <HomePanel/>
          </div>
          <div className="h-[50px] text-white text-center pt-4 font-logo">
          Have an issue? <a
            href="mailto:help@anyq.app"
            className="font-logo text-blue-500 hover:text-blue-300"
          >
            Contact us 
          </a>
        </div>
      </div>
        }
    
    <div className="flex items-center text-slate-300 text-3xl justify-around fixed z-[9000] bottom-0 left-0 w-screen shadow text-center h-[60px] bg-slate-400/10 dark:bg-gray-900/40 backdrop-blur-lg">
      {/* <NavLink to='/shuffle'
                className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "text-slate-900 dark:text-slate-100" : ""
                }>
                <SlShuffle className=''/>
            </NavLink> */}
      <div className="w-[40px]">

      </div>
      <button
        // to="/a/home"
        onClick={() => {
          
          // if (home) {
          //   to("/a/home")
          // }
          if (location.pathname != '/a/home') {
            setHome(h => !h)
          }
        }}
        className={({ isActive, isPending }) =>
          isPending
            ? "pending"
            : isActive
              ? "dark:text-slate-100 text-slate-500"
              : "hover:text-slate-500 dark:hover:text-slate-400 text-slate-400"
        }
      >
        <div className="flex flex-col items-center">
          <PiHouse />
          <div className="text-xs font-logo">Home</div>
        </div>
      </button>
            {/* <NavLink
        to="/a/home/library"
        className={({ isActive, isPending }) =>
          isPending
            ? "pending"
            : isActive
              ? "dark:text-slate-100 text-slate-500"
              : "hover:text-slate-500 dark:hover:text-slate-400 text-slate-400"
        }
      >
        <div className="flex flex-col items-center">
          <PiMagnifyingGlassPlusDuotone 
          // className="-scale-x-100"
          />
          <div className="text-xs font-logo">Search</div>
        </div>
        </NavLink> */}
      <NavLink
        to="/a/profile"
        className={({ isActive, isPending }) =>
          isPending
            ? "pending"
            : isActive
              ? "dark:text-slate-100 text-slate-500"
              : "hover:text-slate-500 dark:hover:text-slate-400 text-slate-400"
        }
      >
        <div className="flex flex-col items-center">
          <PiHeadCircuit 
          // className="-scale-x-100"
          />
          <div className="text-xs font-logo">Profile</div>
        </div>
        </NavLink>
      {/* <FaArrowUpRightDots /> */}
    </div>
    </>
  );
};
