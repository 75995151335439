export const connectTagToStreamEdgeTagged2 = /* GraphQL */ `
  mutation ConnectTagToStreamEdgeTagged($from_id: ID!, $to_id: ID!) {
    connectTagToStreamEdgeTagged(from_id: $from_id, to_id: $to_id) {
      _id
      createdAt
      __typename
    }
  }
`;

export const createTag2 = /* GraphQL */ `
  mutation CreateTag($input: TagInput!) {
    createTag(input: $input) {
      _id
      name
      createdAt
      __typename
    }
  }
`;

export const createPlay2 = /* GraphQL */ `
  mutation CreatePlay($input: PlayInput!) {
    createPlay(input: $input) {
      _id

      start_time
      finished
      correct
      incorrect
      rounds
      score
      speedScore
      dayInt
      weekInt
      monthInt
      yearInt


      __typename
    }
  }
`;
// this one
export const updatePlay2 = /* GraphQL */ `
  mutation UpdatePlay($input: PlayInput!) {
    updatePlay(input: $input) {
      _id
      start_time
      score
      finished
      __typename
    }
  }
`;

export const createAnswer2 = /* GraphQL */ `
  mutation CreateAnswer($input: AnswerInput!) {
    createAnswer(input: $input) {
      _id
      created_at
      answer_id
      was_correct
      __typename
    }
  }
`;

export const createStream2 = /* GraphQL */ `
  mutation CreateStream($input: StreamInput!) {
    createStream(input: $input) {
      _id
      name
      createdAt
      __typename
    }
  }
`;

export const createTopic2 = /* GraphQL */ `
  mutation CreateTopic($input: TopicInput!) {
    createTopic(input: $input) {
      _id
      difficulty
      name
      __typename
    }
  }
`;

export const createSearch2 = /* GraphQL */ `
  mutation CreateSearch($input: SearchInput!) {
    createSearch(input: $input) {
      _id
      text
      createdAt
      __typename
    }
  }
`;

export const createOrGetUser2 = /* GraphQL */ `
  mutation CreateOrGetUser($input: UserInput!, $createdAt: String) {
    createOrGetUser(input: $input, createdAt: $createdAt) {
      _id
      user_id
      name
      __typename
    }
  }
`;

export const createQuestionSetComplete = /* GraphQL */ `
  mutation CreateQuestionSetComplete(
    $input: QuestionsetInput!
    $questionIds: [String]
  ) {
    createQuestionsetComplete(input: $input, questionIds: $questionIds) {
      _id
      title
      description
      published_date
      questionnodeContainssOut {
        _id
        id
        version
        __typename
      }
      __typename
    }
  }
`;