import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';



export const standardUserIdentifier = async () => {
  let userIdentifier = "";
  let loggedIn = false;
  try {
    const user = await getCurrentUser()
    userIdentifier = user.username;
    // console.log('userIdentifier', userIdentifier)
    // const session = await fetchAuthSession()
    // userIdentifier = session.identityId;
    // console.log('userIdentifier', userIdentifier)
    // userIdentifier = session.userSub; // same as username
    // console.log('userIdentifier', userIdentifier)
    loggedIn = true;
  } catch(e) {
    const session = await fetchAuthSession()
    userIdentifier = session.identityId;
  }
  return [userIdentifier, loggedIn]
};
