
import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCheckmarkCircleOutline,
  IoMdReturnRight,
  IoIosClose
} from "react-icons/io";

import { process } from '../api/lm'
import {
  client,
} from "../api/questions";
import { IoIosSearch } from "react-icons/io";



const variants = {
  open: { padding: '12px 10px 10px', height: 'auto', opacity: 1 },
  closed: { padding: '12px 10px 0px', height: '0px', opacity: 0 }
};


export const TopicGenerator = ({ onBuilding, onClear, buttonText, onSubmit, displayedText, setUserStreams, setTopicPage }) => {

  let buttonText_ = buttonText || "start"

  const [splits, setSplits] = useState([])
  const [streamName, setStreamName] = useState('');
  const [finding, setFinding] = useState(false)
  const [streaming, setStreaming] = useState(false)
  const [streamingDone, setStreamingDone] = useState(true)
  const [existingStream, setExistingStream] = useState(null)
  const [toggle, setToggle] = useState(true)

  const extractTopicFields = (row) => {
    let { name, _id } = row;

    const fields = { name, _id };

    const filteredFields = Object.keys(fields)
      .filter((key) => fields[key] !== undefined && fields[key] !== null && fields[key] !== "")
      .reduce((obj, key) => {
        obj[key] = fields[key];
        return obj;
      }, {});


    return filteredFields;
  };

  const onFieldsReady = (msg, done) => {

    if (done) {
      setStreamingDone(true)
      if (!msg) return
    }

    const msgFiltered = msg.filter((m) => m.name)

    if (msgFiltered.length > 0) setSplits(msgFiltered)

    // setSplits(splits => {

    //   // if any key in splits is in msg.name, then replace that key with msg

    //   let replaced = false
    //   const newSplits = splits.map(split => {
    //     if (msg.name.includes(split.name) && split.status !== 'saved') {
    //       replaced = true
    //       return msg
    //     }
    //     return split
    //   })

    //   if (!replaced) {
    //     newSplits.push(msg)
    //   }

    //   return newSplits
    // })
  }


  const findStream = async () => {
    client.streams.saveSearch(streamName)
    window.gtag("event", "findStream", {
      event_category: "engagement",
    });
    window.ttq.track('Search', {
      "contents": [
        // {
        //   "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
        //   "content_type": "<content_type>", // string. Either product or product_group.
        //   "content_name": "<content_name>", // string. The name of the page or product. Example: "shirt".
        //   "content_category": "<content_category>", // string. The category of the page or product. Example: "apparel".
        //   "quantity": "<content_quantity>", // number. The number of items. Example: 4.
        //   "price": "<content_price>", // number. The price of a single item. Example: 25.
        //   "brand": "<brand>" // string. The brand name of the page or product. Example: "Nike".
        // }
      ],
      "query": streamName // string. The word or phrase used to search. Example: "SAVE10COUPON".
    });
    if (onBuilding) onBuilding()
    setFinding(true)
    const system = "When provided a topic, return back the main subtopics of the provided topic."
    const format = "Return a list of topic title, no more than a few words each. Format your response as a json list of topics, each with a name property"
    const prompt2 = system + format

    let childTopics
    try {
      const stream = await client.streams.get(streamName)
      childTopics = stream.topicIncludesOut.map(topic => ({ ...topic, status: 'saved' }))

      if (childTopics.length > 8) {
        childTopics = childTopics.slice(0, 8)
      }
      const streamId = stream._id
      // const topic = await client.topics.getChildTopicsWithStreams('medium', streamName)
      setStreaming(false)
      setExistingStream(streamId)
      // after 1000ms, set the splits
      setTimeout(() => {
        setSplits(childTopics)
      }, 250)


    } catch (e) {
      setStreaming(true)
      setStreamingDone(false)
      process(prompt2, streamName, extractTopicFields, onFieldsReady, true)
      return
    }




  }

  const addStream = async () => {
    window.gtag("event", "addStream", {
      event_category: "engagement",
    });

    setToggle(t => !t)
    var element = document.getElementById("stream-container-2");
    const topPosition = element.getBoundingClientRect().top + window.pageYOffset - 20;
    window.scrollTo({
      top: topPosition,
      behavior: 'smooth',
    });
    if (existingStream) {
      setUserStreams(e => {
        const newPinned = [{ _id: existingStream, name: streamName, priority: true }, ...e]
        return newPinned
      })
      setTopicPage(0)
      setSplits([])
      setStreamName('')
      setFinding(false)
      setToggle(t => !t)
      if (onSubmit) onSubmit()
      client.streams.maybeGen(existingStream)
      const resp = await client.streams.follow(existingStream)

    } else {
      const topic = {
        name: streamName,
        subtopics: splits.map((split) => ({ name: split.name }))
      }
      setUserStreams(e => {
        const newPinned = [{ name: streamName, priority: true }, ...e]
        return newPinned
      })
      setTopicPage(0)
      setSplits([])
      setStreamName('')
      setFinding(false)
      setToggle(t => !t)
      if (onSubmit) onSubmit()
      const stream = await client.streams.make(topic)
      // now go back and add stream._id to the correct stream in pinned streams
      setUserStreams(e => {
        const filtered = e.filter((s) => s.name !== streamName)
        return [{ _id: stream._id, name: streamName, priority: true }, ...filtered]
      })

    }




  }

  const onChange = (e) => {
    if (streamName === "")  {
      window.gtag("event", "firstStreamInput", {
        event_category: "engagement",
      });
    }
    setStreamName(e.target.value)

  }
  const [hideCursor, setHideCursor] = useState(false)
  const onFocus = () => {
    setHideCursor(true)
    window.gtag("event", "focusStreamInput", {
      event_category: "engagement",
    });
  }
  const textSize = finding ?
    streamName.length > 20 ? "text-4xl" : "text-4xl"
    :
    streamName.length > 20 ? "text-4xl" : "text-4xl"


  return (

    <div id="new-stream-container" className={(finding ? "min-h-[370px] " : "") + (splits.length > 0 ? " bg-blue-500/10 dark:bg-slate-800/70  backdrop-blur-lg rounded-lg " : "") + " transition-all max-w-[500px] m-auto"}>
      {splits.length > 0 && (
        <div>
          <button
            onClick={() => {
              setStreamName(""); setSplits([]); setFinding(false); if (onClear) onClear()
              // var element = document.getElementById("stream-container");
              // const topPosition = element.getBoundingClientRect().top + window.pageYOffset + 200;
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });

            }}
            className="text-2xl text-blue-400 hover:text-blue-500 active:text-blue-500 hover:scale-150 transition-all absolute z-[100] top-3 right-3">
            <IoIosClose />
          </button>
        </div>
      )}
      {/* <button className="w-20 h-10 bg-blue-500 rounded" onClick={reset}>Reset</button>
                <button className="w-20 h-10 bg-blue-500 rounded" onClick={() => changeFilter({passed: false})}>fails only</button> */}
      <div className="relative -bottom-14">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: splits.length > 0 ? '100%' : finding ? '70%' : 0 }}
          transition={{ duration: splits.length > 0 ? 0.3 : finding ? 2 : 0 }}


          className="border-b-2 border-blue-500/60 mx-auto"></motion.div>
      </div>
      <div className='flex justify-center items-center relative gap-1 max-w-[400px] mx-auto'>

        <input
          autoCapitalize="off"
          type="text" placeholder={displayedText} value={streamName}
          disabled={splits.length > 0}
          onFocus={onFocus}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // Call the function to handle the submission
              findStream();
              // removed focus
              e.target.blur();
              setTimeout(() => {
                var element = document.getElementById("new-stream-container");
                const topPosition = element.getBoundingClientRect().top + window.pageYOffset - 100;
                window.scrollTo({
                  top: topPosition,
                  behavior: 'smooth',
                });
              }, 100)
              // element.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          className={
            (finding ?
              streamName.length > 20 ? "text-lg" : "text-2xl"
              :
              streamName.length > 20 ? "text-lg" : "text-xl")
            +
            (finding ? " bg-blue-500/0" : " bg-slate-300/30 dark:bg-slate-800/70  left-0 backdrop-blur-sm") + " pl-5 text-left relative transition-all " +
            " dark:placeholder:text-white/60 placeholder:text-black/50 placeholder:font-logo disabled:text-black dark:disabled:text-white dark:text-white font-logo  rounded-lg p-4 w-[400px]"} />
            {!hideCursor &&
            <>
            <IoIosSearch className="absolute top-4 right-4 text-slate-400 text-2xl"/>
            <motion.div
          className="absolute left-3 top-1/2 transform -translate-y-1/2 w-[2px] h-6 bg-black dark:bg-white"
          animate={{ opacity: [0, 1] }}
          transition={{ duration: 0.5, repeat: Infinity, repeatType: 'reverse' }}
        />
        </>
            }

        {streamName && !finding && (
          <div className="absolute right-0 top-0 rounded-lg">
            <button onClick={findStream} className="flex flex-col py-3 px-3 justify-center text-4xl text-blue-500 dark:text-blue-200 hover:text-blue-500 active:text-blue-500 hover:scale-150 transition-all">
              <IoIosArrowForward />
            </button>
          </div>
        )}

        <div className="absolute -bottom-12">

          <div className="">
            {streamName && !finding && (
              <motion.div
                initial="closed"
                animate={splits.length > 0 ? "closed" : "open"}
                variants={variants}
                className="dark:text-blue-200 box-content overflow-hidden w-[95%]"

              >
                type <span className="font-bold dark:text-blue-100">return</span> to enter
              </motion.div>
            )}
          </div>

          {/* {streamName && (
                                        <button
                                            onClick={() => {}}
                                            className="text-4xl text-blue-400 hover:text-blue-500 absolute top-[13px] right-[10px]">
                                            <IoIosCheckmarkCircleOutline />

                                        </button>
                                    )} */}

        </div>

      </div>
      <div className="mt-4 text-center mx-0 sm:mx-8 leading-7">
        {/* <div className="font-logo text-blue-300 border-b-2 border-blue-500 my-1 py-1">
                1 - Introduction
              </div> */}
        <div className="px-6 pb-3">
          <AnimatePresence>
            {toggle && splits.length && (
              splits.map((split, i) => {
                return (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, height: 0, transition: { delay: Math.sqrt((splits.length - i + 2)) * (0.3 / splits.length) } }}
                    transition={{ delay: streaming ? 0 : (0.3 + i * (1.3 / splits.length)) }}
                    className="text-left text-blue-950/90 dark:text-blue-100/90 font-logo rounded-lg relative flex justify-start items-center gap-2">
                    <IoMdReturnRight className="text-blue-600/50 dark:text-blue-400/50" />
                    <div className="rounded-lg w-full ">
                      {split.name.toLowerCase()}
                    </div>
                  </motion.div>
                )
              })
            )}
          </AnimatePresence>
        </div>
        {toggle && splits.length > 0 && streamingDone &&
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: streaming ? 0 : 0.1 + 1.3 }}
            className="text-center my-2 sm:my-4"
          >
            <button
              onClick={addStream}
              // onClick={() => setToggle(t => !t)}
              className="cursor-pointer bg-blue-500 transition-all hover:bg-blue-500/70 hover:scale-[.95] my-4 px-12 py-3 rounded-lg text-white text-lg font-logo">{buttonText_}</button>

          </motion.div>
        }
      </div>
    </div>


  )
}