/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStreamLeaderboard = /* GraphQL */ `
  query GetStreamLeaderboard($filter: StreamInput) {
    getStreamLeaderboard(filter: $filter) {
      user_id
      score
      __typename
    }
  }
`;
export const getTopicLeaderboard = /* GraphQL */ `
  query GetTopicLeaderboard($filter: TopicInput) {
    getTopicLeaderboard(filter: $filter) {
      user_id
      score
      __typename
    }
  }
`;
export const getStreamAverageScore = /* GraphQL */ `
  query GetStreamAverageScore($filter: StreamInput) {
    getStreamAverageScore(filter: $filter) {
      user_id
      score
      __typename
    }
  }
`;
export const getTopicAverageScore = /* GraphQL */ `
  query GetTopicAverageScore($filter: TopicInput) {
    getTopicAverageScore(filter: $filter) {
      user_id
      score
      __typename
    }
  }
`;
export const getUserQuestionsToReview = /* GraphQL */ `
  query GetUserQuestionsToReview($filter: UserInput) {
    getUserQuestionsToReview(filter: $filter) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      userAnsweredsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userAskedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetContainsIn {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runMadeIn {
        _id
        id
        createdAt
        version
        __typename
      }
      answerTosIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      topicInOut {
        _id
        difficulty
        name
        __typename
      }
      rulesPassedIn {
        _id
        name
        __typename
      }
      rulesFailedIn {
        _id
        name
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      TO {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      PASSED {
        _id
        __typename
      }
      FAILED {
        _id
        __typename
      }
      MADE {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($filter: TopicInput) {
    getTopic(filter: $filter) {
      _id
      difficulty
      name
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userEngagedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userSkippedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      topicSubsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSubsIn {
        _id
        difficulty
        name
        __typename
      }
      userSelectedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionnodeInsIn {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      streamIncludesIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      unansweredQuestions {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      SUB {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics($filter: TopicInput, $options: Options) {
    listTopics(filter: $filter, options: $options) {
      _id
      difficulty
      name
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userEngagedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userSkippedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      topicSubsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSubsIn {
        _id
        difficulty
        name
        __typename
      }
      userSelectedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionnodeInsIn {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      streamIncludesIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      unansweredQuestions {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      SUB {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const listRuns = /* GraphQL */ `
  query ListRuns($filter: RunInput, $options: Options) {
    listRuns(filter: $filter, options: $options) {
      _id
      id
      createdAt
      version
      questionnodeMadesOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      streamSpawnedIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      MADE {
        _id
        __typename
      }
      SPAWNED {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($filter: TagInput) {
    getTag(filter: $filter) {
      _id
      name
      createdAt
      streamTaggedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      TAGGED {
        _id
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const getStream = /* GraphQL */ `
  query GetStream($filter: StreamInput) {
    getStream(filter: $filter) {
      _id
      name
      latestVersion
      createdAt
      topicIncludesOut {
        _id
        difficulty
        name
        __typename
      }
      userFollowingsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetMadesOut {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runSpawnedsOut {
        _id
        id
        createdAt
        version
        __typename
      }
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userRequestedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userViewedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userOpenedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userStartedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userCompletedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      tagTaggedsIn {
        _id
        name
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      MADE {
        _id
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      SPAWNED {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      COMPLETED {
        _id
        occuredAt
        __typename
      }
      TAGGED {
        _id
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const listStreams = /* GraphQL */ `
  query ListStreams($filter: StreamInput, $options: Options) {
    listStreams(filter: $filter, options: $options) {
      _id
      name
      latestVersion
      createdAt
      topicIncludesOut {
        _id
        difficulty
        name
        __typename
      }
      userFollowingsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetMadesOut {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runSpawnedsOut {
        _id
        id
        createdAt
        version
        __typename
      }
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userRequestedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userViewedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userOpenedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userStartedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userCompletedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      tagTaggedsIn {
        _id
        name
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      MADE {
        _id
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      SPAWNED {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      COMPLETED {
        _id
        occuredAt
        __typename
      }
      TAGGED {
        _id
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($filter: UserInput) {
    getUser(filter: $filter) {
      _id
      user_id
      name
      createdAt
      streamRankedsIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      topicRankedsIn {
        _id
        difficulty
        name
        __typename
      }
      streamFollowingsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamRequestedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamViewedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamOpenedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamStartedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamCompletedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      playStartedsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      topicEngagedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSkippedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSelectedsOut {
        _id
        difficulty
        name
        __typename
      }
      answerGavesOut {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      questionnodeAskedsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      questionnodeAnsweredsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      searchAskedsOut {
        _id
        text
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      GAVE {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      __typename
    }
  }
`;
export const getUserPlayScore = /* GraphQL */ `
  query GetUserPlayScore($filter: UserInput) {
    getUserPlayScore(filter: $filter) {
      user_id
      score
      __typename
    }
  }
`;
export const getUserTotalCorrectAnswers = /* GraphQL */ `
  query GetUserTotalCorrectAnswers($filter: UserInput) {
    getUserTotalCorrectAnswers(filter: $filter) {
      user_id
      score
      __typename
    }
  }
`;
export const getUserTotalIncorrectAnswers = /* GraphQL */ `
  query GetUserTotalIncorrectAnswers($filter: UserInput) {
    getUserTotalIncorrectAnswers(filter: $filter) {
      user_id
      score
      __typename
    }
  }
`;
export const getUserTopicUnansweredQuestions = /* GraphQL */ `
  query GetUserTopicUnansweredQuestions(
    $filter: UserInput
    $topicFilter: TopicInput
  ) {
    getUserTopicUnansweredQuestions(
      filter: $filter
      topicFilter: $topicFilter
    ) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      userAnsweredsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userAskedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetContainsIn {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runMadeIn {
        _id
        id
        createdAt
        version
        __typename
      }
      answerTosIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      topicInOut {
        _id
        difficulty
        name
        __typename
      }
      rulesPassedIn {
        _id
        name
        __typename
      }
      rulesFailedIn {
        _id
        name
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      TO {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      PASSED {
        _id
        __typename
      }
      FAILED {
        _id
        __typename
      }
      MADE {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const getUserSubtopicUnansweredQuestions = /* GraphQL */ `
  query GetUserSubtopicUnansweredQuestions(
    $filter: UserInput
    $topicFilter: TopicInput
  ) {
    getUserSubtopicUnansweredQuestions(
      filter: $filter
      topicFilter: $topicFilter
    ) {
      _id
      difficulty
      name
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userEngagedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userSkippedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      topicSubsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSubsIn {
        _id
        difficulty
        name
        __typename
      }
      userSelectedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionnodeInsIn {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      streamIncludesIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      unansweredQuestions {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      SUB {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: UserInput, $options: Options) {
    listUsers(filter: $filter, options: $options) {
      _id
      user_id
      name
      createdAt
      streamRankedsIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      topicRankedsIn {
        _id
        difficulty
        name
        __typename
      }
      streamFollowingsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamRequestedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamViewedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamOpenedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamStartedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamCompletedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      playStartedsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      topicEngagedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSkippedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSelectedsOut {
        _id
        difficulty
        name
        __typename
      }
      answerGavesOut {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      questionnodeAskedsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      questionnodeAnsweredsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      searchAskedsOut {
        _id
        text
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      GAVE {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      __typename
    }
  }
`;
export const getQuestionnode = /* GraphQL */ `
  query GetQuestionnode($filter: QuestionnodeInput) {
    getQuestionnode(filter: $filter) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      userAnsweredsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userAskedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetContainsIn {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runMadeIn {
        _id
        id
        createdAt
        version
        __typename
      }
      answerTosIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      topicInOut {
        _id
        difficulty
        name
        __typename
      }
      rulesPassedIn {
        _id
        name
        __typename
      }
      rulesFailedIn {
        _id
        name
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      TO {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      PASSED {
        _id
        __typename
      }
      FAILED {
        _id
        __typename
      }
      MADE {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const listQuestionnodes = /* GraphQL */ `
  query ListQuestionnodes($filter: QuestionnodeInput, $options: Options) {
    listQuestionnodes(filter: $filter, options: $options) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      userAnsweredsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userAskedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetContainsIn {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runMadeIn {
        _id
        id
        createdAt
        version
        __typename
      }
      answerTosIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      topicInOut {
        _id
        difficulty
        name
        __typename
      }
      rulesPassedIn {
        _id
        name
        __typename
      }
      rulesFailedIn {
        _id
        name
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      TO {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      PASSED {
        _id
        __typename
      }
      FAILED {
        _id
        __typename
      }
      MADE {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const getQuestionset = /* GraphQL */ `
  query GetQuestionset($filter: QuestionsetInput) {
    getQuestionset(filter: $filter) {
      _id
      type
      title
      published_date
      createdAt
      version
      id
      questionnodeContainssOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      playOfsIn {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      streamMadeIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      OF {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const listQuestionsets = /* GraphQL */ `
  query ListQuestionsets($filter: QuestionsetInput, $options: Options) {
    listQuestionsets(filter: $filter, options: $options) {
      _id
      type
      title
      published_date
      createdAt
      version
      id
      questionnodeContainssOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      playOfsIn {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      streamMadeIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      OF {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($filter: AnswerInput) {
    getAnswer(filter: $filter) {
      _id
      created_at
      answer_id
      was_correct
      questionnodeToOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      userGaveIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      playDuringsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      TO {
        _id
        __typename
      }
      GAVE {
        _id
        __typename
      }
      DURING {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers($filter: AnswerInput, $options: Options) {
    listAnswers(filter: $filter, options: $options) {
      _id
      created_at
      answer_id
      was_correct
      questionnodeToOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      userGaveIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      playDuringsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      TO {
        _id
        __typename
      }
      GAVE {
        _id
        __typename
      }
      DURING {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const getPlay = /* GraphQL */ `
  query GetPlay($filter: PlayInput) {
    getPlay(filter: $filter) {
      _id
      gameMode
      userStartedIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      start_time
      finished
      correct
      incorrect
      rounds
      score
      speedScore
      dayInt
      weekInt
      monthInt
      yearInt
      questionsetOfOut {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      answerDuringsIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      OF {
        _id
        __typename
      }
      DURING {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const listPlays = /* GraphQL */ `
  query ListPlays($filter: PlayInput, $options: Options) {
    listPlays(filter: $filter, options: $options) {
      _id
      gameMode
      userStartedIn {
        # _id
        user_id
        name
        # createdAt
        __typename
      }
      start_time
      finished
      correct
      incorrect
      rounds
      score
      speedScore
      dayInt
      weekInt
      monthInt
      yearInt
      
      __typename
    }
  }
`;
export const getWebhookEvents = /* GraphQL */ `
  query GetWebhookEvents($id: ID!) {
    getWebhookEvents(id: $id) {
      id
      _ttl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWebhookEvents = /* GraphQL */ `
  query ListWebhookEvents(
    $filter: ModelWebhookEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebhookEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        _ttl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWebhookEvents = /* GraphQL */ `
  query SyncWebhookEvents(
    $filter: ModelWebhookEventsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWebhookEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        _ttl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      type
      text
      version
      correctAnswers
      correctAnswer
      incorrectAnswers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        text
        version
        correctAnswers
        correctAnswer
        incorrectAnswers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuestions = /* GraphQL */ `
  query SyncQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        text
        version
        correctAnswers
        correctAnswer
        incorrectAnswers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserQuestionAnswer = /* GraphQL */ `
  query GetUserQuestionAnswer($userId: String!, $topic: String!, $id: String!) {
    getUserQuestionAnswer(userId: $userId, topic: $topic, id: $id) {
      id
      userId
      topic
      question {
        id
        type
        text
        version
        correctAnswers
        correctAnswer
        incorrectAnswers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chosenAnswers
      wasCorrect
      timeToAnswer
      points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userQuestionAnswerQuestionId
      owner
      __typename
    }
  }
`;
export const listUserQuestionAnswers = /* GraphQL */ `
  query ListUserQuestionAnswers(
    $userId: String
    $topicId: ModelUserQuestionAnswerPrimaryCompositeKeyConditionInput
    $filter: ModelUserQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserQuestionAnswers(
      userId: $userId
      topicId: $topicId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        topic
        chosenAnswers
        wasCorrect
        timeToAnswer
        points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userQuestionAnswerQuestionId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserQuestionAnswers = /* GraphQL */ `
  query SyncUserQuestionAnswers(
    $filter: ModelUserQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserQuestionAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        topic
        chosenAnswers
        wasCorrect
        timeToAnswer
        points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userQuestionAnswerQuestionId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserSurvey = /* GraphQL */ `
  query GetUserSurvey(
    $userId: String!
    $requestChannel: String!
    $id: String!
  ) {
    getUserSurvey(userId: $userId, requestChannel: $requestChannel, id: $id) {
      id
      userId
      requestChannel
      startedAt
      requestTopic
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserSurveys = /* GraphQL */ `
  query ListUserSurveys(
    $userId: String
    $requestChannelId: ModelUserSurveyPrimaryCompositeKeyConditionInput
    $filter: ModelUserSurveyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSurveys(
      userId: $userId
      requestChannelId: $requestChannelId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        requestChannel
        startedAt
        requestTopic
        completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserSurveys = /* GraphQL */ `
  query SyncUserSurveys(
    $filter: ModelUserSurveyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSurveys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        requestChannel
        startedAt
        requestTopic
        completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFact = /* GraphQL */ `
  query GetFact($date: String!, $topic: String!, $id: String!) {
    getFact(date: $date, topic: $topic, id: $id) {
      id
      date
      topic
      text
      interactions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFacts = /* GraphQL */ `
  query ListFacts(
    $date: String
    $topicId: ModelFactPrimaryCompositeKeyConditionInput
    $filter: ModelFactFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFacts(
      date: $date
      topicId: $topicId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        topic
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFacts = /* GraphQL */ `
  query SyncFacts(
    $filter: ModelFactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        topic
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserFactInteraction = /* GraphQL */ `
  query GetUserFactInteraction(
    $userId: String!
    $id: String!
    $action: String!
  ) {
    getUserFactInteraction(userId: $userId, id: $id, action: $action) {
      id
      userId
      fact {
        id
        date
        topic
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      action
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      factInteractionsDate
      factInteractionsTopic
      factInteractionsId
      owner
      __typename
    }
  }
`;
export const listUserFactInteractions = /* GraphQL */ `
  query ListUserFactInteractions(
    $userId: String
    $idAction: ModelUserFactInteractionPrimaryCompositeKeyConditionInput
    $filter: ModelUserFactInteractionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserFactInteractions(
      userId: $userId
      idAction: $idAction
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        action
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        factInteractionsDate
        factInteractionsTopic
        factInteractionsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserFactInteractions = /* GraphQL */ `
  query SyncUserFactInteractions(
    $filter: ModelUserFactInteractionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserFactInteractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        action
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        factInteractionsDate
        factInteractionsTopic
        factInteractionsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
