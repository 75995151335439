import { jsonrepair } from "jsonrepair";
import { fetchWithRetry } from "../util/fetch";
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
// import { Signer } from '@aws-amplify/core';
import { Signer } from './auth/Signer';


export const processTextOnly = async (system, message, onFieldsReady) => {
  const messages = [
    {
      role: "system",
      content: system
    },
    {
      role: "user",
      content: message
    },
  ];

  const model = "gpt-4o"

  const request = await prepareRequest(model, messages);



  const response = await fetchWithRetry(request.url, {
    method: request.method,
    headers: request.headers,
    body: request.body,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Create a ReadableStream
  const reader = response.body.getReader();

  // Read the stream
  let result = await reader.read();

  // Decode streamed data
  const decoder = new TextDecoder("utf-8");
  let respSum = "";

  while (!result.done) {
    respSum += decoder.decode(result.value || new Uint8Array(), {
      stream: true,
    });

    onFieldsReady(respSum);

    result = await reader.read();
  }

  // handle the last chunk
  respSum += decoder.decode(result.value || new Uint8Array(), { stream: true });

  onFieldsReady(respSum, true);
}

export const process = async (system, message, extractFields, onFieldsReady, expectList) => {

  const messages = [
    {
      role: "system",
      content: system
    },
    {
      role: "user",
      content: message
    },
  ];

  const model = "gpt-4o"

  const request = await prepareRequest(model, messages);


  const response = await fetchWithRetry(request.url, {
    method: request.method,
    headers: request.headers,
    body: request.body,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Create a ReadableStream
  const reader = response.body.getReader();

  // Read the stream
  let result = await reader.read();

  // Decode streamed data
  const decoder = new TextDecoder("utf-8");
  let respSum = "";

  while (!result.done) {
    respSum += decoder.decode(result.value || new Uint8Array(), {
      stream: true,
    });

    const fields = processChunk(respSum, extractFields, expectList);
    if (Object.keys(fields).length > 0) {
      onFieldsReady(fields);
    }

    result = await reader.read();
  }

  // handle the last chunk
  respSum += decoder.decode(result.value || new Uint8Array(), { stream: true });

  const fields = processChunk(respSum, extractFields,expectList, true);
  if (Object.keys(fields).length <= 0) {
    console.warn("something is wrong ", respSum);
  }
  if (Object.keys(fields).length !== 0) {
    onFieldsReady(fields, true);
  } else {
    onFieldsReady(null, true);
  }
};

const prepareRequest = async (model, messages) => {
  let request = {};
  let loggedIn = false;
  const user = await fetchAuthSession()
  if (!user) return
  if (!user.tokens) {
    let url = "https://5umt2hsw3u7oh4plhwvgitz2tm0idyyq.lambda-url.us-west-2.on.aws/"



    request = {
      url,
      data: JSON.stringify({ model, messages }), // for signed request
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const creds = user.credentials;
    const credentials = {
      secret_key: creds.secretAccessKey,
      access_key: creds.accessKeyId,
      session_token: creds.sessionToken,
    };
    const region = "us-west-2";
    const service = "lambda";
    const serviceInfo = { region, service };
    request = Signer.sign(request, credentials, serviceInfo);


  } else {
    let url = "https://zupro5wau5a4hftiomdhii5cru0pyyjh.lambda-url.us-west-2.on.aws/"

    request = {
      url,
      body: JSON.stringify({ model, messages }), // for unsigned
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    request.headers.Authorization = `Bearer ${(await fetchAuthSession()).tokens.idToken}`

  }


  return request
}


const processChunk = (respData, extractor, expectList, final) => {
  let mergedFields = {};
  let fieldsList = []
  // remove any newlines and/or extra brackets
  let respDataProcessed = final
    ? respData
    : respData.replace(/\n/g, "").replace(/^[^{}]*(?=\{)/, "");


  // if respData starts with ```json, remove it
  if (respDataProcessed.startsWith("```json")) {
    respDataProcessed = respDataProcessed.replace("```json", "");
    respDataProcessed = respDataProcessed.replace("```", "");
  }

  // make sure we still have something after that
  if (respDataProcessed !== "") {
    // try to repair the json
    try {
      respDataProcessed = jsonrepair(respDataProcessed);
    } catch (e) {
      console.warn("failed to repair", e, respDataProcessed);
      return mergedFields;
    }


    // if the first and last character are not "[" and "]", then add them
    if (respDataProcessed[0] !== "[")
      respDataProcessed = "[" + respDataProcessed + "]";

    // make sure we have a match after repair
    if (respDataProcessed !== undefined) {
      try {
        // try to parse the json
        respDataProcessed = JSON.parse(respDataProcessed);
      } catch (e) {
        console.warn("failed to parse", e, respDataProcessed);

        // drop everything after the last curly brace
        respDataProcessed = respDataProcessed.replace(
          /([\s\S]*\})[\s\S]*/,
          "$1",
        );
        // and re-add the square bracket
        respDataProcessed = respDataProcessed + "]";
        try {
          respDataProcessed = JSON.parse(respDataProcessed);
        } catch (e) {
          console.warn("failed to parse again", e, respDataProcessed);
          return {};
        }
      }

      // make sure we still have a match after parsing
      if (respDataProcessed !== undefined) {
        // ensure we have an array
        if (!Array.isArray(respDataProcessed))
          respDataProcessed = [respDataProcessed];

        // process each match
        respDataProcessed.forEach((m) => {
          const fields = extractor(m);

          // okay the issue is that we are overriding by field name. this works when we are expecting different fields, but not for a list

          // merge fields with mergedFields

          fieldsList.push(fields)

          mergedFields = { ...mergedFields, ...fields };

        });
      }
    }
  }

  return expectList ? fieldsList : mergedFields;
};




