


import { getSigningValues } from './getSigningValues';
import { getSignature } from './getSignature';

 const ALGORITHM_QUERY_PARAM = 'X-Amz-Algorithm';
 const AMZ_DATE_QUERY_PARAM = 'X-Amz-Date';
 const CREDENTIAL_QUERY_PARAM = 'X-Amz-Credential';
 const EXPIRES_QUERY_PARAM = 'X-Amz-Expires';
 const SIGNATURE_QUERY_PARAM = 'X-Amz-Signature';
 const SIGNED_HEADERS_QUERY_PARAM = 'X-Amz-SignedHeaders';
 const TOKEN_QUERY_PARAM = 'X-Amz-Security-Token';

// headers
 const HOST_HEADER = 'host';

// identifiers
 const SHA256_ALGORITHM_IDENTIFIER = 'AWS4-HMAC-SHA256';

// preset values

const AmplifyUrl = URL;
const AmplifyUrlSearchParams = URLSearchParams;
/**
 * Given a `Presignable` object, returns a Signature Version 4 presigned `URL` object.
 *
 * @param presignable `Presignable` object containing at least a url to be presigned with authentication query params.
 * @param presignUrlOptions `PresignUrlOptions` object containing values used to construct the signature.
 * @returns A `URL` with authentication query params which can grant temporary access to AWS resources.
 */
export const presignUrl = (
	{ body, method = 'GET', url },
	{ expiration, ...options },
) => {
	const signingValues = getSigningValues(options);
	const { accessKeyId, credentialScope, longDate, sessionToken } =
		signingValues;

	// create the request to sign
	const presignedUrl = new AmplifyUrl(url);
	Object.entries({
		[ALGORITHM_QUERY_PARAM]: SHA256_ALGORITHM_IDENTIFIER,
		[CREDENTIAL_QUERY_PARAM]: `${accessKeyId}/${credentialScope}`,
		[AMZ_DATE_QUERY_PARAM]: longDate,
		[SIGNED_HEADERS_QUERY_PARAM]: HOST_HEADER,
		...(expiration && { [EXPIRES_QUERY_PARAM]: expiration.toString() }),
		...(sessionToken && { [TOKEN_QUERY_PARAM]: sessionToken }),
	}).forEach(([key, value]) => {
		presignedUrl.searchParams.append(key, value);
	});
	const requestToSign = {
		body,
		headers: { [HOST_HEADER]: url.host },
		method,
		url: presignedUrl,
	};

	// calculate and add the signature to the url
	const signature = getSignature(requestToSign, signingValues);
	presignedUrl.searchParams.append(SIGNATURE_QUERY_PARAM, signature);

	return presignedUrl;
};