export const getQuestionnode2 = /* GraphQL */ `
  query GetQuestionnode($filter: QuestionnodeInput) {
    getQuestionnode(filter: $filter) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      __typename
    }
  }
`;

export const getUserSubtopicUnansweredQuestions2 = /* GraphQL */ `
  query GetUserSubtopicUnansweredQuestions(
    $filter: UserInput
    $topicFilter: TopicInput
  ) {
    getUserSubtopicUnansweredQuestions(
      filter: $filter
      topicFilter: $topicFilter
    ) {
      _id
      difficulty
      name
      # userEngagedsIn {
      #   _id
      #   user_id
      #   name
      #   __typename
      # }
      # userSkippedsIn {
      #   _id
      #   user_id
      #   name
      #   __typename
      # }
      # topicSubsOut {
      #   _id
      #   difficulty
      #   name
      #   __typename
      # }
      # topicSubsIn {
      #   _id
      #   difficulty
      #   name
      #   __typename
      # }
      # userSelectedsIn {
      #   _id
      #   user_id
      #   name
      #   __typename
      # }
      # questionnodeInsIn {
      #   _id
      #   id
      #   version
      #   text
      #   fact
      #   correctAnswer
      #   incorrectAnswers
      #   requestedTopic
      #   __typename
      # }
      # streamIncludesIn {
      #   _id
      #   name
      #   latestVersion
      #   __typename
      # }
      unansweredQuestions {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      # ENGAGED {
      #   _id
      #   __typename
      # }
      # SKIPPED {
      #   _id
      #   __typename
      # }
      # SUB {
      #   _id
      #   __typename
      # }
      # SELECTED {
      #   _id
      #   created_at
      #   __typename
      # }
      # INCLUDES {
      #   _id
      #   __typename
      # }
      __typename
    }
  }
`;

export const getUserTopicUnansweredQuestions2 = /* GraphQL */ `
  query GetUserTopicUnansweredQuestions(
    $filter: UserInput
    $topicFilter: TopicInput
  ) {
    getUserTopicUnansweredQuestions(
      filter: $filter
      topicFilter: $topicFilter
    ) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      # userAnsweredsIn {
      #   _id
      #   user_id
      #   name
      #   __typename
      # }
      # userAskedsIn {
      #   _id
      #   user_id
      #   name
      #   __typename
      # }
      # questionsetContainsIn {
      #   _id
      #   title
      #   published_date
      #   createdAt
      #   version
      #   id
      #   __typename
      # }
      # runMadeIn {
      #   _id
      #   id
      #   createdAt
      #   version
      #   __typename
      # }
      # answerTosIn {
      #   _id
      #   created_at
      #   answer_id
      #   was_correct
      #   __typename
      # }
      # topicInOut {
      #   _id
      #   difficulty
      #   name
      #   __typename
      # }
      # rulesPassedIn {
      #   _id
      #   name
      #   __typename
      # }
      # rulesFailedIn {
      #   _id
      #   name
      #   __typename
      # }
      # ANSWERED {
      #   _id
      #   was_correct
      #   answer_id
      #   created_at
      #   __typename
      # }
      # ASKED {
      #   _id
      #   created_at
      #   __typename
      # }
      # CONTAINS {
      #   _id
      #   __typename
      # }
      # TO {
      #   _id
      #   __typename
      # }
      # IN {
      #   _id
      #   __typename
      # }
      # PASSED {
      #   _id
      #   __typename
      # }
      # FAILED {
      #   _id
      #   __typename
      # }
      # MADE {
      #   _id
      #   __typename
      # }
      __typename
    }
  }
`;


export const listRuns2 = /* GraphQL */ `
  query ListRuns($filter: RunInput, $options: Options) {
    listRuns(filter: $filter, options: $options) {
      _id
      id
      createdAt
      version
      questionnodeMadesOut {
        # _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        rulesFailedIn {
          _id
          name
          __typename
        }
        rulesPassedIn {
          _id
          name
          __typename
        }
        __typename
      }
      streamSpawnedIn {
        # _id
        name
        latestVersion
        __typename
      }
      __typename
    }
  }
`;
// this one
export const getStream2 = /* GraphQL */ `
  query GetStream($filter: StreamInput) {
    getStream(filter: $filter) {
      _id
      name
      latestVersion
      topicIncludesOut {
        _id
        difficulty
        name
        __typename
      }
      __typename
    }
  }
`;

export const getTag2 = /* GraphQL */ `
  query GetTag($filter: TagInput) {
    getTag(filter: $filter) {
      _id
      name
      createdAt
      streamTaggedsOut {
        _id
        name
        latestVersion
        createdAt
        TAGGED {
          _id
          createdAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const listStreams2 = /* GraphQL */ `
  query ListStreams($filter: StreamInput, $options: Options) {
    listStreams(filter: $filter, options: $options) {
      _id
      name
      latestVersion
      createdAt
      # topicIncludesOut {
      #   _id
      #   difficulty
      #   name
      #   __typename
      # }
      
      # questionsetMadesOut {
      #   _id
      #   title
      #   published_date
      #   createdAt
      #   version
      #   id
      #   __typename
      # }
     
      # userRequestedsIn {
      #   _id
      #   user_id
      #   name
      #   createdAt
      #   __typename
      # }
      # userViewedsIn {
      #   _id
      #   user_id
      #   name
      #   createdAt
      #   __typename
      # }
      # userOpenedsIn {
      #   _id
      #   user_id
      #   name
      #   createdAt
      #   __typename
      # }
      # userStartedsIn {
      #   _id
      #   user_id
      #   name
      #   createdAt
      #   __typename
      # }
      # userCompletedsIn {
      #   _id
      #   user_id
      #   name
      #   createdAt
      #   __typename
      # }
      # tagTaggedsIn {
      #   _id
      #   name
      #   createdAt
      #   __typename
      # }
      # MADE {
      #   _id
      #   __typename
      # }
      # INCLUDES {
      #   _id
      #   __typename
      # }
      # FOLLOWING {
      #   _id
      #   __typename
      # }
      # SPAWNED {
      #   _id
      #   __typename
      # }
      # REQUESTED {
      #   _id
      #   occuredAt
      #   __typename
      # }
      # VIEWED {
      #   _id
      #   occuredAt
      #   __typename
      # }
      # OPENED {
      #   _id
      #   occuredAt
      #   __typename
      # }
      # STARTED {
      #   _id
      #   occuredAt
      #   __typename
      # }
      # COMPLETED {
      #   _id
      #   occuredAt
      #   __typename
      # }
      __typename
    }
  }
`;


export const getUserQuestionsToReview2 = /* GraphQL */ `
  query GetUserQuestionsToReview($filter: UserInput) {
    getUserQuestionsToReview(filter: $filter) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      __typename
    }
  }
`;


export const getUserFollowingStreams = /* GraphQL */ `
  query GetUserFollowingStreams($filter: UserInput) {
    getUser(filter: $filter) {
      _id
      name
      # streamFollowingsOut {
      #   _id
      #   name
      #   __typename
      # }
      streamRequestedsOut {
        _id
        name
        REQUESTED {
          occuredAt
        }
        __typename
      }
      streamStartedsOut {
        _id
        name
        STARTED {
          occuredAt
        }
        __typename
      }
      # streamCompletedsOut {
      #   _id
      #   name
      #   COMPLETED {
      #     occuredAt
      #   }
      #   __typename
      # }
      
      __typename
    }
  }
  `;


export const getDaily = /* GraphQL */ `
query GetDaily ($filter: QuestionsetInput) {
  listQuestionsets(filter: $filter) {
    _id
    published_date
    title
    streamMadeIn {
      _id
      name
    }
    questionnodeContainssOut {
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      topicInOut {
        name
      }
    }
  }
}
`;

export const getStreamDailySummary = /* GraphQL */ `
query GetStreamDailySummary ($streamFilter: StreamInput) {
  getStream(filter: $streamFilter) {
    _id
    name
    questionsetMadesOut {
      _id
      id
      createdAt
      published_date
      title
      questionnodeContainssOut {
        id
        __typename
      }
      __typename
    }
    __typename
  }
}
`;


export const getDailySummary = /* GraphQL */ `
query GetDailySummary ($filter: QuestionsetInput) {
  listQuestionsets(filter: $filter) {
    _id
    published_date
    title
  }
}
`;

export const getTopicWithQuestions = /* GraphQL */ `
  query GetTopicWithQuestions($filter: TopicInput, $questionFilter: QuestionnodeInput) {
    getTopic(filter: $filter) {
      _id
      name
      questionnodeInsIn(filter: $questionFilter) {
        _id
        id
        version
        __typename
      }
      __typename
    }
  }
`;

export const getParentTopics2 = /* GraphQL */ `
  query GetParentTopics($filter: TopicInput) {
    getTopic(filter: $filter) {
      _id
      name
      topicSubsIn {
        _id
       name
       __typename
      }
      __typename
    }
  }
`;

export const getChildTopics = /* GraphQL */ `
  query GetChildTopics($filter: TopicInput) {
    getTopic(filter: $filter) {
      _id
      name
      topicSubsOut {
        _id
        name
        __typename
      }
      __typename
    }
  }
`;

export const getChildTopicsWithStreams = /* GraphQL */ `
  query GetChildTopics($filter: TopicInput) {
    getTopic(filter: $filter) {
      _id
      name
      topicSubsOut {
        _id
        name
        streamIncludesIn {
          _id
          name
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getGrandChildTopics = /* GraphQL */ `
  query GetGrandChildTopics($filter: TopicInput) {
    getTopic(filter: $filter) {
      _id
      name
      topicSubsOut {
        _id
        name
        __typename
        topicSubsOut {
          _id
          name
          __typename
      }
      }
      __typename
    }
  }
`;

export const getScoreHistory = /* GraphQL */ `
  query GetScoreHistory($filter: UserInput) {
    getUser(filter: $filter) {
      _id
      playStartedsOut {
        score
        finished
        __typename
      }
      answerGavesOut {
        was_correct
        __typename
      }
      __typename
    }
  }
`;

export const getUserDailies = /* GraphQL */ `
  query GetUserDailies($filter: UserInput) {
    getUser(filter: $filter) {
      _id
      user_id
      name
      playStartedsOut {
        _id
        start_time
        score
        finished
        questionsetOfOut {
          # _id
          title
          published_date
          questionnodeContainssOut {
            # _id
            id
            __typename
          }
          __typename
        }
        answerDuringsIn {
          was_correct
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getUserDailiesSummaries = /* GraphQL */ `
  query GetUserDailiesSummaries($filter: UserInput) {
  # query GetUserDailiesSummaries($filter: UserInput, $playFilter: PlayInput) {
    getUser(filter: $filter) {
      _id
      user_id
      name
      playStartedsOut {
      # playStartedsOut(filter: $playFilter) {
        # _id
        start_time
        score
        finished
        questionsetOfOut {
          # _id
          id
          title
          published_date
          streamMadeIn {
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const userDailiesByStreams = /* GraphQL */ `
  query GetUserDailiesByStreams($filter: UserInput) {
    getUser(filter: $filter) {
      _id
      name
      streamFollowingsOut {
        _id
        name
        questionsetMadesOut {
          _id
          id
          published_date
          title
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;