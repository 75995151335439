export const topNLevels = (tree, n) => {
  let ret;
  try {
    ret = Object.assign(
      ...Object.keys(tree)
        .filter((i) => i !== "name" && i !== "default")
        .map((i) => ({
          [i]: n === 1 ? tree[i].name : topNLevels(tree[i], n - 1),
        })),
    );
  } catch (e) {
    console.log(tree);
  }
  return ret;
};

export const flatten = (tree) => {
  try {
    if (typeof tree[Object.keys(tree)[1]] === "object") {
      return Object.keys(tree)
        .map((i) => flatten(tree[i]))
        .flat();
    } else {
      return Object.keys(tree).map((i) => i + " - " + tree[i]);
    }
  } catch (e) {
    return {};
  }
};

export const domainKeyToSubTree = (tree, key) => {
  const parts = key.split(".");
  let toReturn = tree;
  for (let i = 0; i < parts.length; i++) {
    let key = "";
    for (let j = 0; j <= i; j++) {
      key = (j > 0 ? key + "." : key) + parts[j];
    }
    toReturn = toReturn[key];
  }
  return toReturn;
};

export const domainKeyToPathKeys = (key) => {
  const parts = key.split(".");
  let toReturn = [];
  for (let i = 0; i < parts.length; i++) {
    let key = "";
    for (let j = 0; j <= i; j++) {
      key = (j > 0 ? key + "." : key) + parts[j];
    }
    toReturn.push(key);
  }
  return toReturn;
};

export const domainKeyToName = (tree, key) => {
  const parts = key.split(".");
  let subtree = tree;
  for (let i = 0; i < parts.length; i++) {
    let key = "";
    for (let j = 0; j <= i; j++) {
      key = (j > 0 ? key + "." : key) + parts[j];
    }
    subtree = subtree[key];
  }
  return subtree.name;
};

export const domainKeyToPresentedName = (tree, key) => {
  const parts = key.split(".");
  let subtree = tree;
  for (let i = 0; i < parts.length; i++) {
    let key = "";
    for (let j = 0; j <= i; j++) {
      key = (j > 0 ? key + "." : key) + parts[j];
    }
    subtree = subtree[key];
  }
  const prefix =
    Array.from({ length: parts.length - 1 })
      .fill(">")
      .join(" ") + " ";
  return prefix + subtree.name;
};
