import { createSlice } from "@reduxjs/toolkit";

export const answeredQuestionsSlice = createSlice({
  name: "answeredQuestions",
  initialState: {
    value: [],
  },
  reducers: {
    addAnsweredQuestion: (state, action) => {
      state.value.push({ ...action.payload });
    },
    reset: (state) => {
      state.value = [];
    },
  },
});
