import React, { useState, useEffect } from "react";
import { motion, useAnimation, useMotionValue, useTransform, animate, AnimatePresence } from "framer-motion";
import { IoMdArrowDropup, IoMdRocket } from "react-icons/io";
// import { TbAtom2Filled } from "react-icons/tb";
import { IoIosFlash, IoIosFlame } from "react-icons/io";
import {StaticCircleLoadingIndicator} from "../CircleLoadingIndicator"
import { PiRocketDuotone } from "react-icons/pi";





function CountdownToMidnight({ timeMode }) {
  const calculateTimeLeft = () => {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    return Math.round((tomorrow - now) / 1000);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1);

    return () => clearInterval(timer);
  }, []);

  const formatTimeLeft = () => {
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    // return `clock reset in ${hours}:${String(minutes).padStart(2, '0')}:${seconds}`;
    if (timeMode === "daily") {
      if (hours > 0) return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      return ` ${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
    return ` ${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    // return `t-minus  ${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    const combinedSeconds = minutes * 60 + seconds;
    // return `t-minus  ${combinedSeconds}`;
  };

  return (
    <div>{formatTimeLeft()}</div>
  );
}


export const nextRank = (elo) => {
  const eloToLikelihood = (elo) => {
    // return elo
    let likelihood = 1 / (1 + 10 ** ( (1000-elo) / 2100))
    return (likelihood * 100).toFixed(1)
  }

  const likelihood = eloToLikelihood(elo)

  if (likelihood > 90) {
    return 0;
  }

  if (likelihood > 75) {
    return 90 - likelihood;
  }

  if (likelihood > 60) {
    return 75 - likelihood;
  }

  if (likelihood > 50) {
    return 60 - likelihood;
  }

  if (likelihood > 25) {
    return 50 - likelihood;
  }
  return 25 - likelihood;
}

// export const nextLevel = (score) => {
//   if (score > 850) {
//     return 0;
//   }

//   if (score > 700) {
//     return 850 - score;
//   }

//   if (score > 550) {
//     return 700 - score;
//   }

//   if (score > 400) {
//     return 550 - score;
//   }
//   return 400 - score;
// }

export const nextLevel = (elo) => {
  const eloToLikelihood = (elo) => {
      // return elo
      let likelihood = 1 / (1 + 10 ** ( (1000-elo) / 2100))
      return (likelihood * 100).toFixed(1)
    }
  
    const likelihood = eloToLikelihood(elo)

    if (likelihood > 75) {
      return 90;
    }
    if (likelihood > 60) {
      return 75;
    }
  
    if (likelihood > 50) {
      return 60;
    }
  
    if (likelihood > 25) {
      return 50;
    }
    return 25;

  }


  export const nextRaceLevel = (points) => {
   
      if (points > 3000) {
        return 3500;
      }
      if (points > 2500) {
        return 3000;
      }
      if (points > 2000) {
        return 2500;
      }
    
      if (points > 1500) {
        return 2000;
      }
    
      if (points > 1000) {
        return 1500;
      }
      return 1000;
  
    }
  

export const nextRankTitle = (elo) => {
  const eloToLikelihood = (elo) => {
    // return elo
    let likelihood = 1 / (1 + 10 ** ( (1000-elo) / 2100))
    return (likelihood * 100).toFixed(1)
  }

  const likelihood = eloToLikelihood(elo)

  if (likelihood > 75) {
    return "Genius";
  }
  if (likelihood > 60) {
    return "Expert";
  }

  if (likelihood > 50) {
    return "Competent";
  }

  if (likelihood > 25) {
    return "Average";
  }
  return "Novice";
}

export const nextRaceTitle = (points) => {

  if (points > 3000) {
    return "Double Genius";
  }
  if (points > 2500) {
    return "Genius";
  }

  if (points > 2000) {
    return "Expert";
  }

  if (points > 1500) {
    return "Competent";
  }
  return "Average";
}


export const raceMap = (points) => {

  if (points > 3000) {
    return "Genius";
  }

  if (points > 2500) {
    return "Expert";
  }
  if (points > 2000) {
    return "Competent";
  }

  if (points > 1500) {
    return "Average";
  }

  if (points > 1000) {
    return "Novice";
  }
  return "Beginner";
}


export const rankMap = (elo) => {
    const eloToLikelihood = (elo) => {
      // return elo
      let likelihood = 1 / (1 + 10 ** ( (1000-elo) / 2100))
      return (likelihood * 100).toFixed(1)
    }
  
    const likelihood = eloToLikelihood(elo)
  
    if (likelihood > 90) {
      return "Genius";
    }
  
    if (likelihood > 75) {
      return "Expert";
    }
    if (likelihood > 60) {
      return "Competent";
    }
  
    if (likelihood > 50) {
      return "Average";
    }
  
    if (likelihood > 25) {
      return "Novice";
    }
    return "Beginner";
}


// export const rankMap = (score) => {
//   if (score > 850) {
//     return "Genius";
//   }

//   if (score > 700) {
//     return "Expert";
//   }

//   if (score > 550) {
//     return "Average";
//   }

//   if (score > 400) {
//     return "Novice";
//   }
//   return "Beginner";
// }

export const starMap = (score) => {
  if (score > 850) {
    return 4;
  }

  if (score > 700) {
    return 3;
  }

  if (score > 550) {
    return 2;
  }

  if (score > 400) {
    return 1;
  }
  return 0;
}

export const ScoreGraph = ({ scores, isOpen, themeColor }) => {

  const maxScore = scores ? scores.reduce((acc, curr) => {
    return acc.score > curr.score ? acc : curr
  }, 0) : 0

  const averageScore = scores ? scores.reduce((acc, curr) => {
    return acc + curr.score
  }, 0) / scores.length : 0

  


  const lastScore = scores ? scores[scores.length - 1] : 0


  const scoreToHeight = (score) => {
    if (score === 0) return "5%"
    const desiredheight = 100
    const multiplier = (score) / (4)
    const metric = multiplier * desiredheight
    return `${metric}%`
  }

  const xpToHeight = (score) => {
    const desiredheight = 100
    const multiplier = (score) / (4)
    const metric = Math.min(multiplier * desiredheight + 65, 100)

    return `${metric}%`
  }

  // const [isOpen, setIsOpen] = useState(true);

  // const toggleOpen = () => {
  //   setIsOpen(!isOpen);
  // };

  const starsToColor = (stars) => {
    if (stars === 4) {
      return "bg-purple-500/90"
    }
    if (stars === 3) {
      return "bg-purple-500/80"
    }
    if (stars === 2) {
      return "bg-purple-500/70"
    }
    if (stars === 1) {
      return "bg-purple-500/60"
    }
    return "bg-purple-500/50"
  }

  return (
    <div
      // onClick={toggleOpen}
      className="flex relative h-[100px] ">
      <motion.div
        // initial={{ width: 0 }}
        // animate={{ width: isOpen ? 100 : 40 }}
        // transition={{ duration: 0.25}}

        className={
          "" +
          ` absolute min-w-[100px]  mr-1 rounded` +
          (isOpen ? " sm:min-w-[300px] right-5 bottom-9 mb-2 py-4 sm:px-12 h-[120px] " : " right-0 bottom-0 h-[25px]")
        }>
          <div className={` gap-[2px] flex  items-end border-gray-500 dark:border-${themeColor}-500 ` + (isOpen ? " justify-start min-w-[100px] border-b-2 my-[10px] py-1 h-[80px] " : " justify-end border-0 h-[25px]")}>
        {scores.map((score, i) => {

          const last = i === scores.length - 1
          return (
            <motion.div
              key={i}
              initial={{ height: 0 }}
              animate={{ height: scoreToHeight(score.score) }}
              transition={{ duration: 0.25, delay: i * i * 0.0001 }}
              className={
                // starsToColor(score.score) + " " +
                (isOpen
                  ?
                  (`w-[6px] bg-${themeColor}-500 dark:bg-${themeColor}-500 `)
                  :
                  (`w-[6px] bg-${themeColor}-700/50 dark:bg-${themeColor}-300/50 `)
                )
                +
                "  shrink-0 relative z-[200]"
              }>

              {isOpen && last &&
                <span className={`absolute -top-6 -right-[1px] z-20 font-logo font-bold text-base text-${themeColor}-500 dark:text-${themeColor}-300`}>{(lastScore?.score)?.toFixed(0)}</span>
              }
            </motion.div>
          )

        })}
        </div>
      </motion.div>
      {/* { isOpen &&
      <motion.div 
      // initial={{ width: 0 }}
      // animate={{ width: isOpen ? 100 : 40 }}
      // transition={{ duration: 0.25}}
      
      className={
        "" + 
        " absolute w-[100px] flex  mr-7  items-end gap-1 border-purple-500 transition-all" +
        (isOpen ? " right-5 bottom-9 border-b-[1px] mb-2 py-1 h-[80px]" : " right-0 bottom-0 border-0 h-[30px]")
        }>
        {scores.map((score, i) => {

          const last = i === scores.length - 1
          return (
            <motion.div
              key={i}
              initial={{ height: 0 }}
              animate={{ height: xpToHeight(score.score) }}
              transition={{ duration: 0.25, delay: i*i * 0.0001}}
            className={
              (isOpen 
                ?
                (last ? "bg-purple-400/50 w-[10px]" : "w-[10px] bg-purple-500/50") 
                :
                (last ? "bg-purple-400/50 w-[10px]" : "w-[10px] bg-purple-500/50") 
              )
                + 
                " relative z-1"
                }>
          </motion.div>
          )
          
        })}
      { isOpen &&
              <span className="absolute -right-9 top-0 font-logo font-bold text-base text-purple-300">+{(lastScore?.score / 10).toFixed(0)}</span>
      }
      </motion.div>
} */}
      {/* { isOpen &&
              <div className="text-center leading-[48px] text-purple-300 w-12 h-12 rounded-full absolute right-12 top-[-160px] font-logo text-4xl">
                {(averageScore).toFixed(0)}
                </div>
      } */}
    </div>

  )
}

export const ScoreGraph2 = ({ scores, themeColor, highlightIndex }) => {

  const maxScore = scores ? scores.reduce((acc, curr) => {
    return acc.score > curr.score ? acc : curr
  }, 0) : 0

  const averageScore = scores ? scores.reduce((acc, curr) => {
    return acc + curr.score
  }, 0) / scores.length : 0

  


  const lastScore = scores ? scores[scores.length - 1] : 0


  const scoreToHeight = (score) => {
    if (score === 0) return "5%"
    const desiredheight = 100
    const multiplier = (score) / maxScore.score
    const metric = multiplier * desiredheight
    return `${metric}%`
  }

  const xpToHeight = (score) => {
    const desiredheight = 100
    const multiplier = (score) / (4)
    const metric = Math.min(multiplier * desiredheight + 65, 100)

    return `${metric}%`
  }

  // const [isOpen, setIsOpen] = useState(true);

  // const toggleOpen = () => {
  //   setIsOpen(!isOpen);
  // };

  const starsToColor = (stars) => {
    if (stars === 4) {
      return "bg-purple-500/90"
    }
    if (stars === 3) {
      return "bg-purple-500/80"
    }
    if (stars === 2) {
      return "bg-purple-500/70"
    }
    if (stars === 1) {
      return "bg-purple-500/60"
    }
    return "bg-purple-500/50"
  }

  return (
    <div
      // onClick={toggleOpen}
      className="flex relative h-full">
      <motion.div
        // initial={{ width: 0 }}
        // animate={{ width: isOpen ? 100 : 40 }}
        // transition={{ duration: 0.25}}

        className="">
          <div className={` gap-[2px] flex  items-end border-gray-500 dark:border-${themeColor}-500 ` + " justify-start min-w-[100px] border-b-2 h-[40px]"}>
        {scores.map((score, i) => {

          const last = i === highlightIndex
          return (
            <motion.div
              key={i}
              initial={{ height: 0 }}
              animate={{ height: scoreToHeight(score.score) }}
              transition={{ duration: 0.25, delay: i * i * 0.0001 }}
              className={
                // starsToColor(score.score) + " " +
                  `w-[6px]` + 
                  // (last ? ` bg-${themeColor}-300 dark:bg-${themeColor}-300` : ` bg-${themeColor}-500 dark:bg-${themeColor}-500`)
                  (last ? ` bg-blue-300 dark:bg-blue-300` : ` bg-blue-500 dark:bg-blue-500`)
                +
                "  shrink-0 relative z-[200]"
              }>

              {/* {last &&
                <span className={`absolute -top-6 -right-[1px] z-20 font-logo font-bold text-base text-${themeColor}-500 dark:text-${themeColor}-300`}>{(lastScore?.score)?.toFixed(0)}</span>
              } */}
            </motion.div>
          )

        })}
        </div>
      </motion.div>
      {/* { isOpen &&
      <motion.div 
      // initial={{ width: 0 }}
      // animate={{ width: isOpen ? 100 : 40 }}
      // transition={{ duration: 0.25}}
      
      className={
        "" + 
        " absolute w-[100px] flex  mr-7  items-end gap-1 border-purple-500 transition-all" +
        (isOpen ? " right-5 bottom-9 border-b-[1px] mb-2 py-1 h-[80px]" : " right-0 bottom-0 border-0 h-[30px]")
        }>
        {scores.map((score, i) => {

          const last = i === scores.length - 1
          return (
            <motion.div
              key={i}
              initial={{ height: 0 }}
              animate={{ height: xpToHeight(score.score) }}
              transition={{ duration: 0.25, delay: i*i * 0.0001}}
            className={
              (isOpen 
                ?
                (last ? "bg-purple-400/50 w-[10px]" : "w-[10px] bg-purple-500/50") 
                :
                (last ? "bg-purple-400/50 w-[10px]" : "w-[10px] bg-purple-500/50") 
              )
                + 
                " relative z-1"
                }>
          </motion.div>
          )
          
        })}
      { isOpen &&
              <span className="absolute -right-9 top-0 font-logo font-bold text-base text-purple-300">+{(lastScore?.score / 10).toFixed(0)}</span>
      }
      </motion.div>
} */}
      {/* { isOpen &&
              <div className="text-center leading-[48px] text-purple-300 w-12 h-12 rounded-full absolute right-12 top-[-160px] font-logo text-4xl">
                {(averageScore).toFixed(0)}
                </div>
      } */}
    </div>

  )
}


const CircularProgressBar = ({ value }) => {
  const circleVariants = {
    hidden: {
      strokeDasharray: '0, 100',
    },
    visible: {
      strokeDasharray: `${value}, 100`,
      transition: { duration: 1 },
    },
  };

  // count the number of digits in value
  const digitCount = Math.max(Math.floor(Math.log10(Math.abs(value))), 0) + 1

  return (
    <div className="">
      <svg
        className="w-16 h-16 transform -rotate-90"
        viewBox="0 0 36 36"
      >
        {/* <motion.circle
          cx="18"
          cy="18"
          r="15.9155"
          fill="#3b0764"
          // fill="#3b82f6"
          // fill="none"
          opacity="0.1"
          // stroke="#a855f7"
          stroke="#c084fc"
          strokeWidth="1.5"
          strokeDasharray="100, 100"
          // variants={circleVariants}
          // initial="hidden"
          // animate="visible"
          />
          <motion.circle
          cx="18"
          cy="18"
          r="15.9155"
          fill="none"
          stroke="#a855f7"
          strokeWidth="1.5"
          variants={circleVariants}
          initial="hidden"
          animate="visible"
          /> */}
        {/* <circle
          cx="18"
          cy="18"
          r="15.9155"
          fill="none"
          stroke="#838383" // Light gray for the static border
          strokeWidth="2"
          className={"relative z-[20]"}
        /> */}
        {/* <text
          x="18"
          y="20.35"
          className="text-xl font-bold text-center text-white"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${value}%`}
        </text> */}
      </svg>
      <div className="flex gap-1 items-center absolute top-4 text-center z-[300] font-bold font-logo text-2xl text-red-300">
        {value} <IoIosFlash />
      </div>
    </div>
  );
};


const Rocket = ({ power }) => {


  const enginesOn = power > 0

  return (
    <motion.div
      initial={{ y: 150 }}
      animate={{ y: enginesOn ? 10 : 20 }}
      transition={{
        duration: 1.3,
        // repeat: Infinity,
        //  repeatType: "reverse"
      }}
      className="relative h-full"
    >
      <IoMdRocket className="top-[0px] text-2xl text-white absolute bottom-0" />
      <motion.div initial={{ height: 6 }} animate={{ height: 8 }} transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }} className={"z-[5]                absolute left-[9px] top-[24px] border-l-[1px] " + (enginesOn ? "border-blue-500" : "border-blue-500/0")}></motion.div>
      <motion.div initial={{ height: 11 }} animate={{ height: 14 }} transition={{ delay: 0.7, duration: 0.5, repeat: Infinity, repeatType: "reverse" }} className={"z-[5]  absolute left-[10px] top-[24px] border-l-[1px] " + (enginesOn ? "border-blue-500" : "border-blue-500/0")}></motion.div>
      <motion.div initial={{ height: 16 }} animate={{ height: 18 }} transition={{ delay: 0.14, duration: 0.5, repeat: Infinity, repeatType: "reverse" }} className={"z-[5] absolute left-[11px] top-[24px] border-l-[1px] " + (enginesOn ? "border-blue-500" : "border-blue-500/0")}></motion.div>
      <motion.div initial={{ height: 16 }} animate={{ height: 18 }} transition={{ delay: 0.21, duration: 0.5, repeat: Infinity, repeatType: "reverse" }} className={"z-[5] absolute left-[12px] top-[24px] border-l-[1px] " + (enginesOn ? "border-blue-500" : "border-blue-500/0")}></motion.div>
      <motion.div initial={{ height: 11 }} animate={{ height: 14 }} transition={{ delay: 0.28, duration: 0.5, repeat: Infinity, repeatType: "reverse" }} className={"z-[5] absolute left-[13px] top-[24px] border-l-[1px] " + (enginesOn ? "border-blue-500" : "border-blue-500/0")}></motion.div>
      <motion.div initial={{ height: 6 }} animate={{ height: 8 }} transition={{ delay: 0.35, duration: 0.5, repeat: Infinity, repeatType: "reverse" }} className={"z-[5]    absolute left-[14px] top-[24px] border-l-[1px] " + (enginesOn ? "border-blue-500" : "border-blue-500/0")}></motion.div>
    </motion.div>
  )
}

// Assuming mass of the object is known
const mass = 50_000; // in kilograms

// Function to calculate speed at a given time




function parseDate(dateString) {
  return new Date(dateString.replace(' GMT−7', ':00:00-07:00'));
}

// Function to convert data into forceWindows
export function convertToForceWindows(data) {
  const forceWindows = [];

  data.forEach(item => {
    const startDate = parseDate(item.date);
    const endDate = new Date(startDate.getTime() + 3600 * 1000 * 6); // Add 6 hours
    const force = item.score * 9.81; // Convert G's to m/s^2

    forceWindows.push({
      startTime: (startDate.getTime() / 1000).toFixed(0), // Convert to seconds
      endTime: (endDate.getTime() / 1000).toFixed(0), // Convert to seconds
      force: force,
      score: item.score
    });
  });

  return forceWindows;
}

// Convert data
// const forceWindows = convertToForceWindows(data);
export function calculatePositionAtTime(forceWindows, time) {
  let position = 0;
  let speed = 0;
  let lastTime = 0;

  forceWindows.forEach(window => {
    if (window.endTime <= time) {
      // Full window is before the given time
      const duration = window.endTime - window.startTime;
      const acceleration = window.force / mass;
      const deltaSpeed = acceleration * duration;
      position += speed * duration + 0.5 * acceleration * Math.pow(duration, 2);
      speed += deltaSpeed;
      lastTime = window.endTime;
    } else if (window.startTime <= time && window.endTime > time) {
      // Window overlaps with the given time
      const duration = time - window.startTime;
      const acceleration = window.force / mass;
      position += speed * duration + 0.5 * acceleration * Math.pow(duration, 2);
      speed += acceleration * duration;
    }
  });

  return position;
}

export function calculateSpeedAtTime(forceWindows, time) {
  let speed = 0;
  let lastTime = 0;

  forceWindows.forEach(window => {
    if (window.endTime <= time) {
      // Full window is before the given time
      const duration = window.endTime - window.startTime;
      const acceleration = window.force / mass;
      speed += acceleration * duration;
      lastTime = window.endTime;
    } else if (window.startTime <= time && window.endTime > time) {
      // Window overlaps with the given time
      const duration = time - window.startTime;
      const acceleration = window.force / mass;
      speed += acceleration * duration;
    }
  });

  return speed;
}

export function calculateAccelerationAtTime(forceWindows, time) {
  let totalForce = 0;
  let activeScore = 0

  forceWindows.forEach(window => {
    if (window.startTime <= time && window.endTime > time) {
      totalForce += window.force;
      activeScore += window.score
    }
  });

  const currentAcceleration = totalForce / mass;
  return { activeScore, currentAcceleration };
}



const Digit = ({ value }) => {
  const variants = {
    initial: { y: -20, opacity: 0, position: 'absolute' },
    animate: { y: 0, opacity: 1, position: 'absolute' },
    exit: { y: 20, opacity: 0, position: 'absolute' },
  };

  return (
    <AnimatePresence>
      <motion.div
        key={value}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={variants}
        transition={{ y: { duration: 0.2 }, opacity: { duration: 0.2, delay: 0.1 } }}
        style={{ position: 'relative', width: '1em', textAlign: 'center' }}
      >
        {value}
      </motion.div>
    </AnimatePresence>
  );
};

const DigitContainer = ({ children }) => {
  return (
    <div style={{ position: 'relative', width: '0.8em', height: '1.4em' }}>
      {children}
    </div>
  );
};

export const AnimatedNumber = ({ number }) => {
  const [integerPart, setIntegerPart] = useState('');
  const [fractionalPart, setFractionalPart] = useState('');

  useEffect(() => {
    const [integer, fractional] = number.toString().split('.');
    setIntegerPart(integer);
    setFractionalPart(fractional || '');
  }, [number]);

  return (
    <div style={{ display: 'flex', gap: '0em' }}>
      {integerPart.split('').map((digit, index) => (
        <DigitContainer key={`int-${index}`}>
          <Digit value={digit} />
        </DigitContainer>
      ))}
      {fractionalPart && (
        <>
          <span className="ml-[1px] mr-[-2px]">.</span>
          {fractionalPart.split('').map((digit, index) => (
            <DigitContainer key={`frac-${index}`}>
              <Digit value={digit} />
            </DigitContainer>
          ))}
        </>
      )}
    </div>
  );
};

export const positionToUnit = (position) => {
  let unit;
  let divisor;
  let precision = 0;
  if (position > 149599999999.7966 / 10) {
    unit = "Au";
    divisor = 149599999999.7966;
    precision = 2;
  } else if (position > 1000*1000*1000) {
    unit = "Gm";
    divisor = 1000*1000*1000;
    precision = 2;
  } else if (position > 1000*1000) {
    unit = "Mm";
    divisor = 1000*1000;
    precision = 2;
  } else if (position > 1000) {
    unit = "km";
    divisor = 1000;
    precision = 2;
  } else {
    unit = "m";
    divisor = 1;
  }

  const positionAdjustedForUnit = position / divisor;
  return {positionAdjustedForUnit, unit, precision}
}

export const BigScoreGraph = (props) => {

  const [bigView, setBigView] = useState(!props.isSmall)



  const [timeMode, setTimeMode] = useState("hourly")
  const scores = timeMode === "daily" ? props.dailyScores : props.hourlyScores

  const scoresAsForces = convertToForceWindows(scores)



  // const timeInSeconds = (new Date().getTime() / 1000).toFixed(0);
  // const currentPosition = calculatePositionAtTime(scoresAsForces, timeInSeconds);
  // const currentSpeed = calculateSpeedAtTime(scoresAsForces, timeInSeconds);
  // const currentAcceleration = calculateAccelerationAtTime(scoresAsForces, timeInSeconds);

  const [currentPosition, setPosition] = useState(0)
  const [currentSpeed, setSpeed] = useState(0)
  const [currentAcceleration, setAcceleration] = useState(0)

  //update the position every second
  useEffect(() => {
    const calculateMetrics = () => {
      const timeInSeconds = (new Date().getTime() / 1000).toFixed(0);
      const currentPosition = calculatePositionAtTime(scoresAsForces, timeInSeconds);
      const currentSpeed = calculateSpeedAtTime(scoresAsForces, timeInSeconds);
      const { activeScore, currentAcceleration } = calculateAccelerationAtTime(scoresAsForces, timeInSeconds);
      setPosition(currentPosition);
      setSpeed(currentSpeed);
      setAcceleration(activeScore)
    }

    calculateMetrics()
    const intervalId = setInterval(() => {
      calculateMetrics()
    }, 10000)
    return () => clearInterval(intervalId)
  }, [scoresAsForces])

  // Function to calculate position at a given time


  // const scores = [
  //   { score: 100 },
  //   { score: 200 },
  // ]

  // append enough {score: 0} to scores to make it length 15
  // while (scores.length < 15) {
  //   scores.unshift({ score: 1 })
  // }



  const last14Scores = scores.slice(-14)



  const maxScore = last14Scores ? last14Scores.reduce((acc, curr) => {
    return acc.score > curr.score ? acc : curr
  }, 0) : 0

  const averageScore = last14Scores ? last14Scores.reduce((acc, curr) => {
    return acc + curr.score
  }, 0) / last14Scores.length : 0


  let streakLength = 0
  if (last14Scores.length > 1) {
    for (let i = last14Scores.length - 1; i > 0; i--) {
      if (i === last14Scores.length - 1) {
        last14Scores[i].inStreak = true
        // streakLength++
      }
      if (last14Scores[i - 1].score < last14Scores[i].score) {
        streakLength++
        last14Scores[i - 1].inStreak = true
      }
      else {
        break
      }
    }
  }
  if (last14Scores.length == 1) {
    last14Scores[0].inStreak = true
  }




  const lastScore = last14Scores && last14Scores.length ? last14Scores[last14Scores.length - 1].score : 0
  const previousScore = last14Scores && last14Scores.length > 1 ? last14Scores[last14Scores.length - 2] : 0

  const scoreToHeight = (score) => {
    const multiplier = (score / maxScore.score) + .01
    return multiplier
  }


  // const [scoreRange, setScoreRange] = useState([0,50])
  // // const [to, setTo] = useState(50)

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // setFrom(to)
  //     setScoreRange((prev) => ([prev[1], Math.random() * 10 + prev[1]]))
  //     // setTo(Math.random() * 10 + from)
  //   }, 1000)

  //   return () => clearInterval(intervalId);
  // }, [])



  let isOpen = timeMode === "daily"
  const enginesOnCount = lastScore > 0 ? streakLength + 1 : 0
  const enginesOn = lastScore > 0
  // const speed = 33
  const speed = enginesOnCount > 0 ? lastScore ** enginesOnCount : 0

  let speedClass;

  const speedModifier = timeMode === "hourly" ? 4 : 4
  if (currentSpeed === 0) {
    speedClass = "speed-0";
  } else if (currentSpeed / speedModifier > 4096) {
    speedClass = "speed-6";
  } else if (currentSpeed / speedModifier > 1024) {
    speedClass = "speed-5";
  } else if (currentSpeed / speedModifier > 256) {
    speedClass = "speed-4";
  } else if (currentSpeed / speedModifier > 64) {
    speedClass = "speed-3";
  } else if (currentSpeed / speedModifier > 16) {
    speedClass = "speed-2";
  } else {
    speedClass = "speed-1";
  }

  const netAcceleration = currentAcceleration - props.questionsForReview?.length

  

  const {positionAdjustedForUnit, unit, precision} = positionToUnit(currentPosition)
  const {positionAdjustedForUnit: speedAdjustedForUnit, unit: speedUnit, precision: speedPrecision} = positionToUnit(currentSpeed)


  return (
    <div className="h-full">
      {/* <div className="text-white/60 font-logo text-center">
        <CountdownToMidnight timeMode={timeMode} />
      </div> */}
      <motion.div
        // initial={{height: '70px', width: '50%'}}
        // animate={{
        //   height: bigView ? '150px' : '70px',
        //   width: bigView ? '100%' : '50%'
        // }}
        transition={{ duration: 0.3 }}
        onClick={() => {
          
          // if (!bigView) {
            setBigView(t => !t)
            // return
          // }
          // setTimeMode(timeMode === "daily" ? "hourly" : "daily")
        
        }}
        className={
          // (!bigView ? "" : "") +
          " h-full shadow grid grid-cols-7 gap-1 z-[0] bg-black/90 dark:bg-black/90 backdrop-blur-2xl rounded-lg shadow-lg  relative overflow-hidden cursor-pointer"}>
        <div className="h-full fixed top-0 bottom-0 right-0 left-0 bg-black/50">
          <div id="stars" className={speedClass}></div>
          <div id="stars2" className={speedClass}></div>
          <div id="stars3" className={speedClass}></div>
          <div id="stars4" className={speedClass}></div>
        </div>
        {/* <div className="absolute z-[2] right-4 top-2 w-12 h-12">
          <StaticCircleLoadingIndicator litSegments={currentAcceleration} maximum={currentAcceleration + props.questionsForReview?.length} themeColor={"blue"}/>
          <div className={"z-[-1] flex justify-center items-center absolute" + 
          ` bg-blue-950/0 dark:bg-blue-950/50 ` + 
          "w-full h-full top-0 right-0 font-logo text-center text-base hover:scale-95 active:scale-100 transition-all text-purple-200 dark:text-white/90 font-bold leading-8 p-1 w-11 h-11 rounded-full"}>
            {netAcceleration}
          </div>
        </div> */}
        {/* <div className="absolute z-[2] left-3 top-2 w-12 h-12">
        <Link to="/a/review">
          <StaticCircleLoadingIndicator litSegments={props.questionsForReview?.length} maximum={130} themeColor={"purple"}/>
          <div className="z-[-1] flex justify-center items-center absolute 
          bg-red-950/0 dark:bg-red-950/50
          w-full h-full top-0 right-0 font-logo text-center text-base hover:scale-95 active:scale-100 transition-all text-purple-300 dark:text-white/90 font-bold leading-8 p-1 w-11 h-11 rounded-full">
           {props.questionsForReview?.length}
          </div>
        </Link>
        </div> */}
        <motion.div
          transition={{ duration: 0.5 }}
          className="col-span-3 flex justify-center items-center ">
          {/* {bigView && last14Scores.map((score, i) => {
          // {bigView && last14Scores.map((score, i) => {
            return (
              <motion.div
                key={i}
                initial={{ height: 0 }}
                animate={{ height: `${scoreToHeight(score?.score || 1) * 100}%` }}
                transition={{
                  duration: isOpen ? 0.3 : 0.3,
                  delay: false ? ((last14Scores.length - i) * 0.02) : (i * 0.02)
                }}
                className={
                  (score.inStreak ?
                    "bg-blue-500 w-[5px]"
                    :
                    "bg-purple-300 w-[5px] "
                  )
                  + " relative"}>
              </motion.div>
            )
          })} */}
        </motion.div>
        <div className={
          " h-full col-span-1 font-logo font-bold text-2xl text-zinc-500 relative flex justify-center"
        }>
          <div className="relative">
            <div className={(!bigView ? "" : "") + " relative left-[-12px]"}>
              <Rocket timeMode={timeMode} power={currentAcceleration} speed={speed} />
            </div>
          </div>
        </div>
        <div className={
          " col-span-3 flex flex-col justify-center items-start font-logo font-bold text-2xl text-purple-500"
        }>
           {!!bigView &&
            <div className="absolute grid grid-cols-2 gap-2 mr-3 mt-8">
              <div className="">
                <div className=" h-6 w-full text-white/90 font-logo text-lg w-10 text-right flex justify-end">
                  <AnimatedNumber number={netAcceleration.toFixed(0)} />
                  {/* <RateCounter start={speed} rate={0}/> */}
                </div>
                <div className=" h-6  w-full text-white/90 font-logo text-lg w-10 text-right flex justify-end">
                  <AnimatedNumber number={speedAdjustedForUnit.toFixed(speedPrecision)} />
                  {/* <RateCounter start={speed} rate={0}/> */}
                </div>
                <div className=" h-6 w-full text-white/90 font-logo text-lg w-10 text-right flex justify-end">
                  {/* <RateCounter start={speed} rate={0}/> */}
                  <AnimatedNumber number={positionAdjustedForUnit.toFixed(precision)} />
                </div>
                {/* <div className="h-[25%] leading-[28px] w-full text-blue-400 font-logo text-lg w-10 text-right flex justify-end"><AnimatedNumber number={enginesOnCount} /></div> */}
                {/* <div className="h-[25%] leading-[28px] w-full text-purple-300 font-logo text-lg w-10 text-right flex justify-end"><AnimatedNumber number={currentAcceleration} /></div> */}
              </div>
              <div>
                <div className=" h-6 leading-7 font-bold text-sm text-white/80">
                  {timeMode === "hourly" ? "g" : "g"}
                </div>
                <div className=" h-6 leading-7 font-bold text-sm text-white/80">
                  {speedUnit + "/s"}
                </div>
                <div className=" h-6 leading-7 font-bold text-sm text-white/80">
                  {unit}
                </div>
                {/* <div className="h-[25%] leading-[28px] text-lg text-blue-400"> <IoIosFlame className="relative right-1 top-1" /></div> */}
                {/* <div className="h-[25%] text-lg leading-[28px] text-purple-300 relative top-[5px]"><IoIosFlash className="relative right-1" /></div> */}
              </div>
            </div>
          }
        </div>
      </motion.div>

      </div>
  )
}


export const RocketView = () => {


  return (
    <>
      <div
        className="w-40 grid grid-cols-7 gap-1 z-[0] bg-black dark:bg-black/[.20] backdrop-blur-2xl absolute h-[20vh] rounded-2xl flex relative justify-center relative overflow-hidden">
        <div className="bg-animation fixed top-0 bottom-0 right-0 left-0 bg-black/50">
          <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
          <div id="stars4"></div>
        </div>
        <div className={
          " col-start-4 col-span-1 font-logo font-bold text-2xl text-gold-500 relative flex justify-center"
        }>
          <div className="relative">
            <div className="relative -left-[11px]">
              <Rocket power={1} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const formatNumber = (number) => {
  if (number >= 10000) {
    return (number / 1000).toFixed(1) + 'k';
  }
  return number.toLocaleString();
};

const RateCounter = ({ start, rate }) => {
  const [number, setNumber] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber((prev) => prev + rate);
    }, 17000);

    return () => clearInterval(interval);
  }, [rate]);

  return (
    <span>{formatNumber(number)}</span>
  );
};

const Counter = ({ from, to, correct, speedMultiplier, stars }) => {
  const duration = 2000
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  useEffect(() => {
    const controls = animate(count, from, { duration: 1, delay: 0.3 });

    controls.then(() => {
      // Start the second animation here
      // For example:
      animate(count, to, { duration: 1, delay: 0.3 });
    });
    return controls.stop;
  }, [from, to]);

  const [currentText, setCurrentText] = React.useState(0)

  return (
    <motion.div className="flex items-center gap-1">
      <motion.span className="text-2xl text-purple-300 font-logo font-bold">{rounded}</motion.span>
    </motion.div>
  );
}