import { createSlice } from "@reduxjs/toolkit";

export const surveySlice = createSlice({
  name: "survey",
  initialState: {
    value: {},
  },
  reducers: {
    startSurvey: (state, action) => {
      console.log(action.payload.topic);
      // state.value = action.payload.email
      // for each domain in action.payload.domain, add action.payload.score to the score
    },
  },
});
