
import { Sha256 } from '@aws-crypto/sha256-js';
import { toHex } from '@smithy/util-hex-encoding';


export const SHA256_ALGORITHM_IDENTIFIER = 'AWS4-HMAC-SHA256';

export const SIGNATURE_IDENTIFIER = 'AWS4';
// preset values
export const EMPTY_HASH =
	'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855';
export const UNSIGNED_PAYLOAD = 'UNSIGNED-PAYLOAD';
/**
 * Returns a signing key to be used for signing requests.
 *
 * @param secretAccessKey AWS secret access key from credentials.
 * @param date Current date in the format 'YYYYMMDD'.
 * @param region AWS region in which the service resides.
 * @param service Service to which the signed request is being sent.
 *
 * @returns `Uint8Array` calculated from its composite parts.
 *
 * @internal
 */
export const getSigningKey = (
	secretAccessKey,
	date,
	region,
	service,
) => {
	const key = `${SIGNATURE_IDENTIFIER}${secretAccessKey}`;
	const dateKey = getHashedData(key, date);
	const regionKey = getHashedData(dateKey, region);
	const serviceKey = getHashedData(regionKey, service);
	const signingKey = getHashedData(serviceKey, KEY_TYPE_IDENTIFIER);

	return signingKey;
};


const getCanonicalUri = (
	pathname,
	uriEscapePath = true,
) =>
	pathname
		? uriEscapePath
			? encodeURIComponent(pathname).replace(/%2F/g, '/')
			: pathname
		: '/';


        const getCanonicalQueryString = (
            searchParams,
        ) =>
            Array.from(searchParams)
                .sort(([keyA, valA], [keyB, valB]) => {
                    if (keyA === keyB) {
                        return valA < valB ? -1 : 1;
                    }
        
                    return keyA < keyB ? -1 : 1;
                })
                .map(([key, val]) => `${escapeUri(key)}=${escapeUri(val)}`)
                .join('&');
        
        const escapeUri = (uri) =>
            encodeURIComponent(uri).replace(/[!'()*]/g, hexEncode);
        
        const hexEncode = (c) =>
            `%${c.charCodeAt(0).toString(16).toUpperCase()}`;


            const getCanonicalHeaders = (headers) =>
            Object.entries(headers)
                .map(([key, value]) => ({
                    key: key.toLowerCase(),
                    value: value?.trim().replace(/\s+/g, ' ') ?? '',
                }))
                .sort((a, b) => (a.key < b.key ? -1 : 1))
                .map(entry => `${entry.key}:${entry.value}\n`)
                .join('');

                export const getHashedPayload = (body) => {
                    // return precalculated empty hash if body is undefined or null
                    if (body == null) {
                        return EMPTY_HASH;
                    }
                
                    if (isSourceData(body)) {
                        const hashedData = getHashedDataAsHex(null, body);
                
                        return hashedData;
                    }
                
                    // Defined body is not signable. Return unsigned payload which may or may not be accepted by the service.
                    return UNSIGNED_PAYLOAD;
                };
                
                const isSourceData = (body) =>
                    typeof body === 'string' || ArrayBuffer.isView(body) || isArrayBuffer(body);
                
                const isArrayBuffer = (arg) =>
                    (typeof ArrayBuffer === 'function' && arg instanceof ArrayBuffer) ||
                    Object.prototype.toString.call(arg) === '[object ArrayBuffer]';
export const getCanonicalRequest = (
	{ body, headers, method, url },
	uriEscapePath = true,
) =>
	[
		method,
		getCanonicalUri(url.pathname, uriEscapePath),
		getCanonicalQueryString(url.searchParams),
		getCanonicalHeaders(headers),
		getSignedHeaders(headers),
		getHashedPayload(body),
	].join('\n');


const getFormattedDates = (date) => {
	const longDate = date.toISOString().replace(/[:-]|\.\d{3}/g, '');

	return {
		longDate,
		shortDate: longDate.slice(0, 8),
	};
};

export const KEY_TYPE_IDENTIFIER = 'aws4_request';
/**
 * Returns the hashed data a `Uint8Array`.
 *
 * @param key `SourceData` to be used as hashing key.
 * @param data Hashable `SourceData`.
 * @returns `Uint8Array` created from the data as input to a hash function.
 */

export const getCredentialScope = (
	date,
	region,
	service,
) => `${date}/${region}/${service}/${KEY_TYPE_IDENTIFIER}`;

export const getHashedData = (
	key,
	data,
) => {
	const sha256 = new Sha256(key ?? undefined);
	sha256.update(data);
	// TODO: V6 flip to async digest
	const hashedData = sha256.digestSync();

	return hashedData;
};

/**
 * Returns the hashed data as a hex string.
 *
 * @param key `SourceData` to be used as hashing key.
 * @param data Hashable `SourceData`.
 * @returns String using lowercase hexadecimal characters created from the data as input to a hash function.
 *
 * @internal
 */
export const getHashedDataAsHex = (
	key,
	data,
) => {
	const hashedData = getHashedData(key, data);

	return toHex(hashedData);
};


export const getStringToSign = (
	date,
	credentialScope,
	hashedRequest,
) =>
	[SHA256_ALGORITHM_IDENTIFIER, date, credentialScope, hashedRequest].join(
		'\n',
	);


/**
 * Calculates and returns an AWS API Signature.
 * https://docs.aws.amazon.com/IAM/latest/UserGuide/create-signed-request.html
 *
 * @param request `HttpRequest` to be signed.
 * @param signRequestOptions `SignRequestOptions` object containing values used to construct the signature.
 * @returns AWS API Signature to sign a request or url with.
 *
 * @internal
 */
export const getSignature = (
	request,
	{
		credentialScope,
		longDate,
		secretAccessKey,
		shortDate,
		signingRegion,
		signingService,
		uriEscapePath,
	},
) => {
	// step 1: create a canonical request
	const canonicalRequest = getCanonicalRequest(request, uriEscapePath);

	// step 2: create a hash of the canonical request
	const hashedRequest = getHashedDataAsHex(null, canonicalRequest);

	// step 3: create a string to sign
	const stringToSign = getStringToSign(
		longDate,
		credentialScope,
		hashedRequest,
	);

	// step 4: calculate the signature
	const signature = getHashedDataAsHex(
		getSigningKey(secretAccessKey, shortDate, signingRegion, signingService),
		stringToSign,
	);

	return signature;
};

 const AMZ_DATE_QUERY_PARAM = 'X-Amz-Date';
 const TOKEN_QUERY_PARAM = 'X-Amz-Security-Token';

// headers
 const AUTH_HEADER = 'authorization';
 const HOST_HEADER = 'host';
 const AMZ_DATE_HEADER = AMZ_DATE_QUERY_PARAM.toLowerCase();
 const TOKEN_HEADER = TOKEN_QUERY_PARAM.toLowerCase();

// identifiers

// preset values

/**
 * Extracts common values used for signing both requests and urls.
 *
 * @param options `SignRequestOptions` object containing values used to construct the signature.
 * @returns Common `SigningValues` used for signing.
 *
 * @internal
 */
 const getSigningValues = ({
	credentials,
	signingDate = new Date(),
	signingRegion,
	signingService,
	uriEscapePath = true,
}) => {
	// get properties from credentials
	const { accessKeyId, secretAccessKey, sessionToken } = credentials;
	// get formatted dates for signing
	const { longDate, shortDate } = getFormattedDates(signingDate);
	// copy header and set signing properties
	const credentialScope = getCredentialScope(
		shortDate,
		signingRegion,
		signingService,
	);

	return {
		accessKeyId,
		credentialScope,
		longDate,
		secretAccessKey,
		sessionToken,
		shortDate,
		signingRegion,
		signingService,
		uriEscapePath,
	};
};

/**
 * Returns signed headers.
 *
 * @param headers `headers` from the request.
 * @returns List of headers included in canonical headers, separated by semicolons (;). This indicates which headers
 * are part of the signing process. Header names must use lowercase characters and must appear in alphabetical order.
 *
 * @internal
 */
export const getSignedHeaders = (headers) =>
	Object.keys(headers)
		.map(key => key.toLowerCase())
		.sort()
		.join(';');


/**
 * Given a `HttpRequest`, returns a Signature Version 4 signed `HttpRequest`.
 *
 * @param request `HttpRequest` to be signed.
 * @param signRequestOptions `SignRequestOptions` object containing values used to construct the signature.
 * @returns A `HttpRequest` with authentication headers which can grant temporary access to AWS resources.
 */
export const signRequest = (
	request,
	options,
) => {
	const signingValues = getSigningValues(options);
	const { accessKeyId, credentialScope, longDate, sessionToken } =
		signingValues;

	// create the request to sign
	const headers = { ...request.headers };
	headers[HOST_HEADER] = request.url.host;
	headers[AMZ_DATE_HEADER] = longDate;
	if (sessionToken) {
		headers[TOKEN_HEADER] = sessionToken;
	}
	const requestToSign = { ...request, headers };

	// calculate and add the signature to the request
	const signature = getSignature(requestToSign, signingValues);
	const credentialEntry = `Credential=${accessKeyId}/${credentialScope}`;
	const signedHeadersEntry = `SignedHeaders=${getSignedHeaders(headers)}`;
	const signatureEntry = `Signature=${signature}`;
	headers[AUTH_HEADER] =
		`${SHA256_ALGORITHM_IDENTIFIER} ${credentialEntry}, ${signedHeadersEntry}, ${signatureEntry}`;

	return requestToSign;
};