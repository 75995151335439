/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTag = /* GraphQL */ `
  mutation CreateTag($input: TagInput!) {
    createTag(input: $input) {
      _id
      name
      createdAt
      streamTaggedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      TAGGED {
        _id
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const connectTagToStreamEdgeTagged = /* GraphQL */ `
  mutation ConnectTagToStreamEdgeTagged($from_id: ID!, $to_id: ID!) {
    connectTagToStreamEdgeTagged(from_id: $from_id, to_id: $to_id) {
      _id
      createdAt
      __typename
    }
  }
`;
export const updateEdgeTaggedFromTagToStream = /* GraphQL */ `
  mutation UpdateEdgeTaggedFromTagToStream(
    $from_id: ID!
    $to_id: ID!
    $edge: TagInput!
  ) {
    updateEdgeTaggedFromTagToStream(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      createdAt
      __typename
    }
  }
`;
export const createSearch = /* GraphQL */ `
  mutation CreateSearch($input: SearchInput!) {
    createSearch(input: $input) {
      _id
      text
      createdAt
      userAskedIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      __typename
    }
  }
`;
export const connectUserToSearchEdgeAsked = /* GraphQL */ `
  mutation ConnectUserToSearchEdgeAsked($from_id: ID!, $to_id: ID!) {
    connectUserToSearchEdgeAsked(from_id: $from_id, to_id: $to_id) {
      _id
      created_at
      __typename
    }
  }
`;
export const createOrGetUser = /* GraphQL */ `
  mutation CreateOrGetUser($input: UserInput!, $createdAt: String) {
    createOrGetUser(input: $input, createdAt: $createdAt) {
      _id
      user_id
      name
      createdAt
      streamRankedsIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      topicRankedsIn {
        _id
        difficulty
        name
        __typename
      }
      streamFollowingsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamRequestedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamViewedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamOpenedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamStartedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamCompletedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      playStartedsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      topicEngagedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSkippedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSelectedsOut {
        _id
        difficulty
        name
        __typename
      }
      answerGavesOut {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      questionnodeAskedsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      questionnodeAnsweredsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      searchAskedsOut {
        _id
        text
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      GAVE {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionsetComplete = /* GraphQL */ `
  mutation CreateQuestionsetComplete(
    $input: QuestionsetInput!
    $questionIds: [String]
  ) {
    createQuestionsetComplete(input: $input, questionIds: $questionIds) {
      _id
      type
      title
      published_date
      createdAt
      version
      id
      questionnodeContainssOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      playOfsIn {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      streamMadeIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      OF {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic($input: TopicInput!) {
    createTopic(input: $input) {
      _id
      difficulty
      name
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userEngagedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userSkippedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      topicSubsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSubsIn {
        _id
        difficulty
        name
        __typename
      }
      userSelectedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionnodeInsIn {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      streamIncludesIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      unansweredQuestions {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      SUB {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic($input: TopicInput!) {
    updateTopic(input: $input) {
      _id
      difficulty
      name
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userEngagedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userSkippedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      topicSubsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSubsIn {
        _id
        difficulty
        name
        __typename
      }
      userSelectedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionnodeInsIn {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      streamIncludesIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      unansweredQuestions {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      SUB {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const createStream = /* GraphQL */ `
  mutation CreateStream($input: StreamInput!) {
    createStream(input: $input) {
      _id
      name
      latestVersion
      createdAt
      topicIncludesOut {
        _id
        difficulty
        name
        __typename
      }
      userFollowingsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetMadesOut {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runSpawnedsOut {
        _id
        id
        createdAt
        version
        __typename
      }
      userRankedsOut {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userRequestedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userViewedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userOpenedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userStartedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userCompletedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      tagTaggedsIn {
        _id
        name
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      MADE {
        _id
        __typename
      }
      INCLUDES {
        _id
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      SPAWNED {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      COMPLETED {
        _id
        occuredAt
        __typename
      }
      TAGGED {
        _id
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      _id
      user_id
      name
      createdAt
      streamRankedsIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      topicRankedsIn {
        _id
        difficulty
        name
        __typename
      }
      streamFollowingsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamRequestedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamViewedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamOpenedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamStartedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamCompletedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      playStartedsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      topicEngagedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSkippedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSelectedsOut {
        _id
        difficulty
        name
        __typename
      }
      answerGavesOut {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      questionnodeAskedsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      questionnodeAnsweredsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      searchAskedsOut {
        _id
        text
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      GAVE {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UserInput!) {
    updateUser(input: $input) {
      _id
      user_id
      name
      createdAt
      streamRankedsIn {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      topicRankedsIn {
        _id
        difficulty
        name
        __typename
      }
      streamFollowingsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamRequestedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamViewedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamOpenedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamStartedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      streamCompletedsOut {
        _id
        name
        latestVersion
        createdAt
        __typename
      }
      playStartedsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      topicEngagedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSkippedsOut {
        _id
        difficulty
        name
        __typename
      }
      topicSelectedsOut {
        _id
        difficulty
        name
        __typename
      }
      answerGavesOut {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      questionnodeAskedsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      questionnodeAnsweredsOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      searchAskedsOut {
        _id
        text
        createdAt
        __typename
      }
      RANKED {
        _id
        elo
        updatedAt
        __typename
      }
      ENGAGED {
        _id
        __typename
      }
      SKIPPED {
        _id
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      GAVE {
        _id
        __typename
      }
      SELECTED {
        _id
        created_at
        __typename
      }
      FOLLOWING {
        _id
        __typename
      }
      REQUESTED {
        _id
        occuredAt
        __typename
      }
      VIEWED {
        _id
        occuredAt
        __typename
      }
      OPENED {
        _id
        occuredAt
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionnode = /* GraphQL */ `
  mutation CreateQuestionnode($input: QuestionnodeInput!) {
    createQuestionnode(input: $input) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      userAnsweredsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userAskedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetContainsIn {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runMadeIn {
        _id
        id
        createdAt
        version
        __typename
      }
      answerTosIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      topicInOut {
        _id
        difficulty
        name
        __typename
      }
      rulesPassedIn {
        _id
        name
        __typename
      }
      rulesFailedIn {
        _id
        name
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      TO {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      PASSED {
        _id
        __typename
      }
      FAILED {
        _id
        __typename
      }
      MADE {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestionnode = /* GraphQL */ `
  mutation UpdateQuestionnode($input: QuestionnodeInput!) {
    updateQuestionnode(input: $input) {
      _id
      id
      version
      text
      fact
      correctAnswer
      incorrectAnswers
      requestedTopic
      userAnsweredsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      userAskedsIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      questionsetContainsIn {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      runMadeIn {
        _id
        id
        createdAt
        version
        __typename
      }
      answerTosIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      topicInOut {
        _id
        difficulty
        name
        __typename
      }
      rulesPassedIn {
        _id
        name
        __typename
      }
      rulesFailedIn {
        _id
        name
        __typename
      }
      ANSWERED {
        _id
        was_correct
        answer_id
        created_at
        __typename
      }
      ASKED {
        _id
        created_at
        __typename
      }
      CONTAINS {
        _id
        __typename
      }
      TO {
        _id
        __typename
      }
      IN {
        _id
        __typename
      }
      PASSED {
        _id
        __typename
      }
      FAILED {
        _id
        __typename
      }
      MADE {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionset = /* GraphQL */ `
  mutation CreateQuestionset($input: QuestionsetInput!) {
    createQuestionset(input: $input) {
      _id
      type
      title
      published_date
      createdAt
      version
      id
     
      __typename
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer($input: AnswerInput!) {
    createAnswer(input: $input) {
      _id
      created_at
      answer_id
      was_correct
      questionnodeToOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      userGaveIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      playDuringsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      TO {
        _id
        __typename
      }
      GAVE {
        _id
        __typename
      }
      DURING {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer($input: AnswerInput!) {
    updateAnswer(input: $input) {
      _id
      created_at
      answer_id
      was_correct
      questionnodeToOut {
        _id
        id
        version
        text
        fact
        correctAnswer
        incorrectAnswers
        requestedTopic
        __typename
      }
      userGaveIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      playDuringsOut {
        _id
        gameMode
        start_time
        finished
        correct
        incorrect
        rounds
        score
        speedScore
        dayInt
        weekInt
        monthInt
        yearInt
        __typename
      }
      TO {
        _id
        __typename
      }
      GAVE {
        _id
        __typename
      }
      DURING {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const createPlay = /* GraphQL */ `
  mutation CreatePlay($input: PlayInput!) {
    createPlay(input: $input) {
      _id
      gameMode
      userStartedIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      start_time
      finished
      correct
      incorrect
      rounds
      score
      speedScore
      dayInt
      weekInt
      monthInt
      yearInt
      questionsetOfOut {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      answerDuringsIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      OF {
        _id
        __typename
      }
      DURING {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const updatePlay = /* GraphQL */ `
  mutation UpdatePlay($input: PlayInput!) {
    updatePlay(input: $input) {
      _id
      gameMode
      userStartedIn {
        _id
        user_id
        name
        createdAt
        __typename
      }
      start_time
      finished
      correct
      incorrect
      rounds
      score
      speedScore
      dayInt
      weekInt
      monthInt
      yearInt
      questionsetOfOut {
        _id
        type
        title
        published_date
        createdAt
        version
        id
        __typename
      }
      answerDuringsIn {
        _id
        created_at
        answer_id
        was_correct
        __typename
      }
      STARTED {
        _id
        occuredAt
        __typename
      }
      OF {
        _id
        __typename
      }
      DURING {
        _id
        __typename
      }
      __typename
    }
  }
`;
export const connectUserToTopicEdgeEngaged = /* GraphQL */ `
  mutation ConnectUserToTopicEdgeEngaged($from_id: ID!, $to_id: ID!) {
    connectUserToTopicEdgeEngaged(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectUserToStreamEdgeFollowing = /* GraphQL */ `
  mutation ConnectUserToStreamEdgeFollowing($from_id: ID!, $to_id: ID!) {
    connectUserToStreamEdgeFollowing(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectUserToStreamEdgeRequested = /* GraphQL */ `
  mutation ConnectUserToStreamEdgeRequested($from_id: ID!, $to_id: ID!) {
    connectUserToStreamEdgeRequested(from_id: $from_id, to_id: $to_id) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const connectUserToStreamEdgeViewed = /* GraphQL */ `
  mutation ConnectUserToStreamEdgeViewed($from_id: ID!, $to_id: ID!) {
    connectUserToStreamEdgeViewed(from_id: $from_id, to_id: $to_id) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const connectUserToStreamEdgeOpened = /* GraphQL */ `
  mutation ConnectUserToStreamEdgeOpened($from_id: ID!, $to_id: ID!) {
    connectUserToStreamEdgeOpened(from_id: $from_id, to_id: $to_id) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const connectUserToStreamEdgeStarted = /* GraphQL */ `
  mutation ConnectUserToStreamEdgeStarted($from_id: ID!, $to_id: ID!) {
    connectUserToStreamEdgeStarted(from_id: $from_id, to_id: $to_id) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const connectUserToStreamEdgeCompleted = /* GraphQL */ `
  mutation ConnectUserToStreamEdgeCompleted($from_id: ID!, $to_id: ID!) {
    connectUserToStreamEdgeCompleted(from_id: $from_id, to_id: $to_id) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const updateEdgeFollowingFromUserToStream = /* GraphQL */ `
  mutation UpdateEdgeFollowingFromUserToStream(
    $from_id: ID!
    $to_id: ID!
    $edge: EventInput!
  ) {
    updateEdgeFollowingFromUserToStream(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      __typename
    }
  }
`;
export const updateEdgeRequestedFromUserToStream = /* GraphQL */ `
  mutation UpdateEdgeRequestedFromUserToStream(
    $from_id: ID!
    $to_id: ID!
    $edge: EventInput!
  ) {
    updateEdgeRequestedFromUserToStream(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const updateEdgeViewedFromUserToStream = /* GraphQL */ `
  mutation UpdateEdgeViewedFromUserToStream(
    $from_id: ID!
    $to_id: ID!
    $edge: EventInput!
  ) {
    updateEdgeViewedFromUserToStream(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const updateEdgeOpenedFromUserToStream = /* GraphQL */ `
  mutation UpdateEdgeOpenedFromUserToStream(
    $from_id: ID!
    $to_id: ID!
    $edge: EventInput!
  ) {
    updateEdgeOpenedFromUserToStream(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const updateEdgeStartedFromUserToStream = /* GraphQL */ `
  mutation UpdateEdgeStartedFromUserToStream(
    $from_id: ID!
    $to_id: ID!
    $edge: EventInput!
  ) {
    updateEdgeStartedFromUserToStream(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const updateEdgeCompletedFromUserToStream = /* GraphQL */ `
  mutation UpdateEdgeCompletedFromUserToStream(
    $from_id: ID!
    $to_id: ID!
    $edge: EventInput!
  ) {
    updateEdgeCompletedFromUserToStream(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const connectUserToTopicEdgeSkipped = /* GraphQL */ `
  mutation ConnectUserToTopicEdgeSkipped($from_id: ID!, $to_id: ID!) {
    connectUserToTopicEdgeSkipped(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectUserToQuestionnodeEdgeAnswered = /* GraphQL */ `
  mutation ConnectUserToQuestionnodeEdgeAnswered(
    $from_id: ID!
    $to_id: ID!
    $edge: AnsweredInput!
  ) {
    connectUserToQuestionnodeEdgeAnswered(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      was_correct
      answer_id
      created_at
      __typename
    }
  }
`;
export const updateEdgeAnsweredFromUserToQuestionnode = /* GraphQL */ `
  mutation UpdateEdgeAnsweredFromUserToQuestionnode(
    $from_id: ID!
    $to_id: ID!
    $edge: AnsweredInput!
  ) {
    updateEdgeAnsweredFromUserToQuestionnode(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      was_correct
      answer_id
      created_at
      __typename
    }
  }
`;
export const connectUserToQuestionnodeEdgeAsked = /* GraphQL */ `
  mutation ConnectUserToQuestionnodeEdgeAsked(
    $from_id: ID!
    $to_id: ID!
    $edge: AskedInput!
  ) {
    connectUserToQuestionnodeEdgeAsked(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      created_at
      __typename
    }
  }
`;
export const connectStreamToTopicEdgeIncludes = /* GraphQL */ `
  mutation ConnectStreamToTopicEdgeIncludes($from_id: ID!, $to_id: ID!) {
    connectStreamToTopicEdgeIncludes(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const deleteEdgeIncludesFromStreamToTopic = /* GraphQL */ `
  mutation DeleteEdgeIncludesFromStreamToTopic($from_id: ID!, $to_id: ID!) {
    deleteEdgeIncludesFromStreamToTopic(from_id: $from_id, to_id: $to_id)
  }
`;
export const updateEdgeAskedFromUserToQuestionnode = /* GraphQL */ `
  mutation UpdateEdgeAskedFromUserToQuestionnode(
    $from_id: ID!
    $to_id: ID!
    $edge: AskedInput!
  ) {
    updateEdgeAskedFromUserToQuestionnode(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      created_at
      __typename
    }
  }
`;
export const connectQuestionsetToQuestionnodeEdgeContains = /* GraphQL */ `
  mutation ConnectQuestionsetToQuestionnodeEdgeContains(
    $from_id: ID!
    $to_id: ID!
  ) {
    connectQuestionsetToQuestionnodeEdgeContains(
      from_id: $from_id
      to_id: $to_id
    ) {
      _id
      __typename
    }
  }
`;
export const connectTopicToTopicEdgeSub = /* GraphQL */ `
  mutation ConnectTopicToTopicEdgeSub($from_id: ID!, $to_id: ID!) {
    connectTopicToTopicEdgeSub(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectUserToPlayEdgeStarted = /* GraphQL */ `
  mutation ConnectUserToPlayEdgeStarted($from_id: ID!, $to_id: ID!) {
    connectUserToPlayEdgeStarted(from_id: $from_id, to_id: $to_id) {
      _id
      occuredAt
      __typename
    }
  }
`;
export const connectAnswerToQuestionnodeEdgeTo = /* GraphQL */ `
  mutation ConnectAnswerToQuestionnodeEdgeTo($from_id: ID!, $to_id: ID!) {
    connectAnswerToQuestionnodeEdgeTo(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectUserToAnswerEdgeGave = /* GraphQL */ `
  mutation ConnectUserToAnswerEdgeGave($from_id: ID!, $to_id: ID!) {
    connectUserToAnswerEdgeGave(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectPlayToQuestionsetEdgeOf = /* GraphQL */ `
  mutation ConnectPlayToQuestionsetEdgeOf($from_id: ID!, $to_id: ID!) {
    connectPlayToQuestionsetEdgeOf(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectUserToTopicEdgeSelected = /* GraphQL */ `
  mutation ConnectUserToTopicEdgeSelected(
    $from_id: ID!
    $to_id: ID!
    $edge: SelectedInput!
  ) {
    connectUserToTopicEdgeSelected(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      created_at
      __typename
    }
  }
`;
export const updateEdgeSelectedFromUserToTopic = /* GraphQL */ `
  mutation UpdateEdgeSelectedFromUserToTopic(
    $from_id: ID!
    $to_id: ID!
    $edge: SelectedInput!
  ) {
    updateEdgeSelectedFromUserToTopic(
      from_id: $from_id
      to_id: $to_id
      edge: $edge
    ) {
      _id
      created_at
      __typename
    }
  }
`;
export const connectQuestionsetToStreamEdgeIn = /* GraphQL */ `
  mutation ConnectQuestionsetToStreamEdgeIn($from_id: ID!, $to_id: ID!) {
    connectQuestionsetToStreamEdgeIn(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectQuestionnodeToTopicEdgeIn = /* GraphQL */ `
  mutation ConnectQuestionnodeToTopicEdgeIn($from_id: ID!, $to_id: ID!) {
    connectQuestionnodeToTopicEdgeIn(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const connectAnswerToPlayEdgeDuring = /* GraphQL */ `
  mutation ConnectAnswerToPlayEdgeDuring($from_id: ID!, $to_id: ID!) {
    connectAnswerToPlayEdgeDuring(from_id: $from_id, to_id: $to_id) {
      _id
      __typename
    }
  }
`;
export const createWebhookEvents = /* GraphQL */ `
  mutation CreateWebhookEvents(
    $input: CreateWebhookEventsInput!
    $condition: ModelWebhookEventsConditionInput
  ) {
    createWebhookEvents(input: $input, condition: $condition) {
      id
      _ttl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWebhookEvents = /* GraphQL */ `
  mutation UpdateWebhookEvents(
    $input: UpdateWebhookEventsInput!
    $condition: ModelWebhookEventsConditionInput
  ) {
    updateWebhookEvents(input: $input, condition: $condition) {
      id
      _ttl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWebhookEvents = /* GraphQL */ `
  mutation DeleteWebhookEvents(
    $input: DeleteWebhookEventsInput!
    $condition: ModelWebhookEventsConditionInput
  ) {
    deleteWebhookEvents(input: $input, condition: $condition) {
      id
      _ttl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      type
      text
      version
      correctAnswers
      correctAnswer
      incorrectAnswers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      type
      text
      version
      correctAnswers
      correctAnswer
      incorrectAnswers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      type
      text
      version
      correctAnswers
      correctAnswer
      incorrectAnswers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserQuestionAnswer = /* GraphQL */ `
  mutation CreateUserQuestionAnswer(
    $input: CreateUserQuestionAnswerInput!
    $condition: ModelUserQuestionAnswerConditionInput
  ) {
    createUserQuestionAnswer(input: $input, condition: $condition) {
      id
      userId
      topic
      question {
        id
        type
        text
        version
        correctAnswers
        correctAnswer
        incorrectAnswers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chosenAnswers
      wasCorrect
      timeToAnswer
      points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userQuestionAnswerQuestionId
      owner
      __typename
    }
  }
`;
export const updateUserQuestionAnswer = /* GraphQL */ `
  mutation UpdateUserQuestionAnswer(
    $input: UpdateUserQuestionAnswerInput!
    $condition: ModelUserQuestionAnswerConditionInput
  ) {
    updateUserQuestionAnswer(input: $input, condition: $condition) {
      id
      userId
      topic
      question {
        id
        type
        text
        version
        correctAnswers
        correctAnswer
        incorrectAnswers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chosenAnswers
      wasCorrect
      timeToAnswer
      points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userQuestionAnswerQuestionId
      owner
      __typename
    }
  }
`;
export const deleteUserQuestionAnswer = /* GraphQL */ `
  mutation DeleteUserQuestionAnswer(
    $input: DeleteUserQuestionAnswerInput!
    $condition: ModelUserQuestionAnswerConditionInput
  ) {
    deleteUserQuestionAnswer(input: $input, condition: $condition) {
      id
      userId
      topic
      question {
        id
        type
        text
        version
        correctAnswers
        correctAnswer
        incorrectAnswers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chosenAnswers
      wasCorrect
      timeToAnswer
      points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userQuestionAnswerQuestionId
      owner
      __typename
    }
  }
`;
export const createUserSurvey = /* GraphQL */ `
  mutation CreateUserSurvey(
    $input: CreateUserSurveyInput!
    $condition: ModelUserSurveyConditionInput
  ) {
    createUserSurvey(input: $input, condition: $condition) {
      id
      userId
      requestChannel
      startedAt
      requestTopic
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserSurvey = /* GraphQL */ `
  mutation UpdateUserSurvey(
    $input: UpdateUserSurveyInput!
    $condition: ModelUserSurveyConditionInput
  ) {
    updateUserSurvey(input: $input, condition: $condition) {
      id
      userId
      requestChannel
      startedAt
      requestTopic
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserSurvey = /* GraphQL */ `
  mutation DeleteUserSurvey(
    $input: DeleteUserSurveyInput!
    $condition: ModelUserSurveyConditionInput
  ) {
    deleteUserSurvey(input: $input, condition: $condition) {
      id
      userId
      requestChannel
      startedAt
      requestTopic
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createFact = /* GraphQL */ `
  mutation CreateFact(
    $input: CreateFactInput!
    $condition: ModelFactConditionInput
  ) {
    createFact(input: $input, condition: $condition) {
      id
      date
      topic
      text
      interactions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFact = /* GraphQL */ `
  mutation UpdateFact(
    $input: UpdateFactInput!
    $condition: ModelFactConditionInput
  ) {
    updateFact(input: $input, condition: $condition) {
      id
      date
      topic
      text
      interactions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFact = /* GraphQL */ `
  mutation DeleteFact(
    $input: DeleteFactInput!
    $condition: ModelFactConditionInput
  ) {
    deleteFact(input: $input, condition: $condition) {
      id
      date
      topic
      text
      interactions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserFactInteraction = /* GraphQL */ `
  mutation CreateUserFactInteraction(
    $input: CreateUserFactInteractionInput!
    $condition: ModelUserFactInteractionConditionInput
  ) {
    createUserFactInteraction(input: $input, condition: $condition) {
      id
      userId
      fact {
        id
        date
        topic
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      action
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      factInteractionsDate
      factInteractionsTopic
      factInteractionsId
      owner
      __typename
    }
  }
`;
export const updateUserFactInteraction = /* GraphQL */ `
  mutation UpdateUserFactInteraction(
    $input: UpdateUserFactInteractionInput!
    $condition: ModelUserFactInteractionConditionInput
  ) {
    updateUserFactInteraction(input: $input, condition: $condition) {
      id
      userId
      fact {
        id
        date
        topic
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      action
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      factInteractionsDate
      factInteractionsTopic
      factInteractionsId
      owner
      __typename
    }
  }
`;
export const deleteUserFactInteraction = /* GraphQL */ `
  mutation DeleteUserFactInteraction(
    $input: DeleteUserFactInteractionInput!
    $condition: ModelUserFactInteractionConditionInput
  ) {
    deleteUserFactInteraction(input: $input, condition: $condition) {
      id
      userId
      fact {
        id
        date
        topic
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      action
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      factInteractionsDate
      factInteractionsTopic
      factInteractionsId
      owner
      __typename
    }
  }
`;
