import { createSlice } from "@reduxjs/toolkit";

export const metaSlice = createSlice({
  name: "dataStoreReady",
  initialState: {
    ready: false,
  },
  reducers: {
    setReady: (state, action) => {
      state.ready = true;
    },
  },
});
