import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

export const CircleLoadingIndicator = ({ litSegments, themeColor }) => {
    const magnifier = 4;
    const litSegments_ = litSegments * magnifier;

    const [extra, setExtra] = React.useState(0);

    const totalSegments = 4 * magnifier;
    const radius = 50;
    const strokeWidth = 12;
    const circumference = 2 * Math.PI * radius;
    const segmentLength = circumference / totalSegments;
    const gap = segmentLength * 0.25; // Adjust the gap between segments if needed

    useEffect(() => {
        setExtra(0)
        setTimeout(() => {
          setExtra(l => l + 1);
        }, 1500);
        setTimeout(() => {
          setExtra(l => l + 1);
        }, 2500);
        setTimeout(() => {
          setExtra(l => l + 1);
        }, 5000);
        setTimeout(() => {
          setExtra(l => l + 1);
        }, 8000);
    }, [litSegments]);
  
    return (
      <div className="relative flex justify-center items-center">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 120 120"
          className="transform -rotate-90"
        >
          {Array.from({ length: totalSegments }).map((_, idx) => {
            const comparator = litSegments_ + extra
            const index =  idx;
            const isLit = index < comparator;
            const isPulsing = index === comparator;

            // themeColor

            let color;
            switch (themeColor) {
                case 'purple':
                    color = index < comparator 
                        ? '#c084fc' // purple-400
                        : index === comparator
                        ? '#581c87' // purple-900
                        : "#581c87"
                    break;
                case 'blue':
                    color = index < comparator 
                        ? '#60a5fa' // blue-400
                        : index === comparator
                        ? '#1e3a8a' // blue-900
                        : "#1e3a8a"
                    break;
                case 'green':
                    color = index < comparator 
                        ? '#10b981' // green-500
                        : index === comparator
                        ? '#9ca3af' // text-slate-400
                        : ""
                    break;
                case 'red':
                    color = index < comparator 
                        ? '#ef4444' // red-500
                        : index === comparator
                        ? '#9ca3af' // text-slate-400
                        : ""
                    break;
                default:
                    color = index < comparator 
                        ? '#a855f7' // purple-500
                        : index === comparator
                        ? '#334155' // text-slate-400
                        : "#334155"
                    break;
            }
            return (
              <motion.circle
                key={index}
                cx="60"
                cy="60"
                r={radius}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeDasharray={`${segmentLength - gap} ${circumference}`}
                strokeDashoffset={-index * segmentLength}
                // animate={
                //   isPulsing
                //     ? { opacity: [1, 0.5, 1] }
                //     : {}
                // }
                transition={{ duration: 0.8, repeat: Infinity }}
              />
            );
          })}
        </svg>
      </div>
    );
  };
  
  export const StaticCircleLoadingIndicator = ({ litSegments, maximum, themeColor }) => {
    const magnifier = 1;
    const litSegments_ = litSegments * magnifier;


    const totalSegments = maximum * magnifier;
    const radius = 50;
    const strokeWidth = 9;
    const circumference = 2 * Math.PI * radius;
    const segmentLength = circumference / totalSegments;
    const gap = segmentLength * 0.08; // Adjust the gap between segments if needed

  
    return (
      <div className="z-[500] relative flex justify-center items-center">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 120 120"
          className="transform -rotate-90"
        >
          {Array.from({ length: totalSegments }).map((_, idx) => {
            const comparator = litSegments_
            const index =  idx;
            const isLit = index < comparator;
            const isPulsing = index === comparator;

            // themeColor

            let color;
            switch (themeColor) {
                case 'purple':
                    color = index < comparator 
                        ? 'text-purple-400 dark:text-purple-500' // purple-400
                        : index === comparator
                        ? 'text-blue-600 dark:text-blue-500' // purple-950
                        : "text-blue-600 dark:text-blue-500"
                    break;
                case 'blue':
                  color = index < comparator 
                  ? 'text-blue-600 dark:text-blue-500' // purple-400
                  : index === comparator
                  ? 'text-purple-600 dark:text-purple-500' // purple-950
                  : "text-purple-600 dark:text-purple-500"
                    break;
                case 'green':
                    color = index < comparator 
                        ? '#10b981' // green-500
                        : index === comparator
                        ? '#9ca3af' // text-slate-400
                        : ""
                    break;
                case 'red':
                    color = index < comparator 
                        ? '#ef4444' // red-500
                        : index === comparator
                        ? '#450a0a' // red-950
                        : "#450a0a"
                    break;
                default:
                    color = index < comparator 
                        ? '#a855f7' // purple-500
                        : index === comparator
                        ? '#9ca3af' // text-slate-400
                        : ""
                    break;
            }
            return (
              <motion.circle
                className={color}
                key={index}
                cx="60"
                cy="60"
                r={radius}
                fill="none"
                stroke={"currentColor"}
                strokeWidth={strokeWidth}
                strokeDasharray={`${segmentLength - gap} ${circumference}`}
                strokeDashoffset={-index * segmentLength}
                // animate={
                //   isPulsing
                //     ? { opacity: [1, 0.5, 1] }
                //     : {}
                // }
                transition={{ duration: 0.8, repeat: Infinity }}
              />
            );
          })}
        </svg>
      </div>
    );
  };
  