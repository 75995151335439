import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const LoadingIndicator = () => {
  const [percentage, setPercentage] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage((prev) => {
        const nextPercentage = prev + Math.random() * 1 + (100 - prev) / 40;
        if (nextPercentage <= 99) {
          return Math.round(nextPercentage);
        } else {
          clearInterval(timer);
          return 99;
        }
      });
    }, 400);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    controls.start({ width: `${percentage}%` });
  }, [percentage, controls]);

  return (
    <div className="relative h-full flex items-center rounded overflow-hidden justify-between">
      <motion.div
        initial={{ width: "0%" }}
        animate={controls}
        transition={{ duration: 0.9 }}
        className="bg-blue-500 h-full"
      />
      <div className="absolute right-0 w-full flex items-center justify-center text-white">
        {percentage}%
      </div>
    </div>
  );
};

export default LoadingIndicator;
