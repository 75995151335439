export async function fetchWithRetry(url, options = {}, retries = 3) {
  let lastError;
  for (let i = 0; i <= retries; i++) {
    try {
      let response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
      return response;
    } catch (error) {
      lastError = error;
      console.error(`Attempt ${i + 1} failed. Retrying...`);
      if (i < retries) {
        await new Promise((resolve) => setTimeout(resolve, 100)); // Delay before retrying (100 ms)
      }
    }
  }
  throw lastError; // If all retries failed, throw the last error encountered
}
