


/**
 * Extracts common values used for signing both requests and urls.
 *
 * @param options `SignRequestOptions` object containing values used to construct the signature.
 * @returns Common `SigningValues` used for signing.
 *
 * @internal
 */


export const KEY_TYPE_IDENTIFIER = 'aws4_request';

/**
 * Returns the credential scope which restricts the resulting signature to the specified region and service.
 *
 * @param date Current date in the format 'YYYYMMDD'.
 * @param region AWS region in which the service resides.
 * @param service Service to which the signed request is being sent.
 *
 * @returns  A string representing the credential scope with format 'YYYYMMDD/region/service/aws4_request'.
 *
 * @internal
 */
export const getCredentialScope = (
	date,
	region,
	service,
) => `${date}/${region}/${service}/${KEY_TYPE_IDENTIFIER}`;


export const getFormattedDates = (date) => {
	const longDate = date.toISOString().replace(/[:-]|\.\d{3}/g, '');

	return {
		longDate,
		shortDate: longDate.slice(0, 8),
	};
};

export const getSigningValues = ({
	credentials,
	signingDate = new Date(),
	signingRegion,
	signingService,
	uriEscapePath = true,
}) => {
	// get properties from credentials
	const { accessKeyId, secretAccessKey, sessionToken } = credentials;
	// get formatted dates for signing
	const { longDate, shortDate } = getFormattedDates(signingDate);
	// copy header and set signing properties
	const credentialScope = getCredentialScope(
		shortDate,
		signingRegion,
		signingService,
	);

	return {
		accessKeyId,
		credentialScope,
		longDate,
		secretAccessKey,
		sessionToken,
		shortDate,
		signingRegion,
		signingService,
		uriEscapePath,
	};
};