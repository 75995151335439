import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowForward, IoIosArrowBack, IoMdPlanet } from "react-icons/io";

import CorrectIncorrectAnimation from "./CorrectIncorrectAnimation";


// given a tree of knowledge domains, return the top n levels of the tree
// where the tree is stored like this:
// {
//     "1": {
//         "name": "Natural Sciences",
//         "1.1": {
//             "name": "Physics",
//             "1.1.1": {

function fillArray(array, length) {
  if (array.length >= length) {
    return array; // No need to add additional values
  }

  const additionalValues = length - array.length;
  for (let i = 0; i < additionalValues; i++) {
    array.push(""); // Add empty values to the array
  }

  return array;
}

function max3(arr) {
  if (arr.length <= 3) {
    return arr;
  } else {
    return arr.slice(0, 3);
  }
}

export const MultipleChoiceQuestion = (props) => {
  const [selected, setSelected] = useState(undefined);

  const { handleAnswerClick, handleDetailClick, question, selectedIndex } = props;
  const currentAnswers =
    props.incorrectAnswers === undefined
      ? []
      : max3([...props.incorrectAnswers]);
  const correctAnswer = question.correctAnswer || question.correctAnswers[0]
  const element =
    correctAnswer === undefined ? "" : correctAnswer;
  if (props.correctAnswerPlacement <= props.incorrectAnswers?.length) {
    currentAnswers.splice(props.correctAnswerPlacement, 0, element);
  }

  fillArray(currentAnswers, 4);

  const isCorrect =
    selectedIndex !== undefined &&
    currentAnswers[selectedIndex] === question.correctAnswer;
  const hasAnswered = selectedIndex !== undefined;

  const [animationIndex, setAnimationIndex] = useState(0);
  const handleAnswer = async (answerIndex, isCorrect) => {
    if (!props.doneLoading) {
      return
    }
    if(hasAnswered) {
      handleDetailClick()
      setAnimationIndex( i => i + 1)
      return
    }
    setSelected(answerIndex);
    await handleAnswerClick(answerIndex, isCorrect);
    window.gtag("event", "select_answer", {
      event_category: "engagement",

      is_correct: isCorrect,
      question_id: question.id,
      answer: currentAnswers[answerIndex],
      answer_index: answerIndex,
      question_type: "multiple_choice",
    });
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 1}}
      id="answers" className="p-4 pb-8 grid grid-cols-1 auto-rows-auto	">
      {currentAnswers !== undefined &&
        currentAnswers.map((answer, answerIndex) => {
          const isCorrectAnswer = question.correctAnswer === answer;
          const isSelectedAnswer = selectedIndex === answerIndex;

          let answeredStatus = "";
          let answeredStatusClasses = "";

          const isPrelimSelected = selected === answerIndex;

          // insane lol
          const displayNext = hasAnswered && ((isSelectedAnswer && isCorrect) || (!isSelectedAnswer && isCorrectAnswer));
          
          if (hasAnswered) {
            // if this was their choice and they're right
            if (isSelectedAnswer && isCorrect) {
              answeredStatus = "correct";
              answeredStatusClasses +=
                "p-6  my-1 border-none cursor-pointer  ";
              // if this was their choice and they're wrong
            } else if (isSelectedAnswer && !isCorrect) {
              answeredStatus = "incorrect";
              answeredStatusClasses +=
                "p-6  my-1 border-rose-400 dark:border-rose-500 border-none";
              // if this was not their choice and it's correct
            } else if (!isSelectedAnswer && isCorrectAnswer) {
              answeredStatus = "correct";
              answeredStatusClasses +=
                "my-1 p-6 border-none cursor-pointer ";
              // if this was not their choice and it's not correct
            } else {
              answeredStatus += "wrong";
              answeredStatusClasses +=
                " p-6 my-1 border-none";
            }
            // if they haven't answered yet and loaded
          } else if (answer !== "") {
            answeredStatus = "unanswered";
            const borderClass = isPrelimSelected ? " scale-95 " : "";
            answeredStatusClasses += borderClass;
            answeredStatusClasses +=
              "my-1 border-none hover:dark:text-slate-100 p-6 hover:scale-[1.02] cursor-pointer";
          } else {
            // loading
            answeredStatusClasses +=
              "border-none animate-pulse min-h-[16px] max-h-[300px] p-3";
          }

          const variants = {
            unanswered: {},
            wrong: {
              color: "rgba(0,0,0,0)",
              height: "0px",
              paddingTop: "0px",
              paddingBottom: "8px",
            },
          };

          const displayWrong = answeredStatus === "incorrect";
          const displayCorrect = answeredStatus === "correct";

          return (
                  <motion.div
                  key={answerIndex}
                    variants={variants}
                    transition={{
                      delay: isCorrect ? 0.2 : 0.3,
                      duration: 0.2,
                      ease: "easeOut",
                    }}
                    duration={5}
                    animate={answeredStatus}
                    className={
                      answeredStatusClasses +
                      " border-2 transition-all duration-300 answer flex items-center justify-between box-content ring-offset-2 ring-offset-white dark:ring-offset-slate-800 rounded-lg overflow-hidden shadow backdrop-blur-lg bg-slate-200/30 dark:bg-slate-800/50    dark:text-slate-200 font-sans  text-left"
                    }
                    onClick={() => handleAnswer(answerIndex, isCorrectAnswer)}
                  >
                    <span>{answer}</span>
                    {displayWrong &&  
                    <CorrectIncorrectAnimation key={animationIndex} correct={false}/>
                    // <span className="text-rose-700 dark:text-rose-400 font-logo pr-6">wrong</span>
                    
                    }
                    {displayCorrect && <CorrectIncorrectAnimation key={animationIndex} correct={true} isSelectedAnswer={isSelectedAnswer}/>}
                    {/* {displayNext && <IoIosArrowForward
                      id="next-button"
                      className="animate-pulse absolute right-4 top-[18px] m-auto text-[35px] text-blue-500"
                    />} */}
                  </motion.div>
          );
        })}
    </motion.div>
  );
};
