import React, { useEffect } from "react";


export const ScoreBar = (props) => {
  // const classes = useStyles()
  let { pastQAResults, fill, skipAnimate, length, sessionResults } = props;

  // if pastQAResults is less than 7, take the last 7 elements of the array
  if (pastQAResults.length > 7) {
    pastQAResults = pastQAResults.slice(-7);
  }
  // const [boxes, setBoxes] = React.useState(props.pastQAResults)
  const [displayLength, setDisplayLength] = React.useState(0);

  // const boxes = fillArray(pastQAResults, 25)

  const animate = () => {
    let start;
    let duration = 650; // 1 second

    function easeIn(t) {
      return t;
    }

    function frame(timestamp) {
      if (!start) start = timestamp;
      let elapsed = timestamp - start;

      let progress = Math.min(elapsed / duration, 1);
      let easedProgress = easeIn(progress);

      let currentLength = Math.floor(
        easedProgress * Math.max(7, pastQAResults.length),
      );

      setDisplayLength(currentLength);

      if (progress < 1) {
        window.requestAnimationFrame(frame);
      }
    }

    window.requestAnimationFrame(frame);
  };

  useEffect(() => {
    if (skipAnimate) {
      setDisplayLength(10);
    } else {
      animate();
    }
  }, []);

  // append pastQAResults array with "0" until it has length of 7
  let boxes;
  if (fill) {
    boxes = pastQAResults.concat(
      Array.from({ length: length - pastQAResults.length }, () => 0),
    );
  } else {
    boxes = pastQAResults.concat(Array.from({ length: 1 }, () => 0));
  }

  const resultsLength = pastQAResults.length;

  return (
    <div
      id="score-bar"
      className={
        ("flex justify-center") +
        " px-[0px] gap-[1px] items-center h-full"
      }
    >
      {sessionResults && sessionResults.length > 0 && sessionResults.map((result, index) => {
        return (
          <div
                key={index}
                className={
                  (fill ? "w-10" : "w-10") +
                  " duration-300 transition-all  h-full bg-blue-300/80"
                }
              ></div>
        )
      })}
      {boxes
        .filter((v, i) => i <= displayLength)
        .map((result, index) => {
         
          if (result === 1) {
            return (
              <div
                key={index}
                className={
                  (fill ? "w-full" : "w-5") +
                  " rounded-sm duration-300 transition-all  h-full bg-green-500/80"
                }
              ></div>
            );
          } else if (result === -1) {
            return (
              <div
                key={index}
                className={
                  (fill ? "w-full" : "w-5") +
                  " rounded-sm duration-300 transition-all  h-full bg-rose-500/80"
                }
              ></div>
            );
          } else {
            return (
              <div
                key={index}
                className={
                  (resultsLength === index
                    ? "relative box-content"
                    : " ") +
                  (fill ? " w-full" : " w-5") +
                  " rounded-sm duration-300 transition-all  h-full bg-slate-300 dark:bg-slate-300/30"
                }
              ></div>
            );
          }
        })}
    </div>
  );
};
