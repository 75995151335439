import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Navigate,
  useRouteError,
} from "react-router-dom";
import WelcomePage from "./components/WelcomePage";
import * as Sentry from "@sentry/react";
import { store } from "./store";
import { Provider } from "react-redux";
import { TopBar } from "./components/TopBar";
import { BottomBar } from "./components/BottomBar";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CoursePage } from "./components/CoursePage";

const queryClient = new QueryClient()




if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    release: `anyk-web@${process.env.REACT_APP_VERSION}`,
    dsn: "https://c2f19d7017f0e55231ae3946def27aef@o4506006055157760.ingest.sentry.io/4506006061056000",
    integrations: [
      // new Sentry.BrowserTracing({
      //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);
  return (
    <>
      {/* <TopBar /> */}
      <div className="text-white h-[80svh] flex flex-col justify-center">
        <div className="text-center m-4 text-4xl">Oops</div>
        <div className="text-center m-4 text-2xl">Something went wrong</div>
        <div className="text-center m-4 text-2xl">
          Please refresh to try again
        </div>
      </div>
      <BottomBar />
    </>
  );
};

const Frame = () => {
  return (
    <>
      <Provider store={store}>
        <Outlet />
      </Provider>
    </>
  );
};

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false",
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />} element={<Frame />}>
      <Route path="welcome" element={<WelcomePage />} />
      <Route
          path="onboard"
          lazy={() => lazyRetry(() => import("./components/OnboardPage"))}
        />
      <Route
        path="billingDone"
        element={<Navigate to="/a/home" replace={true} />}
      />
      <Route
        path="challenge/:id"
        element={<Navigate to="/a/shuffle" replace={true} />}
      />
      <Route
        path="auth"
        lazy={() => lazyRetry(() => import("./components/AuthPage2"))}
      />
      \
      {/* <Route path="b/" lazy={() => lazyRetry(() => import("./AppFrameLight"))}>
        <Route path="welcome" element={<WelcomePage />} />
          <Route
            path="onboard"
            lazy={() => lazyRetry(() => import("./components/OnboardPage"))}
          />
      </Route> */}
      <Route path="a/" lazy={() => lazyRetry(() => import("./AppFrame"))}>
      <Route
          path="home"
          // lazy={() => lazyRetry(() => import("./components/HomePage"))}
        />
        <Route
          path="home/main"
          lazy={() => lazyRetry(() => import("./components/HomePage"))}
        />
        <Route
          path="home/library"
          lazy={() => lazyRetry(() => import("./components/LibraryPage"))}
        />
        <Route
          path="home/news"
          lazy={() => lazyRetry(() => import("./components/NewsHomePage"))}
        />
        <Route
          path="profile"
          lazy={() => lazyRetry(() => import("./components/ProfilePage"))}
        />
         <Route
          path="review/dailies"
          lazy={() => lazyRetry(() => import("./components/ReviewDailyPage"))}
        />
        <Route
          path="review"
          lazy={() => lazyRetry(() => import("./components/ReviewPage"))}
        />
        <Route
          path="plans"
          lazy={() => lazyRetry(() => import("./components/PlanPage"))}
        />
        <Route
          path="shuffle/:difficulty"
          lazy={() =>
            lazyRetry(
              () => import("./components/Questions/EndlessQuestionStream"),
            )
          }
        />
        <Route
          path="daily/:id"
          lazy={() =>
            lazyRetry(
              () => import("./components/Questions/QuestionSet"),
            )
          }
        />
        <Route
          path="review/"
          lazy={() =>
            lazyRetry(
              () => import("./components/Questions/ReviewStream"),
            )
          }
        />
        <Route
          path="dailies/:topic"
          lazy={() =>
            lazyRetry(
              () => import("./components/ExploreDailyPage"),
            )
          }
        />
        <Route
          path="create_set"
          lazy={() =>
            lazyRetry(
              () => import("./components/QuestionSetBuilder"),
            )
          }
        />
        <Route
          path="topic/create"
          lazy={() =>
            lazyRetry(
              () => import("./components/TopicBuilder"),
            )
          }
        />
        <Route
          path="stream/analyze"
          lazy={() =>
            lazyRetry(
              () => import("./components/StreamAnalyzer"),
            )
          }
        />
        <Route
          path="shuffle"
          element={<Navigate to="medium" replace={true} />}
        />
        {/* <Route
          path="home"
          element={<Navigate to="main" replace={true} />}
        /> */}
        <Route
          path="topics"
          lazy={() => lazyRetry(() => import("./components/TopicPage"))}
        />
      </Route>
      <Route path="/" element={<Navigate to="/welcome" replace={true} />} />
      <Route path="/b/welcome" element={<Navigate to="/welcome" replace={true} />} />
      <Route path="/home" element={<Navigate to="/a/home" replace={true} />} />
      <Route path="*" element={<Navigate to="/b/welcome" />} />
      <Route path="/course" element={<CoursePage/>} />
    </Route>,
  ),
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
    {/* <ReactQueryDevtools initialIsOpen={true} /> */}
  </QueryClientProvider>
  </>
  );
