// infra
import React, { useState, useEffect } from "react";
import {
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io";
import { motion, useAnimation, useMotionValue, useTransform, animate, AnimatePresence } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";

// children
import { ScoreBar } from "../ScoreBar";

import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";
import { chatStreamNoFunction } from "../../api/openai";
import {
  client
} from "../../api/questions";
import { useStandardUserIdentifier } from "../../hooks/auth";


import { IoIosFlame } from "react-icons/io";
import { starMap, nextLevel, nextRank, rankMap, raceMap, nextRaceLevel, nextRankTitle, nextRaceTitle } from '../score'
import { TopicGenerator } from "../TopicGenerator";
import { BigScoreGraph } from "../score";
import { EloTopicTile } from "../TopicPage";
import { ShipControls } from "../HomePage";

import { PiGlobeHemisphereWestDuotone, PiCaretUpFill, PiCheckFatDuotone, PiXSquare } from "react-icons/pi";





const Star = ({ fill, index }) => {



  return (
    <motion.div
      initial="hidden"
      animate="visible"
    >
      <motion.div
        // variants={draw} 
        // custom={index} 
        className={(fill ? "text-zinc-500 dark:text-zinc-200/90" : "text-zinc-500/30 dark:text-zinc-300/20") + " relative text-4xl"}>
        <motion.svg
          width="33"
          height="30"
          viewBox="45 0 12 22"
          initial="hidden"
          animate="visible"
          className="dark:drop-shadow-gold"
        >
          <motion.g>
            <motion.polygon
              variants={draw3}
              points="50,0 53,7 60,7 54,12 56,19 50,15, 44,19 46,12 40,7 47,7 "
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              custom={index * 2}
            />
          </motion.g>
        </motion.svg>
      </motion.div>
    </motion.div>
  )
}

const StarRatingSm = ({ stars }) => {



  return (
    <div className={"flex justify-center text-purple-300 py-1 h-8"}>
      {Array.apply(null, Array(stars)).map((x, i) => (
        <IoIosFlame />
      ))}

      {stars == 0 &&
        <IoIosFlame className="text-purple-300/20" />
      }

      {/* <div className=" top-2 font-bold text-lg grid grid-cols-2">
                { four.map((i) => {
                  return (
                    <IoIosFlash key={i} className={i < stars ? "text-purple-300" : "text-purple-300/20"} />
                  )
                })}
              </div> */}

      {/* <Star fill={stars >= 1} index={0} />
          <Star fill={stars >= 2} index={1} />
          <Star fill={stars >= 3} index={2} />
          <Star fill={stars >= 4} index={3} /> */}
    </div>
  )
}


const RaceRating = ({ points, nextLevel }) => {



  return (
    <div className="flex flex-col items-center justify-center">
      {/* <div className="flex text-blue-500 dark:text-purple-500">
        {Array.apply(null, Array(4)).map((x, i) => (
          <Star key={i} fill={stars - 1 >= i} index={i} />
        ))}
      </div> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 3.5 }}
        className="font-bold text-center text-slate-900 dark:text-slate-200 font-logo text-2xl underline underline-offset-4 decoration-blue-500">
        {raceMap(points)}
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
      >
          <motion.div custom={1} variants={draw} className="my-2 text-zinc-800/80 dark:text-zinc-300/70 font-logo">
            Need {nextLevel} for <span className="font-bold text-slate-600 dark:text-slate-300">{nextRaceTitle(points)}</span>
          </motion.div>
      </motion.div>
    </div>
  )
} 
const StarRating = ({ points, stars, requiredDiff, nextLevel }) => {



  return (
    <div className="flex flex-col items-center justify-center">
      {/* <div className="flex text-blue-500 dark:text-purple-500">
        {Array.apply(null, Array(4)).map((x, i) => (
          <Star key={i} fill={stars - 1 >= i} index={i} />
        ))}
      </div> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 3.5 }}
        className="font-bold text-center text-slate-900 dark:text-slate-200 font-logo text-2xl underline underline-offset-4 decoration-blue-500">
        {rankMap(points)}
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
      >
        {requiredDiff > 0 &&
          <motion.div custom={1} variants={draw} className="my-2 text-zinc-800/80 dark:text-zinc-300/70 font-logo">
            Need {nextLevel} for <span className="font-bold text-slate-600 dark:text-slate-300">{nextRankTitle(points)}</span>
          </motion.div>
        }
      </motion.div>
    </div>
  )
}
const draw = {
  hidden: {
    pathLength: 0,
    opacity: 0,
    // x: -20,
    y: -10,
    // fill: "#0f172a",
  },
  visible: (i) => {
    const delay = 3.6 + i * 0.3;
    return {
      pathLength: 1,
      opacity: 1,
      // x: 0,
      y: 0,
      // fill: "#F5C242",
      transition: {
        pathLength: { delay, type: "spring", duration: 0.5, bounce: 0 },
        fill: { delay: delay, type: "spring", duration: 0.5, bounce: 0 },
        // x: { delay, type: "spring", duration: 0.5, bounce: 0 },
        y: { delay, type: "spring", duration: 0.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },

      },
    };
  },
};

const draw2 = {
  hidden: {
    pathLength: 0,
    opacity: 0,
    x: -20,
    fill: "none",
  },
  visible: (i) => {
    const delay = 0.5 + i * 0.1;
    return {
      pathLength: 1,
      opacity: 1,
      x: 0,
      fill: "none",
      transition: {
        pathLength: { delay, type: "spring", duration: 0.5, bounce: 0 },
        // fill: { delay, type: "spring", duration: 10, bounce: 0 },
        x: { delay, type: "spring", duration: 0.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },

      },
    };
  },
};

const draw3 = {
  hidden: {
    pathLength: 0,
    opacity: 0,
    // x: -20,
    fill: "none",
  },
  visible: (i) => {
    const delay = 0.5 + i * 0.1;
    return {
      pathLength: 1,
      opacity: 1,
      x: 0,
      fill: "none",
      transition: {
        pathLength: { delay, type: "spring", duration: 0.5, bounce: 0 },
        // fill: { delay, type: "spring", duration: 10, bounce: 0 },
        x: { delay, type: "spring", duration: 0.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },

      },
    };
  },
};

export const MiniCounter = ({ score, date, questionSet }) => {

  const played = score !== null && score !== undefined;
  return (
    <div className="hover:scale-105 transition-all flex flex-col relative items-center justify-center gap-y-1">
      {/* <p className="font-bold font-logo text-base text-slate-100/70">
        {date}
      </p> */}
      <Link to={`/a/daily/${questionSet.id}`}>
        {!played ?
          <button className="  bg-purple-600/70 backdrop-blur-sm  text-purple-200 rounded-lg py-2 w-24">
            play
          </button>

          :
          <button className="relative text-center">
            <div className=" font-logo font-bold relative border-purple-500 border-[1px]  bg-slate-600/20 backdrop-blur-sm rounded-lg py-2 w-24 text-purple-300 text-lg">
              <StarRatingSm stars={starMap(score)} />
            </div>
          </button>
        }
      </Link>
    </div>);
};

const SimpleCounter = ({
  from,
  via,
  to

}) => {


  const eloToLikelihood = (elo) => {
    // return elo
    let likelihood = 1 / (1 + 10 ** ((1000 - elo) / 2100))
    return (likelihood * 100).toFixed(1)
  }

  const count = useMotionValue(from);
  const rounded = useTransform(count, (l) => eloToLikelihood(l));
  const [currentText, setCurrentText] = React.useState(0);

  useEffect(() => {
    const controls = animate(count, to, { duration: 1, delay: 2 });
    return controls.stop;
  }, []);
  // }, []);

  useEffect(() => {
    let count = 0;
    const transitionTimes = [1700, 1500, 1500]; // Customize transition times in milliseconds

    const runTransition = () => {
      count++;
      if (count < 2) {
        setCurrentText((prevText) => (prevText + 1) % 3);
        setTimeout(runTransition, transitionTimes[count]);
      }
    };

    setTimeout(runTransition, transitionTimes[count]);

    return () => clearTimeout(runTransition);
  }, []);

  const textAnimation = {
    visible: (i) => {
      const delay = 0.5 + i * 0.1;
      return {
        opacity: 1, y: 0,
        transition: {
          duration: 0.5,
          delay: delay
        },
      }
    },
    hidden: {
      opacity: 0, y: -20,
    },
    slideOut: (i) => {
      return {
        opacity: 0, y: 30, transition: {
          duration: 0.2,
          // when: "afterChildren"
        },
      }
    },
    slideIn: (i) => {
      const delay = 0;
      return {
        opacity: 1, y: 0, transition: {
          duration: 0.3,
          delay: delay,
          when: "beforeChildren"
        },
      }
    },
    slideIn2: {
      opacity: 1, y: 0, transition: {
        duration: 0.5,
      },
    }
  }

  return <div className="flex relative items-center">
    <div className="relative w-full">
      <AnimatePresence>
        {currentText == 0 && (
          <motion.div
            className="pl-12 top-[-21px] flex  absolute opacity-0 text-xl font-bold font-logo py-2 text-zinc-700 dark:text-zinc-300"
            initial="hidden" animate="slideIn" exit="slideOut"
            custom={0}
            variants={textAnimation}>
            <span className="px-2"> Old Rating </span>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {currentText == 1 && (
          <motion.div
            className="pl-12 top-[-21px] flex  absolute opacity-0 text-xl font-bold font-logo py-2 text-zinc-700 dark:text-zinc-300"
            initial="hidden" animate="slideIn" exit="slideOut"
            custom={1}
            variants={textAnimation}>
            <span className="px-2">New Rating</span>
          </motion.div>
        )}
      </AnimatePresence>


    </div>
    <div className="relative text-center pr-12">
      <motion.p className="font-bold font-logo relative rounded  py-1 text-blue-500 text-2xl my-2 w-20">{rounded}</motion.p>
    </div>
  </div>;
};

export const Counter = ({
  label1,
  label2,
  label3,
  from,
  via,
  to

}) => {
  const duration = 2000
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  useEffect(() => {
    const controls = animate(count, from * 100, { duration: 1, delay: 0.6 });

    controls.then(() => {
      // Start the second animation here
      // For example:
      animate(count, to, { duration: 1, delay: 0.6 });
    });
    return controls.stop;
  }, []);

  const [currentText, setCurrentText] = React.useState(0);

  // useEffect(() => {
  //   let count = 0;
  //   const interval = setInterval(() => {
  //     count++
  //     if (count === 3) {
  //       clearInterval(interval);
  //     } else {
  //       setCurrentText((prevText) => {
  //         return (prevText + 1) % 3
  //       });
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    let count = 0;
    const transitionTimes = [1700, 1500, 1500]; // Customize transition times in milliseconds

    const runTransition = () => {
      count++;
      if (count < 3) {
        setCurrentText((prevText) => (prevText + 1) % 3);
        setTimeout(runTransition, transitionTimes[count]);
      }
    };

    setTimeout(runTransition, transitionTimes[count]);

    return () => clearTimeout(runTransition);
  }, []);

  const textAnimation = {
    visible: (i) => {
      const delay = 0.5 + i * 0.1;
      return {
        opacity: 1, y: 0,
        transition: {
          duration: 0.5,
          delay: delay
        },
      }
    },
    hidden: {
      opacity: 0, y: -20,
    },
    slideOut: (i) => {
      return {
        opacity: 0, y: 30, transition: {
          duration: 0.2,
          // when: "afterChildren"
        },
      }
    },
    slideIn: (i) => {
      const delay = 0;
      return {
        opacity: 1, y: 0, transition: {
          duration: 0.3,
          delay: delay,
          when: "beforeChildren"
        },
      }
    },
    slideIn2: {
      opacity: 1, y: 0, transition: {
        duration: 0.5,
      },
    }
  }

  const numberAnimation = {
    slideIn: {
      opacity: 0, x: 120, transition: {
        duration: 0.5,
        delay: 0.2
      },
    },
    slideOut: {
      opacity: 0, x: 60, transition: {
        duration: 0.5,
      },
    }
  }

  return <div className="flex relative items-center">
    <div className="relative w-full">
      <AnimatePresence>
        {currentText == 0 && (
          <motion.div
            className="pl-12 top-[-21px] flex  absolute opacity-0 font-bold text-xl font-logo py-2 text-amber-500"
            initial="hidden"
            animate="slideIn"
            exit="slideOut"
            custom={0}
            variants={textAnimation}>
            <span className="px-2">correct</span>
            <motion.div className="px-2 pl-4" variants={numberAnimation}>{from}</motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {currentText == 1 && (
          <motion.div
            className="pl-12 top-[-21px] flex  absolute opacity-0 font-bold text-xl font-logo font-bold py-2 text-blue-500"
            initial="hidden" animate="slideIn" exit="slideOut"
            custom={1}
            variants={textAnimation}>
            <span className="px-2"> speed </span>
            <AnimatePresence>
              <motion.div className="px-2 pl-4" variants={numberAnimation}> {via}</motion.div>
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {currentText == 2 && (
          <motion.div
            className="pl-12 top-[-21px] flex  absolute opacity-0 text-xl font-bold font-logo py-2 text-zinc-700 dark:text-zinc-300"
            initial="hidden" animate="slideIn" exit="slideOut"
            custom={2}
            variants={textAnimation}>
            <span className="px-2">points</span>
          </motion.div>
        )}
      </AnimatePresence>


    </div>
    <div className="relative text-center pr-12">
      <motion.p className="font-bold font-logo relative rounded  py-1 text-zinc-500 dark:text-zinc-300 text-2xl my-2 w-20">{rounded}</motion.p>
    </div>
  </div>;
};

export const RoundCompleteComponent = (props) => {
  useEffect(() => {
    window.gtag("event", "plans_tease", {
      event_category: "engagement",
    });
  }, [])
  const navigate = useNavigate();
  const { data: userId, isLoading: userIdLoading, error: userIdError } = useStandardUserIdentifier();
  const { loggedIn } = userId

  const [copyMessageModalVisible, setCopyMessageModalVisible] = useState(false);
  const [questionFilter, setQuestionFilter] = useState(null);

  const { questions, pastQAResults, lessonCompleteTime, points } = props;

  // get today's date in this format (example): Jul 21
  const today = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const correct = pastQAResults.filter((x) => x === 1).length;
  const incorrect = pastQAResults.filter((x) => x === -1).length;
  const accuracy = Math.round((correct / (correct + incorrect)) * 100);

  const handleCopyClick = () => {
    window.gtag("event", "challenge_button", {
      event_category: "engagement",
    });
    const message = "https://anyk.app/challenge/xa3-520";
    setCopyMessageModalVisible(true);
    // Create a temporary textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = message;
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);
  };

  const [playedDailies, setPlayedDailies] = useState(0)
  const [firstUnplayedDaily, setFirstUnplayedDaily] = useState(undefined)
  const fetchPastPlays = async (topic) => {
    if (topic === "loading") return

    const pastUserFinishes_ = await client.plays.pastDailiesSummaries()


    const pastUserFinishesByStream = pastUserFinishes_?.reduce((acc, curr) => {
      const stream = curr.questionsetOfOut.streamMadeIn
      if (!acc[stream.name]) {
        acc[stream.name] = []
      }
      acc[stream.name].push(curr)
      return acc
    }, {}) || {}

    const pastUserFinishes = pastUserFinishes_?.filter(finish => finish.questionsetOfOut.streamMadeIn.name === topic)


    let recentDailies = []
    recentDailies = await client.plays.getPastTwoWeeks(topic)

    const pastFinishesByQuestionSet = pastUserFinishes?.reduce((acc, curr) => {
      if (!acc[curr.questionsetOfOut.id]) {
        acc[curr.questionsetOfOut.id] = []
      }
      acc[curr.questionsetOfOut.id].push(curr)
      return acc
    }, {}) || {}

    // attach the user plays to the dailies

    // now that we have the 2-week count, filter to the 3 most recent
    recentDailies.sort((a, b) => new Date(b.published_date) - new Date(a.published_date))

    let playCount = 0
    let starTotalTmp = 0
    recentDailies.forEach((daily) => {
      daily.userPlays = pastFinishesByQuestionSet[daily.id]
      if (!(daily.userPlays === undefined || daily.userPlays.length === 0)) {
        playCount += 1
        const maxScore = daily.userPlays.reduce((acc, curr) => {
          return acc.score > curr.score ? acc : curr
        })
        starTotalTmp += starMap(maxScore.score)
      }
      // playCount += daily.userPlays?.length ?? 0
    })


    // const recentDailiesFiltered = recentDailies.filter(daily => Boolean(daily.published_date))
    const playedDailies = recentDailies.filter(daily => daily.userPlays !== undefined).length
    const firstUnplayedDaily = recentDailies.filter(r => r.id != props.questionSetId).filter(daily => daily.userPlays === undefined).sort((a, b) => new Date(a.published_date) - new Date(b.published_date))[0]

    setPlayedDailies(playedDailies)
    setFirstUnplayedDaily(firstUnplayedDaily)



  }

  const handleAgain = () => {
    window.gtag("event", "again_button", {
      event_category: "engagement",
    });
    window.location.reload();
  };

  const handleNextLevel = () => {
    window.gtag("event", "next_level_button", {
      event_category: "engagement",
    });
    if (firstUnplayedDaily) {
      window.location.href = `/a/daily/${firstUnplayedDaily.id}`
    }
  };

  const handlePlans = () => {
    window.gtag("event", "plans_button", {
      event_category: "engagement",
    });
    navigate("/a/plans");
    // window.location = "/plans"
  };

  const speedMultiplier = 3 * 5 / (lessonCompleteTime / 1000) + 1;
  const speedMultiplierRounded = Math.round(speedMultiplier * 100) / 100;

  const total = Math.round(correct * 100 * speedMultiplier);

  const averageSpeed = Math.round((lessonCompleteTime / 1000) / (correct + incorrect));




  const stars = starMap(total);

  const moreLikeThisQuestion = async (question) => {
    const parents = await client.topics.getParentTopics('medium', question.topic)
    const params = new URLSearchParams();
    parents.forEach((parent) => {
      params.append("topic", parent.name);
    });
    navigate("/a/shuffle/medium?" + params.toString());
  }

  const correctCount = pastQAResults.filter((x) => x === 1).length;
  const incorrectCount = pastQAResults.filter((x) => x === -1).length;

  const scores = [
    [{ score: 5 }, { score: 0 }, { score: 0 }],
    [{ score: 5 }, { score: 4 }, { score: 0 }],
    [{ score: 5 }, { score: 4 }, { score: 6 }],
  ];

  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  // const displayNewScore = useTransform(rounded, (latest) => scores[Math.min(latest, scores.length - 1)]);
  const [currentScoreIndex, setCurrentScoreIndex] = useState(0);
  const delay = 4;
  const duration = 2;

  useEffect(() => {
    const controls = animate(count, scores.length - 1, {
      type: "tween",
      duration: duration,
      ease: "linear",
      delay: delay,
    });
    return () => controls.stop();
  }, [count]);

  useEffect(() => {
    rounded.onChange((value) => {
      setCurrentScoreIndex(value);
    });
  }, []);

  const [suggestedTopics, setSuggestedTopics] = useState([]);
  const [pastUserFinishesByStream, setPastUserFinishesByStream] = useState({})
  const [pastFinishes, setPastFinishes] = useState([])
  useEffect(() => {
    // const element = document.getElementById('explore-container');
    // const height = element.clientHeight;
    // element.style.minHeight = height + 'px';
    // setExploreTopics([])
    async function fetchDailyCount() {
      const pastUserFinishes = await client.plays.pastDailiesSummaries()
      setPastFinishes(pastUserFinishes)


      const pastUserFinishesByStream = pastUserFinishes?.reduce((acc, curr) => {
        const stream = curr.questionsetOfOut.streamMadeIn
        if (!acc[stream.name]) {
          acc[stream.name] = []
        }
        acc[stream.name].push(curr)
        return acc
      }, {}) || {}

      setPastUserFinishesByStream(pastUserFinishesByStream)

    }
    async function fetchTopics() {
      const topics = await client.topics.getChildTopics('medium', 'root');

      const randomTopic1 = topics[Math.floor(Math.random() * topics.length)]
      const randomTopic2 = topics[Math.floor(Math.random() * topics.length)]
      const randomTopic3 = topics[Math.floor(Math.random() * topics.length)]
      const randomTopic4 = topics[Math.floor(Math.random() * topics.length)]


      setSuggestedTopics([randomTopic1, randomTopic2, randomTopic3, randomTopic4])

      // // for (let topic of topics) {
      // const promises = topics.map(async (topic) => {
      //   const babyTopics = await client.topics.getChildTopics('medium', topic.name);

      //   const randomTopic = babyTopics[Math.floor(Math.random() * babyTopics.length)]
      //   randomTopic.parent = topic
      //   // setExploreTopics(e => [...e, randomTopic])
      //   // const gbabyTopics = await client.topics.getChildTopics('medium', randomTopic.name);
      //   // const grandomTopic = gbabyTopics[Math.floor(Math.random() * gbabyTopics.length)]
      //   // setExploreTopics(e => [...e, grandomTopic])

      // });
      // await Promise.all(promises)
    }
    fetchTopics();
    fetchDailyCount();


  }, []);

  useEffect(() => {
    if (props.title) {
      fetchPastPlays(props.title);
      fetchLeaderboard(props.title)
    }
  }, [props.title])

  const [myELO, setMyELO] = useState(null)
  const fetchLeaderboard = async (topic) => {
    if (topic === "loading") return
    const leaderBoard = await client.streams.leaderboard(topic)
    if (leaderBoard.myELO !== null && leaderBoard.myELO !== undefined) setMyELO(leaderBoard.myELO.score)

  }


  // useEffect(() => {
  //   const controls = animate(count, from, { duration: 1, delay: 0.3 });

  //   controls.then(() => {
  //     // Start the second animation here
  //     // For example:
  //     animate(count, to, { duration: 1, delay: 0.3 });
  //   });
  //   return controls.stop;
  // }, [from, to]);

  const [selectedIndex, setSelectedIndex] = useState(null);

  const eloReady = myELO !== null

  const eloToLikelihood = (elo) => {
    // return elo
    let likelihood = 1 / (1 + 10 ** ((1000 - elo) / 2100))
    return (likelihood * 100).toFixed(0)
  }

  return (
    <div id="round-complete" className="relative">
      {/* <ShipControls /> */}

      <div className=" relative z-20 py-2 rounded overflow-hidden max-w-2xl my-2 sm:m-auto mb-[80px] sm:mb-[80px]">
        {/* <div className="z-[500] fixed px-2 py-3 top-0 left-0 right-0 bg-slate-900/40 backdrop-blur-lg">
            <div className="w-[60%] sm:w-[30%] m-auto">
              <div className="box-content  h-2 ">
                <ScoreBar fill pastQAResults={pastQAResults} />
              </div>
            </div>
          </div> */}
        {/* <div className="pt-4 mx-6 text-xl text-slate-900 dark:text-slate-200 font-bold font-logo">
      Score
    </div> */}
        <div>

        </div>
        <div className="mt-2 py-3 bg-slate-300/30 dark:bg-slate-800/70 mx-6 rounded-xl">
          <StarRating points={myELO} requiredDiff={nextRank(myELO)} nextLevel={nextLevel(myELO)} />
          {eloReady &&
            <SimpleCounter
              from={props.initialELO}
              to={myELO}
            />}
          {/* <div className="my-">
        <Counter stars={stars} via={(speedMultiplier*correct*100).toFixed(0)} from={correct} to={points} />
      </div> */}
        </div>
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 4.0,
            duration: 0.4,
          }} className="pt-4 mx-6 text-xl text-slate-900 dark:text-slate-200 font-bold font-logo">
          Summary
        </motion.div>
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 4.0,
            duration: 0.4,
          }}
          className=" font-logo grid grid-cols-2 gap-1 px-6 mt-2 mb-4 ">




          <div className="px-6 w-full h-20 bg-slate-300/30 dark:bg-slate-800/70 rounded flex items-center justify-start ">

            <motion.svg
              width="30"
              height="30"
              viewBox="15 1 20 20"
              initial="hidden"
              animate="visible"
              className="dark:drop-shadow-emerald text-emerald-500"
            >
              <motion.g>
                <motion.circle
                  cx="25"
                  cy="11"
                  r="8"
                  fill="none"
                  stroke="currentColor"
                  variants={draw3}
                  custom={2}
                />
                <motion.circle
                  cx="25"
                  cy="11"
                  r="3"
                  fill="none"
                  stroke="currentColor"
                  variants={draw3}
                  custom={1}
                />
              </motion.g>
            </motion.svg>
            <div className="flex flex-col ml-2">
              <div className="h-5 text-lg text-slate-900 dark:text-white">Accuracy</div>
              <div className="h-8 text-emerald-500 font-bold text-2xl">{accuracy}<span className="text-lg ml-[2px]">%</span></div>
            </div>
          </div>
          <div className="px-6 text-blue-500 w-full h-20 bg-slate-300/30 dark:bg-slate-800/70 rounded flex items-center justify-start ">

            <motion.svg
              width="30"
              height="38"
              viewBox="64 -1 20 25"
              initial="hidden"
              animate="visible"
              className="dark:drop-shadow-blue"
            >
              <motion.g>
                <motion.polygon
                  variants={draw3}
                  points="75,0 72,10, 78,10, 68,22, 72,13 66,13"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  custom={1}
                />
              </motion.g>
            </motion.svg>
            <div className="flex flex-col ml-2">
              <div className="h-5 text-lg text-slate-900 dark:text-white">Time</div>
              <div className="h-8 text-blue-500 font-bold text-2xl">{(lessonCompleteTime / 1000).toFixed(1)}<span className="text-lg"><span className=" left-[1px] relative">s</span></span></div>
            </div>
          </div>
        </motion.div>

        <div className="my-5">
          {/* <h1 className="text-center font-logo text-slate-900 dark:text-slate-400 py-1 font-bold text-lg">
          travish
        </h1> */}

        </div>
        {/* <div className="px-4 flex justify-between items-center my-8">
        

        <div className="w-full h-4">
          <ScoreBar fill={true} pastQAResults={pastQAResults} />
        </div>
      </div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 3.7,
          duration: 0.4,
        }}
        className="text-white text-center mt-4 mb-6"
      >
        <div className="mb-2"><span className="text-2xl text-[#F5C242] font-logo">17th</span><span> on this round</span></div>
        <div>
          <span className="text-2xl text-[#F5C242] font-logo">7th</span> in <span className="text-blue-500 font-logo text-2xl">physics</span> today </div>

      </motion.div> */}
        <div className="flex justify-around">
          {/* <motion.div
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          y: {
          type: "spring",
          ease: "easeOut",
          delay: 4,
          duration: 0.6,
          },
          opacity: {
            type: "spring",
            ease: "easeOut",
            delay: 4,
            duration: 0.3,
          }
        }}
      className="w-20 pl-12"
      >
      <BigScoreGraph scores={scores[currentScoreIndex]} />
      </motion.div> */}

        </div>



        {/* <motion.div
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 1,
          duration: 0.6,
        }}
        className="font-logo"
      >

        <h1 className="mx-4 text-2xl dark:text-slate-100 font-logo border-blue-500 border-b-2 pb-1 mb-4">
          points
        </h1>

        <div className="p-4 m-1 text-center my-4 space-y-3">

          <div className="flex items-center justify-between text-[#00C682]">

            <div className="flex items-center">
              <motion.svg
                width="30"
                height="30"
                viewBox="15 1 20 20"
                initial="hidden"
                animate="visible"
                className="dark:drop-shadow-green"
              >
                <motion.g>
                  <motion.circle
                    cx="25"
                    cy="11"
                    r="9"
                    fill="none"
                    stroke="#00C682"
                    variants={draw2}
                    custom={1}
                  />
                  <motion.circle
                    cx="25"
                    cy="11"
                    r="3"
                    fill="none"
                    stroke="#00C682"
                    variants={draw2}
                    custom={1}
                  />
                </motion.g>
              </motion.svg>
              <span className="text-xl ml-2">correct</span>
            </div>
            <span className="font-bold text-xl">{correct * 100}</span>
          </div>
          <div className="flex items-center justify-between text-[#0091F9]">
            <div className="flex items-center">
              <motion.svg
                width="30"
                height="38"
                viewBox="64 -1 20 25"
                initial="hidden"
                animate="visible"
                className="dark:drop-shadow-blue"
              >
                <motion.g>
                  <motion.polygon
                    variants={draw2}
                    points="73,0 80,0 72,10, 78,10, 67,22, 72,12 67,12"
                    fill="none"
                    stroke="#0091F9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    custom={1}
                  />
                </motion.g>
              </motion.svg>
              <span className="text-xl ml-2">speed bonus</span>
            </div>
            <span className="font-bold text-xl">{speedMultiplierRounded}x</span>
          </div>
          <div className="flex items-center justify-between text-[#F5C242]">
            <div className="flex">
              <motion.svg
                width="33"
                height="30"
                viewBox="45 0 12 22"
                initial="hidden"
                animate="visible"
                className="dark:drop-shadow-gold"
              >
                <motion.g>
                  <motion.polygon
                    variants={draw2}
                    points="50,0 54,7 61,7 55,12 58,19 50,15, 42,19 45,12 39,7 46,7 "
                    fill="none"
                    stroke="#F5C242"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    custom={1}
                  />
                </motion.g>
              </motion.svg>
              <span className="text-2xl ml-2">total</span>
            </div>
            <p className="font-bold text-2xl">{total}</p>
          </div>
        </div>
      </motion.div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 4.3,
          duration: 0.6,
        }}
        className="flex gap-4 justify-center"
      >
        
        <Link to="/a/home">
        <button 
        className="font-logo overflow-hidden group active:scale-100 hover:scale-95 transition-all hover:bg-blue-600 bg-blue-500/20  text-white w-36 py-2 rounded-lg active:bg-blue-600">
          back home
        </button>
        </Link>
        <button 
        onClick={() => window.location.reload()}
        className="font-logo overflow-hidden group active:scale-100 hover:scale-95 transition-all hover:bg-blue-600 bg-blue-500  text-white w-36 py-2 rounded-lg active:bg-blue-600">
          play again
        </button>
      </motion.div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 4.3,
          duration: 0.6,
        }}
        className="flex gap-3 justify-center pt-6"
      >

        <button
          onClick={() => window.location.reload()}
          className="font-logo overflow-hidden group active:scale-100 hover:scale-[1.02] transition-all hover:bg-blue-600 bg-purple-500/70  text-white w-36 py-4 rounded-lg active:bg-blue-600">
          aim higher
        </button>
        <Link to="/a/home">
          <button
            className="font-logo overflow-hidden group active:scale-100 hover:scale-[1.02] transition-all hover:bg-blue-600 bg-blue-500/70 backdrop-blur  text-white w-36 py-4 rounded-lg active:bg-blue-600">
            next topic
          </button>
        </Link>
      </motion.div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          y: {
          type: "spring",
          ease: "easeOut",
          delay: 3.7,
          duration: 0.6,
          },
          opacity: {
            type: "spring",
            ease: "easeOut",
            delay: 3.7,
            duration: 0.3,
          }
        }}
      className="grid grid-cols-2 gap-1 px-12"
      >
         
        <div 
        onClick={() => setQuestionFilter(f => {
          if (!f) return "wrong"
          if (f === "wrong") return null
          return "wrong"
        })}
        className={
          (questionFilter === "wrong" ? "backdrop-blur-lg text-center bg-purple-400/50 dark:bg-purple-500/50" : "backdrop-blur-sm text-center bg-gray-500/10 dark:bg-purple-500/10") +
          " py-2 px-4 text-gray-800 dark:text-purple-200 font-logo rounded-lg h-16 transition-all"}>
          <div className="font-bold ">+ {incorrectCount}</div>
          <div className="text-gray-800 dark:text-purple-300 ">learning</div>
        </div>
        <div 
        onClick={() => setQuestionFilter(f => {
          if (!f) return "right"
          if (f === "right") return null
          return "right"
        })}
        className={
          (questionFilter === "right" ? "backdrop-blur-lg text-center bg-purple-400/50 dark:bg-purple-500/50" :  "backdrop-blur-sm text-center bg-gray-500/10 dark:bg-purple-500/10") +
          "  py-2 px-4 text-gray-800 dark:text-purple-200 font-logo rounded-lg h-16 transition-all"}>
          <div className="font-bold ">+ {correctCount}</div>
          <div className="text-gray-800 dark:text-purple-300">known</div>
        </div>
        </motion.div> */}


        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 4,
            duration: 0.6,
          }}
          className="mt-4"
        >
          {/* <h1 className="mx-4 text-2xl dark:text-slate-100 font-logo border-blue-500 border-b-2 pb-1 my-8">
          review
        </h1> */}

          {questions.map((question, index) => {
            // .sort((a, b) => a.wasCorrect - b.wasCorrect).map((question, index) => {


            return (
              <Question filter={questionFilter} key={index} question={question} index={index} moreLikeThisQuestion={() => moreLikeThisQuestion(question)} />

            )
          })
          }
        </motion.div>
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            y: {
              type: "spring",
              ease: "easeOut",
              delay: 4.2,
              duration: 0.6,
            },
            opacity: {
              type: "spring",
              ease: "easeOut",
              delay: 4.2,
              duration: 0.3,
            }
          }}
          className="px-12 grid grid-cols-2 gap-4 mt-6 mb-8"
        >

          {firstUnplayedDaily &&
            <div
              disabled={accuracy < 0.7}
              onClick={handleNextLevel}
              className={
                (accuracy > 0.7 ? " bg-blue-500/70 dark:text-blue-100" : " bg-blue-500/20 dark:text-blue-100/20") +
                " cursor-pointer hover:bg-blue-500 backdrop-blur-sm text-center py-3 px-4 text-white dark:text-blue-100 rounded-lg transition-all vertical-center "}>
              <div className="font-logo">
                Start <br /> Round {playedDailies + 2}
              </div>
              {accuracy < 0.7 &&
                <div className="-top-7 absolute text-blue-100/40 ">
                  <span className="font-logo text-emerald-400/70 font-bold">70%</span> to unlock
                </div>
              }
            </div>
          }
          <div
            onClick={handleAgain}
            className={
              (accuracy > 0.7 ? " border-2 text-blue-500 dark:text-blue-400 border-blue-500/70 bg-blue-500/10 dark:bg-blue-500/20" : " dark:text-blue-100 text-white bg-blue-500/70 ") +
              " cursor-pointer hover:bg-blue-500 backdrop-blur-sm text-center py-3 px-4   font-logo rounded-lg transition-all vertical-center"}>
            <div>
              Try <br /> again
            </div>
          </div>
        </motion.div>
        {!false && (
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              y: {
                type: "spring",
                ease: "easeOut",
                delay: 4.5,
                duration: 0.6,
              },
              opacity: {
                type: "spring",
                ease: "easeOut",
                delay: 4.5,
                duration: 0.3,
              }
            }}
            className="relative border-t-2 border-gray dark:border-gray-900 mx-6 mb-6 h-[200px]"
          >

            <div className="relative h-[200px] z-5 mt-6">
              <div className="pb-4 dark:text-blue-500 text-xl text-slate-900 font-bold font-logo">
                Unlock more
              </div>
              <div className=""
                onClick={handlePlans}>
                <div className="border-2 border-blue-500 py-5 group flex flex-col items-center bg-slate-300/30 dark:bg-slate-500/30 backdrop-blur-lg rounded-lg overflow-hidden">
                  <div className=" invisible dark:visible z-[-1] bg-animation fixed top-0 bottom-0 right-0 left-0 dark:bg-black dark:bg-gradient-to-tr dark:to-slate-500/30 dark:via-slate-900/30 dark:from-slate-700/30">
                    <div id="stars"></div>
                    <div id="stars2"></div>
                    <div id="stars3"></div>
                    <div id="stars4"></div>
                  </div>
                  <div className="[text-shadow:_2px_1px_2px_rgb(0_136_242_)] grow text-xl dark:text-white text-slate-900 font-bold px-1 pb-3 font-logo">AnyQ+ </div>
                  {/* <button className="text-slate-100 font-logo group-hover:scale-125 transition-all bg-slate-600/50 w-28 p-2 px-2 rounded-md">base</button> */}
                  <div className="py-2 group flex items-center">
                    <div className=" rounded px-1 pb-2 py-1 inline-block group-hover:text-blue-300 text-blue-400 transition-all text-center">
                      <span className="relative top-[-4px] font-logo font-bold text-sm px-1 text-black dark:text-white">$</span>
                      <span className="font-logo font-bold text-2xl text-black dark:text-white">19</span>
                      <span className="text-slate-600 dark:text-slate-300 font-logo font-bold text-sm px-1">/ year</span>
                    </div>
                    <div className="text-black dark:text-white font-logo text-sm mt-2 mx-3">
                      or
                    </div>
                    <div className=" rounded px-2 pb-2 py-1 inline-block group-hover:text-blue-300 text-blue-400 transition-all text-center">
                      <span className="relative top-[-4px] font-logo font-bold text-sm px-1 text-black dark:text-white">$</span>
                      <span className="font-logo font-bold text-2xl text-black dark:text-white">49</span>
                      <span className="text-slate-600 dark:text-slate-300 font-logo font-bold text-sm px-1">once</span>
                    </div>
                  </div>
                  <div className="mt-2 text-blue-500 font-logo font-bold">Learn more</div>
                </div>
              </div>
              <div className="flex flex-col items-center">
                {/* <a
        onClick={() => navigate("/a/plans")}
        className={
          "mt-2 mx-auto w-[200px] text-blue-400 font-bold font-logo items-center cursor-pointer inline-flex justify-center rounded-lg py-4 px-3 text-xl outline-2 outline-offset-2 transition-colors relative overflow-hidden active:text-white/80 "
        }
      >
        {"compare"}
      </a> */}
              </div>

            </div>


          </motion.div>
        )}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            y: {
              type: "spring",
              ease: "easeOut",
              delay: 4.7,
              duration: 0.6,
            },
            opacity: {
              type: "spring",
              ease: "easeOut",
              delay: 4.7,
              duration: 0.3,
            }
          }}
          className="relative  gap-2 border-t-2 border-gray dark:border-gray-900 mx-6 mt-12 mb-[200px] h-[200px]"
        >


          {/* <div className="text-center font-logo text-xl dark:text-slate-300 py-6">
          continue exploring
        </div> */}
          <div className="pt-6 pb-4 dark:text-white text-xl text-slate-900 font-bold font-logo">
            Continue Exploring
          </div>
          <div className="relative h-full">

            {suggestedTopics.map((topic, index) => {

              const textSize = topic.name.length > 20 ? "text-sm" : "text-lg"

              return (
                <EloTopicTile
                  key={index + topic.name}
                  topic={topic.name}
                  scores={pastUserFinishesByStream[topic.name]}
                  // building={stream.building}
                  index={index}
                  selectedIndex={selectedIndex}
                  onClick={() => setSelectedIndex(i => i == index ? null : index)}
                  // streamId={stream._id}
                  pastUserFinishes={pastFinishes}
                  themeColor={"slate"}
                  height={30}
                // pinned={userStreams.includes(stream)}
                // priority={stream.priority}
                />
              )
            })}
          </div>

        </motion.div>


        {/* <div className="mx-10 my-2 opacity-50">
        <TopicGenerator 
          displayedText={"something else"} 
          buttonText={"start"} 
          onBuilding={() => {}} 
          onClear={() => {}} 
          onSubmit={() => {}} 
          setUserStreams={() => {}} 
          setTopicPage={() => {}}/>
          </div> */}

        {/* <div className="flex justify-center pt-6">
        <button className="font-logo overflow-hidden group active:scale-100 hover:scale-95 transition-all hover:bg-blue-600 bg-blue-500  text-white w-48 m-2 py-3 rounded-lg active:bg-blue-600">
          <span className="grow px-3">Next</span>
        </button>
      </div> */}
        {/* <p className="bg-slate-100 rounded dark:bg-slate-800 dark:text-white p-4 m-3">
        Confident in your score? Challenge a friend to beat you.
        <br />
        <br />
        Not yet? Go again and improve your score.
        <br /> <br />
        Want more? Explore upgrade options.
      </p> */}
        {/* <div className="grid grid-cols-3 gap-4 p-3 pb-6">
        <button
          onClick={() => handleCopyClick()}
          className="relative flex w-full justify-between shadow items-center bg-blue-500 dark:bg-blue-600 dark:text-white py-4 px-1  rounded-lg border-blue-500"
        >
          <span className="grow px-3">Challenge</span>
          <IoIosArrowForward className="text-xl" />
        </button>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={
            (copyMessageModalVisible ? " " : "hidden ") +
            "bg-slate-900/75 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          }
          onClick={() => setCopyMessageModalVisible(false)}
        >
          <div className="relative top-[25%] w-full max-w-2xl max-h-full">
            <div className="relative rounded-lg shadow bg-slate-100 dark:bg-slate-700">
              <div className="p-6 space-y-6">
                <p className="text-base leading-relaxed text-slate-900 text-slate-900 dark:text-slate-200">
                  Shareable link copied to your clipboard!
                  <br />
                  <br />
                  Share it with a friend and challenge them to beat your score.
                </p>
              </div>
              <div className="flex justify-around items-center p-6 space-x-2 rounded-b dark:border-gray-600">
                <button
                  onClick={() => setCopyMessageModalVisible(false)}
                  data-modal-hide="defaultModal"
                  type="button"
                  className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Got it
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={handleAgain}
          className="flex w-full h-full justify-between shadow items-center bg-slate-100 dark:bg-slate-800 dark:text-white py-1 px-1  rounded-lg "
        >
          <span className="grow px-3">Again</span>
        </button>
        <button
          onClick={handlePlans}
          className="border-2 border-[#FF6E56] flex w-full h-full justify-between shadow items-center bg-slate-100 dark:bg-slate-800 dark:text-white py-1 px-1  rounded-lg "
        >
          <span className="grow text-xl [text-shadow:_1px_1px_0_rgb(255_116_81_)] dark:text-white text-slate-900 font-bold px-1 font-logo">
            anyk+
          </span>
        </button>
      </div> */}


      </div>
      {/* <div className=" bg-animation fixed top-0 bottom-0 right-0 left-0 bg-black dark:bg-gradient-to-br dark:to-slate-900/30 dark:via-purple-900/30 dark:from-blue-800/30">
    <div id="stars"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
    <div id="stars4"></div>
  </div> */}
    </div>
  );
};


export const GameOverComponent = (props) => {

  const navigate = useNavigate();
  const { correct, via, total, lessonCompleteTime, incorrect } = props;

  const accuracy = Math.round((correct / (correct + incorrect)) * 100);

  const handleAgain = () => {
    window.gtag("event", "race_again_button", {
      event_category: "engagement",
    });
    window.location.reload();
  };

  const handleReview = () => {
    window.gtag("event", "race_review_button", {
      event_category: "engagement",
    });
    navigate("/a/review");
  };

  return (
    <div className="relative top-[70px] px-3">
        <div className="text-white font-logo text-center text-2xl">TIME'S UP</div>
      <div className=" mt-6 py-3 bg-slate-300/30 dark:bg-slate-800/70 mx-6 rounded-xl">
        <motion.div
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 0.2,
            duration: 0.6,
          }}
          className="font-logo "
        >


          <RaceRating points={total} nextLevel={nextRaceLevel(total)} />


          <Counter from={correct} via={via.toFixed(0)} to={total} />
        </motion.div>
      </div>
      <div className="mt-4">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 4,
            duration: 0.4,
          }} className="pt-4 mx-6 text-xl text-slate-900 dark:text-slate-200 font-bold font-logo">
          Stats
        </motion.div>
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 4,
            duration: 0.4,
          }}
          className=" font-logo grid grid-cols-2 gap-1 px-6 mt-2 mb-4 ">




          <div className="px-6 w-full h-20 bg-slate-300/30 dark:bg-slate-800/70 rounded flex items-center justify-start ">

            <motion.svg
              width="30"
              height="30"
              viewBox="15 1 20 20"
              initial="hidden"
              animate="visible"
              className="dark:drop-shadow-emerald text-emerald-500"
            >
              <motion.g>
                <motion.circle
                  cx="25"
                  cy="11"
                  r="8"
                  fill="none"
                  stroke="currentColor"
                  variants={draw3}
                  custom={2}
                />
                <motion.circle
                  cx="25"
                  cy="11"
                  r="3"
                  fill="none"
                  stroke="currentColor"
                  variants={draw3}
                  custom={1}
                />
              </motion.g>
            </motion.svg>
            <div className="flex flex-col ml-2">
              <div className="h-5 text-lg text-slate-900 dark:text-white">Accuracy</div>
              <div className="h-8 text-emerald-500 font-bold text-2xl">{accuracy}<span className="text-lg ml-[2px]">%</span></div>
            </div>
          </div>
          <div className="px-6 text-blue-500 w-full h-20 bg-slate-300/30 dark:bg-slate-800/70 rounded flex items-center justify-start ">

            <motion.svg
              width="30"
              height="38"
              viewBox="64 -1 20 25"
              initial="hidden"
              animate="visible"
              className="dark:drop-shadow-blue"
            >
              <motion.g>
                <motion.polygon
                  variants={draw3}
                  points="75,0 72,10, 78,10, 68,22, 72,13 66,13"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  custom={1}
                />
              </motion.g>
            </motion.svg>
            <div className="flex flex-col ml-2">
              <div className="h-5 text-lg text-slate-900 dark:text-white">Time</div>
              <div className="h-8 text-blue-500 font-bold text-2xl">{(lessonCompleteTime / 1000 / correct).toFixed(1)}<span className="text-lg"><span className=" left-[1px] relative">s/q</span></span></div>
            </div>
          </div>
        </motion.div>
        <div className="flex gap-x-4 justify-center py-2 mb-[120px]">
          <div
              onClick={handleAgain}
              className={
                "bg-blue-500/70 " +
                " w-[160px] cursor-pointer hover:bg-blue-500 backdrop-blur-sm text-center mt-6 mb-10 py-4 px-4 text-white dark:text-blue-100 font-logo rounded-lg transition-all vertical-center"}>
              <div>Again </div>
            </div>
            <div
              onClick={handleReview}
              className={
                "bg-purple-500/70 " +
                " w-[160px] cursor-pointer hover:bg-blue-500 backdrop-blur-sm text-center mt-6 mb-10 py-4 px-4 text-white dark:text-blue-100 font-logo rounded-lg transition-all vertical-center"}>
              <div>Recover </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export const ReviewCompleteComponent = (props) => {
  useEffect(() => {
    window.gtag("event", "plans_tease", {
      event_category: "engagement",
    });
  }, [])

  const navigate = useNavigate();
  const { data: userId, isLoading: userIdLoading, error: userIdError } = useStandardUserIdentifier();
  const { loggedIn } = userId

  const [copyMessageModalVisible, setCopyMessageModalVisible] = useState(false);
  const [questionFilter, setQuestionFilter] = useState(null);

  const { questions, pastQAResults, lessonCompleteTime, points, onReset } = props;

  // get today's date in this format (example): Jul 21
  const today = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const correct = pastQAResults.filter((x) => x === 1).length;
  const incorrect = pastQAResults.filter((x) => x === -1).length;
  const accuracy = Math.round((correct / (correct + incorrect)) * 100);

  const handleCopyClick = () => {
    window.gtag("event", "challenge_button", {
      event_category: "engagement",
    });
    const message = "https://anyk.app/challenge/xa3-520";
    setCopyMessageModalVisible(true);
    // Create a temporary textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = message;
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);
  };

  const handleAgain = () => {
    window.gtag("event", "review_again_button", {
      event_category: "engagement",
    });
    onReset();
  };

  const handleRace = () => {
    window.gtag("event", "review_race_button", {
      event_category: "engagement",
    });
    navigate('/a/shuffle/medium')
  };

  const handlePlans = () => {
    window.gtag("event", "plans_button", {
      event_category: "engagement",
    });

    navigate("/a/plans");
    // window.location = "/plans"
  };

  const speedMultiplier = 3 * 5 / (lessonCompleteTime / 1000) + 1;
  const speedMultiplierRounded = Math.round(speedMultiplier * 100) / 100;

  const total = Math.round(correct * 100 * speedMultiplier);




  const stars = starMap(total);

  const moreLikeThisQuestion = async (question) => {
    const parents = await client.topics.getParentTopics('medium', question.topic)
    const params = new URLSearchParams();
    parents.forEach((parent) => {
      params.append("topic", parent.name);
    });
    navigate("/a/shuffle/medium?" + params.toString());
  }

  const correctCount = pastQAResults.filter((x) => x === 1).length;
  const incorrectCount = pastQAResults.filter((x) => x === -1).length;

  const scores = [
    [{ score: 5 }, { score: 0 }, { score: 0 }],
    [{ score: 5 }, { score: 4 }, { score: 0 }],
    [{ score: 5 }, { score: 4 }, { score: 6 }],
  ];

  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  // const displayNewScore = useTransform(rounded, (latest) => scores[Math.min(latest, scores.length - 1)]);
  const [currentScoreIndex, setCurrentScoreIndex] = useState(0);
  const delay = 4;
  const duration = 2;

  useEffect(() => {
    const controls = animate(count, scores.length - 1, {
      type: "tween",
      duration: duration,
      ease: "linear",
      delay: delay,
    });
    return () => controls.stop();
  }, [count]);

  useEffect(() => {
    rounded.onChange((value) => {
      setCurrentScoreIndex(value);
    });
  }, []);

  const [suggestedTopics, setSuggestedTopics] = useState([]);
  const [pastUserFinishesByStream, setPastUserFinishesByStream] = useState({})
  const [pastFinishes, setPastFinishes] = useState([])
  useEffect(() => {
    // const element = document.getElementById('explore-container');
    // const height = element.clientHeight;
    // element.style.minHeight = height + 'px';
    // setExploreTopics([])
    async function fetchDailyCount() {
      const pastUserFinishes = await client.plays.pastDailiesSummaries()
      setPastFinishes(pastUserFinishes)


      const pastUserFinishesByStream = pastUserFinishes?.reduce((acc, curr) => {
        const stream = curr.questionsetOfOut.streamMadeIn
        if (!acc[stream.name]) {
          acc[stream.name] = []
        }
        acc[stream.name].push(curr)
        return acc
      }, {}) || {}

      setPastUserFinishesByStream(pastUserFinishesByStream)

    }
    async function fetchTopics() {
      const topics = await client.topics.getChildTopics('medium', 'root');

      const randomTopic1 = topics[Math.floor(Math.random() * topics.length)]
      const randomTopic2 = topics[Math.floor(Math.random() * topics.length)]
      const randomTopic3 = topics[Math.floor(Math.random() * topics.length)]
      const randomTopic4 = topics[Math.floor(Math.random() * topics.length)]


      setSuggestedTopics([randomTopic1, randomTopic2, randomTopic3, randomTopic4])

      // // for (let topic of topics) {
      // const promises = topics.map(async (topic) => {
      //   const babyTopics = await client.topics.getChildTopics('medium', topic.name);

      //   const randomTopic = babyTopics[Math.floor(Math.random() * babyTopics.length)]
      //   randomTopic.parent = topic
      //   // setExploreTopics(e => [...e, randomTopic])
      //   // const gbabyTopics = await client.topics.getChildTopics('medium', randomTopic.name);
      //   // const grandomTopic = gbabyTopics[Math.floor(Math.random() * gbabyTopics.length)]
      //   // setExploreTopics(e => [...e, grandomTopic])

      // });
      // await Promise.all(promises)
    }
    fetchTopics();
    fetchDailyCount();
  }, []);

  // useEffect(() => {
  //   const controls = animate(count, from, { duration: 1, delay: 0.3 });

  //   controls.then(() => {
  //     // Start the second animation here
  //     // For example:
  //     animate(count, to, { duration: 1, delay: 0.3 });
  //   });
  //   return controls.stop;
  // }, [from, to]);

  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div id="round-complete" className="relative">
      {/* <ShipControls /> */}

      <div className=" relative z-20 py-2 rounded overflow-hidden max-w-2xl my-2 my-2 sm:m-auto mb-[80px] sm:mb-[80px]">
        {/* <div className="z-[500] fixed px-2 py-3 top-0 left-0 right-0 bg-slate-900/40 backdrop-blur-lg">
            <div className="w-[60%] sm:w-[30%] m-auto">
              <div className="box-content  h-2 ">
                <ScoreBar fill pastQAResults={pastQAResults} />
              </div>
            </div>
          </div> */}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 0,
            duration: 0.4,
          }} className="pt-4 mx-6 text-xl text-slate-900 dark:text-slate-200 font-bold font-logo">
          Summary
        </motion.div>
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 0,
            duration: 0.4,
          }}
          className=" font-logo grid grid-cols-2 gap-1 px-6 mt-2 mb-4 ">




          <div className="px-6 text-purple-500 w-full h-20 bg-slate-300/30 dark:bg-slate-800/70 rounded flex items-center justify-start ">

            <PiCaretUpFill className="w-[30px] h-full" />
            <div className="flex flex-col ml-2">
              <div className="h-5 text-lg text-slate-900 dark:text-white">Correct</div>
              <div className="h-8 text-purple-500 font-bold text-2xl">{correct}</div>
            </div>
          </div>
          <div className="px-6 text-blue-500 w-full h-20 bg-slate-300/30 dark:bg-slate-800/70 rounded flex items-center justify-start ">

            <motion.svg
              width="30"
              height="30"
              viewBox="15 1 20 20"
              initial="hidden"
              animate="visible"
              className="dark:drop-shadow-green text-emerald-500"
            >
              <motion.g>
                <motion.circle
                  cx="25"
                  cy="11"
                  r="9"
                  fill="none"
                  stroke="currentColor"
                  variants={draw3}
                  custom={1}
                />
                <motion.circle
                  cx="25"
                  cy="11"
                  r="3"
                  fill="none"
                  stroke="currentColor"
                  variants={draw3}
                  custom={1}
                />
              </motion.g>
            </motion.svg>
            <div className="flex flex-col ml-2">
              <div className="h-5 text-lg text-slate-900 dark:text-white">Accuracy</div>
              <div className="h-8 text-emerald-500 font-bold text-2xl">{accuracy}<span className="text-lg ml-[2px]">%</span></div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            y: {
              type: "spring",
              ease: "easeOut",
              delay: 0.2,
              duration: 0.6,
            },
            opacity: {
              type: "spring",
              ease: "easeOut",
              delay: 0.2,
              duration: 0.3,
            }
          }}
          className="px-12"
        >

          <div
            onClick={handleAgain}
            className={
              (stars >= 4 ? "bg-blue-500/20 " : " bg-blue-500/70 ") +
              " cursor-pointer hover:bg-blue-500 backdrop-blur-sm text-center mt-6 mb-10 py-3 px-4 text-white dark:text-blue-100 font-logo rounded-lg transition-all vertical-center"}>
            <div> Again </div>
          </div>
        </motion.div>
        {/* <div className="text-slate-900 dark:text-white pt-4 mx-6 text-xl  text-slate-900 dark:text-slate-200 font-bold font-logo">Experience</div>
    <div className=" font-logo grid grid-cols-4 gap-1 px-6 mt-2 mb-4 ">
            <div className="bg-slate-300/30 dark:bg-slate-800/70 rounded flex flex-col items-center rounded-lg pt-2 overflow-hidden">
              <div className="relative flex flex-col items-center">
                <div className="bg-slate-700/50 rounded-md px-[6px] text-slate-200 text-lg drop-shadow-zinc">
                  +2
                </div>
              </div>
              <div className="py-2 px-2 text-slate-600 dark:text-slate-300  text-center">
                  <span>science</span>
              </div>

            </div>
            <div className="bg-slate-300/30 dark:bg-slate-800/70 rounded flex flex-col items-center rounded-lg pt-2 overflow-hidden">
              <div className="relative flex flex-col items-center">
                <div className="bg-slate-700/50 rounded-md px-[6px] text-slate-200 text-lg drop-shadow-zinc">
                  +3
                </div>
              </div>
              <div className="py-2 px-2   text-slate-600 dark:text-slate-300  text-center">
                  <span>math</span>
              </div>

            </div>
            <div className="bg-slate-300/30 dark:bg-slate-800/70 rounded flex flex-col items-center rounded-lg pt-2 overflow-hidden">
              <div className="relative flex flex-col items-center">
                <div className="bg-slate-700/50 rounded-md px-[6px] text-slate-200 text-lg drop-shadow-zinc">
                  +1
                </div>
              </div>
              <div className="py-2 px-2   text-slate-600 dark:text-slate-300  text-center">
                  <span>history</span>
              </div>

            </div>
            <div className="bg-slate-300/30 dark:bg-slate-800/70 rounded flex flex-col items-center rounded-lg pt-2 overflow-hidden">
              <div className="relative flex flex-col items-center">
                <div className="bg-slate-700/50 rounded-md px-[6px] text-slate-200 text-lg drop-shadow-zinc">
                  +7
                </div>
              </div>
              <div className="py-2 px-2   text-slate-600 dark:text-slate-300  text-center">
                  <span>bio</span>
              </div>

            </div>
            </div> */}


        {/* <div className="px-4 flex justify-between items-center my-8">
        

        <div className="w-full h-4">
          <ScoreBar fill={true} pastQAResults={pastQAResults} />
        </div>
      </div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 3.7,
          duration: 0.4,
        }}
        className="text-white text-center mt-4 mb-6"
      >
        <div className="mb-2"><span className="text-2xl text-[#F5C242] font-logo">17th</span><span> on this round</span></div>
        <div>
          <span className="text-2xl text-[#F5C242] font-logo">7th</span> in <span className="text-blue-500 font-logo text-2xl">physics</span> today </div>

      </motion.div> */}
        <div className="flex justify-around">
          {/* <motion.div
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          y: {
          type: "spring",
          ease: "easeOut",
          delay: 4,
          duration: 0.6,
          },
          opacity: {
            type: "spring",
            ease: "easeOut",
            delay: 4,
            duration: 0.3,
          }
        }}
      className="w-20 pl-12"
      >
      <BigScoreGraph scores={scores[currentScoreIndex]} />
      </motion.div> */}
        </div>


        {/* <motion.div
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 1,
          duration: 0.6,
        }}
        className="font-logo"
      >

        <h1 className="mx-4 text-2xl dark:text-slate-100 font-logo border-blue-500 border-b-2 pb-1 mb-4">
          points
        </h1>

        <div className="p-4 m-1 text-center my-4 space-y-3">

          <div className="flex items-center justify-between text-[#00C682]">

            <div className="flex items-center">
              <motion.svg
                width="30"
                height="30"
                viewBox="15 1 20 20"
                initial="hidden"
                animate="visible"
                className="dark:drop-shadow-green"
              >
                <motion.g>
                  <motion.circle
                    cx="25"
                    cy="11"
                    r="9"
                    fill="none"
                    stroke="#00C682"
                    variants={draw2}
                    custom={1}
                  />
                  <motion.circle
                    cx="25"
                    cy="11"
                    r="3"
                    fill="none"
                    stroke="#00C682"
                    variants={draw2}
                    custom={1}
                  />
                </motion.g>
              </motion.svg>
              <span className="text-xl ml-2">correct</span>
            </div>
            <span className="font-bold text-xl">{correct * 100}</span>
          </div>
          <div className="flex items-center justify-between text-[#0091F9]">
            <div className="flex items-center">
              <motion.svg
                width="30"
                height="38"
                viewBox="64 -1 20 25"
                initial="hidden"
                animate="visible"
                className="dark:drop-shadow-blue"
              >
                <motion.g>
                  <motion.polygon
                    variants={draw2}
                    points="73,0 80,0 72,10, 78,10, 67,22, 72,12 67,12"
                    fill="none"
                    stroke="#0091F9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    custom={1}
                  />
                </motion.g>
              </motion.svg>
              <span className="text-xl ml-2">speed bonus</span>
            </div>
            <span className="font-bold text-xl">{speedMultiplierRounded}x</span>
          </div>
          <div className="flex items-center justify-between text-[#F5C242]">
            <div className="flex">
              <motion.svg
                width="33"
                height="30"
                viewBox="45 0 12 22"
                initial="hidden"
                animate="visible"
                className="dark:drop-shadow-gold"
              >
                <motion.g>
                  <motion.polygon
                    variants={draw2}
                    points="50,0 54,7 61,7 55,12 58,19 50,15, 42,19 45,12 39,7 46,7 "
                    fill="none"
                    stroke="#F5C242"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    custom={1}
                  />
                </motion.g>
              </motion.svg>
              <span className="text-2xl ml-2">total</span>
            </div>
            <p className="font-bold text-2xl">{total}</p>
          </div>
        </div>
      </motion.div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 4.3,
          duration: 0.6,
        }}
        className="flex gap-4 justify-center"
      >
        
        <Link to="/a/home">
        <button 
        className="font-logo overflow-hidden group active:scale-100 hover:scale-95 transition-all hover:bg-blue-600 bg-blue-500/20  text-white w-36 py-2 rounded-lg active:bg-blue-600">
          back home
        </button>
        </Link>
        <button 
        onClick={() => window.location.reload()}
        className="font-logo overflow-hidden group active:scale-100 hover:scale-95 transition-all hover:bg-blue-600 bg-blue-500  text-white w-36 py-2 rounded-lg active:bg-blue-600">
          play again
        </button>
      </motion.div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          ease: "easeOut",
          delay: 4.3,
          duration: 0.6,
        }}
        className="flex gap-3 justify-center pt-6"
      >

        <button
          onClick={() => window.location.reload()}
          className="font-logo overflow-hidden group active:scale-100 hover:scale-[1.02] transition-all hover:bg-blue-600 bg-purple-500/70  text-white w-36 py-4 rounded-lg active:bg-blue-600">
          aim higher
        </button>
        <Link to="/a/home">
          <button
            className="font-logo overflow-hidden group active:scale-100 hover:scale-[1.02] transition-all hover:bg-blue-600 bg-blue-500/70 backdrop-blur  text-white w-36 py-4 rounded-lg active:bg-blue-600">
            next topic
          </button>
        </Link>
      </motion.div> */}

        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          y: {
          type: "spring",
          ease: "easeOut",
          delay: 3.7,
          duration: 0.6,
          },
          opacity: {
            type: "spring",
            ease: "easeOut",
            delay: 3.7,
            duration: 0.3,
          }
        }}
      className="grid grid-cols-2 gap-1 px-12 pt-8"
      >
         
        <div 
        onClick={() => setQuestionFilter(f => {
          if (!f) return "wrong"
          if (f === "wrong") return null
          return "wrong"
        })}
        className={
          (questionFilter === "wrong" ? "backdrop-blur-lg text-center bg-slate-400/50 dark:bg-slate-500/50" : "backdrop-blur-sm text-center bg-gray-500/10 dark:bg-slate-300/30") +
          " py-2 px-4 text-gray-800  text-slate-900 dark:text-slate-200 font-logo rounded-lg h-16 transition-all"}>
          <div className="font-bold ">+ {incorrectCount}</div>
          <div className="text-gray-800 dark:text-slate-300 ">learning</div>
        </div>
        <div 
        onClick={() => setQuestionFilter(f => {
          if (!f) return "right"
          if (f === "right") return null
          return "right"
        })}
        className={
          (questionFilter === "right" ? "backdrop-blur-lg text-center bg-slate-400/50 dark:bg-slate-500/50" :  "backdrop-blur-sm text-center bg-gray-500/10 dark:bg-slate-300/30") +
          "  py-2 px-4 text-gray-800  text-slate-900 dark:text-slate-200 font-logo rounded-lg h-16 transition-all"}>
          <div className="font-bold ">+ {correctCount}</div>
          <div className="text-gray-800 dark:text-slate-300">known</div>
        </div>
        </motion.div> */}


        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            ease: "easeOut",
            delay: 4,
            duration: 0.6,
          }}
          className="my-2"
        >
          {/* <h1 className="mx-4 text-2xl dark:text-slate-100 font-logo border-blue-500 border-b-2 pb-1 my-8">
          review
        </h1> */}

          {questions.map((question, index) => {
            // .sort((a, b) => a.wasCorrect - b.wasCorrect).map((question, index) => {


            return (
              <Question filter={questionFilter} key={index} question={question} index={index} moreLikeThisQuestion={() => moreLikeThisQuestion(question)} />

            )
          })
          }
        </motion.div>

        {!false && (
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              y: {
                type: "spring",
                ease: "easeOut",
                delay: 0.5,
                duration: 0.6,
              },
              opacity: {
                type: "spring",
                ease: "easeOut",
                delay: 0.5,
                duration: 0.3,
              }
            }}
            className="relative sm:px-6 border-t-2 border-gray dark:border-gray-900 mx-6 mb-6 h-[200px]"
          >

            <div className="relative h-[200px] z-5 mt-6">
              <div className="pb-4 dark:text-white text-xl text-slate-900 font-bold font-logo">
                Unlock more
              </div>
              <div className=""
                onClick={handlePlans}>
                <div className="py-5 group flex flex-col items-center bg-slate-300/30 dark:bg-slate-500/30 backdrop-blur-lg rounded-lg overflow-hidden">
                  <div className=" z-[-1] bg-animation fixed top-0 bottom-0 right-0 left-0 dark:bg-black dark:bg-gradient-to-tr dark:to-slate-500/30 dark:via-slate-900/30 dark:from-slate-700/30">
                    <div id="stars"></div>
                    <div id="stars2"></div>
                    <div id="stars3"></div>
                    <div id="stars4"></div>
                  </div>
                  <div className="[text-shadow:_2px_1px_2px_rgb(0_136_242_)] grow text-2xl dark:text-white text-slate-900 font-bold px-1 pb-3 font-logo">AnyQ+ </div>
                  {/* <button className="text-slate-100 font-logo group-hover:scale-125 transition-all bg-slate-600/50 w-28 p-2 px-2 rounded-md">base</button> */}
                  <div className="py-2 group flex items-center">
                    <div className=" rounded px-1 pb-2 py-1 inline-block group-hover:text-blue-300 text-blue-400 transition-all text-center">
                      <span className="relative top-[-4px] font-logo font-bold text-sm px-1 text-slate-700 dark:text-white">$</span>
                      <span className="font-logo font-bold text-2xl text-black dark:text-white">19</span>
                      <span className="text-slate-700 dark:text-slate-300 font-logo font-bold text-sm px-1">/ year</span>
                    </div>
                    <div className="text-black dark:text-white font-logo text-sm mt-2 mx-3">
                      or
                    </div>
                    <div className=" rounded px-2 pb-2 py-1 inline-block group-hover:text-blue-300 text-blue-400 transition-all text-center">
                      <span className="relative top-[-4px] font-logo font-bold text-sm px-1 text-slate-700 dark:text-white">$</span>
                      <span className="font-logo font-bold text-2xl text-black dark:text-white">49</span>
                      <span className="text-slate-700 dark:text-slate-300 font-logo font-bold text-sm px-1">once</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center">
                {/* <a
        onClick={() => navigate("/a/plans")}
        className={
          "mt-2 mx-auto w-[200px] text-blue-400 font-bold font-logo items-center cursor-pointer inline-flex justify-center rounded-lg py-4 px-3 text-xl outline-2 outline-offset-2 transition-colors relative overflow-hidden active:text-white/80 "
        }
      >
        {"compare"}
      </a> */}
              </div>

            </div>


          </motion.div>
        )}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            y: {
              type: "spring",
              ease: "easeOut",
              delay: 0.8,
              duration: 0.8,
            },
            opacity: {
              type: "spring",
              ease: "easeOut",
              delay: 0.8,
              duration: 0.3,
            }
          }}
          className="relative  gap-2 border-t-2 border-gray dark:border-gray-900 mx-6 mb-[200px] h-[200px]"
        >


          {/* <div className="text-center font-logo text-xl dark:text-slate-300 py-6">
          continue exploring
        </div> */}
          <div className="pt-6 pb-4 dark:text-white text-xl text-slate-900 font-bold font-logo">
            Continue Exploring
          </div>
          <div className="relative h-full">

            {suggestedTopics.map((topic, index) => {

              const textSize = topic.name.length > 20 ? "text-sm" : "text-lg"

              return (
                <EloTopicTile
                  key={index + topic.name}
                  topic={topic.name}
                  scores={pastUserFinishesByStream[topic.name]}
                  // building={stream.building}
                  index={index}
                  selectedIndex={selectedIndex}
                  onClick={() => setSelectedIndex(i => i == index ? null : index)}
                  // streamId={stream._id}
                  pastUserFinishes={pastFinishes}
                  themeColor={"slate"}
                  height={30}
                // pinned={userStreams.includes(stream)}
                // priority={stream.priority}
                />
              )
            })}
          </div>

        </motion.div>
        {/* <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          y: {
          type: "spring",
          ease: "easeOut",
          delay: 3.7,
          duration: 0.6,
          },
          opacity: {
            type: "spring",
            ease: "easeOut",
            delay: 3.7,
            duration: 0.3,
          }
        }}
      className="relative  gap-2 px-2 border-t-2 border-gray dark:border-gray-900 mx-6 mb-[200px] h-[200px]"
      >


        <div className="text-center font-logo text-xl dark:text-purple-300 py-6">
          explore more
        </div>
        <div className="relative h-full">
         
         { suggestedTopics.map((topic, index) => {

          const textSize = topic.name.length > 20 ? "text-sm" : "text-lg"

          return (
            <EloTopicTile
                  key={index + topic.name}
                  topic={topic.name}
                  scores={pastUserFinishesByStream[topic.name]}
                  // building={stream.building}
                  index={index}
                  selectedIndex={selectedIndex}
                  onClick={() =>  setSelectedIndex(i => i == index ? null : index)}
                  // streamId={stream._id}
                  pastUserFinishes={pastFinishes}
                  themeColor={"purple"}
                  height={30}
                  // pinned={userStreams.includes(stream)}
                  // priority={stream.priority}
                />
          )
          })}
         </div>

</motion.div> */}


        {/* <div className="mx-10 my-2 opacity-50">
        <TopicGenerator 
          displayedText={"something else"} 
          buttonText={"start"} 
          onBuilding={() => {}} 
          onClear={() => {}} 
          onSubmit={() => {}} 
          setUserStreams={() => {}} 
          setTopicPage={() => {}}/>
          </div> */}

        {/* <div className="flex justify-center pt-6">
        <button className="font-logo overflow-hidden group active:scale-100 hover:scale-95 transition-all hover:bg-blue-600 bg-blue-500  text-white w-48 m-2 py-3 rounded-lg active:bg-blue-600">
          <span className="grow px-3">Next</span>
        </button>
      </div> */}
        {/* <p className="bg-slate-100 rounded dark:bg-slate-800 dark:text-white p-4 m-3">
        Confident in your score? Challenge a friend to beat you.
        <br />
        <br />
        Not yet? Go again and improve your score.
        <br /> <br />
        Want more? Explore upgrade options.
      </p> */}
        {/* <div className="grid grid-cols-3 gap-4 p-3 pb-6">
        <button
          onClick={() => handleCopyClick()}
          className="relative flex w-full justify-between shadow items-center bg-blue-500 dark:bg-blue-600 dark:text-white py-4 px-1  rounded-lg border-blue-500"
        >
          <span className="grow px-3">Challenge</span>
          <IoIosArrowForward className="text-xl" />
        </button>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={
            (copyMessageModalVisible ? " " : "hidden ") +
            "bg-slate-900/75 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          }
          onClick={() => setCopyMessageModalVisible(false)}
        >
          <div className="relative top-[25%] w-full max-w-2xl max-h-full">
            <div className="relative rounded-lg shadow bg-slate-100 dark:bg-slate-700">
              <div className="p-6 space-y-6">
                <p className="text-base leading-relaxed text-slate-900  text-slate-900 dark:text-slate-200">
                  Shareable link copied to your clipboard!
                  <br />
                  <br />
                  Share it with a friend and challenge them to beat your score.
                </p>
              </div>
              <div className="flex justify-around items-center p-6 space-x-2 rounded-b dark:border-gray-600">
                <button
                  onClick={() => setCopyMessageModalVisible(false)}
                  data-modal-hide="defaultModal"
                  type="button"
                  className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Got it
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={handleAgain}
          className="flex w-full h-full justify-between shadow items-center bg-slate-100 dark:bg-slate-800 dark:text-white py-1 px-1  rounded-lg "
        >
          <span className="grow px-3">Again</span>
        </button>
        <button
          onClick={handlePlans}
          className="border-2 border-[#FF6E56] flex w-full h-full justify-between shadow items-center bg-slate-100 dark:bg-slate-800 dark:text-white py-1 px-1  rounded-lg "
        >
          <span className="grow text-xl [text-shadow:_1px_1px_0_rgb(255_116_81_)] dark:text-white text-slate-900 font-bold px-1 font-logo">
            anyk+
          </span>
        </button>
      </div> */}


      </div>
      {/* <div className=" bg-animation fixed top-0 bottom-0 right-0 left-0 bg-black dark:bg-gradient-to-br dark:to-slate-900/30 dark:via-purple-900/30 dark:from-blue-800/30">
    <div id="stars"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
    <div id="stars4"></div>
  </div> */}
    </div>
  );
};


const Question = ({ question, index, moreLikeThisQuestion, filter }) => {
  const variants = {
    open: { padding: '12px 10px 10px', height: 'auto', opacity: 1 },
    closed: { padding: '12px 10px 0px', height: '6px', opacity: 1 }
  };

  const variants2 = {
    open: { padding: '12px 10px 10px', height: 'auto', opacity: 1 },
    closed: { padding: '0px 10px 0px', height: '0px', opacity: 1 }
  };

  const [opened, setOpened] = useState(false);
  const [everOpened, setEverOpened] = useState(false);
  const [detail, setDetail] = useState(null);

  const handleDetailStreamResponse = (index, resp, done) => {
    setDetail(resp.content);
  };


  // const getDetail = async (question) => {
  //   // const topic = domainKeyToPathKeys(actualTopicId).filter(i => i.split('.').length === 3).map(i => domainKeyToName(tree, i)).join(" > ")

  //   let detailSystem = ``;

  //   const conceptFormat = "Keep the explanation as short as possible";

  //   //  let content = "Question: " + question.questionText + "\nAnswer: " + question.correctAnswer + "\nConcept: " + hintText

  //   const system = detailSystem + "\n" + conceptFormat;

  //   const content = `Provide an interesting fact to build off of the following question and answer. ${question.text} ${question.correctAnswer}`;
  //   chatStreamNoFunction(system, content, 0, handleDetailStreamResponse);
  //   setDetail(" ");
  // };

  // useEffect(() => {
  //   getDetail(question);
  // }, [])

  if (!filter) {
    return null
  }

  if (filter === "wrong" && question.wasCorrect) {
    return null;
  }

  if (filter === "right" && !question.wasCorrect) {
    return null;
  }


  return (
    <div key={index} className="cursor-pointer  mx-4 flex items-center space-y-2" >
      <div className="text-2xl pr-3">{question.wasCorrect || everOpened ?
        <IoIosCheckmarkCircleOutline className={"transition-all " + (everOpened ? "text-blue-500" : "text-green-500")} />
        :
        <>
          <IoIosCloseCircleOutline className={"transition-all " + (everOpened ? "text-blue-500" : "text-red-500")} />

        </>

      }
      </div>
      <div className="bg-gray-500/10 dark:bg-slate-300/30 backdrop-blur-xl py-3 rounded  grow" onClick={() => { setEverOpened(true); setOpened(o => !o) }}>
        <div className="text-slte-900 dark:text-slate-100 mx-4 text-sm" >{question.text}</div>

        <div className="flex items-center">
          <motion.div
            initial="closed"
            animate={opened ? "open" : "closed"}
            variants={variants2}
            className="box-content overflow-hidden text-slate-900 dark:text-slate-300 ml-2 py-2 px-3 border-green-500/80 w-[80%]">
            <span className="font-bold text-lg font-condensed">{question.correctAnswer}</span>
            <p className="pt-3 text-slate-900 dark:text-slate-400">{question.fact}</p>
          </motion.div>
        </div>

      </div>
      {/* <div>
        <div
          onClick={moreLikeThisQuestion}
          className={
            "cursor-pointer z-40 w-8 flex rounded-sm dark:hover:bg-slate-700 hover:ring-4 items-center"
          }
        >
          <IoIosArrowForward
            id="next-button"
            className="text-[40px] text-blue-500"
          />
        </div>
      </div> */}
    </div>
  )
}

