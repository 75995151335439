import { motion } from 'framer-motion';

export const TextWriter = ({ children }) => {
  const letters = Array.from(children);

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.02 + Math.random() * 0.00, delayChildren: 0.1, },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        duration: 0.02,
      },
    },
    hidden: {
      opacity: 0,
      y: 0,
      transition: {
        type: 'tween',
        duration: 0.02,
      },
    },
  };

  return (
    <motion.div
      style={{ display: 'flex', justifyContent: 'flex-start' }}
      variants={container}
      initial="hidden"
      animate="visible"
    >
      {letters.map((letter, index) => (
        <motion.span
          variants={child}
          key={index}
          style={{ display: 'inline-block', marginRight: '0.0em' }}
        >
          {letter === ' ' ? '\u00A0' : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default TextWriter;