import { useQuery } from '@tanstack/react-query';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';


export const useCheckUserIsAdmin = () => {
  const { data: session, isError, isLoading } = useAuthSession();

  // Define a query function that uses the session data
  const getIsAdmin = async (sessionData) => {
    if (!sessionData) {
      // Handle case where session data is not available
      throw new Error('Session data is not available');
    }
    const groups = sessionData.tokens.idToken.payload["cognito:groups"];
    const isPremium = groups?.includes("admin");
    return Boolean(isPremium);
  };

  // Use the useQuery hook with the session data
  return useQuery({
    queryKey: ['userIsAdmin'], // Use session as part of the query key for cache uniqueness
    queryFn: () => getIsAdmin(session),
    enabled: !isLoading && !isError && Boolean(session), // Only run the query if the session is loaded and has no errors
    onError: (error) => {
      console.warn("Error checking user group:", error.message);
    }
  });
};


export const useCheckUserGroup = () => {
  const { data: session, isError, isLoading } = useAuthSession();

  // Define a query function that uses the session data
  const getIsUserPremium = async (sessionData) => {
    if (!sessionData) {
      // Handle case where session data is not available
      throw new Error('Session data is not available');
    }
    const groups = sessionData.tokens.idToken.payload["cognito:groups"];
    const isPremium = groups?.includes("anyk_plus") || groups?.includes("anyk_plus_level1") || groups?.includes("anyk_plus_level2") || groups?.includes("anyk_plus_level3") || groups?.includes("admin");
    return Boolean(isPremium);
  };

  // Use the useQuery hook with the session data
  return useQuery({
    queryKey: ['userGroup'], // Use session as part of the query key for cache uniqueness
    queryFn: () => getIsUserPremium(session),
    enabled: !isLoading && !isError && Boolean(session), // Only run the query if the session is loaded and has no errors
    onError: (error) => {
      console.warn("Error checking user group:", error.message);
    }
  });
};

export const useAuthSession = () => {
  return useQuery({
    queryKey: ['authSession'],
    queryFn: fetchAuthSession,
    onError: (error) => {
      // Optional: handle error, for example, logging or setting an error state
      console.warn("Error fetching auth session:", error.message);
    },
    // You can add more options here, such as refetch intervals, cache time, etc.
  });
};

const getCurrentUserWrapped = async () => {
  let resp
  try {
    resp = await getCurrentUser()
  } catch (e) {
    // console.log('error', e)
    return false
  }
  return resp
}
const useCurrentUser = () => {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: getCurrentUserWrapped,
    // onError: (error) => {
    //     console.error("Error fetching current user:", error);
    // }
  });
};

export const useStandardUserIdentifier = () => {
  const { data: session, isLoading: sessionLoading, isError: sessionError } = useAuthSession();
  const { data: currentUser, isLoading: userLoading, isError: userError } = useCurrentUser();

  const fetchUserIdentifier = async () => {

    
    let userIdentifier = "";
    let loggedIn = false;

    // Prefer currentUser data if available
    if (currentUser && !userError) {
      userIdentifier = currentUser.username;
      loggedIn = true;
    } else if (session && !sessionError) {
      // Fallback to session data if currentUser isn't available
      if (window.guestUser_piuyertpqiuerpiqweurpiqwuerpqwue) {
        return { userIdentifier: "guest", loggedIn: false };
      }
      userIdentifier = session.identityId;
      loggedIn = false;
    }

    return { userIdentifier, loggedIn };
  };

  return useQuery({
    queryKey: ['userIdentifier'],
    queryFn: fetchUserIdentifier,
    enabled: !sessionLoading && !userLoading, // Only enable when both session and user data are not loading
    onError: (error) => {
      console.error("Error fetching user identifier:", error);
    }
  });
};