// infra
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// data
import { default as tree } from "../../scripts/tree.json";
import { domainKeyToPathKeys, domainKeyToName } from "./util";
import { newExplanationStream, chatStreamNoFunction } from "../../api/openai";
import { client } from '../../api/questions'
import { process, processTextOnly } from '../../api/lm'
import { useDispatch } from "react-redux";
import { answeredQuestionsSlice } from "../../store";

// ui
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { TextAnim, StringVacuum } from "../TypeWriter";

import { FaBolt } from "react-icons/fa6";
import { FaShieldHalved } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";

import { IoMdReturnLeft, IoIosCloseCircleOutline, IoMdPlanet } from "react-icons/io";



// children
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";

const { addAnsweredQuestion } = answeredQuestionsSlice.actions;

const wrapConceptInSpan = (question, concept) => {
    const conceptIndex = question.indexOf(concept);
    if (conceptIndex === -1) {
        return question;
    }
    const beforeConcept = question.substring(0, conceptIndex);
    const afterConcept = question.substring(conceptIndex + concept.length);
    return `${beforeConcept}<span>${concept}</span>${afterConcept}`;
};

export const QuestionComponent = (props) => {
    const innerTo = useNavigate();

    const to = (dest, tag) => {
        window.gtag("event", tag, {
            event_category: "engagement",
        });
        return innerTo(dest);
    };

    const {
        question,
        correctAnswerPlacement,
        topic,
        doneLoading,
        mode,
        handleNextClick,
        lastQuestion,
        setAutoNext,
        autoNext
    } = props;



    const [answered, setAnswered] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(undefined);
    const [explanation, setExplanation] = useState("");
    const [concept, setConcept] = useState("");
    const [explanationIntro, setExplanationIntro] = useState("fact")
    // const [hint, setHint] = useState("");
    // const [showHint, setShowHint] = useState(false);
    const [startQuestionTime, setStartQuestionTime] = useState(0);
    const [moreDetails, setMoreDetails] = useState(false);



    useEffect(() => {
        if (doneLoading) {
            setStartQuestionTime(Date.now());
        }
        setExplanation(question.fact)
    }, [doneLoading]);

    const calculatePoints = (isCorrect, timeTaken) => {
        const points = isCorrect ? 100 : -25;
        const pointsWBonus =
            timeTaken < 10000 ? points * (1 + (10000 - timeTaken) / 10000) : points;
        const pointsWBonusAndBase = pointsWBonus + 50; // add 50 so it can't go negative
        return pointsWBonusAndBase;
    };

    const answerFn = async (answerIndex, isCorrect) => {
        if (!props.skipSaveAnswer) {
            if (props.handleAnswerClick) props.handleAnswerClick(answerIndex, isCorrect, question.id);
        }
        setAnswered(true); // this is duplicative
        setTimeout(() => {
            setShowVacuum(true)
        }, 1100)
        setIsCorrect(isCorrect); // duplicative
        setSelectedIndex(answerIndex);
        // return props.handleAnswerClick(answer, correct)

        // save the answer

        const timeToAnswer = Date.now() - startQuestionTime;
        const points = calculatePoints(isCorrect, timeToAnswer);



    };


    const [showVacuum, setShowVacuum] = useState(false)

    const showExplanation = moreDetails || (answered && !isCorrect);

    const { text } = question;

    // let domainColor = '#7780ff'
    let domainColor = "";
    switch (props.difficulty) {
        case "hard":
            domainColor = "#FF6E56";
            break;
        case "medium":
            domainColor = "#F8B849";
            break;
        case "easy":
            domainColor = "#00C682";
            break;
        default:
            domainColor = "#00C682";
            break;
    }

    // if (actualTopicId) {
    //     domainPath = domainKeyToPathKeys(actualTopicId).filter(i => i.split('.').length === 3).map(i => domainKeyToName(tree, i)).join(" > ")
    //     domainColor = ['#7780ff', '#ed7dae', '#ff00a0', '#ff006e', '#ff4341', '#ff910d'][domainKeyToPathKeys(actualTopicId)[0] - 1]
    // }

    let questionTextWrapped = text;
    // if (concept && text) {
    //     questionTextWrapped = wrapConceptInSpan(text, concept);
    //     questionTextWrapped = questionTextWrapped.replace(
    //         "<span>",
    //         '<span id="hint" class="transition-all underline-offset-4	underline decoration-dotted  hover:cursor-pointer  decoration-blue-500    text-blue-500 hover:decoration-blue-300    hover:text-blue-300 ">',
    //     );
    // }

    //     const getHint = async (hintText) => {
    //         // const topic = domainKeyToPathKeys(actualTopicId).filter(i => i.split('.').length === 3).map(i => domainKeyToName(tree, i)).join(" > ")

    //         let conceptSystem = `
    //  You are a professor. Your student is trying to answer an exam question but needs some help. 
    //  Provide more details, without giving away the answer itself. 
    //  Only provide the hint itself, no additional instructions. 
    //  Do not mention anything about study materials, classes, or exam materials. 
    //              `;

    //         const conceptFormat = "Keep the explanation as short as possible";

    //         //  let content = "Question: " + question.questionText + "\nAnswer: " + question.correctAnswer + "\nConcept: " + hintText

    //         const system = conceptSystem + "\n" + conceptFormat;

    //         const content = `User is trying to answer: ${text} and has the question: What is a ${hintText}?`;
    //         chatStreamNoFunction(system, content, 0, handleHintStreamResponse);
    //         setHint(" ");
    //     };

    // const [pin, setPin] = useState(-1)

    // const updatePin = (newPin) => {
    //     if (pin === newPin) {
    //         setPin(-1)
    //         pinReceive(-1)
    //     } else {
    //         setPin(newPin)
    //         pinReceive(newPin)
    //     }

    // }

    // const handleHintClick = () => {
    //     f((shown) => {
    //         if (!shown) {
    //             window.gtag("event", "show_hint", {
    //                 event_category: "engagement",
    //             });
    //         }
    //         return !shown;
    //     });
    // };

    // if (!topicString) {
    //     return <></>;
    // }





    return (
        <div>
            <div className={"sm:pr-0 sm:flex "}>

                {/* {handleSwitchModel && gpt4 && (
                <FaShieldHalved
                    className="absolute text-2xl text-blue-500 top-2 right-4 z-[1000]"
                    onClick={handleSwitchModel}
                />
            )}
            {handleSwitchModel && !gpt4 && (
                <FaBolt
                    className="absolute text-2xl text-blue-500 top-2 right-4 z-[1000]"
                    onClick={handleSwitchModel}
                />
            )} */}
                <div className="rounded-md ">
                    

                    <h1
                        id="question"
                        className=" mt-2 sm:rounded-lg text-base p-4 text-slate-700 dark:text-slate-200 w-screen sm:max-w-[700px] md:w-[800px]"
                    >
                        {questionTextWrapped ? (
                            <span
                                // onClick={handleHintClick}
                                dangerouslySetInnerHTML={{ __html: questionTextWrapped }}
                            />
                        ) : (
                            <div className="animate-pulse mt-4  h-10 dark:bg-slate-800/70 rounded-lg"></div>
                        )}
                    </h1>
                    {/* <AnimatePresence>
                    {hint && showHint && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            duration={0.2}
                            className="border-box overflow-hidden px-3 border-l-4 border-blue-500 text-slate-700 dark:text-slate-200"
                        >
                            <span className="text-blue-400 font-bold text-lg pr-1">
                                HINT{" "}
                            </span>
                            {hint}
                        </motion.div>
                    )}
                </AnimatePresence> */}
                    <div className="font-condensed flex relative">
                        <div
                            className={
                                "max-w-2xl w-full transition-all delay-1000 duration-300"
                            }
                        >
                            <MultipleChoiceQuestion
                                // key={question.id}
                                question={question}
                                incorrectAnswers={question.incorrectAnswers}
                                correctAnswerPlacement={correctAnswerPlacement}
                                selectedIndex={selectedIndex}
                                handleAnswerClick={async (answer, correct) => answerFn(answer, correct)}
                                handleDetailClick={() => setMoreDetails(true)}
                                doneLoading={doneLoading}
                            />
                        </div>
                    </div>
                    {/* {answered && !showExplanation && (
                        <div className="rounded px-2 py-1 mx-4 absolute -bottom-2 right-0 bg-blue-500 text-white text-xl font-logo">
                        say more
                        </div>

                    )} */}
                    {showExplanation && (
                            <motion.div 
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{duration: 0.2, delay: isCorrect ? 0 : 1}}
                            
                            id="explanation" className=" relative sm:max-w-[700px] md:w-[800px]">
                                {answered && (
                                    <div>
                                        <div className="flex">
                                            {/* <img
                                                className=" h-[32px] w-[32px] ml-4"
                                                src={process.env.PUBLIC_URL + "/space_logo_transparent.png"}
                                            ></img> */}
                                            <div
                                                className={
                                                    "mx-6 relative text-black/80 rounded-2xl shadow bg-slate-200/30 dark:bg-blue-700/30 backdrop-blur-lg dark:text-slate-200 p-4 pt-2 mb-8 w-full"
                                                }
                                            >
                                                <div className="text-blue-500 font-logo ">
                                                    {explanationIntro}
                                                </div>
                                                {/* <TextAnim text={explanation}> */}
                                                {showVacuum && <StringVacuum inputString={explanation.trim()} />}
                                                {/* </TextAnim> */}

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </motion.div>

                    )}
                </div>

            </div>
            <div className="">
                {answered &&
                    <AiToolbar autoNext={autoNext} isCorrect={isCorrect} lastQuestion={lastQuestion} handleNextClick={handleNextClick} 
                    expand={() => {
                        if(autoNext) setAutoNext(false)
                        setMoreDetails(true)
                        window.gtag("event", "expand_chat", {
                            event_category: "engagement",
                        });
                    } }
                    minimized={!moreDetails} setExplanation={setExplanation} question={question} setExplanationIntro={setExplanationIntro} />
                }

            </div>
            <div>
            {/* {answered &&
                    <div className="font-logo text-blue-100 bg-blue-500 w-32 rounded-md py-2 text-center m-auto">
                        Next
                    </div>
                } */}
            </div>
        </div>
    );
};

const AutoButton = ({onClick, auto, lastQuestion}) => {

    const [wasAuto, setWasAuto] = useState(false)
    useEffect(() => {
        if (auto) {
            setWasAuto(true)
        } else {
            setTimeout(() => {
                setWasAuto(false)
            }, 300)
        }
    }, [auto])
    return (
        <AnimatePresence>
            { lastQuestion && 
      <motion.div 
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, delay:0.5 }}
      className="group relative inline-block overflow-hidden rounded-md">
        <motion.div
          initial={{ width: '0%' }}
          animate={{ width: auto ? '100%' : '0%' }}
          transition={{ 
            duration: auto ? 1.5 : 0.2, 
            delay: auto ? 0.5 : 0, 
        }}
          className="absolute z-[500] inset-0 bg-blue-600"
        />
        <motion.div
          className={( wasAuto ? "group-active:bg-blue-500 " : "group-active:bg-blue-600 ") + " absolute inset-0 bg-blue-500 group-hover:bg-blue-600"}
        />
        <button onClick={onClick} className="z-[501] relative font-logo text-blue-100  py-2 h-full w-full">
          Next
        </button>
      </motion.div>
}
      </AnimatePresence>
    );
  };


export const AiToolbar = (props) => {

    const {
        question,
        doneLoading,
        setExplanation,
        setExplanationIntro,
        minimized,
        expand,
        lastQuestion,
        isCorrect,
        autoNext
    } = props;




    const [explanationStreaming, setExplanationStreaming] = useState(false)
    const [simplifyLoading, setSimplifyLoading] = useState(false)
    const [simplifyUsed, setSimplifyUsed] = useState(false)
    const [giveMeMoreUsed, setGiveMeMoreUsed] = useState(false)
    const [moreLoading, setMoreLoading] = useState(false)
    const [typedLoading, setTypedLoading] = useState(false)
    // const [hint, setHint] = useState("");
    // const [showHint, setShowHint] = useState(false);
    const [startQuestionTime, setStartQuestionTime] = useState(0);

    const [moreTold, setMoreTold] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        if (doneLoading) {
            setStartQuestionTime(Date.now());
        }
        setExplanation(question.fact)
    }, [doneLoading]);

    let domainColor = "";
    switch (props.difficulty) {
        case "hard":
            domainColor = "#FF6E56";
            break;
        case "medium":
            domainColor = "#F8B849";
            break;
        case "easy":
            domainColor = "#00C682";
            break;
        default:
            domainColor = "#00C682";
            break;
    }

    const onRespReady = (text, done) => {
        if (done) {
            if (simplifyLoading) {
                setSimplifyUsed(true)
            }
            setSimplifyLoading(i => {
                if(i) setSimplifyUsed(true)
                    return false
        })

        setMoreLoading(i => {
            if(i) setGiveMeMoreUsed(true)
                return false
    })


            setTypedLoading(false)
        }


        setExplanation(text)
        setMoreTold(true)


    }

    const system = "Say something interesting about the topic of the question."
    // const format = "Wrap your answer into a json response with one property called :text"
    const prompt2 = system

    const simplify = () => {
        window.gtag("event", "simplify", {
            event_category: "engagement",
        });
        setSimplifyLoading(true)
        setExplanation("")
        setExplanationIntro("simpler")
        const system = "Explain the question to a layman"
        // const format = "Wrap your answer into a json response with one property called :text"
        const prompt3 = system


        const context = question.text

        setExplanationStreaming(true)
        processTextOnly(prompt3, context, onRespReady)

    }

    const giveMeMore = () => {
        window.gtag("event", "give_me_more", {
            event_category: "engagement",
        });
        setMoreLoading(true)
        setExplanation("")
        setExplanationIntro("here's more")

        const context = question.text

        processTextOnly(prompt2, context, onRespReady)

    }

    const [typedQuestion, setTypedQuestion] = useState("")

    const sendTypedQuestion = () => {
        if (typedLoading) return
        window.gtag("event", "send_typed_question", {
            event_category: "engagement",
        });
        // first, scroll to the bottom of the page
        // window.scrollTo(0, document.body.scrollHeight);
        setTypedLoading(true)
        setExplanation("")
        // if typedQuestion is longer than 28 characters, then use the first 28 characters and add "..."
        const explanationIntro = typedQuestion.length > 28 ? typedQuestion.substring(0, 28) + "..." : typedQuestion
        setExplanationIntro(explanationIntro)

        const system = "You are a helpful AI assistant. Your user has asked you a question. Provide a helpful response. The previous conversation history will be provided, then the new question."
        const format = ""
        const prompt3 = system + format



        const context = "Conversation history: " + "AI asked the question:" + question.text + "with correct answer" + question.correctAnswer + "and then after answering the User asked the question:" + typedQuestion

        processTextOnly(prompt3, context, onRespReady)
        setTypedQuestion("")

    }

    return (
        <div className="relative">
            <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{duration: 0.2, delay: isCorrect ? 0.3 : 2}}
                className={`pb-4 `}
                id="ai-toolbar"
            >
                <>
                {!minimized && 
                    <div className="pb-2">
                        <div
                            className="w-full px-8">
                            <motion.input
                                // initial={{margin: '0', opacity: 1}}
                                // animate={{margin: '0 7%', opacity: 1}}
                                // transition={{delay: 0.7}}

                                type="text"
                                // disabled={typedLoading}
                                placeholder="ask a follow-up..."
                                onChange={(e) => {
                                    setTypedQuestion(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        sendTypedQuestion()
                                    }
                                }}
                                value={typedQuestion}
                                className="
                                        h-12 text-left rounded-lg transition-all w-full focus:mx-0 
                                        focus:w-full focus:rounded-none focus:bg-white overflow-hidden 
                                        z-[7000] font-logo cursor-pointer hover:scale-[1.02] 
                                        text-white placeholder:text-blue-500 border-2 border-blue-500/40 bg-slate-200/30 dark:bg-blue-700/30 focus:text-black 
                                        backdrop-blur-lg px-4" />
                            <div className="">
                                {typedQuestion && ( 
                                    <button
                                        onClick={sendTypedQuestion}
                                        className="text-xl text-left text-blue-900 hover:text-blue-400  absolute  bottom-3 right-4 z-[8000]">
                                        <IoMdReturnLeft />

                                    </button>
                                )}
                            </div>
                        </div>



                    </div>
}

                    <div className="px-8 relative grid grid-cols-3 gap-x-2 pb-3">


{ minimized ?
                        <button
                            onClick={expand}
                            className="  col-span-2 shadow bg-slate-200/30 border-2 border-blue-500/40 dark:bg-blue-700/30 text-blue-500  cursor-pointer hover:scale-[1.02]  font-logo backdrop-blur-lg py-2 rounded-lg">
                            tell me more

                        </button>
: 
    <>
    { !giveMeMoreUsed ?
                        <button
                            onClick={giveMeMore}
                            disabled={moreLoading || simplifyLoading || typedLoading}
                            className="  shadow bg-slate-200/30 border-2 border-blue-500/40 dark:bg-blue-700/30 text-blue-500  cursor-pointer hover:scale-[1.02]  font-logo backdrop-blur-lg py-2  rounded-lg">
                            {moreLoading ? <IoMdPlanet className="inline-block text-blue-400 text-center animate-spin" /> : "say more"}

                        </button>
                        : 
                        <div></div>
    }
    { !simplifyUsed ?
                        <button
                            disabled={simplifyLoading || moreLoading || typedLoading}
                            onClick={simplify}
                            className="  shadow bg-slate-200/30 border-2 border-blue-500/40 dark:bg-blue-700/30 text-blue-500  cursor-pointer hover:scale-[1.02] font-logo  backdrop-blur-lg py-2  rounded-lg">
                            {simplifyLoading ? <IoMdPlanet className="inline-block text-blue-400 text-center animate-spin" /> : "simplify"}
                        </button>
                        : 
                        <div></div>
                    }
    </>

}
                        <AutoButton lastQuestion={lastQuestion} onClick={props.handleNextClick} auto={autoNext} />
                    </div>

 
                </>
            </motion.div>
        </div>
    )

}