import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { post } from 'aws-amplify/api';
import { signOut, getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { Link } from "react-router-dom";
import { UserQuestionAnswer } from "../models";
import {motion} from "framer-motion";
import { IoMdPlanet, IoIosArrowForward } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { starMap } from './score/ScoreGraph'
import {AnimatedNumber, positionToUnit, convertToForceWindows, calculatePositionAtTime, calculateSpeedAtTime, calculateAccelerationAtTime} from './score/ScoreGraph'
import {
  client,
} from "../api/questions";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";



function getEarliestTimestampObj(arr) {
  return arr.reduce((earliest, current) => {
    const earliestDate = new Date(earliest.createdAt);
    const currentDate = new Date(current.createdAt);
    return earliestDate < currentDate ? earliest : current;
  });
}



const groupToSubscriptionMap = {
  anyk_plus: "AnyQ+",
  anyk_plus_level1: "AnyQ+Curious",
  anyk_plus_level2: "AnyQ+Scholar",
  anyk_plus_level3: "AnyQ+Hero",
  admin: "Admin",
}

const apiName = "subscriptions";

export default function ProfilePage() {
  const [user, setUser] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [manageURL, setManageURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [totalIncorrectCount, setTotalIncorrectCount] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const [startedDelta, setStartedDelta] = useState("");
  const [points, setPoints] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [redirectWhenReady, setRedirectWhenReady] = React.useState(false);
  const [loadingDone, setLoadingDone] = React.useState(0);

  const fetchManageSubscriptionURL = async () => {
    setLoading(true)
    const url = await getManageSubscriptionURL();
    setManageURL(url);
  }

  useEffect(() => {

    async function fetchCorrect() {
      const totalCorrect = await client.scores.getUserTotalCorrectAnswers()
      setTotalCorrect(totalCorrect)
      setLoadingDone(i => i + 1)
    }

    async function fetchIncorrect() {
      const incorrect = await client.scores.getUserTotalIncorrectAnswers()
      setTotalIncorrectCount(incorrect)
      setLoadingDone(i => i + 1)

    }


    async function fetchUser() {
      let user;
      try {
        user = await getCurrentUser();
      } catch (e) {
        console.warn("not logged in");
        setLoadingDone(i => i + 1)
        return;
      }
      const attributes = await fetchUserAttributes();
      setUserEmail(attributes.email)
      setUser(user);
      setLoggedIn(true);

      const session = await fetchAuthSession({forceRefresh: true});
      const groups = session?.tokens?.idToken.payload?.["cognito:groups"] || []

      const groupsWithoutApple = groups.filter(group => group !== "us-west-2_SSBSvXDZi_SignInWithApple");
      if (groupsWithoutApple && groupsWithoutApple.length > 0) {
        const group = groupsWithoutApple[0]
        setSubscription(groupToSubscriptionMap[group]);
      }
      setLoadingDone(i => i + 1)

    }
    fetchUser();
    fetchCorrect()
    fetchIncorrect()
  }, []);

  useEffect(() => {
    if (redirectWhenReady && manageURL !== "") {
      window.location.href = manageURL;
    }
  }, [redirectWhenReady, manageURL]);

  // if subscribed, return the URL to the billing portal
  // if not subscribed, return the URL to the subscription page
  const getManageSubscriptionURL = async () => {
    let path = "";
    let params = {};
    let session;
    try {
      const user = await getCurrentUser()
      session = await fetchAuthSession()
    } catch (e) {
      return "/a/plans";
    }

    const groups = session?.tokens?.idToken?.payload?.["cognito:groups"]
    if (groups?.includes("anyk_plus")) {
      path = "/subscriptions/billing_portal";
    } else {
      path = "/subscriptions";
    }
    const Authorization = `Bearer ${session?.tokens?.idToken}`;
    params["headers"] = { Authorization };

    let response;
    try {
      response = await post({apiName, path, options: params}).response;
    } catch (e) {
      console.error(e);
    }

    const {url} = await response.body.json()
    return url;
  };

  const logOut = async () => {
    try {
      await signOut();
      await DataStore.clear();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    window.location.href = "/welcome";
  };

  const handleManageClick = async () => {
    window.gtag("event", "manage_subscription_button", {
      event_category: "engagement",
    });
    fetchManageSubscriptionURL();
    // setLoading(true)
    if (manageURL) {
      window.location.href = manageURL;
    } else {
      setRedirectWhenReady(true);
    }
  };

  const userEmailPresented = loggedIn ? userEmail : "Guest";
  const subscriptionPresented = subscription ? subscription : "Free";
  const manageOrUpgradeText = subscription ? "Manage" : "Upgrade";
  const accuracy = Math.round(totalCorrect / (totalCorrect + totalIncorrectCount) * 100);

  return (
    <div className="">
      <div className="mb-6 px-4 py-6  dark:text-slate-100">
       
        <div className=" pb-8 pt-4 rounded">
         <div className="flex justify-between items-end">
         <div className="text-slate-900 dark:text-white mx-6 text-xl  text-slate-900 dark:text-slate-200 font-bold font-logo">Profile</div>
         </div>
         { loadingDone < 3 &&
         <div
         initial={{ x: -20, opacity: 0 }}
         animate={{ x: 0, opacity: 1 }}
         transition={{
           type: "spring",
           ease: "easeOut",
           delay: 0.2,
           duration: 0.4,
         }}
         className="h-[145px] bg-slate-300/30 dark:bg-slate-800/70 mx-6 grid grid-cols-1 my-2 px-2 rounded-lg gap-y-3 gap-x-2 m-auto py-4"
       >
          <IoMdPlanet className="text-slate-700/80 dark:text-slate-400 text-4xl animate-spin m-auto" />
          </div>
         }
         { loadingDone >= 3 &&
       <div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              type: "spring",
              ease: "easeOut",
              delay: 0.2,
              duration: 0.4,
            }}
            className="h-[145px] bg-slate-300/30 dark:bg-slate-800/70 mx-6 grid grid-cols-1 my-2 px-2 rounded-lg gap-y-3 gap-x-2 m-auto py-4"
          >
            <div 
            className="
            
             rounded-xl 
            overflow-hidden  px-4">
                  <div className="text-slate-700/80 dark:text-slate-400 font-logo">Membership </div>
                  <div className="text-lg text-black dark:text-white">{subscriptionPresented} <a
                  onClick={handleManageClick}
                  className="cursor-pointer hover:text-blue-300 text-blue-500 mx-2 font-logo"
                >
                  {
                    loading 
                    ? 
                    <>
                    Loading...
                    <IoMdPlanet className="text-blue-500 animate-spin inline-block" />
                    </>
                    : manageOrUpgradeText}{" "}<IoIosArrowForward className="font-logo inline-block text-lg"/>
                </a></div>


            </div>
            {/* <div 
            className="
            
             rounded-xl 
            overflow-hidden  px-4">
                  <div className="text-slate-700/80 dark:text-slate-400 font-logo">Username</div>
                  <div className=" text-white">
                    <span className="text-lg relative">TRV</span>
                  </div>

            </div> */}
            <div 
            className="
            
             rounded-xl 
            overflow-hidden  px-4">
                  <div className="text-slate-700/80 dark:text-slate-400 font-logo">Email</div>
                  <div className="dark:text-white text-black">
                    <span className="text-lg relative">{userEmailPresented}</span>
                    </div>

            </div>
            {/* <div 
            className="
            
             rounded-xl 
            overflow-hidden  px-4">
                  <div className="text-slate-800 dark:text-slate-400 font-logo">Joined</div>
                  <div className=" text-lg text-white h-6">{startedDelta}</div>

            </div> */}
           
          </div>
          }
      </div>
        <div className=" pb-8 pt-4 rounded">
         <div className="flex justify-between items-end">
         <div className="text-slate-900 dark:text-white mx-6 text-xl  text-slate-900 dark:text-slate-200 font-bold font-logo">Stats</div>
         </div>
         { loadingDone < 3 &&
         <div
         initial={{ x: -20, opacity: 0 }}
         animate={{ x: 0, opacity: 1 }}
         transition={{
           type: "spring",
           ease: "easeOut",
           delay: 0.2,
           duration: 0.4,
         }}
         className="h-[130px] dark:bg-slate-800/70 bg-slate-300/30 cursor-pointer  text-lg rounded-lg p-4 px-6 m-3 mx-6 text-left"
       >
          <IoMdPlanet className="text-slate-700/80 dark:text-slate-400 text-4xl animate-spin m-auto" />
          </div>
         }
         {loadingDone >= 3 && !loggedIn && 

          
          <NavLink
          to="/auth" >
            <div className="h-[130px] dark:bg-slate-800/70 bg-slate-300/30 cursor-pointer  text-lg rounded-lg p-4 px-6 m-3 mx-6 text-left">
            An account is required to track your progress
             <div className="text-blue-500 mt-4 font-logo">
              Create an account <IoIosArrowForward className="inline-block text-lg"/>
             </div>
             </div>
          </NavLink>

         
         
         }
       {loadingDone >= 3 && loggedIn && <div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              type: "spring",
              ease: "easeOut",
              delay: 0.2,
              duration: 0.4,
            }}
            className="mx-4 grid grid-cols-2 py-2 px-2 rounded-lg gap-y-2 gap-x-2 m-auto"
          >
            <div 
            className="
            
            bg-slate-300/30 dark:bg-slate-800/70 rounded-xl 
            pt-3 overflow-hidden  px-2 pb-6 pl-8">
                  <div className="h-4 text-slate-900 dark:text-white font-logo">Correct</div>
                  <div className="font-logo h-5 text-2xl text-blue-500 font-bold">{totalCorrect}</div>

            </div>
            <div 
            className="
            
            bg-slate-300/30 dark:bg-slate-800/70 rounded-xl 
            pt-3 overflow-hidden  px-2 pb-6 pl-8">
                  <div className="h-4 text-slate-900 dark:text-white font-logo">Accuracy</div>
                  <div className="font-logo h-5 text-2xl text-emerald-500 font-bold">{accuracy}<span className="text-lg ml-[2px]">%</span></div>

            </div>
            {/* <div 
            className="
            
            bg-slate-300/30 dark:bg-slate-800/70 rounded-xl 
            pt-3 overflow-hidden  px-2 pb-6 pl-8">
                  <div className="h-4 text-slate-900 dark:text-white font-logo">Learn rate</div>
                  <div className="font-logo h-5 text-2xl text-amber-500 font-bold">
                    {2.3}
                    <span className="text-2xl left-[1px] bottom-[3px] relative"></span>
                    <span className="text-lg relative">tries</span>
                    </div>

            </div> */}
            {/* <div 
            className="
            
            bg-slate-300/30 dark:bg-slate-800/70 rounded-xl 
            pt-3 overflow-hidden  px-2 pb-6 pl-8">
                  <div className="h-4 text-slate-900 dark:text-white font-logo">Pace</div>
                  <div className="font-logo h-5 text-2xl text-violet-500 font-bold">
                    {12.1}
                    <span className="text-2xl left-[1px] relative">/</span>
                    <span className="text-lg relative">day</span>
                  </div>

            </div> */}
            
           
          </div>
}
          <div className="text-center pb-[80px] pt-[40px] font-logo">
              Have an issue? <a
                href="mailto:help@anyq.app"
                className="font-logo text-blue-500 hover:text-blue-300"
              >
                Contact us 
              </a>
            </div>
      </div>
      </div>
    </div>
  );
}

export const Component = ProfilePage;



{/* <div className="mb-6">
<div className="flex flex-col space-y-4">
  <div className="grid grid-cols-2 gap-4 p-2 h-16">
    {loggedIn && (
      <button
        onClick={logOut}
        className="active:scale-100 hover:scale-95 transition-all flex w-full h-full justify-between shadow items-center bg-slate-100 dark:bg-slate-800 dark:text-white py-1 px-1  rounded-lg "
      >
        <span className="grow px-3">Log out</span>
      </button>
    )}
    {!loggedIn && (
      <Link to="/auth">
        <button className="active:scale-100 hover:scale-95 flex w-full h-full justify-between shadow items-center bg-slate-100 dark:bg-slate-800 dark:text-white py-1 px-1  rounded-lg">
          <span className="grow px-3">Log in</span>
        </button>
      </Link>
    )}
  </div>
  
</div>
</div> */}


export const StatsPanel = (props) => {

  const {visible} = props
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [manageURL, setManageURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [totalIncorrectCount, setTotalIncorrectCount] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const [startedDelta, setStartedDelta] = useState("");
  const [points, setPoints] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [redirectWhenReady, setRedirectWhenReady] = React.useState(false);
  const [loadingDone, setLoadingDone] = React.useState(0);

  const fetchManageSubscriptionURL = async () => {
    setLoading(true)
    const url = await getManageSubscriptionURL();
    setManageURL(url);
  }

  useEffect(() => {

    async function fetchCorrect() {
      const totalCorrect = await client.scores.getUserTotalCorrectAnswers()
      setTotalCorrect(totalCorrect)
      setLoadingDone(i => i + 1)
    }

    async function fetchIncorrect() {
      const incorrect = await client.scores.getUserTotalIncorrectAnswers()
      setTotalIncorrectCount(incorrect)
      setLoadingDone(i => i + 1)

    }


    async function fetchUser() {
      let user;
      try {
        user = await getCurrentUser();
      } catch (e) {
        console.warn("not logged in");
        setLoadingDone(i => i + 1)
        return;
      }
      const attributes = await fetchUserAttributes();
      setUserEmail(attributes.email)
      setUser(user);
      setLoggedIn(true);

      const session = await fetchAuthSession({forceRefresh: true});
      const groups = session?.tokens?.idToken.payload?.["cognito:groups"] || []

      const groupsWithoutApple = groups.filter(group => group !== "us-west-2_SSBSvXDZi_SignInWithApple");
      if (groupsWithoutApple && groupsWithoutApple.length > 0) {
        const group = groupsWithoutApple[0]
        setSubscription(groupToSubscriptionMap[group]);
      }
      setLoadingDone(i => i + 1)

    }
    fetchUser();
    fetchCorrect()
    fetchIncorrect()
    fetchLeaderboard("root")
    fetchPopulationAverage("root")
  }, []);

  useEffect(() => {
    fetchLeaderboard("root")
  }, [visible])

  useEffect(() => {
    if (redirectWhenReady && manageURL !== "") {
      window.location.href = manageURL;
    }
  }, [redirectWhenReady, manageURL]);

  // if subscribed, return the URL to the billing portal
  // if not subscribed, return the URL to the subscription page
  const getManageSubscriptionURL = async () => {
    let path = "";
    let params = {};
    let session;
    try {
      const user = await getCurrentUser()
      session = await fetchAuthSession()
    } catch (e) {
      return "/a/plans";
    }

    const groups = session?.tokens?.idToken?.payload?.["cognito:groups"]
    if (groups?.includes("anyk_plus")) {
      path = "/subscriptions/billing_portal";
    } else {
      path = "/subscriptions";
    }
    const Authorization = `Bearer ${session?.tokens?.idToken}`;
    params["headers"] = { Authorization };

    let response;
    try {
      response = await post({apiName, path, options: params}).response;
    } catch (e) {
      console.error(e);
    }

    const {url} = await response.body.json()
    return url;
  };

  const logOut = async () => {
    try {
      await signOut();
      await DataStore.clear();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    window.location.href = "/welcome";
  };

  const handleManageClick = async () => {
    window.gtag("event", "manage_subscription_button", {
      event_category: "engagement",
    });
    fetchManageSubscriptionURL();
    // setLoading(true)
    if (manageURL) {
      window.location.href = manageURL;
    } else {
      setRedirectWhenReady(true);
    }
  };

  const userEmailPresented = loggedIn ? userEmail : "Guest";
  const subscriptionPresented = subscription ? subscription : "Free";
  const manageOrUpgradeText = subscription ? "Manage" : "Upgrade";
  const accuracy = Math.round(totalCorrect / (totalCorrect + totalIncorrectCount) * 100);


  const [myLeaderBoardPlace, setMyLeaderBoardPlace] = useState(0)
  const [populationN, setPopulationN] = useState(0)
  const [myELO, setMyELO] = useState(0)
  const fetchLeaderboard = async (topic) => {
    if (topic === "loading") return
    const leaderBoard = await client.topics.leaderboard(topic)
    setMyLeaderBoardPlace(leaderBoard.myPlace)
    setPopulationN(leaderBoard.length)
    if (leaderBoard.myELO) setMyELO(leaderBoard.myELO.score)
    
  }

  const [populationAverageScore, setPopulationAverageScore] = useState(0)
  const fetchPopulationAverage = async (topic) => {
    if (topic === "loading") return
    const average = await client.topics.average(topic)
    setPopulationAverageScore(average.score)
  }

  const eloToLikelihood = (elo) => {
    let likelihood = 1 / (1 + 10 ** ( (1000-elo) / 2100))
    return likelihood
  }

  const likelihood = (eloToLikelihood(populationAverageScore) * 100).toFixed(0)
  const myLikelihood = myELO ? (eloToLikelihood(myELO)*100).toFixed(0) : 25
  const eloDiff = myLikelihood ? myLikelihood - likelihood : 0

  const [currentPosition, setPosition] = useState(0)
  const [currentSpeed, setSpeed] = useState(0)
  const [currentAcceleration, setAcceleration] = useState(0)


  const [scores, setScores] = useState([])
  const [questionsForReview, setQuestionsForReview] = useState([])

  const scoresAsForces = convertToForceWindows(scores)

  useEffect(() => {
   
    async function fetchDailyCount() {
      const pastUserFinishes = await client.plays.pastDailiesSummaries()


      const pastUserFinishesByStream = pastUserFinishes?.reduce((acc, curr) => {
        const stream = curr.questionsetOfOut.streamMadeIn
        if (!acc[stream.name]) {
          acc[stream.name] = []
        }
        acc[stream.name].push(curr)
        return acc
      }, {}) || {}


      const hourlyPoints = {};
      const hourlyStars = {};

      pastUserFinishes?.map((finish) => {
        // finish.start_time is a string like "2021-09-01T00:00:00.000Z"
        const startTime = new Date(finish.start_time);
        const localHourString = startTime.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          timeZoneName: 'short'
        }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"

        if (!hourlyPoints[localHourString]) {
          hourlyPoints[localHourString] = 0;
          hourlyStars[localHourString] = 0;
        }

        hourlyStars[localHourString] += starMap(finish.score);
        hourlyPoints[localHourString] += finish.score;
      });

      // let dailyStars = hourlyStars;
      // let dailyPoints = hourlyPoints;



      const hourlyStarsSorted = Object.keys(hourlyStars).sort((a, b) => {
        const aDate = new Date(a.replace(' GMT−7', ':00:00-07:00'))
        const bDate = new Date(b.replace(' GMT−7', ':00:00-07:00'))
        const diff = bDate - aDate
        return diff
      })

      const hourlyStarsSorted2 = hourlyStarsSorted.map((date) => {
        return { date, score: hourlyStars[date] }
      })

      // add an entry for the current hour if it doesn't exist
      const now = new Date();
      const currentHourString = now.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        timeZoneName: 'short'
      }).replace(' ', 'T'); // Format as "yyyy-mm-ddTHH"
      if (!hourlyStarsSorted2.find((entry) => entry.date === currentHourString)) {
        hourlyStarsSorted2.unshift({ date: currentHourString, score: 0 });
      }
      setScores(hourlyStarsSorted2.reverse())

    }

    const fetchQuestionToReview = async () => {
      const questions = await client.questions.questionsToReview()
      setQuestionsForReview(questions)
    }
    fetchDailyCount()
    fetchQuestionToReview()
  }, []);

  //update the position every second
  useEffect(() => {
    const calculateMetrics = () => {
      const timeInSeconds = (new Date().getTime() / 1000).toFixed(0);
      const currentPosition = calculatePositionAtTime(scoresAsForces, timeInSeconds);
      const currentSpeed = calculateSpeedAtTime(scoresAsForces, timeInSeconds);
      const { activeScore, currentAcceleration } = calculateAccelerationAtTime(scoresAsForces, timeInSeconds);
      setPosition(currentPosition);
      setSpeed(currentSpeed);
      setAcceleration(activeScore)
    }

    calculateMetrics()
    const intervalId = setInterval(() => {
      calculateMetrics()
    }, 10000)
    return () => clearInterval(intervalId)
  }, [scoresAsForces])

  const handleSigninUpgradeClick = () => {
    if (!loggedIn) {
     navigate("/auth")
    } else {
      navigate("/a/plans")
    }
  }


  const {positionAdjustedForUnit, unit, precision} = positionToUnit(currentPosition)




  return (
    <div className="">
      <div className="px-4 dark:text-slate-100">
        <div className="rounded">
         <div className="flex justify-between items-end">
          {/* <div className="text-slate-900 dark:text-white mx-6 text-xl  text-slate-900 dark:text-slate-200 font-bold font-logo">
            Welcome back, Travis
          </div> */}
         </div>
         {/* { loadingDone < 3 &&
         <div
         initial={{ x: -20, opacity: 0 }}
         animate={{ x: 0, opacity: 1 }}
         transition={{
           type: "spring",
           ease: "easeOut",
           delay: 0.2,
           duration: 0.4,
         }}
         className="h-[130px] dark:bg-slate-800/70 bg-slate-300/30 cursor-pointer  text-lg rounded-lg p-4 px-6 m-3 mx-6 text-left"
       >
          <IoMdPlanet className="text-slate-700/80 dark:text-slate-400 text-4xl animate-spin m-auto" />
          </div>
         } */}
         
         <div className="bg-slate-100 dark:bg-black/20 rounded-lg">
          <div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              type: "spring",
              ease: "easeOut",
              delay: 0.2,
              duration: 0.4,
            }}
            className="mx-4 grid grid-cols-3 py-2 px-2 rounded-lg gap-y-2 gap-x-2 m-auto"
          >
            {/* <div 
            className="
            
            bg-slate-300/30 dark:bg-black/20 rounded-xl 
            pt-3 overflow-hidden  px-2 pb-6 pl-4">
                  <div className="h-4 text-slate-900 dark:text-white font-logo">Correct</div>
                  <div className="font-logo h-5 text-2xl text-blue-500 font-bold">{totalCorrect}</div>

            </div> */}
            { subscription ?
            (
            <div 
            id="rating-box"
            className="
            
             rounded-xl 
            overflow-hidden  px-2 pl-4">
                <>
                  <div className="font-logo text-2xl text-blue-500 font-bold flex items-end gap-2">
                    {myLikelihood}
                    <div className={ (eloDiff > 0 ? "text-emerald-500" : "text-fuchsia-500" ) +
                              (eloDiff === 0 ? " invisible " : "" ) + 
                              " flex text-base font-bold  relative bottom-[3px]"}>
                                {Math.abs(eloDiff)}
                                <div className={(eloDiff > 0 ? "top-[4px]" : "top-[6px]" ) + " absolute right-[-17px] "}>
                                  {(eloDiff) > 0 ? <IoMdArrowDropup/> : <IoMdArrowDropdown/>}
                                </div>
                              </div>
                   {/* <span className="text-lg ml-[2px]">%</span> */}
                  </div>
                                 <div className="mt-[-5px] text-slate-900 dark:text-white font-logo">Rating</div>
              </>
                </div>
              ) : (
                <div 
                id="rating-box"
            className="
            
             rounded-xl 
            overflow-hidden  px-2 pl-4">
                <>
                  <div className="font-logo text-2xl text-blue-500 font-bold flex items-end gap-2">
                    {myLikelihood}
                  </div>
                                 <div className="mt-[-5px] text-slate-900 dark:text-white font-logo">Rating</div>
              </>
                </div>
              )

                                }
            {/* <div 
            className="
            
             rounded-xl 
            pt-2 overflow-hidden  px-2 pb-3 pl-4">
                  <div className="h-4 text-slate-900 dark:text-white font-logo">Delta</div>
                  <div className="font-logo h-5 text-2xl text-emerald-500 font-bold">{eloDiff}<span className="text-lg ml-[2px]">%</span></div>

            </div> */}
            <div className="flex justify-center items-center">
            <img
          className="h-[36px] w-[36px] my-1"
          src={process.env.PUBLIC_URL + "/space_logo_transparent.png"}
        ></img>
        </div>
        { !loggedIn && (
                  <button
                  onClick={() => navigate("/auth")}
                  className=" 
            px-1 text-center active:bg-blue-500  bg-blue-100 dark:bg-blue-900/40 rounded-full  my-1 text-blue-500 font-logo">
                    
                  Sign in
                  </button>
        )}

            {/* <div 
            className="
            
             rounded-xl 
            pt-3 overflow-hidden  px-2 pb-6 pl-4">
                  <div className="h-4 text-slate-900 dark:text-white font-logo">Accuracy</div>
                  <div className="font-logo h-5 text-2xl text-emerald-500 font-bold">{accuracy}<span className="text-lg ml-[2px]">%</span></div>

            </div> */}
            
           
          </div>
        </div>
          
      </div>
      </div>
    </div>
  );
}