// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Question, UserQuestionAnswer, UserSurvey, Fact, UserFactInteraction, WebhookEvents, Search, Tag, Tagged, Stream, Topic, User, Ranked, Completed, Viewed, Opened, Requested, Following, Questionnode, Rule, Passed, Failed, Run, Spawned, TimedSession, Questionset, Answer, Play, Engaged, Made, Skipped, Answered, Asked, Contains, Sub, Started, To, Gave, Of, Selected, Includes, In, During, Score, TopicQuestions } = initSchema(schema);

export {
  Question,
  UserQuestionAnswer,
  UserSurvey,
  Fact,
  UserFactInteraction,
  WebhookEvents,
  Search,
  Tag,
  Tagged,
  Stream,
  Topic,
  User,
  Ranked,
  Completed,
  Viewed,
  Opened,
  Requested,
  Following,
  Questionnode,
  Rule,
  Passed,
  Failed,
  Run,
  Spawned,
  TimedSession,
  Questionset,
  Answer,
  Play,
  Engaged,
  Made,
  Skipped,
  Answered,
  Asked,
  Contains,
  Sub,
  Started,
  To,
  Gave,
  Of,
  Selected,
  Includes,
  In,
  During,
  Score,
  TopicQuestions
};