import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../api/questions";

import { Link } from "react-router-dom";

import TextWriter from './TextWriter'
import { motion, AnimatePresence } from "framer-motion";
import { MiniCounter } from './Questions/RoundCompleteComponent'
import { IoIosStar, IoIosClose, IoIosReturnRight, IoMdPlanet } from "react-icons/io";
import { process } from '../api/lm'
import { useCheckUserGroup } from "../hooks/auth";


import { ScoreGraph, LineChart, starMap, AnimatedNumber } from './score'
import { CircleLoadingIndicator } from './CircleLoadingIndicator'
import { TbTargetArrow } from "react-icons/tb";
import { MdPeopleAlt } from "react-icons/md";
import { FaRegThumbsUp } from "react-icons/fa6";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { FaDiceD6 } from "react-icons/fa";
import { FaHockeyPuck, FaMedal } from "react-icons/fa";
import { FaPercent } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";
import { MdSignalCellularAlt } from "react-icons/md";
import { MdSignalCellular4Bar } from "react-icons/md";
import { RiBarChart2Fill } from "react-icons/ri";
import { HiMiniChartBarSquare } from "react-icons/hi2";
import { TbAntennaBars1, TbAntennaBars2, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from "react-icons/tb";
import { LuBadgePercent } from "react-icons/lu";





const eloToLikelihood = (elo) => {
  let likelihood = 1 / (1 + 10 ** ( (1000-elo) / 2100))
  return (likelihood * 100).toFixed(0)
}







const questionsNeededToStart = 4

function getOrdinalSuffix(number) {
  const j = number % 10,
        k = number % 100;
  
  if (j === 1 && k !== 11) {
      return  "st";
  }
  if (j === 2 && k !== 12) {
      return  "nd";
  }
  if (j === 3 && k !== 13) {
      return  "rd";
  }
  return  "th";
}

const correct = [
  12,
  24,
  5,
  3,
  9,
  7,
  12,
  9,
  1,
  1,
  0,
  7]
const topTopics = [
  {
    name: "director",
    topic: "movies",
    priority: 1,
    correct: 12
  },
  {
    name: "engineer",
    topic: "engineering",
    priority: 2,
    correct: 24
  },
  {
    name: "musician",
    topic: "music",
    priority: 3,
    correct: 5
  },
  {
    name: "fashionista",
    topic: "fashion",
    correct: 3
  },
  {
    name: "scientist",
    topic: "science",
    correct: 9
  },
  {
    name: "author",
    topic: "books",
    correct: 7
  },
  {
    name: "cartographer",
    topic: "geography",
    correct: 12
  },
  {
    name: "natural",
    topic: "nature",
    correct: 9
  },
  {
    name: "chef",
    topic: "food",
    correct: 1
  },
  {
    name: "coach",
    topic: "sports",
    correct: 1
  },
  {
    name: "historian",
    topic: "history",
    correct: 0
  },
  {
    name: "doctor",
    topic: "medicine",
    correct: 7
  },
];

const TopicPage = () => {
  const innerTo = useNavigate();
  const [eloMode, setEloMode] = useState(0);

  const to = (dest, tag) => {
    window.gtag("event", tag, {
      event_category: "engagement",
    });
    return innerTo(dest);
  };

  const [selected, setSelected] = useState([]);
  const [opened, setOpened] = useState("");
  const [search, setSearch] = useState("");

  const addSelected = (topic) => {
    setSelected([...selected, topic]);
  };

  const toggleSelected = (topic) => {
    setOpened(topic)
    if (selected.includes(topic)) {
      setSelected(selected.filter((t) => t !== topic));
    } else {
      addSelected(topic);
    }
  };

  const isSelected = (topic) => {
    return selected.includes(topic);
  };

  const handleKeyDown = async (event) => {
    if (event.key !== "Enter") {
      return;
    }

    submit();
  };

  const submit = () => {
    console.log(search);
  };

  const start = () => {
    client.markTopicsSelected(selected);
    // pass selected as query param
    const params = new URLSearchParams();
    selected.forEach((topic) => {
      params.append("topic", topic);
    });
    return to("/a/shuffle/medium?" + params.toString(), "start_topic");
  };

  const activeIndexes = new Set([3, 1, 2])

  const largerIndexes = new Set([0, 1])

  const scores = [
    82,
    92,
    51,
    32,
    95,
    72,
    86,
    97,
    75,
    55,
    79,
    73]



  const power = [
    "12k",
    "24k",
    "500",
    "3k",
    "9k",
    "14k",
    "7k",
    "25k",
    "1k",
    "0",
    "4k",
    "3k"

  ]

  const mode = eloMode == 0 ? "elo" : eloMode == 1 ? "green" : "power"
  // const mode = "power"

  // if (mode == "green") topTopics.sort((a, b) => a.correct - b.correct)

  const title = () => {
    if (mode == "green") return (
      <span
        onClick={() => setEloMode((eloMode + 1) % 3)}

        className="text-green-500 font-logo text-lg font-bold">
        explore
      </span>
    )

    if (mode == "elo") return (
      <span
        onClick={() => setEloMode((eloMode + 1) % 3)}

        className="text-purple-500 font-logo text-lg font-bold">
        elo
      </span>
    )

    if (mode == "power") return (
      <span
        onClick={() => setEloMode((eloMode + 1) % 3)}

        className="text-[#F5C242] font-logo text-lg font-bold">
        points
      </span>
    )
  }

  const actionButton = () => {
    if (mode == "green") return (
      <button
        onClick={() => setEloMode((eloMode + 1) % 3)}

        className="rounded-lg py-2 px-20 bg-green-500/80 text-white font-logo text-xl active:scale-100 hover:scale-95 transition-all h-14rounded-lg mb-4">
        <TextWriter>start</TextWriter>
      </button>
    )

    if (mode == "elo") return (
      <button
        onClick={() => setEloMode((eloMode + 1) % 3)}

        className="rounded-lg py-2 px-20 bg-purple-500/80 text-white font-logo text-xl active:scale-100 hover:scale-95 transition-all h-14rounded-lg mb-4">
        <TextWriter>start</TextWriter>
      </button>
    )

    if (mode == "power") return (
      <button
        onClick={() => setEloMode((eloMode + 1) % 3)}

        className="rounded-lg py-2 px-20 bg-[#F5C242]/80 text-white font-logo text-xl active:scale-100 hover:scale-95 transition-all h-14rounded-lg mb-4">
        <TextWriter>start</TextWriter>
      </button>
    )
  }
  return (
    <>
      <div className="h-[80vh] bg-white dark:bg-slate-900 m-2 pt-1 pb-4 px-4 rounded flex flex-col justify-between">
        <div className="">
          <h1 className="py-4 text-2xl dark:text-slate-100 font-logo border-blue-500 border-b-2 pb-1 mb-4">
            <div className="relative">
              topics
              <div className="absolute -top-2 left-[80px]">
                {title()}

              </div>
            </div>
          </h1>




          {/* <input 
                        className="w-full border-2 dark:text-white border-blue-500 rounded-lg p-2 mb-4 dark:bg-slate-900" 
                        type="text" 
                        placeholder="search"
                        onChange={(e) => setSearch(e.target.value)} 
                        onKeyDown={handleKeyDown}/> */}
        </div>
        <div className="flex flex-wrap justify-between relative">
          {topTopics.map((topic, index) => {

            let color, scoreColor, score, scoreSize
            let width = " w-[48%] "
            let height = " p-3 "
            let titleSize = "text-md"
            if (mode == "elo") {

              if (!activeIndexes.has(index)) return
              color = activeIndexes.has(index) ? " border-2 border-purple-500 text-purple-500 " : " border-2 border-blue-500/30 text-gray-700 "
              scoreColor = activeIndexes.has(index) ? " text-purple-500 " : " text-gray-500 "
              // const color = ""
              width = activeIndexes.has(index) ? " w-[100p%] " : " w-[48%] "
              height = " p-3 "
              score = scores[index]
              scoreSize = "text-lg"
              titleSize = "text-lg"

            }
            if (mode == "green") {
              color = " border-2 border-green-500/50 "
              // color = activeIndexes.has(index) ? " border-2 border-purple-500 text-purple-500 " : " border-2 border-blue-500/30 text-gray-700 "
              scoreColor = " text-green-500 "
              // const color = ""
              score = correct[index]
            }

            if (mode == "power") {
              color = " border-2 border-[#F5C242]/50 "
              // color = activeIndexes.has(index) ? " border-2 border-purple-500 text-purple-500 " : " border-2 border-blue-500/30 text-gray-700 "
              scoreColor = " text-[#F5C242] "
              // const color = ""
              score = power[index]
            }
            // let scoreColor = "  "  .
            // if (score > 7) scoreColor = " text-purple-500 "

            // const larger = largerIndexes.has(index) ? " text-purple-500 font-bold " : " "
            const larger = ""
            return (
              <div
                key={index}
                onClick={() => toggleSelected(topic.name)}
                className={"" +
                  // (isSelected(topic.name)
                  // ? 
                  // "dark:bg-blue-500 bg-blue-500 text-slate-200"
                  // : 
                  " hover:text-slate-200 dark:bg-slate-800/50" +
                  // ) +
                  color +
                  width +
                  height +
                  titleSize +
                  " text-lg overflow-hidden text-center cursor-pointer hover:scale-95 active:scale-100 hover:bg-blue-500 font-logo w-[48%] rounded-lg mb-4  dark:text-slate-100"
                }
              >
                <div className="relative flex items-center">
                  {topic.topic}
                  <div className="right-0 px-2 absolute">
                    <span className={`font-bold  ${scoreSize} ${scoreColor} ${larger}`}>{score}</span>
                    {mode == "elso" && <span className={`px-2 transition-all duration-500 font-bold ${scoreSize} text-green-500/80 ${larger}`}>+5</span>}
                  </div>
                </div>
                {opened == topic.name && (

                  <div className="py-3">
                    <div className="flex justify-around text-gray-300">
                      <div>March 5</div>
                      <div className="text-green-500 text-lg">+25</div>
                    </div>
                    <div className="flex justify-around text-gray-300">
                      <div>March 4</div>
                      <div className="text-red-500 text-lg">-13</div>
                    </div>
                    <div className="flex justify-around text-gray-300">
                      <div>March 3</div>
                      <div className="text-green-500 text-lg">+12</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div></div>
        <div className="mt-4 flex justify-around">
          {/* <button className="hover:scale-95 transition-all w-full border-2 border-blue-500 rounded-lg p-2 mb-4 dark:bg-slate-800/50 text-slate-200">
                        random
                    </button> */}
          <button
            onClick={() => setEloMode((eloMode + 1) % 3)}

            className="rounded-lg py-2 px-4 border-2 border-blue-500 text-blue-300 font-logo text-xl active:scale-100 hover:scale-95 transition-all h-14rounded-lg mb-4">
            switch
          </button>
          {actionButton()}

        </div>
      </div>
    </>
  );
};



export const GreenTopicTile = (props) => {

  let { score, topic, last } = props

  let color, scoreColor, scoreSize
  let height = " p-4 "
  let titleSize = "text-md"

  scoreSize = "text-lg"
  titleSize = "text-lg"
  const hover = " bg-blue-600/40 backdrop-blur-sm hover:bg-blue-600/60 cursor-pointer "

  color = " text-blue-100 "
  scoreColor = " text-blue-500 "

  const larger = ""
  const grow = last ? " flex-grow-0 " : ""

  const params = new URLSearchParams();
  // selected.forEach((topic) => {
  params.append("topic", topic.name);
  // });
  const url = "/a/shuffle/medium?" + params.toString()


  return (
    <Link
      to={url}
      onClick={() => { }}
      className={"" +
        // (isSelected(topic.name)
        // ? 
        // "dark:bg-blue-500 bg-blue-500 text-slate-200"
        // : 
        " " +
        // ) +
        color +
        hover +
        // width +
        height +
        titleSize +
        grow +
        " transition-all flex-auto overflow-hidden text-center cursor-pointer hover:bg-blue-500 font-logo rounded-lg"
      }
    >
      <div className="text-sm sm:text-lg relative flex items-center justify-around ">
        <TextWriter>{topic.name.toLowerCase()}</TextWriter>

      </div>

    </Link>
  );
}


export const EloTopicTile = (props) => {
  const navigate = useNavigate();

  let {
    topic,
    onClick,
    index,
    scores,
    selectedIndex,
    pastUserFinishes,
    pinned,
    building,
    streamId,
    themeColor,
    height,
    hash,
    border,
    isAdmin
  } = props


  const [dailies, setDailies] = useState([])
  const [playCount, setPlayCount] = useState("-")
  const [starTotal, setStarTotal] = useState(0)
  const [firstUnplayedDaily, setFirstUnplayedDaily] = useState(undefined)

  const [priorityOver, setPriorityOver] = useState(false)


  const playedCount = dailies.filter(daily => daily.userPlays !== undefined).length
  const dailyCount = dailies.length

  const opened = selectedIndex === index


  const [leaderBoard, setLeaderBoard] = useState([])
  const fetchLeaderboard = async (topic) => {
    if (topic === "loading") return
    const leaderBoard = await client.streams.myLeaderboardPlace(topic)
    console.log(leaderBoard)
  }

  // const color = pinned ? " border-[1px] border-purple-500/90 " : "  "
 

  const handleAnimationComplete = (index) => {
    if (selectedIndex !== index) {
      document.getElementById(`item-${index}-${hash}`).style.zIndex = 0;
    }
  };

  const handleAnimationStart = (index) => {
    document.getElementById(`item-${index}-${hash}`).style.zIndex = 100;
  };

  const fetchPastPlays = async (topic) => {
    if (topic === "loading") return
    let recentDailies = []
    try {
      recentDailies = await client.plays.getPastTwoWeeks(topic)

    } catch (error) {
      if (error.errors && error.errors[0].message === "Cannot return null for non-nullable type: 'ID' within parent 'Stream' (/getStream/_id)") {
        setTimeout(() => {
          fetchPastPlays(topic)
        }, 8000)

      } else {
        console.error(error)
        return
      }
    }

    const pastFinishesByQuestionSet = pastUserFinishes?.reduce((acc, curr) => {
      if (!acc[curr.questionsetOfOut.id]) {
        acc[curr.questionsetOfOut.id] = []
      }
      acc[curr.questionsetOfOut.id].push(curr)
      return acc
    }, {}) || {}

    // attach the user plays to the dailies

    // now that we have the 2-week count, filter to the 3 most recent
    recentDailies.sort((a, b) => new Date(b.published_date) - new Date(a.published_date))

    let playCount = 0
    let starTotalTmp = 0
    recentDailies.forEach((daily) => {
      daily.userPlays = pastFinishesByQuestionSet[daily.id]
      if (!(daily.userPlays === undefined || daily.userPlays.length === 0)) {
        playCount += 1
        const maxScore = daily.userPlays.reduce((acc, curr) => {
          return acc.score > curr.score ? acc : curr
        })
        starTotalTmp += starMap(maxScore.score)
      }
      // playCount += daily.userPlays?.length ?? 0
    })


    // const recentDailiesFiltered = recentDailies.filter(daily => Boolean(daily.published_date))
    const firstUnplayedDaily = recentDailies.filter(daily => daily.userPlays === undefined).sort((a, b) => new Date(a.published_date) - new Date(b.published_date))[0]

    setFirstUnplayedDaily(firstUnplayedDaily)
    setDailies(recentDailies)
    setPlayCount(playCount)
    setStarTotal(starTotalTmp)

    const litSegments = firstUnplayedDaily?.questionnodeContainssOut?.length || 0

    if (litSegments < questionsNeededToStart) {
      setTimeout(() => {
        fetchPastPlays(topic)
      }, 3000)
    }

  }
  useEffect(() => {
    if (!topic) return
    fetchLeaderboard(topic)
    fetchPastPlays(topic);
  }, [pastUserFinishes, topic]);

  const genNewLevel = async () => {
    await client.streams.gen(topic)
  }


  // TODO: turn these adjustments on for desktop version
  // const percentWidth = pinned ? "calc(50% - 4px)" : "calc(25% - 8px)"
  // const left = pinned ? `calc(${(index % 2) * 50}% + ${index % 2 * 4}px)` : `calc(${(index - 2) * 25}% + ${(index - 2) * 2}px)`
  // const top = pinned ? `calc(${Math.floor(index / 2) * 50}% + ${Math.floor(index / 2) * 4}px)` : `calc(${Math.floor((index+2) / 4) * 50}% + ${Math.floor((index+2) / 4) * 4}px)`
  const percentWidth = "calc(50% - 4px)"
  const left = `calc(${(index % 2) * 50}% + ${index % 2 * 4}px)`
  const top = `calc(${Math.floor(index / 2) * 50}% + ${Math.floor(index / 2) * 4}px)`

  let textSize = topic.length > 12 ? " text-base sm:text-lg" : " text-xl "

  // find the longest word in the topic
  const longestWord = topic.split(" ").reduce((acc, curr) => {
    return acc.length > curr.length ? acc : curr
  })

  if (longestWord.length > 10) textSize = " text-xs sm:text-lg"

  const four = [0, 1, 2, 3]

  const starAverage = starTotal / playCount


  // dailies.map((questionSet, index) => {
  //   const maxScore = questionSet.userPlays?.reduce((acc, curr) => {
  //     return acc.score > curr.score ? acc : curr
  //   })
  //   const date = new Date(questionSet.published_date);
  //   const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
  //   return (



  const minimumDaily = dailies.reduce((acc, curr) => {
    return new Date(acc.published_date) < new Date(curr.published_date) ? acc : curr
  }, [])


  const earliestDate = new Date(minimumDaily.published_date)

  const scoresSorted = scores ? scores.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)) : []
  const scoresMapped = scoresSorted.map((score) => {
    return { score: starMap(score.score) }
  })

  // const scores = [
  //   { score: 5 },
  //   { score: 10 },
  //   { score: 9 },
  //   { score: 13 },
  //   { score: 15 },
  //   { score: 14 },
  //   { score: 10 },
  //   { score: 9 },
  //   { score: 17 },
  //   { score: 16 },
  //   { score: 18 },
  //   { score: 20 }
  // ]

  const averageScore = scoresMapped ? scoresMapped.reduce((acc, curr) => {
    return acc + curr.score
  }, 0) / scoresMapped.length : 0


  const starsToColor = (stars) => {
    return "text-purple-300/90"
    if (stars == 4) {
      return "text-purple-300/90"
    }
    if (stars == 3) {
      return "text-purple-300/80"
    }
    if (stars == 2) {
      return "text-purple-300/70"
    }
    if (stars == 1) {
      return "text-purple-300/60"
    }
    return "text-purple-300/50"
  }

  const handleNextClick = async () => {
    window.gtag("event", "start_round", {
      event_category: "engagement",
    });
    if (firstUnplayedDaily) {
      navigate(`/a/daily/${firstUnplayedDaily.id}`);
    }
  };


  const litSegments = firstUnplayedDaily?.questionnodeContainssOut?.length || 0

  // bg-slate-800/50

  
  const bg = opened 
    ? 
      `  bg-${themeColor}-950/10  dark:bg-${themeColor}-500/10 ` 
    : 
      ` bg-${themeColor}-300/30 hover:bg-${themeColor}-500/40  shadow-md
      dark:bg-${themeColor}-800/70  dark:hover:bg-${themeColor}-500/30 `

  const borderStyle = border 
  ? opened ? ` border-${themeColor}-500 border-[1px] ` : ` border-${themeColor}-500 border-2 `
  : ''



  return (
    <motion.div
      id={`item-${index}-${hash}`}
      // onClick={onClick}
      onClick={() => {
        if (isAdmin) {
          onClick()
        } else {
          handleNextClick()
        }
      }}
      initial={{
        z: 100,
        width: selectedIndex === index ? 'calc(100% + 10px)' : percentWidth,
        height: selectedIndex === index ? 'calc(100% + 30px)' : "calc(50% - 4px)",
        left: selectedIndex === index ? '-5px' : left,
        top: selectedIndex === index ? '-5px' : top,
      }}
      animate={{
        z: 100,
        width: selectedIndex === index ? 'calc(100% + 10px)' : percentWidth,
        height: selectedIndex === index ? 'calc(100% + 30px)' : 'calc(50% - 4px)',
        left: selectedIndex === index ? '-5px' : left,
        top: selectedIndex === index ? '-5px' : top,
      }}
      onAnimationStart={() => handleAnimationStart(index)}
      onAnimationComplete={() => handleAnimationComplete(index)}
      transition={{
        duration: 0.15,
        ease: 'easeOut',
      }}
      className={"" +
        "  backdrop-blur-xl dark:backdrop-blur-lg" +
        bg +
        borderStyle
        + " leading-7 " +
        (pinned ? " cols-span-2 " : " ") +
        // (priority && !priorityOver ? " border-[1px] border-purple-500 " : " ")+
        " absolute flex flex-col shrink-0 overflow-hidden cursor-pointer transition-border font-logo rounded-lg  dark:text-slate-100"
      }
    >

      <div className="flex flex-col justify-center">
        <div 
        style={{paddingTop: height + "px"}}
        className={"relative " + `${opened ? "flex transition-all items-center ease-out duration-[150ms] pt-3 pl-[30px]" : `flex transition-all items-center ease-out duration-[150ms] pl-[25px]`}`}>
          <div className={"h-8 " + textSize + `${opened ? "transition-all ease-out duration-[150ms] py-2 leading-4 max-w-[220px] sm:max-w-[240px]" : ("transition-all ease-out duration-[150ms] py-0 max-w-[140px] sm:max-w-[240px] leading-4 ")}`}>
            {topic.toLowerCase()}
          </div>
          {opened && (
            <IoIosClose className={`text-gray-700 dark:text-gray-400 hover:text-blue-500 absolute right-0 top-[6px] right-[6px] text-2xl`} />
          )}
          {!opened && dailies.length > 0 && firstUnplayedDaily && litSegments >= questionsNeededToStart && (
            <>
              {/* <div className="absolute right-3 top-2 text-center leading-[32px] text-purple-300  w-8 h-8 rounded-full font-logo text-xl">
                {(averageScore).toFixed(0)}
                </div> */}
              {/* <div className=" top-2 font-bold text-sm absolute right-3 grid grid-cols-2">
                {four.map((i) => {
                  if (starAverage.toFixed(0) == 3) {
                    if (i === 0) {
                      return (
                        <IoIosStar key={i} className={"relative left-[7px] text-purple-300/80"} />
                      )
                    }
                    if (i === 1) {
                      return (
                        <IoIosStar key={i} className={"text-purple-300/0"} />
                      )
                    }
                    if (i === 3) {
                      return (
                        <IoIosStar key={i} className={"text-purple-300/80"} />
                      )
                    }
                    return (
                      <IoIosStar key={i} className={i < starAverage ? "text-purple-300/80" : "text-purple-300/0"} />
                    )
                  }
                  return (
                    <IoIosStar key={i} className={i < starAverage ? starsToColor(starAverage.toFixed(0)) : "text-purple-300/0"} />
                  )
                })}
              </div> */}
            </>
          )}
          {!opened && !firstUnplayedDaily && (
            <div className="text-lg absolute right-2 top-2">
              <IoMdPlanet className={`text-slate-400/70 animate-spin-medium`} />
            </div>
          )}
          <AnimatePresence>
          {!opened && firstUnplayedDaily && litSegments < questionsNeededToStart && (
          //  {true && (
            <motion.div 
             exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
            className="text-2xl w-5 h-5 absolute right-2 top-2 animate-spin-slow">
              <CircleLoadingIndicator litSegments={litSegments} themeColor={themeColor}/>
              {/* <IoMdPlanet className="text-purple-300/90 animate-spin" /> */}
            </motion.div>
          )}
          </AnimatePresence>
        </div>
        <div className={`${opened ? "transition-all ease-out duration-[300ms] py-0" : "opacity-0 transition-all ease-out duration-[300ms] py-2"} flex justify-start items-center mt-1 gap-4 ml-9`}>
          {opened && (
            <>
              <div className="absolute bottom-0 flex my-8 gap-[1px]">
                {firstUnplayedDaily && litSegments >= questionsNeededToStart && (
                  <button
                    disabled={!firstUnplayedDaily || litSegments < questionsNeededToStart}
                    onClick={handleNextClick}
                    className={`  bg-blue-500 rounded-lg text-white dark:text-blue-200  py-3 px-10 h-12 flex items-center justify-center`}>
                    {playCount > 0 ? "next" : "start"}
                  </button>
                )}
                {!firstUnplayedDaily || litSegments < questionsNeededToStart && (
                  <IoMdPlanet className={`text-${themeColor}-300/90 animate-spin"`} />
                )}
              </div>
              {/* {dailies.map((questionSet, index) => {
                  const maxScore = questionSet.userPlays?.reduce((acc, curr) => {
                    return acc.score > curr.score ? acc : curr
                  })
                  const date = new Date(questionSet.published_date);
                  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
                  return (
                  <MiniCounter
                        key={index}
                        score={maxScore?.score}
                        date={formattedDate}
                        questionSet={questionSet}
                  />
                  )
                }
                )} */}
            </>
          )}
        </div>
      </div>
      <div className={(opened ? "absolute bottom-0 right-0" : "absolute bottom-0 right-0")}>
      <ScoreGraph scores={scoresMapped} isOpen={opened} themeColor={themeColor}/>
      </div>
      {/* <LineChart scores={scores.map(i => i.score)} isOpen={opened} /> */}
    </motion.div>
  );
}

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 604800;
  if (interval > 1) {
    return Math.floor(interval) + " weeks ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export const AdminTopicTile = (props) => {
  const navigate = useNavigate();
  const { data: isPremium, isPending: isPending2, isError: isError2, error:error2 } = useCheckUserGroup();


  let {
    topic,
    onClick,
    index,
    scores,
    selectedIndex,
    pastUserFinishes,
    pinned,
    building,
    streamId,
    themeColor,
    pageSize,
    height,
    hash,
    border,
    isAdmin,
    stream,
  } = props
  const [dailies, setDailies] = useState([])
  const [playCount, setPlayCount] = useState("-")
  const [starTotal, setStarTotal] = useState(0)
  const [firstUnplayedDaily, setFirstUnplayedDaily] = useState(undefined)
  const [populationAverageScore, setPopulationAverageScore] = useState(0)

  const [priorityOver, setPriorityOver] = useState(false)

  const publishedDate = stream?.TAGGED?.createdAt

  const date = new Date(stream?.createdAt)
  const formattedDate = `${date.getMonth() + 1} / ${date.getDate()} / ${date.getFullYear()}`;


  const playedCount = dailies.filter(daily => daily.userPlays !== undefined).length
  const dailyCount = dailies.length

  const opened = selectedIndex === index
  // const color = pinned ? " border-[1px] border-purple-500/90 " : "  "
 

  const handleAnimationComplete = (index) => {
    if (selectedIndex !== index) {
      document.getElementById(`item-${index}-${hash}`).style.zIndex = 0;
    }
  };

  const handleAnimationStart = (index) => {
    document.getElementById(`item-${index}-${hash}`).style.zIndex = 100;
  };

  const fetchPopulationAverage = async (topic) => {
    if (topic === "loading") return
    const average = await client.streams.average(topic)
    setPopulationAverageScore(average.score)
  }

  const fetchPastPlays = async (topic) => {
    if (topic === "loading") return
    let recentDailies = []
    try {
      recentDailies = await client.plays.getPastTwoWeeks(topic)

    } catch (error) {
      if (error.errors && error.errors[0].message === "Cannot return null for non-nullable type: 'ID' within parent 'Stream' (/getStream/_id)") {
        setTimeout(() => {
          fetchPastPlays(topic)
        }, 8000)

      } else {
        console.error(error)
        return
      }
    }

    const pastFinishesByQuestionSet = pastUserFinishes?.reduce((acc, curr) => {
      if (!acc[curr.questionsetOfOut.id]) {
        acc[curr.questionsetOfOut.id] = []
      }
      acc[curr.questionsetOfOut.id].push(curr)
      return acc
    }, {}) || {}

    // attach the user plays to the dailies

    // now that we have the 2-week count, filter to the 3 most recent
    recentDailies.sort((a, b) => new Date(b.published_date) - new Date(a.published_date))

    let playCount = 0
    let starTotalTmp = 0
    recentDailies.forEach((daily) => {
      daily.userPlays = pastFinishesByQuestionSet[daily.id]
      if (!(daily.userPlays === undefined || daily.userPlays.length === 0)) {
        playCount += 1
        const maxScore = daily.userPlays.reduce((acc, curr) => {
          return acc.score > curr.score ? acc : curr
        })
        starTotalTmp += starMap(maxScore.score)
      }
      // playCount += daily.userPlays?.length ?? 0
    })


    // const recentDailiesFiltered = recentDailies.filter(daily => Boolean(daily.published_date))
    const firstUnplayedDaily = recentDailies.filter(daily => daily.userPlays === undefined).sort((a, b) => new Date(a.published_date) - new Date(b.published_date))[0]

    setFirstUnplayedDaily(firstUnplayedDaily)
    setDailies(recentDailies)
    setPlayCount(playCount)
    setStarTotal(starTotalTmp)

    const litSegments = firstUnplayedDaily?.questionnodeContainssOut?.length || 0

    if (litSegments < questionsNeededToStart) {
      setTimeout(() => {
        fetchPastPlays(topic)
      }, 3000)
    }

  }

  const [myLeaderBoardPlace, setMyLeaderBoardPlace] = useState(0)
  const [populationN, setPopulationN] = useState(0)
  const [myELO, setMyELO] = useState(0)
  const fetchLeaderboard = async (topic) => {
    if (topic === "loading") return
    const leaderBoard = await client.streams.leaderboard(topic)
    setMyLeaderBoardPlace(leaderBoard.myPlace)
    setPopulationN(leaderBoard.length)
    if (leaderBoard.myELO) setMyELO(leaderBoard.myELO.score)
    
  }

  useEffect(() => {
    if (!topic) return
    fetchPopulationAverage(topic)
    fetchLeaderboard(topic)
    fetchPastPlays(topic);
    // client.streams.recordView(streamId)
  }, [topic]);

  const genNewLevel = async () => {
    await client.streams.gen(topic)
  }


  // TODO: turn these adjustments on for desktop version
  // const percentWidth = pinned ? "calc(50% - 4px)" : "calc(25% - 8px)"
  // const left = pinned ? `calc(${(index % 2) * 50}% + ${index % 2 * 4}px)` : `calc(${(index - 2) * 25}% + ${(index - 2) * 2}px)`
  // const top = pinned ? `calc(${Math.floor(index / 2) * 50}% + ${Math.floor(index / 2) * 4}px)` : `calc(${Math.floor((index+2) / 4) * 50}% + ${Math.floor((index+2) / 4) * 4}px)`
  

  let textSize = topic.length > 12 ? " text-base sm:text-lg" : " text-xl "

  // find the longest word in the topic
  const longestWord = topic.split(" ").reduce((acc, curr) => {
    return acc.length > curr.length ? acc : curr
  })

  if (longestWord.length > 10) textSize = " text-xs sm:text-lg"

  const four = [0, 1, 2, 3]

  const starAverage = starTotal / playCount


  // dailies.map((questionSet, index) => {
  //   const maxScore = questionSet.userPlays?.reduce((acc, curr) => {
  //     return acc.score > curr.score ? acc : curr
  //   })
  //   const date = new Date(questionSet.published_date);
  //   const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
  //   return (



  const minimumDaily = dailies.reduce((acc, curr) => {
    return new Date(acc.published_date) < new Date(curr.published_date) ? acc : curr
  }, [])


  const earliestDate = new Date(minimumDaily.published_date)

  const scoresSorted = scores ? scores.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)) : []
  const scoresMapped = scoresSorted.map((score) => {
    return { score: starMap(score.score) }
  })

  // const scores = [
  //   { score: 5 },
  //   { score: 10 },
  //   { score: 9 },
  //   { score: 13 },
  //   { score: 15 },
  //   { score: 14 },
  //   { score: 10 },
  //   { score: 9 },
  //   { score: 17 },
  //   { score: 16 },
  //   { score: 18 },
  //   { score: 20 }
  // ]

  const averageScore = scoresMapped ? scoresMapped.reduce((acc, curr) => {
    return acc + curr.score
  }, 0) / scoresMapped.length : 0


  const starsToColor = (stars) => {
    return "text-purple-300/90"
    if (stars == 4) {
      return "text-purple-300/90"
    }
    if (stars == 3) {
      return "text-purple-300/80"
    }
    if (stars == 2) {
      return "text-purple-300/70"
    }
    if (stars == 1) {
      return "text-purple-300/60"
    }
    return "text-purple-300/50"
  }

  

  const handlePublishClick = async () => {
    await client.streams.tag("2e58698d-fb34-440c-a548-d746bbd110a9", streamId)
  }


  const litSegments = firstUnplayedDaily?.questionnodeContainssOut?.length || 0

  const handleNextClick = async () => {
    if (litSegments < questionsNeededToStart) {
      return
    }
    window.gtag("event", "start_round", {
      event_category: "engagement",
    });
    if (firstUnplayedDaily) {
      navigate(`/a/daily/${firstUnplayedDaily.id}`);
    }
  };

  // bg-slate-800/50

  
  const bg = opened 
    ? 
      `  bg-${themeColor}-950/10  dark:bg-${themeColor}-500/20 ` 
    : 
      ` bg-${themeColor}-300/30 hover:bg-${themeColor}-500/40  shadow-md
      dark:bg-${themeColor}-800/70  dark:hover:bg-${themeColor}-500/30 `

  const borderStyle = true 
  ? opened ? ` border-blue-500 border-[1px] ` : ` border-blue-500 border-2 `
  : ''


  const percentWidth = "calc(50% - 4px)"
  const left = `calc(${(index % 2) * 50}% + ${index % 2 * 4}px)`
  const top = `calc(${index*(height) }px)`

  const myLeaderBoardPlaceMessage = myLeaderBoardPlace !== 0 ? `${myLeaderBoardPlace}st` : "- rank"

  

  const likelihood = (eloToLikelihood(populationAverageScore))
  const myLikelihood = myELO ? (eloToLikelihood(myELO)) : null
  const myContribution = populationN > 1 ? ((myLikelihood - likelihood) / (populationN - 1)).toFixed(0) : null

  const eloDiff = myLikelihood ? myLikelihood - likelihood : 0


  let signalBar;
  if (myLikelihood) {
    const signalClass = "absolute text-2xl text-blue-400 inline-block  h-[28px] mt-[px]"
    if (myLikelihood > 90) {
      signalBar = <TbAntennaBars5 className={signalClass}/>
    } else if (myLikelihood > 75) {
      signalBar = <TbAntennaBars3 className={signalClass}/>
    }else if (myLikelihood > 50) {
      signalBar = <TbAntennaBars3 className={signalClass}/>
    } else if (myLikelihood > 25) {
      signalBar = <TbAntennaBars2 className={signalClass} />
    } else {
      signalBar = <TbAntennaBars1 className={signalClass} />
    }
  }


  return (
    <motion.div
      id={`item-${index}-${hash}`}
      onClick={() => {
        if (isAdmin) {
          onClick()
        } else {
          handleNextClick()
        }
      }}
      initial={{
        z: 100,
        width: '100%',
        height: selectedIndex === index ? 'calc(100% + 30px)' : `calc(${100/pageSize}% - 8px )`,
        left: 0,
        top: selectedIndex === index ? '-5px' : top,
      }}
      animate={{
        z: 100,
        width: '100%',
        height: selectedIndex === index ? 'calc(100% + 30px)' : `calc(${100/pageSize}% - 8px )`,
        left: 0,
        top: selectedIndex === index ? '-5px' : top,
      }}
      onAnimationStart={() => handleAnimationStart(index)}
      onAnimationComplete={() => handleAnimationComplete(index)}
      transition={{
        duration: 0.15,
        ease: 'easeOut',
      }}
      className={"" +
        "  backdrop-blur-xl dark:backdrop-blur-2xl" +
        bg +
        borderStyle
        + " leading-7 " +
        (pinned ? " cols-span-2 " : " ") +
        // (priority && !priorityOver ? " border-[1px] border-purple-500 " : " ")+
        " absolute flex flex-col shrink-0 overflow-hidden cursor-pointer transition-border rounded-lg  dark:text-slate-100"
      }
    >
      <div className="flex flex-col justify-center h-full">
        <div 
          className={"relative  " + `${opened ? "flex transition-all items-center ease-out duration-[150ms] pt-3 pl-[30px]" : `flex transition-all justify-between  ease-out duration-[150ms] pl-[25px]`}`}>
           
            <div className={"font-logo h-8 flex flex-col " + `${opened ? "transition-all ease-out duration-[150ms] py-2 leading-4 " : ("justify-center transition-all ease-out duration-[150ms] py-0  leading-4 ")}`}>
              {topic.toLowerCase()}
                              {publishedDate &&
                              <div className="mt-2 text-slate-800 dark:text-slate-400 font-condensed text-sm ">{timeSince(new Date(publishedDate))}</div>
}
            </div>
            <div className="pl-2 text-blue-300 text-xl font-condensed flex gap-2">
                          <div className="flex justify-between items-center text-blue-600 px-[4px] rounded mx-2 mr-6">
                            
                            {!opened &&
                            <div className="flex flex-col items-end">
                              <div className={ (!myLikelihood ? " invisible " : "" ) + " h-[22px] font-bold text-blue-400 relative"}>
                                {myLikelihood}
                                {signalBar}
                              </div> 
                              
                              { isPremium && 
                              <div className={ (eloDiff > 0 ? "text-emerald-500" : "text-fuchsia-500" ) +
                              (eloDiff === 0 ? " invisible " : "" ) + 
                               " flex text-base font-bold  relative"}>
                                {Math.abs(eloDiff)}
                                <div className={(eloDiff > 0 ? "top-[4px]" : "top-[6px]" ) + " absolute right-[-17px] "}>
                                  {(eloDiff) > 0 ? <IoMdArrowDropup/> : <IoMdArrowDropdown/>}
                                </div>
                              </div>
}
                              {/* <div className="h-5 font-bold">
                                {likelihood}
                              </div> */}
                              </div> 
}
                            </div>
                          {/* <div className="flex items-center text-purple-400 px-[4px] rounded">
                            <MdPeopleAlt className="inline-block mr-1 ml-1 h-5"/>
                            <span className="text-2xl">{populationN}</span>
                          </div>  */}
                          {myLeaderBoardPlace == 0 && firstUnplayedDaily && litSegments >= questionsNeededToStart &&
                            <div className="font-logo flex items-center text-blue-400">
                              Start
                            </div>
}
                              <div className="flex items-center w-14">
                          { !opened && isAdmin && myLeaderBoardPlace != 0 &&
                          <div className="h-[28px] px-2 pr-3 w-14 text-white bg-emerald-500 dark:bg-emerald-500 rounded-l font-logo text-lg flex justify-end">
                <AnimatedNumber number={myLeaderBoardPlace} />{getOrdinalSuffix(myLeaderBoardPlace)}
              </div>
            //   <div className=" h-[30px] px-2 pr-3 w-20 text-white bg-emerald-500 dark:bg-emerald-500 rounded-l font-logo text-lg flex justify-end items-center">
            //   <AnimatedNumber number={myLeaderBoardPlace} />
            //               <FaMedal className="text-emerald-200 ml-1 text-sm mt-1"/>
            // </div>
}
              </div>
                          {/* <div className="flex items-center bg-slate-400/20 text-white px-[4px] rounded">
                            <FaRegThumbsUp className="inline-block mr-1 ml-1 h-3"/>
                            97% 
                          </div>  */}
                      </div>
          <AnimatePresence>
          {!opened && firstUnplayedDaily && litSegments < questionsNeededToStart && (
            //  {true && (
              <motion.div 
              // exit={{ opacity: 0 }}
              // transition={{ duration: 3 }}
              className="text-2xl w-9 h-9 right-4 top-3 relative">
                <CircleLoadingIndicator litSegments={litSegments} themeColor={themeColor}/>
                {/* <IoMdPlanet className="text-purple-300/90 animate-spin" /> */}
              </motion.div>
            )}
            </AnimatePresence>
            {!opened && !firstUnplayedDaily && (
              <div className="absolute text-2xl right-6 top-4">
                <IoMdPlanet className={`text-slate-400/70 animate-spin-medium`} />
              </div>
            )}
            {opened && (
              <IoIosClose className={`text-gray-700 dark:text-gray-400 hover:text-blue-500 absolute right-0 top-[6px] right-[6px] text-2xl`} />
            )}
          </div>
         
            {opened && (
          <div className="px-8 pt-6 flex">
            <div>
              <div>
                {dailies.length} lessons
                </div>
                <div>
                {populationN} players
                </div>
                <div>
                  {eloToLikelihood(populationAverageScore) || '-'} avg
                  </div>
                    </div>
                {/* <ScoreGraph scores={scoresMapped} isOpen={opened} themeColor={themeColor}/> */}
        <div className={`${opened ? "transition-all ease-out duration-[300ms] py-0" : "opacity-0 transition-all ease-out duration-[300ms] py-2"} pl-6 grid grid-cols-2 grid-rows-3 items-center mt-1 gap-1`}>
          {opened && (
            <>
               <button
                    onClick={handlePublishClick }
                    className={`  w-full bg-blue-500/20 text-blue-500 rounded-lg  py-2 px-4  flex items-center justify-center`}>
                    Publish
                  </button>
            <div>
               <button
                    onClick={() => client.streams.maybeGen(stream._id) }
                    className={`  w-full bg-blue-500/20 text-blue-500 rounded-lg  py-2 px-4  flex items-center justify-center`}>
                    Gen
                  </button>
            </div>
              {firstUnplayedDaily && litSegments >= questionsNeededToStart && (
                <button
                  disabled={!firstUnplayedDaily || litSegments < questionsNeededToStart}
                  onClick={handleNextClick}
                  className={`  w-full bg-blue-500/20 text-blue-500 rounded-lg rounded-lg  py-2 px-4  flex items-center justify-center`}>
                  {playCount > 0 ? "Next" : "Start"}
                </button>
              )}
              {!firstUnplayedDaily || litSegments < questionsNeededToStart && (
                <IoMdPlanet className={`text-${themeColor}-300/90 animate-spin"`} />
              )}
            </>
          )}
        </div>
                </div>
            )}
      </div>
      {/* <LineChart scores={scores.map(i => i.score)} isOpen={opened} /> */}
    </motion.div>
  );
}


export const ScoreExplainer = () => {

  const myLikelihood = 50;

  let signalBar;
  if (myLikelihood) {
    const signalClass = "absolute text-2xl text-blue-400 inline-block  h-[28px] mt-[px]"
    if (myLikelihood > 90) {
      signalBar = <TbAntennaBars5 className={signalClass}/>
    } else if (myLikelihood > 75) {
      signalBar = <TbAntennaBars3 className={signalClass}/>
    }else if (myLikelihood > 50) {
      signalBar = <TbAntennaBars3 className={signalClass}/>
    } else if (myLikelihood > 25) {
      signalBar = <TbAntennaBars2 className={signalClass} />
    } else {
      signalBar = <TbAntennaBars1 className={signalClass} />
    }
  }


  return (
        <div className="flex flex-col items-end">
          <div className={ (!myLikelihood ? " invisible " : "" ) + " h-[22px] font-bold text-blue-400 relative"}>
            {myLikelihood}
            {signalBar}
          </div> 
          </div>
  )
}


export const TopicCreatorTile = (props) => {

  let {
    topic,
    onClick,
    index,
    selectedIndex,
    pastUserFinishes,
    onAddStream,
    pinned
  } = props

  const opened = selectedIndex === index
  const color = pinned ? " border-[1px] border-purple-500/90 " : "  "
  const bg = opened ? "  bg-purple-500/10 " : " bg-purple-500/10 hover:bg-purple-500/20 border-purple-500 border-2 "

  const handleAnimationComplete = (index) => {
    if (selectedIndex !== index) {
      document.getElementById(`item-${index}`).style.zIndex = 0;
    }
  };

  const handleAnimationStart = (index) => {
    document.getElementById(`item-${index}`).style.zIndex = 100;
  };


  // TODO: turn these adjustments on for desktop version
  // const percentWidth = pinned ? "calc(50% - 4px)" : "calc(25% - 8px)"
  // const left = pinned ? `calc(${(index % 2) * 50}% + ${index % 2 * 4}px)` : `calc(${(index - 2) * 25}% + ${(index - 2) * 2}px)`
  // const top = pinned ? `calc(${Math.floor(index / 2) * 50}% + ${Math.floor(index / 2) * 4}px)` : `calc(${Math.floor((index+2) / 4) * 50}% + ${Math.floor((index+2) / 4) * 4}px)`
  const percentWidth = "calc(50% - 4px)"
  const left = `calc(${(index % 2) * 50}% + ${index % 2 * 4}px)`
  const top = `calc(${Math.floor(index / 2) * 50}% + ${Math.floor(index / 2) * 4}px)`

  const textSize = " text-lg "
  const verticalPosition = " w-[100%] "

  const four = [0, 1, 2, 3]

  const [displayedText, setDisplayedText] = useState('+ stream');
  const [streamName, setStreamName] = useState('');
  const [splits, setSplits] = useState([]);
  const [streaming, setStreaming] = useState(false);
  const [streamingDone, setStreamingDone] = useState(false);

  const extractTopicFields = (row) => {
    let { name, _id } = row;

    const fields = { name, _id };

    const filteredFields = Object.keys(fields)
      .filter((key) => fields[key] !== undefined && fields[key] !== null && fields[key] !== "")
      .reduce((obj, key) => {
        obj[key] = fields[key];
        return obj;
      }, {});

    return filteredFields;
  };

  const onFieldsReady = (msg, done) => {

    if (done) {
      setStreamingDone(true)
      if (!msg) return
    }

    setSplits(splits => {

      // if any key in splits is in msg.name, then replace that key with msg

      let replaced = false
      const newSplits = splits.map(split => {
        if (msg.name.includes(split.name) && split.status !== 'saved') {
          replaced = true
          return msg
        }
        return split
      })

      if (!replaced) {
        newSplits.push(msg)
      }

      return newSplits
    })
  }

  const findTopic = async () => {
    const system = "When provided a topic, return back the main subtopics of the provided topic."
    const format = "Return a list of topic title, no more than a few words each. Format your response as a json list of topics, each with a name property"
    const prompt2 = system + format

    let childTopics
    try {
      const topic = await client.topics.getChildTopicsWithStreams('medium', streamName)
      setStreaming(false)
      childTopics = topic.topicSubsOut.map(topic => ({ ...topic, status: 'saved' }))
    } catch (e) {
      setStreaming(true)
      setStreamingDone(false)
      process(prompt2, streamName, extractTopicFields, onFieldsReady)
      return
    }



    // after 1000ms, set the splits
    setTimeout(() => {
      setSplits(childTopics)
    }, 250)
  }

  const variants = {
    open: { padding: '12px 10px 10px', height: 'auto', opacity: 1 },
    closed: { padding: '12px 10px 0px', height: '0px', opacity: 0 }
  };


  return (
    <motion.div
      id={`item-${index}`}
      onClick={onClick}
      initial={{
        z: 100,
        width: selectedIndex === index ? 'calc(100% + 10px)' : percentWidth,
        // height: selectedIndex === index ? 'calc(100% + 30px)' : "calc(50% - 4px)",
        left: selectedIndex === index ? '-5px' : left,
        top: selectedIndex === index ? '-5px' : top,
      }}
      animate={{
        z: 100,
        width: selectedIndex === index ? 'calc(100% + 10px)' : percentWidth,
        // height: '46%',
        left: selectedIndex === index ? '-5px' : left,
        top: selectedIndex === index ? '80px' : top,
      }}
      onAnimationStart={() => handleAnimationStart(index)}
      onAnimationComplete={() => handleAnimationComplete(index)}
      transition={{
        duration: 0.15,
        ease: 'easeOut',
      }}
      className={"" +
        " hover:text-slate-200  backdrop-blur-md" +
        color +
        bg +
        textSize + " leading-7 " +
        (pinned ? " cols-span-2 " : " ") +
        " absolute flex flex-col shrink-0 overflow-hidden cursor-pointer font-logo rounded-lg  dark:text-slate-100"
      }
    >
      <div className="flex flex-col justify-center">
        <div className={"relative " + `${opened ? "flex transition-all items-center ease-out duration-[150ms]" : "flex transition-all items-center ease-out duration-[150ms]"}`}>
          <div className={`${opened ? "transition-all ease-out duration-[150ms] leading-4 w-full" : "transition-all ease-out duration-[150ms] py-0 leading-4"}`}>
            <input type="text" placeholder={displayedText} value={streamName}
              disabled={splits.length > 0}
              onChange={(e) => setStreamName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // Call the function to handle the submission
                  findTopic();
                  // removed focus
                  e.target.blur();
                  var element = document.getElementById("new-stream-container");
                  const topPosition = element.getBoundingClientRect().top + window.pageYOffset - 20;
                  window.scrollTo({
                    top: topPosition,
                    behavior: 'smooth',
                  });
                  // element.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              className={(splits.length > 0 ? "bg-purple-500/0 text-blue-500 " : "bg-purple-500/30") + " w-full relative transition-all " +
                " placeholder:text-white placeholder:font-logo text-lg font-logo text-white  p-4 rounded-lg"} />
            <div className="">
              <div className="">
                {streamName && (


                  <motion.div
                    initial="closed"
                    animate={splits.length > 0 ? "closed" : "open"}
                    variants={variants}
                    className="text-purple-200 box-content overflow-hidden ml-2 w-[95%]"

                  >

                    type <span className="font-bold text-purple-100">enter</span> to start
                  </motion.div>

                )}
              </div>
              <div className="mt-[-20px]">
                <AnimatePresence>
                  {splits.map((split, i) => {
                    return (
                      <motion.div
                        key={i}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, y: -20 - i * 12, transition: { duration: 0.2 + (splits.length - i) * 0.05 } }}
                        transition={{ delay: streaming ? 0 : (0.5 + i * (1.5 / splits.length)) }}
                        className="text-purple-100 font-logo rounded-lg relative flex justify-start items-center gap-2">
                        <IoIosReturnRight className="text-purple-500" />
                        {split.name}
                      </motion.div>
                    )
                  })}
                </AnimatePresence>
                {splits.length > 0 && streamingDone &&
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: streaming ? 0 : 0.5 + 2 }}
                    className="text-right"
                  >
                    <button
                      onClick={onAddStream}
                      className="bg-purple-500/50 mt-4 px-10 py-3 rounded-lg text-white font-logo">+ add</button>

                  </motion.div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className={`${opened ? "transition-all ease-out duration-[300ms] py-0" : "opacity-0 transition-all ease-out duration-[300ms] py-2"} flex justify-center items-center mt-1 gap-4`}>
        </div>
      </div>
    </motion.div>
  );
}

export const Component = TopicPage;
