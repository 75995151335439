import React, { useState, useEffect } from "react";
import { motion, useMotionValue, useTransform, animate, AnimatePresence } from "framer-motion";
// import CursorBlinker from "./CursorBlinker";


// from https://blog.noelcserepy.com/how-i-created-a-typing-text-animation-with-framer-motion
export const TextAnim = (props) => {
  const delay = props.delay || 0;
  const duration = props.duration || 2;
  const baseText = props.text
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const displayText = useTransform(rounded, (latest) =>
    baseText.slice(0, latest)
  );

  useEffect(() => {
    const controls = animate(count, baseText.length, {
      type: "tween",
      duration: duration,
      ease: "easeInOut",
      delay: delay,
    });
    return controls.stop;
  }, [props.text]);

  return (
    <span className="">
      <motion.span>{displayText}</motion.span>
      {/* <CursorBlinker /> */}
    </span>
  );
}


export const LetterTypeWriter = ({ inputText }) => {
  const speed = 8;
  const letters = inputText.split("");
  const [text, setText] = useState("");
  let count = 0;

  useEffect(() => {
    setText("");

    const interval = setInterval(() => {
      if (count === letters.length) {
        clearInterval(interval);
        return;
      }
      setText((prevText) => prevText + letters[count]);
      count++;
    }, speed);
    return () => clearInterval(interval);
  }, [inputText]);

  return <span>{text}</span>;
};

export const WordTypeWriter = ({ children, fromCache }) => {
  const speed = 30;
  const inputText = children;
  const words = inputText.split(" ");
  const [text, setText] = useState("");
  let count = 0;

  useEffect(() => {
    if (fromCache) {
      setText("");

      const interval = setInterval(() => {
        if (count === words.length) {
          clearInterval(interval);
          return;
        }
        setText((prevText) => prevText + " " + words[count]);
        count++;
      }, speed);
      return () => clearInterval(interval);
    }
  }, [inputText]);

  return <span>{fromCache ? text : inputText}</span>;
};


export const StringVacuum = ({ inputString }) => {
  const [displayString, setDisplayString] = useState("");

  useEffect(() => {
    let timer;
    if (inputString === "") {
      timer = setInterval(() => {
        setDisplayString((prev) => {
          if (prev.length < 12) return "";
          return prev.slice(0, Math.round(-prev.length / 2))
      });
      }, 50);
    } else if (displayString === "") {
      timer = setInterval(() => {
        setDisplayString((prev) => {
          // in this case, build up the string
          return inputString.slice(0, prev.length + 2);

      });
      }, 20);
    } else {
      setDisplayString(inputString);
    }

    return () => clearInterval(timer);
  }, [inputString]);

  return (
    <span>
        {displayString}
    </span>
  );
};