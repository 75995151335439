import { DataStore } from "aws-amplify/datastore";
import { useQuery } from '@tanstack/react-query';
import { useAuthSession } from "../hooks/auth"
import { get, post, generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getQuestion, getTopicWithQuestions, getDaily, } from '../graphql';
import {
  createSearch2,
  getUserQuestionsToReview2,
  connectUserToSearchEdgeAsked,
  connectAnswerToPlayEdgeDuring,
  connectAnswerToQuestionnodeEdgeTo,
  connectPlayToQuestionsetEdgeOf,
  connectUserToAnswerEdgeGave,
  connectUserToPlayEdgeStarted,
  connectUserToQuestionnodeEdgeAsked,
  connectUserToTopicEdgeSelected,
  createAnswer2,
  createOrGetUser2,
  createPlay2,
  listPlays,
  createQuestionnode,
  createQuestionSetComplete,
  createQuestionset,
  getChildTopics,
  getDailySummary,
  getGrandChildTopics,
  getParentTopics2,
  getScoreHistory,
  getStreamLeaderboard,
  getTopicLeaderboard,
  getStreamDailySummary,
  getUserDailies,
  getUserDailiesSummaries,
  updatePlay2,
  getUserPlayScore,
  getUserTotalCorrectAnswers,
  getUserTotalIncorrectAnswers,
  getUserTopicUnansweredQuestions2,
  getUserSubtopicUnansweredQuestions2,
  getChildTopicsWithStreams,
  connectStreamToTopicEdgeIncludes,
  createStream2,
  createTag2,
  connectTagToStreamEdgeTagged2,
  getStream2,
  listStreams2,
  deleteEdgeIncludesFromStreamToTopic,
  createTopic2,
  connectTopicToTopicEdgeSub,
  listRuns2,
  connectUserToStreamEdgeFollowing,
  getUserFollowingStreams,
  getQuestionnode2,
  getTag2,
  getStreamAverageScore,
  getTopicAverageScore,
  connectUserToStreamEdgeRequested,
  connectUserToStreamEdgeViewed,
  connectUserToStreamEdgeOpened,
  connectUserToStreamEdgeStarted,
  connectUserToStreamEdgeCompleted,
  updateEdgeFollowingFromUserToStream,
  updateEdgeRequestedFromUserToStream,
  updateEdgeViewedFromUserToStream,
  updateEdgeOpenedFromUserToStream,
  updateEdgeStartedFromUserToStream,
  updateEdgeCompletedFromUserToStream,
  updateEdgeTaggedFromTagToStream,
  connectQuestionsetToQuestionnodeEdgeContains
} from '../graphql';
import { Question } from "../models";
import { v4 as uuidv4 } from "uuid";

// export const getRandomTopic = async (session, difficulty, topics) => {
//   let apiName = "topics";
//   let path = "";
//   let params = {};

//   if (topics.length > 0) {
//     const randomTopic = topics[Math.floor(Math.random() * topics.length)];
//     params["queryParams"] = { baseTopic: randomTopic };
//   }

//   if (session) {
//     const Authorization = `Bearer ${session.tokens.idToken}`;
//     path = `/topics/next/${difficulty}`;
//     params["headers"] = { Authorization };
//   } else {
//     path = `/g/topics/next/${difficulty}`;
//   }

//   try {
//     console.log('fetching2')
//     const response = await get({ apiName, path, options: params }).response;
//     const newTopic = await response.body.json();
//     // Capitalize the first letter
//     return newTopic.charAt(0).toUpperCase() + newTopic.slice(1);
//   } catch (e) {
//     console.error(e);
//     throw new Error("Failed to fetch topic");
//   }
// };

// export const useRandomTopic = (difficulty, topics) => {
//   console.log(topics)
//   const { data: session, isError, isLoading: isLoadingSession } = useAuthSession();

//   const fetchRandomTopic = async ({ queryKey }) => {
//     console.log('feching', session)
//     const [_key, { difficulty, topics }] = queryKey;
    

//   return useQuery({
//     queryKey: ['randomTopic', { difficulty, topics }],
//     queryFn: fetchRandomTopic,
//     enabled: !isLoadingSession && !isError, // Only fetch random topic if the session is loaded/not error
//     onError: (error) => {
//       console.error("Error fetching random topic:", error);
//     }
//   });
// };


function getWeekNumber() {
  // Create a copy of the date object
  const d = new Date();
  
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday (0) day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  
  // Get first day of the year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  
  return weekNo;
}

export const retrievePastQuestionIdsOnTopic = async (topic, difficulty) => {
  const topicPath = topic.split(" > ");
  const topicName = topicPath[topicPath.length - 1];
  let questions;
  const filter = { difficulty: `= ${difficulty}` }
  let userId = "3987403f-6343-4da5-8116-9ba354457e12"
  if (topicName) {
    filter['name'] = `= ${topicName}`
  }
  try {
    // const resp = await client.graphql({
    //   query: getTopicWithQuestions,
    //   variables: {
    //     filter,
    //     questionFilter: {
    //       version: `= v1.0.5`
    //     }
    //   },
    // })
    const resp = await client.graphql({
      query: getUserTopicUnansweredQuestions2,
      variables: {
        filter: {
          user_id: `${userId}`,
        },
        topicFilter: {
          name: `${topicName}`,
          difficulty: `${difficulty}`
        }
      }
    })
    questions = resp.getUserTopicUnansweredQuestions
  } catch (e) {
    console.error(e);
  }

  const questionIds = questions.map((q) => q.id);
  return questionIds;
};

export const saveNewQuestion = async (question, topic, difficulty) => {
  if (!question.text) {
    console.warn("no question text, not saving,", question);
    return;
  }
  const newQuestion = await DataStore.save(
    new Question({
      id: uuidv4(),
      version: "v1.0.5",
      topic, // not needed for datastore but needed for neptune, which builds on the object resulting from this save()
      type: "multiple_choice",
      text: question.text,
      correctAnswer: question.correctAnswer,
      incorrectAnswers: question.incorrectAnswers,
    }),
  );
  const questionId = newQuestion.id;

  const saveQtoNeptune = async (difficulty) => {

    try {
      await client.graphql({
        mutation: createQuestionnode,
        variables: {
          input: {
            id: questionId,
            text: newQuestion.text,
            version: newQuestion.version,
            difficulty
          }
        },
      })
    } catch (e) {
      console.error(e);
    }
  };

  await saveQtoNeptune(difficulty);
  return questionId;
};

class APIClient {
  constructor() {
    this.loggedIn = false;
    this.userId = ''
    this.client = generateClient()
  }

  setLoggedIn(loggedIn) {
    this.loggedIn = loggedIn;
  }

  async setUserId(userId) {
    this.userId = userId
    await this.graphql({
      mutation: createOrGetUser2,
      variables: {
        input: {
          user_id: `${userId}`,
        },
        createdAt: new Date().toISOString(),
      }
    })
  }

  async graphql({ query, mutation, variables }) {
    let authParams = {};
    if (!this.loggedIn) authParams["authMode"] = 'iam';
    let resp;
    if (query) {
      resp = await this.client.graphql({
        ...authParams,
        query,
        variables,
      });
    } else {
      resp = await this.client.graphql({
        ...authParams,
        query: mutation,
        variables
      });
    }
    const { data, errors } = resp;
    if (errors) {
      throw new Error(JSON.stringify(errors));
    }
    return resp.data
  }

  async createQuestionSet() {
    const resp = await this.graphql({
      mutation: createQuestionset,
      variables: {
        input: {
          createdAt: new Date().toISOString(),
          type: 'stream',
          version: 'v1.0.0'
        },
      },
    })

    return resp.createQuestionset
  }

  async createQuestionSetBatch(date, questionIds) {
    await this.graphql({
      mutation: createQuestionSetComplete,
      variables: {
        input: {
          title: 'Daily',
          published_date: date,
          type: 'batch'
        },
        questionIds
      },
    })
  }
  async saveAnswer(questionId, answerId, wasCorrect) {
    let userId = this.userId
    const answerResp = await this.graphql({
      mutation: createAnswer2,
      variables: {
        input: {
          created_at: new Date().toISOString(),
          answer_id: answerId,
          was_correct: wasCorrect
        }
      }
    })
    const savedAnswerId = answerResp.createAnswer._id

    await this.graphql({
      mutation: connectAnswerToQuestionnodeEdgeTo,
      variables: {
        from_id: `${savedAnswerId}`,
        to_id: `id = ${questionId}`,
      }
    })
    await this.graphql({
      mutation: connectUserToAnswerEdgeGave,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${savedAnswerId}`,
      }
    })

    return savedAnswerId
  }
  async markTopicsSelected(topics) {
    for (let t of topics) {
      let userId = this.userId
      // only problem is this now requires a user node to have already been created 
      await this.graphql({
        mutation: connectUserToTopicEdgeSelected,
        variables: {
          edge: { created_at: new Date().toISOString() },
          from_id: `user_id = ${userId}`,
          to_id: `name = ${t}`
        },
      })
    }
  };

  async markQuestionSeen(questionId) {
    // let [userId, _] = await standardUserIdentifier()
    let userId = this.userId
    await this.graphql({
      mutation: connectUserToQuestionnodeEdgeAsked,
      variables: {
        edge: { created_at: new Date().toISOString() },
        from_id: `user_id = ${userId}`,
        to_id: `id = ${questionId}`
      },
    })
  };

  async getQuestionById(qId) {
    // let question = await DataStore.query(Question, qId);
    // if no question found, it might not be synced yet
    // if (!question) {
      // console.warn("question not found in DataStore, falling back to AppSync");
      const resp = await this.graphql({
        query: getQuestionnode2,
        variables: { 
          filter: {
            id: `= ${qId}` 
          }
        }
      });
      const question = resp.getQuestionnode
      question.incorrectAnswers = question.incorrectAnswers.split('◊')
      return resp.getQuestionnode
  }

  async getUserTopicUnansweredQuestions(topic) {
    
    let userId = this.userId
    const resp = await this.graphql({
      query: getUserTopicUnansweredQuestions2,
      variables: {
        filter: {
          user_id: `${userId}`,
        },
        topicFilter: {
          name: `${topic}`,
          difficulty: `medium`
        }
      }
    })
    const questions = resp.getUserTopicUnansweredQuestions
    questions.map(q => {
      q.incorrectAnswers = q.incorrectAnswers.split('◊')
    })
    return questions
  }

  // async getQuestionOnTopic(qId) {
  //   // now we have the ids already 
  //   // const qIds = await retrievePastQuestionIdsOnTopic(topic, difficulty);
  //   // qIds.sort(() => 0.5 - Math.random());

  //   let question;
  //   for (let i = 0; i < qIds.length; i++) {
  //     const qId = qIds[i];

  //     // load the question from DataStore if it exists
  //     question = await DataStore.query(Question, qId);

  //     // if no question found, it might not be synced yet
  //     if (!question) {
  //       console.warn("question not found in DataStore, falling back to AppSync");
  //       const resp = await this.graphql({
  //         query: getQuestion,
  //         variables: { id: qId },
  //       });
  //       if (resp.getQuestion) question = resp.getQuestion;
  //     }
  //     if (question) break;
  //   }

  //   // if (!question.correctAnswer) {
  //   //   question.correctAnswer = question.correctAnswers[0]
  //   // }

  //   return question;
  // };

  async getRandomTopic(difficulty, topics) {
    const apiName = "topics";
    // const difficulty = 'medium'
    let path = "";
    let params = {};

    if (topics.length > 0) {
      // choose a random topic from the list
      const randomTopic = topics[Math.floor(Math.random() * topics.length)];
      params["queryParams"] = { baseTopic: randomTopic };
    }

    if (this.loggedIn) {
      const session = await fetchAuthSession();
      const Authorization = `Bearer ${session.tokens.idToken}`;
      path = `/topics/next/${difficulty}`;
      params["headers"] = { Authorization };
    } else {
      console.warn("not logged in");
      path = `/g/topics/next/${difficulty}`;
    }

    let response;
    try {
      response = await get({ apiName, path, options: params }).response;
    } catch (e) {
      console.error(e);
    }
    const newTopic = await response.body.json();

    // capitalzie the first letter
    const capitalizedTopic = newTopic.charAt(0).toUpperCase() + newTopic.slice(1);
    return capitalizedTopic;
  };

  async getDailyQuestionSet(id) {
    let resp
    resp = await this.graphql({
      query: getDaily,
      variables: {
        filter: {
          id: '= ' + id,
        },
      },
    })
    const questions = resp.listQuestionsets[0].questionnodeContainssOut
    const questionsAdjusted = questions.map(q => {
      q.incorrectAnswers = q.incorrectAnswers.split('◊')
      return q
    })
    resp.listQuestionsets[0].questionnodeContainssOut = questionsAdjusted
    return resp.listQuestionsets[0]
  }
}

class ScoreClient {
  constructor(client) {
    this.client = client
  }
  async graphql(args) {
    return await this.client.graphql(args)
  }

  async getUserTotalCorrectAnswers() {
    let userId = this.client.userId
    const resp = await this.graphql({
      query: getUserTotalCorrectAnswers,
      variables: {
        filter: {
          user_id: `= ${userId}`,
        },
      },
    })
    return resp.getUserTotalCorrectAnswers.score
  }

  async getUserTotalIncorrectAnswers() {
    let userId = this.client.userId
    const resp = await this.graphql({
      query: getUserTotalIncorrectAnswers,
      variables: {
        filter: {
          user_id: `= ${userId}`,
        },
      },
    })
    return resp.getUserTotalIncorrectAnswers.score
  }

  async scoreHistory() {
    let userId = this.client.userId
    const playScoreResp = await this.graphql({
      query: getUserPlayScore,
      variables: {
        filter: {
          user_id: `${userId}`,
        },
      },
    })
    const totalCorrectResp = await this.graphql({
      query: getUserTotalCorrectAnswers,
      variables: {
        filter: {
          user_id: `${userId}`,
        },
      },
    })


    const playScore = playScoreResp?.getUserPlayScore.score || 0
    const answersCorrect = totalCorrectResp.getUserTotalCorrectAnswers.score
    return { playScore, answersCorrect }
  }
}

class TopicClient {
  constructor(client) {
    this.client = client
  }
  async graphql(args) {
    return await this.client.graphql(args)
  }

  async leaderboard(topicName) {
    const user_id = this.client.userId
    const resp = await this.graphql({
      query: getTopicLeaderboard,
      variables: {
        filter: {
          name: `= ${topicName}`,
        },
      },
    })
    resp.getTopicLeaderboard.myPlace = resp.getTopicLeaderboard.findIndex(u => u.user_id === user_id) + 1
    resp.getTopicLeaderboard.myELO = resp.getTopicLeaderboard.find(u => u.user_id === user_id)
    return resp.getTopicLeaderboard
  }

  async average(topicName) {
    const resp = await this.graphql({
      query: getTopicAverageScore,
      variables: {
        filter: {
          name: `= ${topicName}`,
        },
      },
    })
    return resp.getTopicAverageScore
  }

  async create(name) {
    const resp = await this.graphql({
      query: createTopic2,
      variables: {
        input: {
          name,
          difficulty: 'medium'
        },
      },
    })
    return resp.createTopic
  }

  async connectToParentTopic(topicId, parentTopicId) {
    const resp = await this.graphql({
      mutation: connectTopicToTopicEdgeSub,
      variables: {
        from_id: `${parentTopicId}`,
        to_id: `${topicId}`,
      }
    })

    return resp.connectStreamToTopicEdgeIncludes
  }


  async getParentTopics(difficulty, topicName) {
    const filter = {
      difficulty: `= ${difficulty}`,
      name: `= ${topicName}`
    }
    const resp = await this.graphql({
      query: getParentTopics2,
      variables: {
        filter,
      },
    })
    return resp.getTopic.topicSubsIn
  }

  async getChildTopics(difficulty, topicName) {
    const filter = {
      difficulty: `= ${difficulty}`,
      name: `= ${topicName}`
    }
    const resp = await this.graphql({
      query: getChildTopics,
      variables: {
        filter,
      },
    })
    return resp.getTopic.topicSubsOut
  }

  async getChildTopicsWithStreams(difficulty, topicName) {
    const topicLower = topicName.trim().toLowerCase()
    const filter = {
      difficulty: `= ${difficulty}`,
      name: `= ${topicLower}`
    }
    const resp = await this.graphql({
      query: getChildTopicsWithStreams,
      variables: {
        filter,
      },
    })
    return resp.getTopic
  }

  async connectToStream(topicId, streamId) {
    const resp = await this.graphql({
      mutation: connectStreamToTopicEdgeIncludes,
      variables: {
        from_id: `${streamId}`,
        to_id: `${topicId}`,
      }
    })

    return resp.connectStreamToTopicEdgeIncludes
  }

  async removeFromStream(topicId, streamId) {
    const resp = await this.graphql({
      mutation: deleteEdgeIncludesFromStreamToTopic,
      variables: {
        from_id: `${streamId}`,
        to_id: `${topicId}`,
      }
    })

    return resp.connectStreamToTopicEdgeIncludes
  }

  async getGrandChildTopics(difficulty, topicName) {
    const filter = {
      difficulty: `= ${difficulty}`,
      name: `= ${topicName}`
    }
    const resp = await this.graphql({
      query: getGrandChildTopics,
      variables: {
        filter,
      },
    })
    return resp.getTopic.topicSubsOut
  }
}

class PlayClient {
  constructor(client) {
    this.client = client
  }
  async graphql(args) {
    return await this.client.graphql(args)
  }

  async getPastTwoWeeks(streamName) {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - 13)
    const dateString = startDate.toISOString().split('T')[0]

    let resp
    resp = await this.graphql({
      query: getStreamDailySummary,
      variables: {
        streamFilter: {
          name: `= ${streamName.toLowerCase()}`,
        }
      },
    })

    // sort by published_date, descending 
    if (!resp.getStream.questionsetMadesOut) {
      return []
    }
    resp.getStream.questionsetMadesOut.sort((a, b) => new Date(b.published_date) - new Date(a.published_date))
    return resp.getStream.questionsetMadesOut
  }

  async pastDailiesSummaries() {
    let userId = this.client.userId
    const pastPlaysResp = await this.graphql({
      query: getUserDailiesSummaries,
      variables: {
        filter: {
          user_id: `= ${userId}`,
        },
        // playFilter: {
        //   finished: `= True`
        // }
      },
    })
    return pastPlaysResp.getUser.playStartedsOut?.filter(p => p.finished)
  }

  async pastDailies() {
    let userId = this.client.userId
    const pastPlaysResp = await this.graphql({
      query: getUserDailies,
      variables: {
        filter: {
          user_id: `= ${userId}`,
        },
      },
    })
    return pastPlaysResp.getUser.playStartedsOut.filter(p => p.finished).map(p => ({
      ...p,
      start_time: new Date(p.start_time),
    }))
  }


  async timesUp(playId, score, speedScore, correct, incorrect, rounds) {

    await this.graphql({
      mutation: updatePlay2,
      variables: {
        input: {
          _id: playId,
          score,
          speedScore,
          finished: true,
          correct,
          incorrect,
          rounds,
          // end_time: new Date().toISOString(), 
        }
      }
    })
  }


  async complete(playId, score) {
    await this.graphql({
      mutation: updatePlay2,
      variables: {
        input: {
          _id: playId,
          score,
          finished: true,
          // end_time: new Date().toISOString(), 
        }
      }
    })

    try {
      post({
        apiName: 'openaiRelay',
        path: '/stream/maybe-gen',
        options: {
          body: {
            playId,
          }
        }
      });
    } catch(e) {
      console.log('POST call failed', JSON.parse(e.response.body))
    }  
  }

  async thisWeeksSprintScores() {

    console.log({
      gameMode: "= endless",
      finished: "= True",
      weekInt: `= ${getWeekNumber()}`,
      yearInt: `= ${new Date().getFullYear()}`,
    })
    

    const resp = await this.graphql({
      mutation: listPlays,
      variables: {
        filter: {
          gameMode: "= endless",
          // these are not working
          // finished: "= True", 
          // weekInt: `= ${getWeekNumber()}`,
          // yearInt: `= ${new Date().getFullYear()}`,
        }
      }
    })

    return resp.listPlays
  }

  async started(questionSetId, gameMode) {
    let userId = this.client.userId

    const playResp = await this.graphql({
      mutation: createPlay2,
      variables: {
        input: {
          gameMode,
          start_time: new Date().toISOString(),
          yearInt: new Date().getFullYear(),
          monthInt: new Date().getMonth(),
          weekInt: getWeekNumber(),
          dayInt: new Date().getDate(),
        }
      }
    })
    const savedPlayId = playResp.createPlay._id

    await client.graphql({
      mutation: connectPlayToQuestionsetEdgeOf,
      variables: {
        from_id: `${savedPlayId}`,
        to_id: `${questionSetId}`,
      }
    })
    await client.graphql({
      mutation: connectUserToPlayEdgeStarted,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${savedPlayId}`,
      }
    })

    return savedPlayId
  }

  async attachQuestion(questionSetId, questionId) {

    await this.graphql({
      mutation: connectQuestionsetToQuestionnodeEdgeContains,
      variables: {
        from_id: `${questionSetId}`,
        to_id: `id = ${questionId}`,
      }
    })

  }

  async attachAnswer(playId, savedAnswerId) {
    await this.graphql({
      mutation: connectAnswerToPlayEdgeDuring,
      variables: {
        from_id: `${savedAnswerId}`,
        to_id: `${playId}`,
      }
    })

  }
}

class QuestionsClient {
  constructor(client) {
    this.client = client
  }
  async graphql(args) {
    return await this.client.graphql(args)
  }

  async questionsToReview() {
    const resp = await this.graphql({
      query: getUserQuestionsToReview2,
      variables: {
        filter: {
          user_id: `= ${this.client.userId}`,
        },
      },
    })
    return resp.getUserQuestionsToReview
  }

  async startThread(topic) {
    try {
      const restOperation = post({
        apiName: 'openaiRelay',
        path: '/relay/thread',
        options: {
          body: {
            topic,
          }
        }
      });
  
      const { body } = await restOperation.response;
      const response = await body.json();
  
      return response.thread
  
    } catch(e) {
      console.log('POST call failed', JSON.parse(e.response.body))
    }  
  }

  async newInThread(threadId) {
    try {
      const restOperation = post({
        apiName: 'openaiRelay',
        path: '/relay/next',
        options: {
          body: {
            threadId,
          }
        }
      });
  
      const { body } = await restOperation.response;
      const response = await body.json();
  
      return response.message
  
    } catch(e) {
      console.log('POST call failed', JSON.parse(e.response.body))
    }  
  }
}


class StreamsClient {
  constructor(client) {
    this.client = client
  }
  async graphql(args) {
    return await this.client.graphql(args)
  }

  async trending() {
    const resp = await this.graphql({
      query: getTag2,
      variables: {
        filter: {
          _id: '2e58698d-fb34-440c-a548-d746bbd110a9',
        },
      },
    })
    return resp.getTag
  }

  async average(streamName) {
    const resp = await this.graphql({
      query: getStreamAverageScore,
      variables: {
        filter: {
          name: `= ${streamName}`,
        },
      },
    })
    return resp.getStreamAverageScore
  }

  async myLeaderboardPlace(streamName) {
    const user_id = this.client.userId
    const resp = await this.graphql({
      query: getStreamLeaderboard,
      variables: {
        filter: {
          name: `= ${streamName}`,
        },
      },
    })
    const myPlace = resp.getStreamLeaderboard.findIndex(u => u.user_id === user_id)
    return myPlace + 1 // make it 1-indexed
  }

  async leaderboard(streamName) {
    const user_id = this.client.userId
    const resp = await this.graphql({
      query: getStreamLeaderboard,
      variables: {
        filter: {
          name: `= ${streamName}`,
        },
      },
    })
    resp.getStreamLeaderboard.myPlace = resp.getStreamLeaderboard.findIndex(u => u.user_id === user_id) + 1
    resp.getStreamLeaderboard.myELO = resp.getStreamLeaderboard.find(u => u.user_id === user_id)
    return resp.getStreamLeaderboard
  }

  async tag(tagId, streamId) {
      let userId = this.client.userId
  
      await client.graphql({
        mutation: connectTagToStreamEdgeTagged2,
        variables: {
          from_id: `${tagId}`,
          to_id: `${streamId}`,
        }
      })
        await this.graphql({
          mutation: updateEdgeTaggedFromTagToStream,
          variables: {
            from_id: `${tagId}`,
            to_id: `${streamId}`,
            edge: {
              createdAt: new Date().toISOString(),
            }
          }
        })
    }

    
    // const create = async (name) => {
    //   const nameLower = name.trim().toLowerCase()
    //   const resp = await this.graphql({
    //     query: createTag2,
    //     variables: {
    //       input: {
    //         createdAt: new Date().toISOString(),
    //         name: nameLower,
    //       },
    //     },
    //   })
    //   return resp.createTag
    // }
  // }

  async maybeGen(streamId) {
    try {
      post({
        apiName: 'openaiRelay',
        path: '/stream/maybe-gen',
        options: {
          body: {
            streamId: streamId,
          }
        }
      });
    } catch(e) {
      console.log('POST call failed', JSON.parse(e.response.body))
    }  
  }

  async saveSearch(searchText) {
    const resp = await this.graphql({
      mutation: createSearch2, 
      variables: {
        input: {
          text: searchText,
          createdAt: new Date().toISOString(),
        }
      }
    })

    const search = resp.createSearch

    await client.graphql({
      mutation: connectUserToSearchEdgeAsked,
      variables: {
        from_id: `user_id = ${this.client.userId}`,
        to_id: `${search._id}`,
      }
    })
  }

  async listRuns() {
    const resp = await this.graphql({
      query: listRuns2,
      variables: {
        // filter: {
        //   type: '= run',
        // },
      },
    })
    return resp.listRuns

  }

  async create(name) {
    const nameLower = name.trim().toLowerCase()
    const resp = await this.graphql({
      query: createStream2,
      variables: {
        input: {
          createdAt: new Date().toISOString(),
          name: nameLower,
        },
      },
    })
    return resp.createStream
  }

  async following() {
    let userId = this.client.userId
    const resp = await this.graphql({
      query: getUserFollowingStreams,
      variables: {
        filter: {
          user_id: `= ${userId}`,
        },
      },
    })
    return resp.getUser
  }

  async follow(streamId) {
    let userId = this.client.userId

    await client.graphql({
      mutation: connectUserToStreamEdgeFollowing,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${streamId}`,
      }
    })
    await this.addTimeToEdge(updateEdgeFollowingFromUserToStream, userId, streamId)
    await this.recordRequest(streamId)
  }

  async addTimeToEdge(mutation, userId, streamId) {
    await this.graphql({
      mutation: mutation,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${streamId}`,
        edge: {
          occuredAt: new Date().toISOString(),
        }
      }
    })
  }

  async recordRequest(streamId) {
    let userId = this.client.userId

    await client.graphql({
      mutation: connectUserToStreamEdgeRequested,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${streamId}`,
      }
    })
    await this.addTimeToEdge(updateEdgeRequestedFromUserToStream, userId, streamId)
  }

  async recordView(streamId) {
    let userId = this.client.userId

    await client.graphql({
      mutation: connectUserToStreamEdgeViewed,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${streamId}`,
      }
    })
    await this.addTimeToEdge(updateEdgeViewedFromUserToStream, userId, streamId)
  }

  async recordOpen(streamId) {
    let userId = this.client.userId

    await client.graphql({
      mutation: connectUserToStreamEdgeOpened,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${streamId}`,
      }
    })
    await this.addTimeToEdge(updateEdgeOpenedFromUserToStream, userId, streamId)
  }

  async recordStart(streamId) {
    let userId = this.client.userId

    await client.graphql({
      mutation: connectUserToStreamEdgeStarted,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${streamId}`,
      }
    })
    await this.addTimeToEdge(updateEdgeStartedFromUserToStream, userId, streamId)
  }

  async recordComplete(streamId) {
    let userId = this.client.userId

    await client.graphql({
      mutation: connectUserToStreamEdgeCompleted,
      variables: {
        from_id: `user_id = ${userId}`,
        to_id: `${streamId}`,
      }
    })
    await this.addTimeToEdge(updateEdgeCompletedFromUserToStream, userId, streamId)
  }

  async gen(streamName) {
    try {
      const restOperation = post({
        apiName: 'openaiRelay',
        path: '/stream/gen',
        options: {
          body: {
            streamName,
          }
        }
      });
  
      const { body } = await restOperation.response;
      const response = await body.json();
  
      return response.message
  
    } catch(e) {
      console.log('POST call failed', JSON.parse(e.response.body))
    }  

  }

  async make(streamTree) {
    const stream = await this.create(streamTree.name)

    this.follow(stream._id)
    
    try {
      const restOperation = post({
        apiName: 'openaiRelay',
        path: '/stream/create',
        options: {
          body: {
            topic: streamTree,
          }
        }
      });
  
      const { body } = await restOperation.response;
      const response = await body.json();
  
      return stream
  
    } catch(e) {
      console.log('POST call failed', JSON.parse(e.response.body))
    }  
  }

  async get(name) {
    const nameLower = name.trim().toLowerCase()
    const resp = await this.graphql({
      query: getStream2,
      variables: {
        filter: {
          name: `= ${nameLower}`
        },
      },
    })
    return resp.getStream
  }

  async list() {
    const resp = await this.graphql({
      query: listStreams2,
      // variables: {
      //   filter: {
      //     type: '= stream',
      //   },
      // },
    })
    return resp.listStreams
  }
  
}




export const client = new APIClient();
client.plays = new PlayClient(client);
client.topics = new TopicClient(client);
client.scores = new ScoreClient(client);
client.questions = new QuestionsClient(client);
client.streams = new StreamsClient(client);
