import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 0 + i;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "linear", duration: 7, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

const drawOuter = (delay, customDuration) => ({
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "linear", duration: customDuration, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    }
  });

export default function CorrectIncorrectAnimation({correct, isSelectedAnswer}) {

    const [color, setColor] = useState("text-slate-500");

    // after 400 ms, add the color class to the svg
    useEffect(() => {
        setTimeout(() => {
            setColor(c => (correct ? "text-green-500" : "text-red-500"));
        }, 400);
      }
    , [correct]);

if (correct) {
    const startDelay = isSelectedAnswer ? 0 : 0.3;
    return (
          <motion.svg
          width="40"
          height="40"
          viewBox="170 75 350 300"
          initial="hidden"
          animate="visible"
          strokeWidth={20}
          className={color + " -my-3 -mx-2"}
        >
          <motion.line
            x1="220"
            y1="230"
            x2="290"
            y2="300"
            stroke="currentColor"
            strokeLinecap={"round"}
            variants={drawOuter(startDelay, isSelectedAnswer ? 0.3 : 0.2)}
          />
          <motion.line
            x1="290"
            y1="300"
            x2="430"
            y2="160"
            stroke="currentColor"
            strokeLinecap={"round"}
            variants={drawOuter(startDelay + (isSelectedAnswer ? 0.3 : 0.2), isSelectedAnswer ? 0.2 : 0.1)}
          />
        </motion.svg>
      );
    }
else {
    return (
        <motion.svg
          width="40"
          height="40"
          viewBox="200 0 280 300"
          initial="hidden"
          animate="visible"
          strokeWidth={20}
          strokeLinecap={"round"}
          className={color + " -mr-3 -mt-1 -mb-3"}
        >
          <motion.line
            x1="220"
            y1="30"
            x2="290"
            y2="100"
            stroke="currentColor"
            variants={drawOuter(0, 0.3)}
          />
          <motion.line
            x1="290"
            y1="100"
            x2="360"
            y2="170"
            stroke="currentColor"
            variants={drawOuter(0.3, 0.2)}
          />
          <motion.line
            x1="290"
            y1="100"
            x2="360"
            y2="30"
            stroke="currentColor"
            variants={drawOuter(0.3, 0.2)}
          />
          <motion.line
            x1="290"
            y1="100"
            x2="220"
            y2="170"
            stroke="currentColor"
            variants={drawOuter(0.3, 0.2)}
          />
          </motion.svg>
      );
}
}
