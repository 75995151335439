import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";


import { WordAnimation } from "./WordAnimation";
import { TopicGenerator } from "./TopicGenerator";
import { Placeholder } from "@aws-amplify/ui-react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { TextAnim } from "./TypeWriter";
import { IoMdPlanet } from "react-icons/io";


export default function WelcomePage() {
  const [buttonClicked, setButtonClicked] = useState("none");

  const navigate = useNavigate();

  const [displayText, setDisplayText] = useState(false);
  const logClick = (event) => {
    window.gtag("event", event, {
      event_category: "engagement",
    });
  };

  const nextText = (text) => {
    if (text === "orcas") {
      return "nuclear bombs";
    }
    if (text === "nuclear bombs") {
      return "french cuisine";
    }
    if (text === "french cuisine") {
      return "orcas";
    }
  }

  const [placeholderText, setPlaceholderText] = useState("what's your curiosity?");
  const [building, setBuilding] = useState(false);

  const onSubmit = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    window.gtag("event", "welcomeStart", {
      event_category: "engagement",
    });
    setTimeout(() => {
      navigate("/onboard");
    }, 600); // Delay navigate by 500 milliseconds
  }

  // setTimeout(() => {
  //   setPlaceholderText(s => nextText(s));
  // }, 2000);

  return (
    <div className=" relative min-h-[100vh] right-0 left-0 text-slate-900">
      <div className="mt-[60px] z-10 relative flex flex-col items-center justify-center">
        <img
          className="h-[56px] w-[56px] mb-2"
          src={process.env.PUBLIC_URL + "/space_logo_transparent.png"}
        ></img>
        <div className="flex">
          <div className="inline-block [text-shadow:_1px_1px_0_rgb(255_116_81_)] dark:text-white text-4xl mb-5 font-logo font-bold	">AnyQ</div>
          {/* <WordAnimation text="and-" />
          <WordAnimation text="now-" />
          <WordAnimation text="you-" />
          <WordAnimation text="know-" /> */}
        </div>
        {/* <span 
                    className="[text-shadow:_1px_1px_0_rgb(255_116_81_)] text-4xl mb-5 font-logo font-bold		">anyq</span> */}
        <div className="mt-4 mb-[30px] dark:text-white">
          <div className="text-center text-xl font-logo mb-4 leading-9">
            learn <span className="hover:scale-125 text-blue-400 font-bold transition-all inline-block">anything</span>  <br></br>
            {/* play to  <span className="hover:scale-125 transition-all inline-block">learn</span>  <br></br> */}
            just by <span className="text-blue-400 hover:scale-125 font-bold transition-all inline-block">playing</span>  <br></br>
            with <span className="hover:scale-125 text-purple-400 font-bold transition-all inline-block">AI</span> as your guide <br></br><br></br>

            {/* <a className="text-blue-500 underline underline-offset-4">we</a> try to make it so  */}

            {/* Learning game for the <span className="font-bold text-orange-400">curious</span> */}
          </div>
          {/* <div className="text-center text-xl font-logo mb-8 leading-9">
            give answers  <br></br>
            get points<br></br>
            extend your streak<br></br>
          </div> */}
          {/* <div id="stream-container-2" className=" absolute left-0 right-0 mx-3 top-[250px] m-auto">
            <div className="mt-4 mb-3 mx-12 sm:w-80 sm:mx-auto text-purple-300 font-logo text-lg h-[40px] flex flex-col justify-center">
              {displayText && !building && (
                <>
                  <TextAnim delay={1} text="
                Anything you want to learn...
              "/>
                </>
              )}
              {building && (
                <>
                  <TextAnim delay={0} duration={2} text="
                Building your course...
              "/>
                </>
              )}
            </div>
            <div className="mx-4" onClick={() => setDisplayText(true)}>

              <TopicGenerator onBuilding={() => setBuilding(true)} onClear={() => setBuilding(false)} buttonText={"start"} onSubmit={onSubmit} displayedText={placeholderText} setUserStreams={() => { }} setTopicPage={() => { }} />
            </div>
          </div> */}
          <div className="flex flex-col">
           <Link to="/a/home">
          <button
            onClick={() => setButtonClicked("start")}
            id="play-button"
            className="font-logo overflow-hidden group active:scale-100 hover:scale-95 transition-all hover:bg-blue-500 bg-blue-500 dark:bg-opacity-50 text-white w-48 m-2 h-14 rounded-lg active:bg-blue-600 flex justify-center items-center"
          >
            <span>{buttonClicked == "start" ? <IoMdPlanet className="text-slate-400 text-lg animate-spin" /> : "Start"}</span>
          </button>
        </Link>
        <Link to="/auth">
          <button
            onClick={() => setButtonClicked("login")}
            id="login-button"
            className="font-logo active:scale-100 hover:scale-95 transition-all dark:bg-blue-500/20 hover:text-white hover:bg-blue-500 dark:active:bg-slate-800   active:bg-blue-300  bg-blue-200/50 backdrop-blur dark:text-white text-slate-900 w-48 m-2 h-14 rounded-lg flex justify-center items-center"
          >
            <span>{buttonClicked == "login" ? <IoMdPlanet className="text-slate-400 text-lg animate-spin" /> : "Log in"}</span>
          </button>
        </Link>
        <Link to="/a/home">
          <button
          onClick={() => setButtonClicked("howto")}
            id="howto-button"
            className="font-logo active:scale-100 hover:scale-95 dark:bg-opacity-50 transition-all dark:bg-blue-500/20 hover:text-white hover:bg-blue-500 dark:active:bg-slate-800  active:bg-blue-300  bg-blue-200/50 backdrop-blur dark:text-white text-slate-900 w-48 m-2 h-14 rounded-lg flex justify-center items-center"
          >
            <span>{buttonClicked == "howto" ? <IoMdPlanet className="text-slate-400 text-lg animate-spin" /> : "How this works"}</span>
          </button>
        </Link>
        </div>
          <div className="text-purple-300 px-6 font-logo absolute left-0 right-0 top-[300px] flex flex-col items-center">
          </div>
        </div>
        {/* <div className="mt-8 text-gray-300/80 font-logo italic">
          free for first 24 hours
        </div> */}
        {/* <div className="mt-8 text-gray-300/80 font-logo italic">
          free for first 24 hours
        </div>
        <div className="text-gray-300/80 font-logo italic">
          $5/month minimum after that
        </div> */}
        {/* <div className="mt-1 text-gray-300/80 font-logo italic">
          AI ain't cheap
        </div> */}
      </div>
      <div className="bg-animation fixed top-0 bottom-0 right-0 left-0">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
      </div>
        <footer className="absolute bottom-0 left-0 right-0 text-center text-sm text-gray-400/80 ">
          © 2024 anyq, inc |
          privacy policy | terms of service
        </footer>
    </div>
  );
}
